import store from '../store';

export const getLogguedUser = () => {
    const state = store.getState();
    const userState = state.users;
    const authState = state.auth;
    const logguedUser = userState.list.find(user => user.id === authState.user_id);
    return logguedUser;
};

export const getLogguedUserEmployeeList = () => {
    const currentUser = getLogguedUser();

    const state = store.getState();
    const employeeState = state.employees;

    const employeeListIds = currentUser.is_multi_employee === 1 ? employeeState.all.map(e => e.id) : employeeState.team.map(e => e.id);
    return employeeListIds;
};

export const hasAccessToPlanning = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 1) > 0;
};

export const hasAccessToEmployeeDoc = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 2) > 0;
};

export const hasAccessToDayOff = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 4) > 0;
};

export const hasAccessToMaterial = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 16) > 0;
};

export const hasAccessToSite = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 32) > 0;
};

export const hasAccessToIntervention = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 8) > 0;
};

export const hasAccessToPlanningValidation = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return user.is_valid;
};

export const hasAccessToValidation = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 64) > 0;
};

export const hasAccessToSitePlanning= () => {
    const user = getLogguedUser();
    if (!user) return false;

    if (getLogguedUserEmployeeList().length > 1) return true;

    return (user.module_access_mask & 128) > 0;
};

export const hasAccessToConsumption= () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 256) > 0;
};

export const hasAccessToDepositTicket = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 512) > 0;
};

export const hasAccessToSiteTicket = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 1024) > 0;
};

export const hasAccessToSiteValidation = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 2048) > 0;
};

export const hasAccessToSupply= () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 4096) > 0;
};

export const hasAccessToSecurity= () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 8192) > 0;
};

export const hasAccessToDangerousSituation= () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 16384) > 0;
};

export const hasAccessToDocMaterial = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 32768) > 0;
};

export const hasAccessToOdometer = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return (user.module_access_mask & 65536) > 0;
};

export const canValidEvent = () => {
    const user = getLogguedUser();
    if (!user) return false;
    return user.is_valid;
};

export const canCreateTicketWithoutCommand = () => {
    const company = store.getState().app.company;
    const version_info = getOptimFileVersion(company.optim_file_version);
    if (!(version_info[0] >= 23 && version_info[1] >= 3)) return false;
    const articles = store.getState().articles.all;
    return hasAccessToSiteTicket() && (articles.length > 0);
};


export const canSetPrestationQuantity = () => {
    const company = store.getState().app.company;
    const version_info = getOptimFileVersion(company.optim_file_version);
    
    if (version_info[0] >= 23 && version_info[1] >= 2) {
        return true;
    }
    return false;
};

export const getOptimFileVersion = (version) => {
    let version_info;

    if (version.charAt(0) !== 'A') {
        version_info = [22, 1];
    } else {
        version_info = version.substr(1).split('.');
    }

    return version_info;
};