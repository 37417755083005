import React from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment-business-days';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

const Dayoff = props => {
    const total = props.dayoff.total_ca + props.dayoff.total_rtt + props.dayoff.total_rc;
    const { t } = useTranslation();

    const confirmDelete = () =>  {
        confirmDialog({
            message: t('app:really_remove_dayoff'),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                props.delete();
            },
            reject: () => {}
        });
    };

    return <div className="dayoff_row p-shadow-1">
        <div className="inside_text">
            <strong>{t('app:dayoff_desc', {'sum': total}) }</strong><br/>
            {t('app:go_date')} : <strong>{moment(props.dayoff.start_date).format('DD/MM/YYYY')}</strong><br/>
            {t('app:back_date')} : <strong>{moment(props.dayoff.end_date).format('DD/MM/YYYY')}</strong><br/>
            <span className="update">
                {t('app:create_at')}: {moment(props.dayoff.created_at).format('DD/MM/YYYY HH:mm')}
            </span>
        </div>
        <Button className="p-button-link" onClick={confirmDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
    </div>;
};

export default Dayoff;