import { addImageToStore, addToStoreAndCache, deleteStoreAndCache, updateInStoreAndCache } from '../offline/helper';

import axios from 'axios';
import i18n from 'i18next';
import store from '../../store';
import { toast } from 'react-toastify';

export const setSelectedMaterial = material => ({
    type: 'SET_SELECTED_MATERIAL',
    payload: {
        material
    }
});

export const getMaterialSuccess = materials => ({
    type: 'MATERIALS_RECEIVED',
    payload: {
        materials
    }
});

export const createSendPictureSuccess = () => toast.success(i18n.t('app:add_picture_success'));
export const createSendPictureError = () => toast.error(i18n.t('app:cannot_update_picture'));

export const pictureSentWithSuccess = picture => ({
    type: 'MATERIAL_PICTURE_SENT',
    payload: picture
});

export const sendMaterialPicture = (material_id, file, folder_id) => dispatch => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('material_id', material_id);
    if (folder_id) {
        formData.append('folder_id', folder_id);
    }
    return axios.post('/pictures/upload_picture', formData)
        .then(res => {
            createSendPictureSuccess();
            dispatch(pictureSentWithSuccess(res.data));
        })
        .catch(err => createSendPictureError(err));
};

export const materialEventUpdated = depositTicket => ({
    type: 'MATERIAL_EVENT_EDITED',
    payload: depositTicket
});

export const materialEventDeleted = depositTicketId => ({
    type: 'MATERIAL_EVENT_DELETED',
    payload: depositTicketId
});

export const materialEventCreated = depositTicket => ({
    type: 'MATERIAL_EVENT_CREATED',
    payload: depositTicket
});

export const materialEventCreateSuccess = () => toast.success(i18n.t('app:material_event_created'));
export const updateMaterialEventSuccess = () => toast.success(i18n.t('app:material_event_edited'));
export const deleteMaterialEventSuccess = () => toast.success(i18n.t('app:material_event_deleted'));

export const materialEventCreateError = () => toast.error(i18n.t('app:cannot_create_material_event'));
export const updateMaterialEventError = () => toast.error(i18n.t('app:cannot_update_material_event'));
export const deleteMaterialEventError = () => toast.error(i18n.t('app:cannot_delete_material_event'));

export const picturesSentWithSuccess = (files) => ({
    type: 'MATERIAL_EVENT_PICTURE_SENT',
    payload: files
});


export const createMaterialEvent = (params, files) => async (dispatch) => {
    const isOfflineMode = store.getState().core.offlineMode;
    let promise ;
    if(isOfflineMode) {
        promise = await addToStoreAndCache('materialEvent', params, (id) => {
            dispatch(materialEventCreated(params));
            files.forEach(file => {
                const formdata = new FormData();
                formdata.append('file', file.file);
                formdata.append('comment', file.desc);
                formdata.append('event_id', id);
                addImageToStore({
                    eventId: id,
                    file: file
                }, () => {});
            });
        });
    } else {
        promise = axios.post('/material_event', {...params})
            .then(data => {
                dispatch(materialEventCreated(data.data));
                const axiosCalls = [];
                files.forEach(file => {
                    const formdata = new FormData();
                    formdata.append('file', file.file);
                    formdata.append('comment', file.desc);
                    formdata.append('material_event_id', data.data.id);
                    axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
                });
                return Promise.all(axiosCalls);
            });
    }
    return promise.then(data => {
        materialEventCreateSuccess();
        if (files.length > 0) {
            dispatch(picturesSentWithSuccess(data));
        }
    }).catch(err => materialEventCreateError(err));
};

export const deleteMaterialEvent = (id) => (dispatch) => {
    const isOfflineMode = store.getState().core.offlineMode;
    if(isOfflineMode) {
        deleteStoreAndCache('material_events', id, () => {
            deleteMaterialEventSuccess();
            dispatch(materialEventDeleted(id));
        });
    } else {
        return axios.delete(`/material_event/${id}`)
            .then(() => {
                deleteMaterialEventSuccess();
                dispatch(materialEventDeleted(id));
            })
            .catch(err => deleteMaterialEventError(err));
    }
};

export const updateMaterialEvent = (params, files = []) => (dispatch) => {
    const isOfflineMode = store.getState().core.offlineMode;
    if(isOfflineMode) {
        updateInStoreAndCache('material_events', params, () => {
            dispatch(materialEventUpdated());
            updateMaterialEventSuccess();
            if (files.length > 0) {
                files.forEach(file => {
                    addImageToStore({
                        materialEventId: params.id,
                        file: file.file,
                        comment:file.desc
                    }, () => {});
                });
            }
        });
    } else {
        return axios.patch(`/material_event/${params.id}`, params)
            .then(data => {
                dispatch(materialEventUpdated(data.data));
                const axiosCalls = [];
                files.forEach(file => {
                    const formdata = new FormData();
                    formdata.append('file', file.file);
                    formdata.append('comment', file.desc);
                    formdata.append('material_event_id', data.data.id);
                    axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
                });
                return Promise.all(axiosCalls);
            })
            .then(data => {
                updateMaterialEventSuccess();
                if (files.length > 0) {
                    dispatch(picturesSentWithSuccess(data));
                }
            })
            .catch(err => updateMaterialEventError(err));
    }
};
