import React, { useState } from 'react';
import { faCalendarPlus, faShieldCheck, faUmbrellaBeach } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';

import PopupCreateEvent from '../../events/components/PopupCreateEvent';
import { hasAccessToDangerousSituation, hasAccessToDayOff, hasAccessToOdometer, hasAccessToSecurity } from '../../../utils/access';
import FormDayoff from '../../dayoff/components/FormDayoff';
import useRouter from '../../../utils/hooks/useRouter';
import FormMaterialEvent from '../../materials/components/FormMaterialEvent';
import { faTachometerAltSlow } from '@fortawesome/pro-duotone-svg-icons';
import FormSecurityReport from '../../securityReports/components/FormSecurityReport';

const EmployeeOverlayCreate= props => {
    const { t } = useTranslation();
    const [showCreateEventPopup, setShowCreateEventPopup] = useState(false);
    const [showDayOffPopup, setShowDayOffPopup] = useState(false);
    const [showFormMaterialEvent, setShowFormMaterialEvent] = useState(false);
    const [showSecurityReport, setShowSecurityReport] = useState(false);

    const router = useRouter();

    let optionShownForCurrentPath = [];
    switch(router.location.pathname) {
        case '/app/employees/event/day':
        case '/app/employees/event/week':
            optionShownForCurrentPath = ['event'];
            break;
        case '/app/employees/material':
            optionShownForCurrentPath = ['materialevent'];
            break;
        case '/app/employees/document':
            optionShownForCurrentPath = [];
            break;
        case '/app/employees/dayoff':
            optionShownForCurrentPath = ['dayoff'];
            break;
        case '/app/employees/security':
            optionShownForCurrentPath = ['security'];
            break;
        default:
            optionShownForCurrentPath = ['event', 'dayoff'];
            break;
    }

    return <div className={`${props.show ? 'show' : ''} overlay-creation`}>
        <div className="action-zone">
            <>
                <div className="label">{t('app:iwant')}</div>
                {
                    optionShownForCurrentPath.includes('event') ?
                        <Button onClick={() => {
                            setShowCreateEventPopup(true);
                            props.onClose();
                        }}>
                            <FontAwesomeIcon icon={faCalendarPlus} />
                            { t('app:create_event') }
                        </Button> : null
                }
                {
                    hasAccessToDayOff() && optionShownForCurrentPath.includes('dayoff') ?
                        <Button onClick={() => {
                            setShowDayOffPopup(true);
                            props.onClose();
                        }}>
                            <FontAwesomeIcon icon={faUmbrellaBeach} />
                            { t('app:create_dayoff') }
                        </Button> : null
                }
                {
                    hasAccessToOdometer() && optionShownForCurrentPath.includes('materialevent') ?
                        <Button onClick={() => {
                            setShowFormMaterialEvent(true);
                            props.onClose();
                        }}>
                            <FontAwesomeIcon icon={faTachometerAltSlow} />
                            { t('app:create_odometer') }
                        </Button> : null
                }
                {
                    (hasAccessToSecurity() || hasAccessToDangerousSituation())&& optionShownForCurrentPath.includes('security') ?
                        <Button onClick={() => {
                            setShowSecurityReport(true);
                            props.onClose();
                        }}>
                            <FontAwesomeIcon icon={faShieldCheck} />
                            {t('app:create_security_report')}
                        </Button> : null
                }
            </>
        </div>
        <PopupCreateEvent
            show={showCreateEventPopup}
            onClose={() => {setShowCreateEventPopup(false);}}
        />
        <FormMaterialEvent
            opened={showFormMaterialEvent}
            onClose={() => setShowFormMaterialEvent(false)}
        />
        <FormDayoff
            open={showDayOffPopup}
            onClose={() => {setShowDayOffPopup(false);}}
        />
        <FormSecurityReport
            mode='site'
            opened={showSecurityReport}
            onClose={() => { setShowSecurityReport(false); }}
        />
    </div>;
};

export default EmployeeOverlayCreate;
