import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SiteSupplyRow from './SiteSupplyRow';

import FormCreateOrViewSupply from './FormCreateOrViewSupply';
import { updateSite } from '../../app/updateApp';

import noSupply from '../../../assets/no-supply.png';

const SiteSupply= () => {
    const { t } = useTranslation();

    useEffect(function () {
        updateSite();
    }, []);

    const [popupOpen, setPopupOpen] = React.useState(false);
    const [supplyOrderShown, setSupplyOrderShown] = React.useState(null);
    const siteState = useSelector(state => state.sites);

    const selectedSite = siteState.list.find(s =>
        s.id === siteState.selectedSite
    );

    const showNoData = selectedSite.supplies.length === 0;
    const supplyInProgress = selectedSite.supplies.filter(s => s.sent === 0);
    const supplySent = selectedSite.supplies.filter(s => s.sent === 1);

    return (
        <div className="module-supply">
            { showNoData &&
                <div className="no-supply">
                    <img src={noSupply} />
                    <div className="label">{t('app:no_supply')}</div>
                </div>
            }
            <div className="supplies">
                {
                    supplyInProgress.map(s =>
                        <SiteSupplyRow
                            key={s.id}
                            open={() => {
                                setSupplyOrderShown(s);
                                setPopupOpen(true);
                            }}
                            supply={s}
                        />
                    )
                }
                { (supplySent.length > 0) && <div className="previous_label"> { t('app:previous_site_supply') } : </div> }
                {
                    supplySent.map(s =>
                        <SiteSupplyRow
                            key={s.id}
                            open={() => {
                                setSupplyOrderShown(s);
                                setPopupOpen(true);
                            }}
                            supply={s}
                        />
                    )
                }
            </div>
            <FormCreateOrViewSupply
                onClose={() => setPopupOpen(false)}
                opened={popupOpen}
                supplyOrder={supplyOrderShown}
            />
        </div>
    );
};

export default SiteSupply;
