import '../style.scss';

import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import DocumentRow from './DocumentRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PictureRow from '../../pictures/PictureRow';
import React from 'react';
import Uploader from '../../uploader';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import noDoc from '../../../assets/no-doc.png';
import { useTranslation } from 'react-i18next';

const FileBrowser = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [folder, setFolder] = React.useState(null);
    const folders = useSelector(state => state.folders.list
        .filter(f => f.type === props.resourceType)
        .filter(f => !f.default_folder)
    );
    const defaultFolder = useSelector(state => state.folders.list
        .filter(f => f.type === props.resourceType)
        .find(f => f.default_folder)
    );
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);

    const currentUser = userState.list.find(user => user.id === authState.user_id);

    let files = [];
    if (folder === null) {
        files = props.selectedResource?.files
            .filter(f => f.file_access_level <= currentUser.file_access_level)
            .filter(f => (f.folder ===  null) || (f.folder.id === defaultFolder?.id));
    } else {
        files = props.selectedResource?.files
            .filter(f => f.file_access_level <= currentUser.file_access_level)
            .filter(f => f.folder?.id ===  folder?.id);
    }

    const pictures = props.selectedResource?.pictures?.filter(p => {
        if (p.folder_id === null && folder === null) return true;
        if (folder === null) return false;
        if (p.folder_id === folder.id) return true;
    });

    let showNoData = true;
    if (files && files.length > 0) showNoData = false;
    if (pictures && pictures.length > 0) showNoData = false;

    return (
        <div className={`file-browser folder-${props.title}`}>
            {
                folders.length === 0
                    ? null :
                    folder === null ?
                        <>
                            {folders.sort((a, b) => {
                                if (a.order > b.order) return 1;
                                if (a.order < b.order) return -1;
                                return 0;
                            }).map(folder =>
                                <Button key={folder.id} onClick={() => setFolder(folder)} variant="contained" className="button-folder p-shadow-1">
                                    <FontAwesomeIcon icon={faFolder} className="folder"/>
                                    {folder.title}
                                    <FontAwesomeIcon icon={faChevronRight} className="arrow"/>
                                </Button>
                            )}
                        </>
                        : <>
                            <Button onClick={() => setFolder(null)} className="button-folder-back p-button-link">
                                <FontAwesomeIcon icon={faChevronLeft} className="back"/>
                                {folder.title}
                            </Button>
                        </>
            }
            {props.sendPicture &&
                <div className="send-picture-wrapper">
                    <Uploader sendPicture={file => dispatch(props.sendPicture(file, folder?.id))} />
                </div>
            }
            {
                files?.length > 0 &&
                    files.sort((a, b) => {
                        if(a.extension > b.extension) return 1;
                        if(a.extension > b.extension) return -1;
                        return 0;
                    })
                        .sort((a, b) => {
                            if (a.title > b.title) return 1;
                            if (a.title < b.title) return -1;
                            return 0;
                        })
                        .map(file => {
                            if (file.extension.toLowerCase() === 'pdf') {
                                return <DocumentRow key={file.id} file={file}/>;
                            }
                            if (file.extension.toLowerCase() === 'png' || file.extension.toLowerCase() === 'jpg' || file.extension.toLowerCase() === 'jpeg' ) {
                                return <PictureRow key={file.id} file={file}/>;
                            }
                            return null;
                        })
            }
            {
                pictures?.map(p =>
                    <PictureRow key={p.id} picture={p} />
                )
            }
            {
                showNoData &&
                    <div className="no-data">
                        <img src={noDoc} />
                        <div className="label">{t('app:no_document')}</div>
                    </div>
            }
        </div>
    );
};

export default FileBrowser;
