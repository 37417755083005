// actions
import { createAskMaterialOrder, updateAskMaterialOrder } from '../actions';
import { faArrowLeft, faConstruction, faCopy, faMinus, faPaperPlane, faSave, faWarehouseAlt } from '@fortawesome/pro-solid-svg-icons';
import { faChevronLeft, faChevronRight, faPlus } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
// components
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormSearchMaterialType from '../../materialTypes/FormSearchMaterialType';
import { InputTextarea } from 'primereact/inputtextarea';
// libs
import React from 'react';
import { addLocale } from 'primereact/api';
import moment from 'moment-business-days';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../../utils/components/NumberInput';

addLocale('fr', {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
    monthNamesShort: ['jan', 'fev', 'mar', 'avr', 'mai', 'juin', 'juil', 'aout', 'sept', 'oct', 'nov', 'dec'],
    today: 'Aujourd\'hui',
    clear: 'Effacer'
});

const FormCreateOrViewAskMaterial = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const siteState = useSelector(state => state.sites);
    const { opened, askMaterial, onClose } = props;
    const site = siteState.list.find(s => s.id === siteState.selectedSite);
    const [showFormMaterialTypes, setShowFormMaterialTypes] = React.useState(false);

    const [disabled, setDisabled] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [sent, setSent] = React.useState(null);
    const [askMaterialDate, setAskMaterialDate] = React.useState(moment().businessAdd(1, 'day'));
    const [note, setNote] = React.useState('');
    const [returnDate, setReturnDate] = React.useState(moment().businessAdd(1, 'day'));
    const [selectedMaterialTypes, setSelectedMaterialTypes] = React.useState([]);
    const [supplyLocation, setSupplyLocation] = React.useState('site');

    const { offlineMode: isOfflineMode } = useSelector(state => state.core);

    const allMaterialTypes = useSelector(state => state.materialTypes.all);
    const selectedMaterialTypesKeys = Object.keys(selectedMaterialTypes);
    const selectedMaterialTypesValues = selectedMaterialTypesKeys.map(k => selectedMaterialTypes[k].value);

    const groups = {};

    allMaterialTypes.forEach(a => {
        if (groups[a.group] === undefined) {
            groups[a.group] = [];
        }
        groups[a.group].push(a);
    });

    const groupLabels = Object.keys(groups);
    const materialTypesOptions = groupLabels.sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }).map(groupLabel => {
        return {
            label: groupLabel,
            items: groups[groupLabel]
        };
    });

    const onDupplicate = () => {
        setId(null);
        setSent(false);
        setAskMaterialDate(moment().businessAdd(1, 'day'));
        setDisabled(false);
    };

    const validForm = (sent) => {
        const selectedMaterialTypesKeys = Object.keys(selectedMaterialTypes);
        const params = {
            note: note,
            material_types: selectedMaterialTypesKeys
                .map(k => {
                    return {
                        'id': selectedMaterialTypes[k].id,
                        'count': selectedMaterialTypes[k].count,
                    };
                }),
            date: askMaterialDate.format('YYYY-MM-DD'),
            return_date: returnDate.format('YYYY-MM-DD'),
            site_id: site.id,
            location: supplyLocation,
            sent: sent ? 1 : 0
        };
        if (params.material_types.length === 0) {
            toast.error(t('app:cannot_create_ask_material_without_article'));
            return;
        }
        if (id) {
            params.id = id;
            dispatch(updateAskMaterialOrder(params));
            if (props.callback) {
                props.callback();
            }
        } else {
            dispatch(createAskMaterialOrder(params));
            if (props.callback) {
                props.callback();
            }
        }
        onClose();
    };

    const updateAskMaterialDate = (date) => {
        setAskMaterialDate(date);
    };

    const updateReturnDate = (date) => {
        setReturnDate(date);
    };

    React.useEffect(() => {
        if (opened && !sent && !askMaterial) {
            setDisabled(false);
            setAskMaterialDate(moment().businessAdd(1, 'day'));
        }
    }, [opened, sent, askMaterial]);

    React.useEffect(function () {
        if (opened) {
            if (askMaterial) {
                setId(askMaterial.id);
                setSent(askMaterial.sent);
                setNote(askMaterial.note);
                setSupplyLocation(askMaterial.location);
                setAskMaterialDate(moment(askMaterial.date));
                setReturnDate(moment(askMaterial.return_date));
                const _material_types = {};
                askMaterial.material_types.forEach(a => {
                    _material_types[a.id] = {
                        id: a.id,
                        code: a.code,
                        unit: a.unit,
                        label: a.label,
                        count: a.pivot.count
                    };
                });
                setSelectedMaterialTypes(_material_types);
                setDisabled(!!askMaterial.sent);
            } else {
                setId(null);
                setSent(false);
                setNote('');
                setSupplyLocation('site');
                setAskMaterialDate(moment().businessAdd(1, 'day'));
                setReturnDate(moment().businessAdd(1, 'day'));
                setSelectedMaterialTypes([]);
                setDisabled(false);
            }
        }
    }, [askMaterial, opened]);

    const addArticle = material_type => {
        const _selectedMaterialTypes = Object.assign({}, selectedMaterialTypes);
        if (_selectedMaterialTypes[material_type.id]) {
            _selectedMaterialTypes[material_type.id].count = parseFloat(_selectedMaterialTypes[material_type.id].count) + 1;
        } else {
            _selectedMaterialTypes[material_type.id] = {
                id: material_type.id,
                code: material_type.code,
                count: 1,
                label: material_type.label
            };
        }
        setSelectedMaterialTypes(_selectedMaterialTypes);
    };

    const changeSelectedArticles = articleIds => {
        const _selectedMaterialTypes = Object.assign({}, selectedMaterialTypes);
        const selectedMaterialTypesKeys = Object.keys(selectedMaterialTypes);

        // remove missing materialTypes
        selectedMaterialTypesKeys.forEach(k => {
            if (!articleIds.includes(selectedMaterialTypes[k].id)) {
                delete (_selectedMaterialTypes[k]);
            }
        });

        // add new materialTypes
        articleIds.forEach(id => {
            if (!selectedMaterialTypesValues.includes(id)) {
                const article = allMaterialTypes.find(a => a.id === id);
                _selectedMaterialTypes[article.id] = {
                    count: 1,
                    id: article.id,
                    code: article.code,
                    unit: article.unit,
                    label: article.label
                };
            }
        });

        setSelectedMaterialTypes(_selectedMaterialTypes);
    };

    const substractArticle = article => {
        const _selectedMaterialTypes = Object.assign({}, selectedMaterialTypes);
        if (_selectedMaterialTypes[article.id]) {
            if (_selectedMaterialTypes[article.id].count === 1) {
                delete _selectedMaterialTypes[article.id];
            } else {
                _selectedMaterialTypes[article.id].count = Math.max(0, _selectedMaterialTypes[article.id].count - 1);
            }
            setSelectedMaterialTypes(_selectedMaterialTypes);
        }
    };

    const changeArticleCount = (article, count) => {
        const _selectedMaterialTypes = Object.assign({}, selectedMaterialTypes);
        let _count = parseInt(count);
        if (isNaN(_count)) _count = '';
        if (_count === 0) {
            delete _selectedMaterialTypes[article.id];
        } else {
            _selectedMaterialTypes[article.id] = {
                id: article.id,
                code: article.code,
                unit: article.unit,
                count: _count,
                label: article.label
            };
        }
        setSelectedMaterialTypes(_selectedMaterialTypes);
        return _selectedMaterialTypes;
    };

    return (
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={onClose} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div className="title">
                        {id ?
                            t('app:see_a_ask_material') :
                            t('app:add_a_ask_material')
                        }
                    </div>
                    {
                        !isOfflineMode && !sent ?
                            <Button
                                onClick={() => validForm(true)}
                                className={'p-button-link create-event-button'}>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </Button> : null
                    }
                    {
                        !sent ?
                            <Button
                                onClick={() => validForm(false)}
                                className={'p-button-link create-event-button'}>
                                <FontAwesomeIcon icon={faSave} />
                            </Button> : null
                    }
                    {
                        askMaterial ?
                            <Button
                                onClick={onDupplicate}
                                className={'p-button-link create-event-button'}>
                                <FontAwesomeIcon icon={faCopy} />
                            </Button> : null
                    }
                </div>
            </div>
            <div className="day-navigation supply">
                <Button disabled={disabled} size="small" className="p-button-link" onClick={() => updateAskMaterialDate(moment(askMaterialDate).clone().subtract(1, 'days'))}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <div className="currentDay">
                    <Calendar
                        locale="fr"
                        disabled={disabled}
                        dateFormat="DD dd/mm/yy"
                        value={askMaterialDate.toDate()}
                        onChange={date => setAskMaterialDate(moment(date.value))}
                    />
                </div>
                <Button disabled={disabled} size="small" className="p-button-link" onClick={() => updateAskMaterialDate(moment(askMaterialDate).clone().add(1, 'days'))}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
            <div className="label">
                {t('app:ask_material_location')} :
            </div>
            <div className="type-selector">
                <div
                    onClick={() => disabled ? null : setSupplyLocation('site')}
                    className={supplyLocation === 'site' ? 'selected' : ''}
                >
                    <FontAwesomeIcon icon={faConstruction} />
                    {t('common:site')}
                </div>
                <div
                    onClick={() => disabled ? null : setSupplyLocation('deposit')}
                    className={supplyLocation === 'deposit' ? 'selected' : ''}
                >
                    <FontAwesomeIcon icon={faWarehouseAlt} />
                    {t('common:deposit')}
                </div>
            </div>
            <div className="label">
                {t('app:return_date')} :
            </div>
            <div className="day-navigation supply">
                <Button disabled={disabled} size="small" className="p-button-link" onClick={() => updateReturnDate(moment(returnDate).clone().subtract(1, 'days'))}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <div className="currentDay">
                    <Calendar
                        locale="fr"
                        disabled={disabled}
                        dateFormat="DD dd/mm/yy"
                        value={returnDate.toDate()}
                        onChange={date => setReturnDate(moment(date.value))}
                    />
                </div>
                <Button disabled={disabled} size="small" className="p-button-link" onClick={() => updateReturnDate(moment(returnDate).clone().add(1, 'days'))}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
            <div className="article-list p-shadow-2">
                <p className="select_title">
                    {t('common:articles')}
                </p>
                <div className="available-articles">
                    <Button className="button-add-article p-button-link" onClick={() => setShowFormMaterialTypes(true)}>
                        {t('app:add_articles')}
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <FormSearchMaterialType
                        updateSelectedArticles={setSelectedMaterialTypes}
                        opened={showFormMaterialTypes}
                        onClose={() => setShowFormMaterialTypes(false)}
                        selectedArticles={selectedMaterialTypes}
                        options={materialTypesOptions}
                        onChange={e => changeSelectedArticles(e.value)}
                    />
                </div>
                {selectedMaterialTypesKeys.length > 0 ?
                    <div className="selected-article-list">
                        {selectedMaterialTypesKeys.map(k => {
                            const art = selectedMaterialTypes[k];
                            return <div key={art.id}><div className="article-row">
                                <div className="title">
                                    {`${art.code} > ${art.label.length > 50 ? art.label.substr(0, 50) + '...' : art.label} [${art.unit}]`}
                                </div>
                                <div className="counter">
                                    {!disabled ?
                                        <Button
                                            className="counter-button p-button-link"
                                            onClick={() => substractArticle(art)}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                        : null
                                    }
                                    <div className="article-count">
                                        <NumberInput
                                            disabled={disabled}
                                            value={art.count}
                                            onValueChange={e => changeArticleCount(art, e.value)}
                                        />
                                    </div>
                                    {!disabled ?
                                        <Button
                                            className="counter-button p-button-link"
                                            onClick={() => addArticle(art)}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                        : null
                                    }
                                </div></div>
                            </div>;
                        }
                        )}
                    </div>
                    : null
                }
            </div>
            <div className="label">
                {t('common:note')}
            </div>
            <InputTextarea
                className="note"
                disabled={disabled}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                rows={4}
            />
        </Dialog>
    );
};

export default FormCreateOrViewAskMaterial;
