const initialState = () => ({
    list: [],
    selectedMaterial: null
});

const materialsReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    let lsMatId;
    switch (action.type) {
        case 'SET_SELECTED_MATERIAL':
            newState.selectedMaterial = action.payload.material;
            localStorage.setItem('selected_material', newState.selectedMaterial);
            return newState;
        case 'MATERIALS_RECEIVED':
            newState.list = action.payload.materials;
            lsMatId = localStorage.getItem('selected_material', null);
            if ( lsMatId ) {
                newState.selectedMaterial = parseInt(lsMatId);
            }
            return newState;
        case 'PICTURE_UPDATED':
            if (!action.payload.material_id) return state;
            newState.list.forEach((m, idx) => {
                if (m.id === action.payload.material_id) {
                    newState.list[idx].pictures.forEach((p, m_idx) => {
                        if (newState.list[idx].pictures[m_idx].id === action.payload.id) {
                            newState.list[idx].pictures[m_idx].comment = action.payload.comment;
                        }
                    });
                }
            });
            return newState;
        case 'PICTURE_REMOVE_SUCCESS':
            if (action.payload.material_id) {
                newState.list.forEach((m, idx) => {
                    if (m.id === action.payload.material_id) {
                        newState.list[idx].pictures.forEach((p, m_idx) => {
                            if (newState.list[idx].pictures[m_idx].id === action.payload.id) {
                                newState.list[idx].pictures.splice(m_idx, 1);
                            }
                        });
                    }
                });
                return newState;
            }
            if (action.payload.material_event_id) {
                newState.list.forEach((m, idx1) => {
                    m.material_events.forEach((me, idx2) => {
                        if (me.id === action.payload.material_event_id) {
                            me.pictures.forEach((p, p_idx) => {
                                if (p.id === action.payload.id) {
                                    newState.list[idx1].material_events[idx2].pictures.splice(p_idx, 1);
                                }
                            });
                        }
                    });
                });
                return newState;
            }
            return state;
        case 'MATERIAL_PICTURE_SENT':
            newState.list.forEach((material, idx_material) => {
                if (parseInt(material.id) === parseInt(action.payload.material_id)) {
                    newState.list[idx_material].pictures.push(action.payload);
                }
            });
            return newState;
        case 'MATERIAL_EVENT_PICTURE_SENT':
            newState.list.forEach((m, idx_material) => {
                m.material_events.forEach((me, idx_me) => {
                    if (me.id === action.payload[0].data.material_event_id) {
                        action.payload.forEach(row => {
                            newState.list[idx_material].material_events[idx_me].pictures.push(row.data);
                        });
                    }
                });
            });
            return newState;
        case 'MATERIAL_EVENT_CREATED':
            newState.list.forEach((m, idx_material) => {
                if (m.id === action.payload.material_id) {
                    newState.list[idx_material].material_events.push(action.payload);
                }
            });
            return newState;
        case 'MATERIAL_EVENT_EDITED':
            newState.list.forEach((m, idx_material) => {
                if (m.id === action.payload.material_id) {
                    m.material_events.forEach((me, idx_me) => {
                        if (me.id === action.payload.id) {
                            newState.list[idx_material].material_events[idx_me] = action.payload;
                        }
                    });
                }
            });
            return newState;
        case 'MATERIAL_EVENT_DELETED':
            newState.list.forEach((m, idx_material) => {
                m.material_events.forEach((me, idx_me) => {
                    if (me.id === action.payload) {
                        newState.list[idx_material].material_events.splice(idx_me, 1);
                    }
                });
            });
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default materialsReducer;
