import './styles.scss';

// libs
import React, { useEffect } from 'react';
import { createAttachment, updateAttachment } from './actions';
import { faCheck, faMinus, faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import Canvas from '../signature/SignatureCanva';
import DayPicker from '../events/components/DayPicker';
// actions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import PictureRow from '../pictures/PictureRow';
import PictureRowPreview from '../pictures/PictureRowPreview';
import Uploader from '../uploader';
// components
import { addLocale } from 'primereact/api';
import moment from 'moment-business-days';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../utils/components/NumberInput';

addLocale('fr', {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
    monthNamesShort: ['jan', 'fev', 'mar', 'avr', 'mai', 'juin', 'juil', 'aout', 'sept', 'oct', 'nov', 'dec'],
    today: 'Aujourd\'hui',
    clear: 'Effacer'
});

const SiteAttachment = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const attachmentState = useSelector(state => state.attachment);
    const siteState = useSelector(s => s.sites);
    const prestationState = useSelector(state => state.prestations);
    const attachment = attachmentState.all.find(a => {
        if (a.site_id !== siteState.selectedSite) return false;
        if (!moment(a.date).isSame(moment(props.date), 'day')) return false;
        return true;
    });

    const [disabled, setDisabled] = React.useState(false);
    const [fieldErrors, setFieldErrors] = React.useState([]);
    const [id, setId] = React.useState(null);
    const [date, setDate] = React.useState(moment());
    const [comment, setComment] = React.useState('');
    const [prestations, setPrestations] = React.useState([]);
    const [prestationList, setPrestationList] = React.useState({
        all: prestationState?.all,
        groups: prestationState?.groups
    });
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [signature, setSignature] = React.useState([]);
    const [signatureDate, setSignatureDate] = React.useState([]);
    const [signatory, setSignatory] = React.useState(undefined);

    const dropdownOptionPrestation = (option) => {
        return (
            <div key={option.value} className="dropdown-row">
                <div>{option.label}</div>
            </div>
        );
    };

    const prestationIds = prestations.map(p => p.id);
    const prestationOptions = prestationList.groups.map(group => {
        return {
            label: group.label,
            items: prestationList.all
                .filter(p => {
                    if(p.prestation_group_id === group.id) return true;
                    return false;
                })
                .map(p => {
                    return {
                        'label': p.description,
                        'value': p.id,
                        'code': '' + p.code
                    };
                })
        };
    }).filter(g => g.items.length > 0);

    const handleUpdatePrestation = (p, qt, _date) => {
        const _prestations = prestations.slice(0);
        setPrestations(_prestations.map(pr => {
            if (pr.id === p.id) {
                pr.pivot.qt_prestation = qt >= 0 ? qt : Math.max(0, qt);
                pr.pivot.date_prestation = _date;
            }
            return pr;
        }));
    };

    const handleOnChangePrestation = (e) => {
        // Parcours des prestations sélectionnées.
        const _prestations = e.value.map(p_id => {
            const prestationExist = prestations.find(p => p.id === p_id);

            if (!prestationExist) {
                const _prest = prestationList.all.find(p => p.id === p_id);

                return ({
                    code: _prest.code,
                    company_id: _prest.company_id,
                    created_at: _prest.created_at,
                    description: _prest.description,
                    disabled: _prest.disabled,
                    id: _prest.id,
                    import_id: _prest.import_id,
                    pivot: {
                        prestation_id: _prest.id,
                        qt_prestation: 1,
                        date_prestation: (date)
                    },
                    prestation_group_id: _prest.prestation_group_id,
                    updated_at: _prest.updated_at
                });
            }
            return prestationExist;
        });
        setPrestations(_prestations);
    };

    useEffect(function () {
        const readOnly = props.synchronized;
        if (attachment) {
            setId(attachment.id);
            setDisabled(attachment.synchronized || readOnly);
            setDate(moment(attachment.date));
            setComment(attachment.comment || '');
            setPrestations(attachment.prestations);
            setFieldErrors([]);
            setSignature(attachment.signature);
            setSignatureDate(attachment.signature_date);
            setSignatory(attachment.signatory);
            setUploadedFiles([]);
        } else {
            setId(null);
            setDisabled(false);
            setDate(moment(props.date));
            setComment('');
            setPrestations([]);
            setFieldErrors([]);
            setSignature(undefined);
            setSignatureDate(undefined);
            setSignatory(undefined);
            setUploadedFiles([]);
        }
    }, [attachment]);

    useEffect(function () {
        let unitPriceListId = 0;

        let site;
        if (siteState.selectedSite) {
            site = siteState.list.find(s => s.id === siteState.selectedSite);
            unitPriceListId = site?.unit_price_list_id ? site.unit_price_list_id : 0;
        }

        const prestList = prestationState.all.filter(p => {
            if(p.unit_price_list_id === 0) return false;
            return p.unit_price_list_id === unitPriceListId;
        });
        const prest = {
            all: prestList,
            groups: prestationState.groups
        };

        setPrestationList(prest);
    }, [siteState.selectedSite]);

    const addFile = file => {
        const _files = uploadedFiles.slice(0);
        _files.push({ file: file, desc: '' });
        setUploadedFiles(_files);
    };

    const removeTempPicture = (idx) => {
        const newUploadedFile = uploadedFiles.splice(0);
        newUploadedFile.splice(idx, 1);
        setUploadedFiles(newUploadedFile);
    };

    const validForm = (ready) => {
        let fieldErrors = [];

        if (prestations.length === 0) {
            fieldErrors.push('no_prestations');
        }
        const params = {
            date: moment(date).format('YYYY-MM-DD'),
            comment: comment,
            ready: ready || attachment?.ready,
            site_id: siteState.selectedSite,
            prestations: prestations.map(p => {
                p.pivot.date_prestation = moment(p.pivot.date_prestation).format('YYYY-MM-DD');
                return p;
            }),
            signatory: signatory,
            signature: signature,
            signature_date: signatureDate ? moment(signatureDate).format('YYYY-MM-DD HH:mm:ss') : null,
        };

        setFieldErrors(fieldErrors);
        if (fieldErrors.length > 0) {
            if (fieldErrors.includes('no_prestations')) {
                toast.error(t('app:need_at_least_on_prestation'));
            } else {
                toast.error(t('app:cannot_create_attachment2'));
            }
        } else {
            if (id) {
                params.id = id;
                dispatch(updateAttachment(params, uploadedFiles));
            } else {
                dispatch(createAttachment(params, uploadedFiles));
            }
        }
    };

    return (<div className="create-dialog attachment-variant">
        <div className="p-dialog-content">
            <div className="tabIntervention p-shadow-2">
                <p className="select_title">
                    Fourniture/Prestation réalisée
                </p>
                {
                    <div className="event-options-error" justify="center">
                        {fieldErrors.includes('prestation') ? t('app:need_prestation') : ''}
                    </div>

                }
                {prestations.length > 0 ?
                    <div className="selected-prestation-list attachment-variant">
                        {prestations.map(p =>
                            <div key={p.id} className="prestation-row">
                                <div className="title" >
                                    <div className="category">
                                        {prestationList.groups.find(g => g.id === p.prestation_group_id)?.label}
                                    </div>
                                    {!disabled ?
                                        <Button
                                            className="remove p-button-link"
                                            onClick={() => {
                                                const _prestations = prestations.slice(0);
                                                const index = prestationIds.indexOf(p.id);
                                                _prestations.splice(index, 1);
                                                setPrestations(_prestations);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                        : null
                                    }
                                </div>
                                <div>
                                    <div className="desc">
                                        {p.description}
                                    </div>
                                </div>
                                <div className='prestation-pivot'>
                                    <div className='prestation-input-date'>
                                        <DayPicker
                                            value={moment(p.pivot.date_prestation)}
                                            onChange={(d) => handleUpdatePrestation(p, p.pivot.qt_prestation, d.value)}
                                            disabled={disabled}
                                        ></DayPicker>
                                    </div>
                                    <div className='prestation-input-qt'>
                                        <Button
                                            disabled={disabled}
                                            className="prestation-button p-button-link"
                                            onClick={() => { handleUpdatePrestation(p, p.pivot.qt_prestation - 1, p.pivot.date_prestation); }}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                        <div className="article-count">
                                            <NumberInput
                                                disabled={disabled}
                                                value={p.pivot.qt_prestation}
                                                onValueChange={e => { handleUpdatePrestation(p, e.value, p.pivot.date_prestation); }}
                                            />
                                        </div>
                                        <Button
                                            disabled={disabled}
                                            className="prestation-button p-button-link"
                                            onClick={() => { handleUpdatePrestation(p, parseFloat(p.pivot.qt_prestation) + 1, p.pivot.date_prestation); }}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    : null
                }
                <div className="available-prestation">
                    <MultiSelect
                        panelClassName="multiselect-prestation"
                        disabled={disabled}
                        value={prestationIds}
                        options={prestationOptions}
                        onChange={e => { handleOnChangePrestation(e); }}
                        placeholder={t('app:find_prestations')}
                        filter
                        filterBy="code,label"
                        itemTemplate={dropdownOptionPrestation}
                        dropdownIcon="pi pi-plus"
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        showSelectAll={false}
                        selectedItemsLabel={t('app:prestation_selected', { count: prestationIds.length })}
                        maxSelectedLabels={2}

                    />
                </div>
            </div>
            <div className="tabIntervention p-shadow-2">
                <p className="select_title">
                    {t('common:comment')}
                </p>
                <div className="form-attachment-comment-zone">
                    <InputTextarea
                        className="comment"
                        disabled={disabled}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        rows={4}
                    />
                </div>
            </div>
            <div className="tabIntervention p-shadow-2">
                <p className="select_title">
                    {t('common:pictures')}
                </p>
                <div className="pictures-list">
                    <Uploader withFootbar sendPicture={addFile} />
                    {
                        attachment?.pictures.length > 0
                            ? attachment.pictures.map(p => <PictureRow readOnly={attachment?.synchronized} key={p.id} picture={p} onDelete={() => { }} />
                            ) : null
                    }
                    {uploadedFiles.map((p, idx) => <PictureRowPreview readOnly={attachment?.synchronized} key={p.file} onDelete={() => removeTempPicture(idx)} picture={p.file} desc={p.desc} onChangeDesc={value => {
                        const newUploadedFiles = uploadedFiles.slice(0);
                        newUploadedFiles[idx].desc = value;
                        setUploadedFiles(newUploadedFiles);
                    }} />)}
                </div>
            </div>
            <div className="tabIntervention p-shadow-2">
                <p className="select_title">
                    {t('common:signature')}
                </p>
                <Canvas
                    disabled={disabled}
                    signature={signature}
                    signatory={signatory}
                    showSignatory={true}
                    signatureDate={signatureDate}
                    onSignatoryChange={setSignatory}
                    onSignatureChange={(val) => {
                        setSignature(val);
                        setSignatureDate(moment());
                    }}
                />
                {
                    (signature) ? <div style={{
                        margin: '1rem',
                        marginBottom: '1rem'
                    }} className="signature-date">
                        {t('app:attach_signed_by', {
                            who: signatory,
                            day: moment(signatureDate).format('DD/MM/YY'),
                            time: moment(signatureDate).format('HH:mm')
                        })}
                    </div> : null
                }
            </div>
            {/* <Button onClick={() => validForm(true)}>
                Validate
            </Button> */}
            <div className="attachment-actions">
                {!attachment?.synchronized &&
                    <Button
                        className={'site_validation p-shadow-2 p-button-link'}
                        onClick={() => validForm(false)}
                    >
                        <label htmlFor="valid">
                            {t('common:save')}
                        </label>
                    </Button>
                }
                <Button
                    className={`site_validation p-shadow-2 p-button-link ${attachment?.synchronized ? 'validated' : ''}`}
                    disabled={attachment?.ready}
                    onClick={() => validForm(true)}
                >
                    {attachment?.synchronized ? <FontAwesomeIcon icon={faCheck} /> : ''}
                    <label htmlFor="valid">
                        {t(attachment?.ready ? 
                            attachment.synchronized ?
                                'app:synchronized'
                                :'app:sending'
                            : 'common:valid'
                        )}
                    </label>
                </Button>
            </div>
        </div>
    </div>
    );
};

export default SiteAttachment;

