import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { sendMaterialPicture } from '../actions';
import FileBrowser from '../../files/components/FileBrowser';
import DropDownMaterial from './DropDownMaterial';
import { updateMaterial } from '../../app/updateApp';
import Footbar from './FootBar';
// import Footbar from './FootBar';

const MaterialDocument = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(function() { updateMaterial();} , []);

    const materialState = useSelector(state => state.materials);
    const selectedResource = (materialState.list.find(m =>
        parseInt(m.id) === parseInt(materialState.selectedMaterial)
    ));

    return <div className="module-page">
        { !props.hideSelector && <>
            <div className="label">
                {t('app:selected_material')}
            </div>
            <DropDownMaterial materials={materialState.list} />
        </>}
        <FileBrowser
            resourceType='Mat'
            selectedResource={selectedResource}
            title={t('app:material_document')}
            sendPicture={(file, folder) => dispatch(sendMaterialPicture(materialState.selectedMaterial, file, folder))}
        />
        <Footbar />
    </div>;
};

export default MaterialDocument;
