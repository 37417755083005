import React from 'react';

import { Route } from 'react-router-dom';

import SubmoduleList from './components/SubmoduleList';
import MaterialDocument from './components/MaterialDocument';

import './style.scss';
import MaterialEvent from './components/MaterialEvent';

const Materials = () => {

    return <>
        <Route exact path="/app/materials">
            <SubmoduleList />
        </Route>
        <Route path="/app/materials/documents">
            <MaterialDocument/>
        </Route>
        <Route path="/app/materials/events">
            <MaterialEvent/>
        </Route>
    </> ;
};

export default Materials;
