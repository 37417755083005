import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import useRouter from '../../utils/hooks/useRouter';
import { useTranslation } from 'react-i18next';

import AuthUser from './components/AuthUser';
import AuthCompany from './components/AuthCompany';

import './style.scss';

import logo from '../../assets/logo.png';

function Auth() {
    const { t } = useTranslation();
    const coreState = useSelector(state => state.core);
    const authState = useSelector(state => state.auth);
    const router = useRouter();

    useEffect(function() {
        if (authState.offlinemode || authState.logguedAsCompany && authState.logguedAsUser) {
            router.push('/app');
        }
    }, [authState, router]);

    if (!coreState.ready) {
        return null;
    }

    return (
        <div className="module-auth">
            <div className="img-auth"/>
            <div className="auth-panel">
                <div className="welcome-text">{t('common:welcome')}</div>
                <img alt="Optim'BTP" className="app-logo" src={logo}/>
                {
                    authState.logguedAsCompany ? <AuthUser /> : <AuthCompany />
                }
            </div>

        </div>
    );
}

export default Auth;
