import axios from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import store from '../../store';
import { addImageToStore, addToStoreAndCache, deleteStoreAndCache, updateInStoreAndCache } from '../offline/helper';

export const deleteSiteTrashDepositSuccess = () => toast.success(i18n.t('app:site_trash_deposit_deleted'));
export const deleteSiteTrashDepositError = () => toast.error(i18n.t('app:cannot_delete_site_trash_deposit'));
export const createSiteTrashDepositSuccess = data => {
    toast.success(i18n.t('app:site_trash_deposit_created', { code: data.data.code }));
};
export const createSiteTrashDepositError = () => toast.error(i18n.t('app:cannot_create_site_trash_deposit'));
export const updateSiteTrashDepositSuccess = () => toast.success(i18n.t('app:site_trash_deposit_updated'));
export const updateSiteTrashDepositError = () => toast.error(i18n.t('app:cannot_update_site_trash_deposit'));

export const siteTrashDepositReceived = siteTrashDeposits => ({
    type: 'SITE_TRASH_DEPOSIT_RECEIVED',
    payload: siteTrashDeposits
});

export const siteTrashDepositUpdate = siteTrashDeposit => ({
    type: 'SITE_TRASH_DEPOSIT_EDITED',
    payload: siteTrashDeposit
});

export const siteTrashDepositDeleted = siteTrashDepositId => ({
    type: 'SITE_TRASH_DEPOSIT_DELETED',
    payload: siteTrashDepositId
});

export const siteTrashDepositCreated = siteTrashDeposit => ({
    type: 'SITE_TRASH_DEPOSIT_CREATED',
    payload: siteTrashDeposit
});

export const picturesSentWithSuccess = (files) => ({
    type: 'SITE_TRASH_DEPOSIT_UPLOAD_PICTURES_SUCCESS',
    payload: files
});

export const createSiteTrashDeposit = (params, files) => dispatch => {
    const state = store.getState();
    const isOfflineMode = state.core.offlineMode;
    const sites = state.sites.list;
    const suppliers = state.suppliers.all;
    const type_docs = state.typedoc.all;
    if(isOfflineMode) {
        addToStoreAndCache('siteTrashDeposits', params, (id) => {
            params.site = sites.find(s => s.id === params.site_id);
            params.type_doc = type_docs.find(d => d.id === params.type_doc_id);
            params.supplier = suppliers.find(s => s.id === params.supplier_id);
            params.pictures = [];
            dispatch(siteTrashDepositCreated(params));
            files.forEach(file => {
                const formdata = new FormData();
                formdata.append('file', file.file);
                formdata.append('trash_deposit_id', id);
                formdata.append('comment', file.desc);
                addImageToStore({
                    trash_deposit_id: id,
                    file: file
                }, () => {});
            });
        });
    } else {
        return axios.post('/trash-deposit', params)
            .then(data => {
                dispatch(siteTrashDepositCreated(data.data));
                const axiosCalls = [];
                files.forEach(file => {
                    const formdata = new FormData();
                    formdata.append('file', file.file);
                    formdata.append('trash_deposit_id', data.data.id);
                    formdata.append('comment', file.desc);
                    axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
                });
                return Promise.all(axiosCalls)
                    .then((file_data) => {
                        createSiteTrashDepositSuccess(data);
                        if (files.length > 0) {
                            dispatch(picturesSentWithSuccess(file_data));
                        }
                    })
                ;
            })
            .catch(err => createSiteTrashDepositError(err));
    }
};

export const updateSiteTrashDeposit = (params, files) => dispatch => {
    const state = store.getState();
    const isOfflineMode = state.core.offlineMode;
    const sites = state.sites.list;
    const suppliers = state.suppliers.all;
    const type_docs = state.typedoc.all;
    const siteTrashDeposits = state.siteTrashDeposit.all;
    if(isOfflineMode) {
        updateInStoreAndCache('siteTrashDeposits', params, () => {
            const current_site_trash_deposit = siteTrashDeposits.find(t => t.id === params.id);
            params.site = sites.find(s => s.id === params.site_id);
            params.type_doc = type_docs.find(d => d.id === params.type_doc_id);
            params.supplier = suppliers.find(s => s.id === params.supplier_id);
            dispatch(siteTrashDepositUpdate({...current_site_trash_deposit, ...params}));
            updateSiteTrashDepositSuccess();
            if (files.length > 0) {
                files.forEach(file => {
                    addImageToStore({
                        siteTrashDepositId: params.id,
                        comment: file.desc,
                        file: file.file
                    }, () => {});
                });
            }
        });
    } else {
        return axios.patch(`/trash-deposit/${params.id}`, params)
            .then(data => {
                dispatch(siteTrashDepositUpdate(data.data));
                const axiosCalls = [];
                files.forEach(file => {
                    const formdata = new FormData();
                    formdata.append('file', file.file);
                    formdata.append('trash_deposit_id', data.data.id);
                    formdata.append('comment', file.desc);
                    axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
                });
                return Promise.all(axiosCalls);
            })
            .then(data => {
                updateSiteTrashDepositSuccess();
                if (files.length > 0) {
                    dispatch(picturesSentWithSuccess(data));
                }
            })
            .catch(err => updateSiteTrashDepositError(err));
    }
};

export const deleteSiteTrashDeposit = site_trash_deposit_id => dispatch => {
    const isOfflineMode = store.getState().core.offlineMode;
    if(isOfflineMode) {
        deleteStoreAndCache('siteTrashDeposits', site_trash_deposit_id, () => {
            deleteSiteTrashDepositSuccess();
            dispatch(siteTrashDepositDeleted(site_trash_deposit_id));
        });
    } else {
        return axios.delete(`/trash-deposit/${site_trash_deposit_id}`)
            .then(() => {
                deleteSiteTrashDepositSuccess();
                dispatch(siteTrashDepositDeleted(site_trash_deposit_id));
            })
            .catch(err => deleteSiteTrashDepositError(err));
    }
};
