import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { updateSite } from '../../app/updateApp';

import noSupply from '../../../assets/no-supply.png';
import SiteTrashDeposit from '../../siteTrashDeposit/SiteTrashDeposit';

const SiteSupply= () => {
    const { t } = useTranslation();

    useEffect(function () {
        updateSite();
    }, []);

    const siteState = useSelector(state => state.sites);

    const selectedSite = siteState.list.find(s =>
        s.id === siteState.selectedSite
    );

    const showNoData = selectedSite?.trash_management?.length === 0;

    return (
        <div className="module-subpage module-supply">
            { showNoData &&
                <div className="no-supply">
                    <img src={noSupply} />
                    <div className="label">{t('app:no_supply')}</div>
                </div>
            }
            <SiteTrashDeposit />
        </div>
    );
};

export default SiteSupply;
