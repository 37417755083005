import { faFileAlt, faTachometerAltSlow, faTruckPlow } from '@fortawesome/pro-solid-svg-icons';
import { hasAccessToDocMaterial, hasAccessToOdometer } from '../../../utils/access';

import { Button } from 'primereact/button';
import DropDownMaterial from '../../materials/components/DropDownMaterial';
import DropDownSite from './DropDownSite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FootBar from './FootBar';
import MaterialDocument from '../../materials/components/MaterialDocument';
import MaterialEventContainer from '../../materials/components/MaterialEventContainer';
import MaterialInfo from '../../materials/components/MaterialInfo';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SiteMaterial = () => {
    const { t } = useTranslation();
    const [tab, setTab] = React.useState('mat');

    const siteState = useSelector(state => state.sites);
    const materialState = useSelector(state => state.materials);

    const materials = materialState.list.filter(m => m.sites.map(s => s.id).includes(siteState.selectedSite));
    const material = materials.find(mat => mat.id === materialState.selectedMaterial);

    return <div className="module-page">
        <div className="label">
            {t('app:selected_site')}
        </div>
        <DropDownSite/>
        <div className="label">
            {t('app:selected_material')}
        </div>
        <DropDownMaterial materials={materials} />
        <div className="panel" style={{marginTop:'32px'}}>
            <Button disabled={!material} onClick={() => setTab('mat')} className={`p-button-link ${tab === 'mat' ? 'selected' : ''}`}>
                <div className="icon">
                    <FontAwesomeIcon icon={faTruckPlow} />
                </div>
                <div className="text">
                    {t('app:material_info')}
                </div>
            </Button>
            {hasAccessToDocMaterial() &&
                <Button disabled={!material} onClick={() => setTab('doc')} className={`p-button-link ${tab === 'doc' ? 'selected' : ''}`}>
                    <div className="icon">
                        <FontAwesomeIcon icon={faFileAlt} />
                    </div>
                    <div className="text">
                        {t('common:documents')}
                    </div>
                </Button>
            }
            {hasAccessToOdometer() &&
                <Button disabled={!material} onClick={() => setTab('odometer')} className={`p-order-line p-button-link ${tab === 'odometer' ? 'selected' : ''}`}>
                    <div className="icon">
                        <FontAwesomeIcon icon={faTachometerAltSlow} />
                    </div>
                    <div className="text">
                        {t('common:event')}
                    </div>
                </Button>
            }
        </div>
        <div className="material-info-wrapper p-shadow-2">
            {tab === 'mat' && material && <MaterialInfo material={material} />}
            {tab === 'doc' && material && <MaterialDocument material={material} hideSelector={true} />}
            {tab === 'odometer' && material && <MaterialEventContainer />}
        </div>
        <FootBar />
    </div>;
};

export default SiteMaterial;
