import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loginAsCompany } from '../actions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

function AuthCompany() {
    const { t } = useTranslation();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    const authenticate = () => {
        dispatch(loginAsCompany(login, password));
    };

    return (
        <form noValidate autoComplete="off">
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="siret_number">{t('common:siret_number')}</label>
                    <InputText value={login} onChange={(e) => setLogin(e.target.value)} />
                </div>
                <div className="p-field">
                    <label htmlFor="password">{t('common:password')}</label>
                    <InputText type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <Button type="button" label={t('common:next')} className="valid p-shadow-4 flashy" disabled={login.length === 0 || password.length === 0}  onClick={authenticate}/>
            </div>
        </form>
    );
}

export default AuthCompany;
