// libs
import React, { useEffect } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { faArrowLeft, faCheck, faTruckPlow, faUserHardHat, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
import { updateApp, updateEmployee, updateMaterial, updateSiteTicket } from '../app/updateApp';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
// components
import { Dialog } from 'primereact/dialog';
// actions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiSelect } from 'primereact/multiselect';
import axios from 'axios';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { getLogguedUser } from '../../utils/access';
import localforage from 'localforage';
import moment from 'moment';
import siteActive from '../../assets/site_active.svg';
import { startOfflineMode } from '../core/actions';
import { toast } from 'react-toastify';
import useRouter from '../../utils/hooks/useRouter';
import { useTranslation } from 'react-i18next';

const FormStartOffline = props => {
    const { t } = useTranslation();
    const { opened, onClose } = props;

    const dispatch = useDispatch();
    const { list: sitesStateList } = useSelector(state => state.sites);
    const { list: materialList } = useSelector(state => state.materials);
    const { all: employeeStateAll, team: employeeStateTeam } = useSelector(state => state.employees);
    const { list: materialsStateList } = useSelector(state => state.materials);
    const usersState = useSelector(state => state.users);
    const articlesState = useSelector(state => state.articles);
    const appState = useSelector(state => state.app);
    const authState = useSelector(state => state.auth);
    const customersState = useSelector(state => state.customers);
    const dayoffState = useSelector(state => state.dayoff);
    const depositTicketsState = useSelector(state => state.depositTickets);
    const eventsState = useSelector(state => state.events);
    const foldersState = useSelector(state => state.folders);
    const ordersState = useSelector(state => state.orders);
    const prestationsState = useSelector(state => state.prestations);
    const siteTicketsState = useSelector(state => state.siteTickets);
    const suppliersTicketsState = useSelector(state => state.suppliers);
    const timeslotProfilesState = useSelector(state => state.timeslotProfiles);
    const typedocState = useSelector(state => state.typedoc);
    const groupRiskState = useSelector(state => state.groupRisks);

    const currentUser = getLogguedUser();
    const userEmployee = currentUser?.employees.find(e => e.pivot.is_default);
    const employeeList = currentUser?.is_multi_employee === 1 ? employeeStateAll : employeeStateTeam;
    const [siteSelected, setSiteSelected] = React.useState([]);
    const [employeeSelected, setEmployeeSelected] = React.useState(userEmployee ? [userEmployee.id] : []);
    const [materialSelected, setMaterialSelected] = React.useState([]);
    const [employeeOptions, setEmployeeOptions] = React.useState(employeeList);
    const [materialOptions, setMaterialOptions] = React.useState(materialList);
    // const [materialOptions, setMaterialOptions] = React.useState(materialsStateList);
    // eslint-disable-next-line no-unused-vars
    const [fieldErrors, setFieldErrors] = React.useState([]);
    // eslint-disable-next-line no-unused-vars
    const router = useRouter();
    const lastSelectedSite = localStorage.getItem('last-selected-site')?.split(',') || [];
    const [siteOptions, setSiteOptions] = React.useState(sitesStateList.sort((a, b) => {
        const aIsInclude = siteSelected.includes(a.id);
        const bIsInclude = siteSelected.includes(b.id);
        const aWasSelected = lastSelectedSite.map(parseInt)?.includes(parseInt(a.id));
        const bWasSelected = lastSelectedSite.map(parseInt)?.includes(parseInt(b.id));
        if (aIsInclude) return -1;
        if (bIsInclude) return 1;
        if (aWasSelected) return -1;
        if (bWasSelected) return 1;
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
    }));

    useEffect(function () {
        if (opened) {
            const siteIdsForCurrentUserToday = eventsState.all.filter(event => {
                if (event.employee_id !== userEmployee?.id) return false;
                if (!moment(event.event_date).isSame(new Date(), 'day')) return false;
                return true;
            }).map(e => e.site_id).filter((val, index, array) => array.indexOf(val) === index);
            setSiteSelected(siteIdsForCurrentUserToday);
            setEmployeeSelected(userEmployee ? [userEmployee.id] : []);
        }
    }, [opened]);

    useEffect(function () {
        updateSiteTicket();
        updateMaterial();
        updateEmployee();
    }, []);

    useEffect(function () {
        setMaterialSelected([]);
    }, [materialsStateList]);

    useEffect(function () {
        setSiteSelected([]);
    }, [sitesStateList]);

    useEffect(function () {
        setEmployeeSelected([]);
    }, [employeeStateAll, employeeStateTeam]);

    const validForm = () => {
        let fieldErrors = [];
        if (siteSelected.length === 0 &&
            employeeSelected.length === 0 &&
            materialSelected.length === 0) {
            fieldErrors.push('nothing');
        }
        setFieldErrors(fieldErrors);
        if (fieldErrors.length > 0) {
            toast.error(t('app:nothing_selected_for_offlinemode'));
        } else {
            const filesToStore = [];
            const picturesToStore = [];
            const resourceLF = localforage.createInstance({ name: 'optim_resources' });
            const filesLF = localforage.createInstance({ name: 'optim_files' });
            const picturesLF = localforage.createInstance({ name: 'optim_pictures' });
            const dataFilesLF = localforage.createInstance({ name: 'optim_data_files' });
            const dataPicturesLF = localforage.createInstance({ name: 'optim_data_pictures' });

            const employeeSelectedFromOtherEntities = [];
            const siteSelectedFromOtherEntities = [];

            resourceLF.setItem('articles', articlesState);
            resourceLF.setItem('app', appState);
            resourceLF.setItem('auth', authState);
            resourceLF.setItem('customers', customersState);
            resourceLF.setItem('dayoff', dayoffState);
            resourceLF.setItem('groupRisks', groupRiskState);
            resourceLF.setItem('depositTickets', depositTicketsState);
            resourceLF.setItem('events', {
                all: eventsState.all.filter(e => {
                    let sync = false;
                    if (employeeSelected.includes(e.employee_id)) sync = true;
                    if (siteSelected.includes(e.site_id)) sync = true;
                    if (sync) {
                        employeeSelectedFromOtherEntities.push(e.employee_id);
                        siteSelectedFromOtherEntities.push(e.site_id);
                        e.pictures.forEach(picture => {
                            picturesToStore.push(picture);
                        });
                    }
                    return sync;
                })
            });
            resourceLF.setItem('folders', foldersState);
            resourceLF.setItem('materials', {
                list: materialsStateList.filter(m => {
                    if (materialSelected.includes(m.id)) return true;
                    if (employeeSelected.includes(m.employee_linked_id)) return true;
                    const sites = sitesStateList.filter(s => siteSelected.includes(s.id) || siteSelectedFromOtherEntities.includes(s.id));
                    let on_material_site = sites.reduce((p, _s) => {
                        const included_mat = _s.materials.map(mat => mat.id).includes(m.id);
                        return p || included_mat;
                    }, false);
                    if (on_material_site) {
                        return true;
                    }
                    return false;
                })
            });
            resourceLF.setItem('orders', ordersState);
            resourceLF.setItem('prestations', prestationsState);
            resourceLF.setItem('siteTickets', siteTicketsState);
            resourceLF.setItem('employees', {
                all: employeeStateAll.filter(e => employeeSelected.includes(e.id) || employeeSelectedFromOtherEntities.includes(e.id)),
                team: employeeStateTeam.filter(e => employeeSelected.includes(e.id) || employeeSelectedFromOtherEntities.includes(e.id))
            });
            resourceLF.setItem('sites', {
                list: sitesStateList.filter(s => siteSelected.includes(s.id) || siteSelectedFromOtherEntities.includes(s.id))
            });
            resourceLF.setItem('suppliersTickets', suppliersTicketsState);
            resourceLF.setItem('timeslotProfiles', timeslotProfilesState);
            resourceLF.setItem('typedoc', typedocState);
            resourceLF.setItem('users', usersState);

            siteSelected.forEach(sid => {
                const site = sitesStateList.find(s => s.id === sid);
                site.files.forEach(file => {
                    filesToStore.push(file);
                });
                site.pictures.forEach(picture => {
                    picturesToStore.push(picture);
                });
            });

            materialSelected.forEach(mid => {
                const material = materialsStateList.find(m => m.id === mid);
                material.files.forEach(file => {
                    filesToStore.push(file);
                });
                material.pictures.forEach(picture => {
                    picturesToStore.push(picture);
                });
            });

            employeeSelected.forEach(eid => {
                const employee = employeeList.find(e => e.id === eid);
                employee.files.forEach(file => {
                    filesToStore.push(file);
                });
            });
            // picturesLF.setItem('list', picturesToStore.map(p => p.id));
            picturesToStore.forEach(picture => {
                picturesLF.setItem(picture.id, picture);
                axios({
                    url: `${localStorage.optimbtp_api_url}/api/picture/${btoa(picture.file_path)}`,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        'Authorization': localStorage.getItem('optimbtp_session')
                    }
                }).then(result => {
                    dataPicturesLF.setItem(picture.id, result.data);
                });
            });
            // filesLF.setItem('list', filesToStore.map(f => f.id));
            filesToStore.forEach(file => {
                filesLF.setItem(file.id, file);
                axios({
                    url: `${localStorage.optimbtp_api_url}/api/file/${file.uuid}`,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        'Authorization': localStorage.getItem('optimbtp_session')
                    }
                }).then(result => {
                    dataFilesLF.setItem(file.id, result.data);
                });
            });
            dispatch(startOfflineMode());
            setTimeout(updateApp, 0);
            onClose();
            router.push('/');
        }
    };

    const dropdownOptionTemplate = (option) => {
        return (
            <div className="dropdown-row">
                <div>{option.label}</div>
                <div style={{ color: '#777', fontSize: '12px' }}>{option.code}</div>
            </div>
        );
    };


    return (
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={onClose} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div className="title">
                        {t('app:start_offline_mode')}
                    </div>
                </div>
            </div>
            <div className="offline-header">
                <FontAwesomeIcon icon={faWifiSlash} />
                <div>{t('common:offline_mode')}</div>
            </div>
            <TabView className="offline-tabs">
                <TabPanel header={<>
                    <img src={siteActive} /><br />
                    {t('common:sites')}
                </>}>
                    <div className="select-please">{t('app:selected_site_count', { count: siteSelected.length })}</div>
                    <MultiSelect
                        panelClassName="multiselect-site"
                        value={siteSelected}
                        options={siteOptions.map(s => {
                            return {
                                label: s.label,
                                code: s.code,
                                value: s.id
                            };
                        })}
                        onHide={() => setSiteOptions(siteOptions.sort((a, b) => {
                            const aIsInclude = siteSelected.includes(a.id);
                            const bIsInclude = siteSelected.includes(b.id);
                            const aWasSelected = lastSelectedSite.map(parseInt)?.includes(parseInt(a.id));
                            const bWasSelected = lastSelectedSite.map(parseInt)?.includes(parseInt(b.id));
                            if (aIsInclude && !bIsInclude) return -1;
                            if (!aIsInclude && bIsInclude) return 1;
                            if (aWasSelected && !bWasSelected) return -1;
                            if (!aWasSelected && bWasSelected) return 1;
                            if (a.label > b.label) return 1;
                            if (a.label < b.label) return -1;
                            return 0;
                        }).slice(0))}
                        onChange={e => {
                            if (e.value.length > 10) {
                                toast.error(t('app:max_selected_site_reached'));
                            } else {
                                setSiteSelected(e.value);
                            }
                        }}
                        placeholder={t('common:sites')}
                        filter
                        filterBy="label,code"
                        itemTemplate={dropdownOptionTemplate}
                        dropdownIcon="pi pi-search"
                        optionLabel="label"
                        showSelectAll={false}
                        selectedItemsLabel={t('app:site_selected', { count: siteSelected.length })}
                        maxSelectedLabels={1}
                    />
                    <div className="select-list">Chantiers qui seront offline:</div>
                    <div className="selection-list">
                        {siteSelected.map(site => <div key={site} className="row">
                            <div>
                                {sitesStateList.find(s => s.id === site).label}
                            </div>
                            <div>
                                <Button
                                    onClick={() => setSiteSelected(siteSelected.filter(s => s !== site))}
                                    className="p-button-link"><FontAwesomeIcon icon={faTimes} /></Button>
                            </div>
                        </div>)}
                    </div>
                    <Button className="flashy button-save" onClick={validForm}><FontAwesomeIcon icon={faCheck} />{t('common:save')}</Button>
                </TabPanel>
                <TabPanel header={<>
                    <FontAwesomeIcon icon={faUserHardHat} /><br />
                    {t('common:employees')}
                </>}>
                    <div className="select-please">{t('app:selected_employee_count', { count: siteSelected.length })}</div>
                    <MultiSelect
                        panelClassName="multiselect-employee"
                        value={employeeSelected}
                        options={employeeOptions.map(e => {
                            return {
                                label: `${e.lastname.toUpperCase()} ${e.firstname}`,
                                value: e.id
                            };
                        })}
                        onHide={() => setEmployeeOptions(employeeOptions.sort((a, b) => {
                            const aIsInclude = employeeSelected.includes(a.id);
                            const bIsInclude = employeeSelected.includes(b.id);
                            if (aIsInclude && !bIsInclude) return -1;
                            if (!aIsInclude && bIsInclude) return 1;
                            return 0;
                        }).slice(0))}
                        onChange={e => setEmployeeSelected(e.value)}
                        placeholder={t('common:employees')}
                        filter
                        filterBy="label"
                        itemTemplate={dropdownOptionTemplate}
                        dropdownIcon="pi pi-plus"
                        optionLabel="label"
                        showSelectAll={false}
                        selectedItemsLabel={t('app:employee_selected', { count: employeeSelected.length })}
                        maxSelectedLabels={1}
                    />
                    <div className="select-list">Employés qui seront offline:</div>
                    <div className="selection-list">
                        {employeeSelected.map(employee => {
                            const employeeObj = employeeList.find(e => e.id === employee);
                            return <div key={employee} className="row">
                                <div>
                                    {employeeObj?.lastname.toUpperCase()} {employeeObj?.firstname}
                                </div>
                                <div>
                                    <Button
                                        onClick={() => setEmployeeSelected(employeeSelected.filter(e => e !== employee))}
                                        className="p-button-link"><FontAwesomeIcon icon={faTimes} /></Button>
                                </div>
                            </div>;
                        })}
                    </div>
                    <Button className="flashy button-save" onClick={validForm}><FontAwesomeIcon icon={faCheck} />{t('common:save')}</Button>
                </TabPanel>
                <TabPanel header={<>
                    <FontAwesomeIcon icon={faTruckPlow} /><br />
                    {t('common:materials')}
                </>}>
                    <div className="select-please">{t('app:selected_material_count', { count: materialSelected.length })}</div>
                    <MultiSelect
                        panelClassName="multiselect-material"
                        value={materialSelected}
                        options={materialOptions.map(m => {
                            return {
                                label: `${m.brand} > ${m.model} > ${m.designation}`,
                                value: m.id
                            };
                        })}
                        onHide={() => setMaterialOptions(materialOptions.sort((a, b) => {
                            const aIsInclude = materialSelected.includes(a.id);
                            const bIsInclude = materialSelected.includes(b.id);
                            if (aIsInclude && !bIsInclude) return -1;
                            if (!aIsInclude && bIsInclude) return 1;
                            return 0;
                        }).slice(0))}
                        onChange={e => setMaterialSelected(e.value)}
                        placeholder={t('common:materials')}
                        filter
                        filterBy="label"
                        itemTemplate={dropdownOptionTemplate}
                        dropdownIcon="pi pi-plus"
                        optionLabel="label"
                        showSelectAll={false}
                        selectedItemsLabel={t('app:material_selected', { count: materialSelected.length })}
                        maxSelectedLabels={1}
                    />
                    <div className="select-list">Matériels qui seront offline:</div>
                    <div className="selection-list">
                        {materialSelected.map(material => {
                            const materialObj = materialList.find(m => m.id === material);
                            return <div key={material} className="row">
                                <div>
                                    {materialObj?.brand} &gt; {materialObj?.model} &gt; {materialObj?.designation}
                                </div>
                                <div>
                                    <Button
                                        onClick={() => setMaterialSelected(materialSelected.filter(m => m !== material))}
                                        className="p-button-link"><FontAwesomeIcon icon={faTimes} /></Button>
                                </div>
                            </div>;
                        })}
                    </div>
                    <Button className="flashy button-save" onClick={validForm}><FontAwesomeIcon icon={faCheck} />{t('common:save')}</Button>
                </TabPanel>
            </TabView>
        </Dialog>
    );
};

export default FormStartOffline;

