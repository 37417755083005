import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { faCalendarDay, faTruckPlow, faFileAlt, faUmbrellaBeach, faShieldCheck } from '@fortawesome/pro-solid-svg-icons';
import { faPlus} from '@fortawesome/pro-light-svg-icons';

import { useTranslation } from 'react-i18next';
import useRouter from '../../../utils/hooks/useRouter';
import { hasAccessToPlanning, hasAccessToEmployeeDoc, hasAccessToDayOff, hasAccessToMaterial, hasAccessToSecurity, hasAccessToDangerousSituation } from '../../../utils/access';

import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmployeeOverlayCreate from './EmployeeOverlayCreate';

const Footbar = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const [showOverlayCreate, setShowOverlayCreate] = useState(false);
    const employeeState = useSelector(state => state.employees);
    const materialState = useSelector(state => state.materials);
    const materialSelected = materialState.list.filter(m => m.employee_linked_id === employeeState.selectedEmployee).find(m => m.id === materialState.selectedMaterial);
    return <div className="footbar">
        { hasAccessToPlanning() ?
            <Button
                className={`p-button-link ${router.history.location.pathname.includes('/app/employees/event') ? 'selected' : null}`}
                onClick={() => {
                    if (employeeState.selectedEmployee) {
                        router.push('/app/employees/event/day');
                    } else {
                        toast.error(t('app:please_select_a_employee'));
                    }
                }}>
                <div className="icon">
                    <FontAwesomeIcon icon={faCalendarDay} /><br/>
                </div>
                <div className="text">
                    {t('common:planning')}
                </div>
            </Button>
            : null
        }
        { hasAccessToEmployeeDoc() ?
            <Button
                className={`p-button-link ${router.history.location.pathname === '/app/employees/documents' ? 'selected' : null}`}
                onClick={() => {
                    if (employeeState.selectedEmployee) {
                        router.push('/app/employees/documents');
                    } else {
                        toast.error(t('app:please_select_a_employee'));
                    }
                }}>
                <div className="icon">
                    <FontAwesomeIcon icon={faFileAlt} /><br/>
                </div>
                <div className="text">
                    {t('common:document')}
                </div>
            </Button>
            : null
        }
        { hasAccessToDayOff() ?
            <Button
                className={`p-button-link ${router.history.location.pathname === '/app/employees/dayoff' ? 'selected' : null}`}
                onClick={() => {
                    if (employeeState.selectedEmployee) {
                        router.push('/app/employees/dayoff');
                    } else {
                        toast.error(t('app:please_select_a_employee'));
                    }
                }}>
                <div className="icon">
                    <FontAwesomeIcon icon={faUmbrellaBeach} /><br/>
                </div>
                <div className="text">
                    {t('common:day_off')}
                </div>
            </Button>
            : null
        }
        { hasAccessToMaterial() ?
            <Button
                className={`p-button-link ${router.history.location.pathname === '/app/employees/material' ? 'selected' : null}`}
                onClick={() => {
                    if (employeeState.selectedEmployee) {
                        router.push('/app/employees/material');
                    } else {
                        toast.error(t('app:please_select_a_employee'));
                    }
                }}>
                <div className="icon">
                    <FontAwesomeIcon icon={faTruckPlow} /><br/>
                </div>
                <div className="text">
                    {t('common:material')}
                </div>
            </Button>
            : null
        }
        { (hasAccessToSecurity() || hasAccessToDangerousSituation()) ?
            <Button
                className={`p-button-link ${router.history.location.pathname === '/app/employees/security' ? 'selected' : null}`}
                onClick={() => {
                    if (employeeState.selectedEmployee) {
                        router.push('/app/employees/security');
                    } else {
                        toast.error(t('app:please_select_a_employee'));
                    }
                }}>
                <div className="icon">
                    <FontAwesomeIcon icon={faShieldCheck} /><br/>
                </div>
                <div className="text">
                    {t('app:security')}
                </div>
            </Button>
            : null
        }
        <Button disabled={(router.location.pathname === '/app/employees/material' && !materialSelected) || !employeeState.selectedEmployee || router.location.pathname === '/app/employees/documents'} className={`flashy plus-button-footbar ${showOverlayCreate ? 'overlay-shown' : ''}`} onClick={() => setShowOverlayCreate(!showOverlayCreate)}>
            <FontAwesomeIcon icon={faPlus}/><br/>
        </Button>
        <EmployeeOverlayCreate show={showOverlayCreate} onClose={() =>  setShowOverlayCreate(false)}/>
    </div>;
};

export default Footbar;
