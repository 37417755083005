import React, { useEffect } from 'react';
import { goToNextCalendarDay, goToPreviousCalendarDay, setCalendarDay } from '../../employees/actions';
import { signEvent, validateEvent } from '../actions';
import { useDispatch, useSelector } from 'react-redux';

import DayNavigation from './DayNavigation';
import EventRow from './EventRow';
import FormEvent from './FormEvent';
import SubFootbarDay from './SubFootbarDay';
import { canValidEvent } from '../../../utils/access';
import { getEventDuration } from '../../../utils/event_tool';
import imgNoEvent from '../../../assets/no-event.png';
import moment from 'moment';
import { updateEmployee } from '../../app/updateApp';
import { useTranslation } from 'react-i18next';

const EvenementDay = () => {
    const dispatch = useDispatch();
    const employeeState = useSelector(state => state.employees);
    const eventState = useSelector(state => state.events);
    const [event, setEvent] = React.useState(null);
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [eventType, setEventType] = React.useState('production');
    const { t } = useTranslation();
    const appState = useSelector(state => state.app);
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);
    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const userEmployee = currentUser?.employees.find(e => e.pivot.is_default);

    // get current employee
    let currentEmploye = employeeState.team.find(empl =>
        empl.id === employeeState.selectedEmployee
    );
    if (!currentEmploye) {
        currentEmploye = employeeState.all.find(empl =>
            empl.id === employeeState.selectedEmployee
        );
    }
    const events = eventState.all
        .filter((event) => {
            if (event.employee_id !== employeeState.selectedEmployee) return false;
            if (!moment(event.event_date).isSame(employeeState.dayShown, 'day')) return false;
            return true;
        });

    useEffect(function() { updateEmployee();} , []);

    useEffect(function() {
        if(employeeState.dayShown === null) {
            dispatch(setCalendarDay(moment()));
        }
    }, [employeeState.dayShown, dispatch]);

    const signEvents = () => {
        dispatch(signEvent(events.filter(e => parseInt(e.state) === 0)));
    };

    const validateEvents = () => {
        if (appState.company.is_signature) {
            dispatch(validateEvent(events.filter(e => parseInt(e.state) === 2)));
        } else {
            dispatch(validateEvent(events.filter(e => parseInt(e.state) === 0)));
        }
    };

    return (
        <>
            <DayNavigation
                goBefore={() => dispatch(goToPreviousCalendarDay())}
                goNext={() => dispatch(goToNextCalendarDay())}
                value={employeeState.dayShown}
                onChange={(d) => dispatch(setCalendarDay(moment(d.value)))}
            />
            {
                events.length === 0 ?
                    <div className="no-data">
                        <img src={imgNoEvent} />
                        <div className="label">{t('app:no_evenement')}</div>
                    </div>:
                    <div className="event-data">
                        <div className="planning">
                            {
                                events.sort((eventA, eventB) => {
                                    if (eventA.start_hour < eventB.start_hour) return -1;
                                    if (eventA.start_hour > eventB.start_hour) return 1;
                                    if (eventA.start_minute < eventB.start_minute) return -1;
                                    if (eventA.start_minute > eventB.start_minute) return 1;
                                    return 0;
                                }).map(
                                    event => <EventRow onClick={() => {
                                        if(event.parent_event_id) {
                                            setEvent(events.find(e => e.id === event.parent_event_id));
                                        } else {
                                            setEvent(event);
                                        }
                                        setPopupOpen(true);
                                        setEventType(event.event_type);
                                    }} key={event.id} event={event} />
                                )
                            }
                            <SubFootbarDay
                                canSign={events.length > 0 && events.filter( e => {
                                    if (appState.company.is_signature) {
                                        // user can only sign his event or any event if he is "admin"
                                        if (canValidEvent() || e.employee_id === userEmployee?.id || e.user_id === currentUser.id) {
                                            return e.state === 0 || e.state === '0';
                                        }
                                    }
                                    return false;
                                }).length > 0}
                                canValidate={events.length > 0 && events.filter( e => {
                                    if (appState.company.is_signature) {
                                        return e.state === 2 || e.state === '1';
                                    } else {
                                        return e.state === 0 || e.state === '1';
                                    }
                                }).length > 0}
                                onClickSign={signEvents}
                                onClickValid={validateEvents}
                                count={events.reduce((acc, event) => {
                                    return acc + (getEventDuration(event) / 3600);
                                }, 0)}
                            />
                        </div>
                    </div>
            }
            <FormEvent
                event={event}
                type={eventType}
                open={popupOpen}
                onClose={() => {
                    setEvent(null);
                    setPopupOpen(false);
                }}
            />
        </>
    );
};

export default EvenementDay;