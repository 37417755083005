import { Document, Page } from 'react-pdf';
import React, { useEffect, useMemo, useState } from 'react';
import { faArrowToLeft, faArrowToRight, faHourglass, faSearchMinus, faSearchPlus, faSync } from '@fortawesome/pro-regular-svg-icons';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapInteractionCSS } from 'react-map-interaction';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import localforage from 'localforage';
import { useSelector } from 'react-redux';

const DocumentDialog = props => {
    const offlineMode = useSelector(store => store.core.offlineMode);

    const { file, opened, onClose, type } = props;

    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1);
    const [apiurl] = useState(localStorage.optimbtp_api_url);
    const [urlBlob, setUrlBlob] = useState(null);
    const [rotate, setRotate] = useState(0);
    const [ready, setReady] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setReady(true);
        setNumPages(numPages);
    }

    const fileObject = useMemo(() => (
        {
            url: `${apiurl}/api/file/${file.uuid}`,
            httpHeaders: { 'Authorization': localStorage.getItem('optimbtp_session') }
        }
    ), [file.uuid, apiurl]);

    const [offlineFile, setOfflineFile] = useState(null);

    useEffect(() => {
        if (offlineMode) {
            const LFOptimDataFilesOfflineMode = localforage.createInstance({ name: 'optim_data_files' });
            LFOptimDataFilesOfflineMode.getItem(file.id).then((blobFile) => {
                setOfflineFile(new File([blobFile], file.title));
            });
        } else {
            if (type === 'jpg' || type === 'png') {
                const options = {
                    headers: {
                        'Authorization': localStorage.getItem('optimbtp_session')
                    }
                };
                fetch(`${apiurl}/api/file/${file.uuid}`, options)
                    .then(res => res.blob())
                    .then(blob => {
                        setUrlBlob(URL.createObjectURL(blob));
                    });
            }
        }
    }, [file, offlineMode]);

    return <Dialog
        maximized={true}
        modal={true}
        visible={opened}
        onHide={onClose}
        className="create-dialog"
        position="bottom"
        closable
    >
        <div className="header p-shadow-8">
            <div className="topnavbar">
                <Button onClick={onClose} className="p-button-link close-button">
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <div className="title">
                    {file.title}
                </div>
            </div>
        </div>
        {(type === 'jpg' || type === 'png') ?
            <MapInteractionCSS>
                <img src={urlBlob} />
            </MapInteractionCSS>
            :
            <>
                <Document
                    file={offlineMode ? offlineFile : fileObject}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {ready ?
                        <Page
                            className={'file-viewport'}
                            pageNumber={isFinite(pageNumber) ? pageNumber : 1}
                            rotate={rotate}
                            width={document.documentElement.clientWidth}
                            scale={scale}
                        /> : <div style={{ 'marginTop': '100px' }}>
                            <FontAwesomeIcon icon={faHourglass} />
                        </div>
                    }
                </Document>
                <div className="file-viewer-footbar">
                    <Button className="p-button-link" onClick={() => setRotate(rotate === 270 ? 0 : rotate + 90)}>
                        <FontAwesomeIcon icon={faSync} />
                    </Button>
                    <Button className="p-button-link" onClick={() => setScale(scale / 2)}>
                        <FontAwesomeIcon icon={faSearchMinus} />
                    </Button>
                    <Button className="p-button-link" onClick={() => setScale(scale * 2)}>
                        <FontAwesomeIcon icon={faSearchPlus} />
                    </Button>
                    <Button className="p-button-link" onClick={() => setPageNumber(Math.max(1, pageNumber - 1))}>
                        <FontAwesomeIcon icon={faArrowToLeft} />
                    </Button>
                    <input value={pageNumber} type="number" onChange={e => setPageNumber(parseInt(e.target.value))} />
                    / {numPages}
                    <Button className="p-button-link" onClick={() => setPageNumber(Math.min(numPages, parseFloat(pageNumber) + 1))}>
                        <FontAwesomeIcon icon={faArrowToRight} />
                    </Button>
                </div>
            </>
        }

    </Dialog>;
};

export default DocumentDialog;