import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { loginAsUser } from '../actions';
import { updateUser } from '../../app/updateApp';
import { useTranslation } from 'react-i18next';

function AuthUser() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const users = useSelector(state => state.users.list);

    useEffect(function () {
        dispatch(updateUser());
    }, [dispatch]);

    const authenticate = () => {
        dispatch(loginAsUser(login, password));
    };

    const userOptions = users.map(user => { return { value: user.login, label: `${user.lastname} ${user.firstname}`};});

    return (
        <form noValidate autoComplete="off">
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="siret_number">{t('auth:choose_user')}</label>
                    <Dropdown options={userOptions} value={login} onChange={(e) => setLogin(e.target.value)} />
                </div>
                <div className="p-field">
                    <label htmlFor="password">{t('common:password')}</label>
                    <InputText type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <Button type="button" label={t('common:connection')} className="valid p-shadow-4 flashy" disabled={login.length === 0 || password.length === 0}  onClick={authenticate}/>
            </div>
        </form>
    );
}

export default AuthUser;
