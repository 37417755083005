import moment from 'moment';

const initialState = () => ({
    all: [],
    siteDayShown: moment()
});

const eventsReducer = (state = initialState(), action) => {
    let newState = Object.assign({}, state);
    const updatedEvent = [];
    const updatedEventIds = [];
    switch (action.type) {
        case 'EVENTS_RECEIVED':
            newState.all = action.payload.map(e => {
                return { ...e,
                    event_options: typeof e.event_options === 'object' ? e.event_options : JSON.parse(e.event_options)
                };
            });
            return newState;
        case 'EVENT_CREATED':
            newState.all.push({ ...action.payload,
                event_options: JSON.parse(action.payload.event_options)
            });
            return newState;
        case 'EVENT_UPDATED':
            newState.all.forEach((event, idx) => {
                if (event.id === action.payload.id) {
                    newState.all[idx] = {...action.payload, event_options:
                        typeof action.payload.event_options === 'object' ? action.payload.event_options : JSON.parse(action.payload.event_options)};
                }
            });
            return newState;
        case 'EVENTS_SIGNED':
            action.payload.forEach(event => {
                updatedEvent[event.id] = event;
                updatedEventIds.push(event.id);
            });
            newState.all.forEach((event, idx) => {
                if (updatedEventIds.includes(event.id)) {
                    newState.all[idx].state = 2;
                }
            });
            return newState;
        case 'EVENTS_VALIDATED':
            action.payload.forEach(event => {
                updatedEvent[event.id] = event;
                updatedEventIds.push(event.id);
            });
            newState.all.forEach((event, idx) => {
                if (updatedEventIds.includes(event.id)) {
                    newState.all[idx].state = 1;
                }
            });
            return newState;
        case 'EVENT_DELETED':
            newState.all = newState.all.filter(event => {
                if (event.parent_event_id === action.payload) return false;
                if (event.id === action.payload) return false;
                return true;
            });
            return newState;
        case 'EVENT_UPLOAD_PICTURES_SUCCESS':
            newState.all.forEach((event, idx) => {
                if (parseInt(event.id) === parseInt(action.payload[0][0].data.event_id)) {
                    action.payload.forEach(row2 => {
                        row2.forEach(row => {
                            newState.all[idx].pictures.push(row.data);
                        });
                    });
                }
            });
            return newState;
        case 'PICTURE_UPDATED':
            if (!action.payload.event_id) return state;
            newState.all.forEach((m, idx) => {
                if (m.id === action.payload.event_id) {
                    newState.all[idx].pictures.forEach((p, m_idx) => {
                        if (newState.all[idx].pictures[m_idx].id === action.payload.id) {
                            newState.all[idx].pictures[m_idx].comment = action.payload.comment;
                        }
                    });
                }
            });
            return newState;
        case 'PICTURE_REMOVE_SUCCESS':
            if (!action.payload.event_id) return state;
            newState.all.forEach((m, idx) => {
                if (m.id === action.payload.event_id) {
                    newState.all[idx].pictures.forEach((p, m_idx) => {
                        if (newState.all[idx].pictures[m_idx].id === action.payload.id) {
                            newState.all[idx].pictures.splice(m_idx, 1);
                        }
                    });
                }
            });
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default eventsReducer;
