import axios from 'axios';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { bootstrap, notReady } from '../core/actions';

export const loginCompanyError = () => toast.error(i18n.t('auth:bad_company_credentials'));
export const loginUserError = () => toast.error(i18n.t('auth:bad_user_credentials'));

export const loginAsCompany = (login, password) => (dispatch) => {
    axios.post('/auth/company', { login, password })
        .then(data => {
            dispatch(checkToken(data.data.token));
            toast.success(i18n.t('auth:loggued_as_company'));
        })
        .catch(err => loginCompanyError(err));
};

export const loginAsUser = (login, password) => (dispatch) => {
    axios.post('/auth/user', { login, password })
        .then(data => {
            dispatch(checkToken(data.data.token));
            toast.success(i18n.t('auth:loggued_as_user'));
            dispatch(notReady());
            dispatch(bootstrap());
        })
        .catch(err => loginUserError(err));
};

export const checkToken = (token) => ({
    type: 'CHECK_TOKEN',
    payload: {
        token
    }
});

export const logout = () => {
    window.localStorage.removeItem('optimbtp_session');
    localStorage.removeItem('selected_employee');
    localStorage.removeItem('selected_site');
    localStorage.removeItem('last-selected-employees');
    localStorage.removeItem('last-selected-site');
    localStorage.removeItem('last-selected-site-intervention');
    localStorage.removeItem('site_ticket_label');
    localStorage.removeItem('optim_module');
    setTimeout(() => {
        window.location.href = '/';
    }, 100);
    // toast.success(i18n.t('auth:logout_success'));
    // return {
    //     type: 'LOGOUT'
    // };
};

