import { toast } from 'react-toastify';
import i18n from 'i18next';

export const getCompanyError = () => toast.error(i18n.t('app:cannot_get_company'));

export const getCompanySuccess = (company) => ({
    type: 'GET_COMPANY_SUCCESS',
    payload: {
        company
    }
});

export const getConfigCalendarDaySuccess = (config) => ({
    type: 'GET_CONFIG_CALENDAR_DAY_SUCCESS',
    payload: {
        config
    }
});

export const getConfigOptionalFieldSuccess = (config) => ({
    type: 'GET_CONFIG_OPTIONNAL_FIELD_SUCCESS',
    payload: {
        config
    }
});

export const getConfigDayOffCodeSuccess = (config) => ({
    type: 'GET_CONFIG_DAY_OFF_CODE_SUCCESS',
    payload: {
        config
    }
});

export const applicationOnError = status => ({
    type: 'APPLICATION_ON_ERROR',
    payload: status
});

