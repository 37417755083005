import React, { useEffect } from 'react';
import { createEvent, deleteEvent, updateEvent } from '../actions';
import { faArrowLeft, faCopy, faSave, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { faBallotCheck, faCalendar, faCalendarStar, faCog, faFileAlt, faTools } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import DayNavigation from './DayNavigation';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormEventCore from './FormEventComponents/FormEventCore';
import FormEventInformations from './FormEventComponents/FormEventInformation';
import FormEventIntervention from './FormEventComponents/FormEventIntervention';
import FormEventOptions from './FormEventComponents/FormEventOptions';
import FormEventTimeslot from './FormEventComponents/FormEventTimeslot';
import FormSelector from '../../forms/FormSelector';
import { canEditPlanningForDay } from '../../../utils/event_tool';
import { confirmDialog } from 'primereact/confirmdialog';
import iconDayoff from '../../../assets/icon-dayoff.svg';
import iconIntervention from '../../../assets/icon-intervention.svg';
import iconProduction from '../../../assets/icon-production.svg';
import moment from 'moment';
import { toast } from 'react-toastify';
import useRouter from '../../../utils/hooks/useRouter';
import { useTranslation } from 'react-i18next';

const FormEvent = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const eventState = useSelector(state => state.events);
    const employeeState = useSelector(state => state.employees);
    const appState = useSelector(state => state.app);
    const sitesState = useSelector(state => state.sites);
    const customersState = useSelector(state => state.customers);
    const timeslotProfileState = useSelector(state => state.timeslotProfiles);
    const company = useSelector(state => state.app.company);
    const router = useRouter();
    const prestationState = useSelector(state => state.prestations);

    // get employee list ids
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);
    const formList = useSelector(state => state.forms.list);
    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const employeeListIds = currentUser.is_multi_employee === 1 ? employeeState.all.map(e => e.id) : employeeState.team.map(e => e.id);

    const [tab, setTab] = React.useState('event');
    const [id, setId] = React.useState(undefined);
    const [disabled, setDisabled] = React.useState(false);
    const [userHasSetDuration, setUserHasSetDuration] = React.useState(false);
    const [fillabled, setFillabled] = React.useState(false);
    const [fieldErrors, setFieldErrors] = React.useState([]);
    const [eventDate, setEventDate] = React.useState(moment());
    const [eventSite, setEventSite] = React.useState(undefined);
    const [eventCustomer, setEventCustomer] = React.useState(undefined);
    const [eventWorkshop, setEventWorkshop] = React.useState(undefined);
    const [childrenEvents, setChildrenEvents] = React.useState([]);
    const [dayOffCode, setDayOffCode] = React.useState(undefined);
    const [eventOptions, setEventOptions] = React.useState({});
    const [workshopList, setWorkshopList] = React.useState([]);
    const [durationHour, setDurationHour] = React.useState(0);
    const [durationMinute, setDurationMinute] = React.useState(0);
    const [eventNote, setEventNote] = React.useState('');
    const [startingDateHour, setStartingDateHour] = React.useState(0);
    const [startingDateMinute, setStartingDateMinute] = React.useState(0);
    const [endingDateHour, setEndingDateHour] = React.useState(0);
    const [endingDateMinute, setEndingDateMinute] = React.useState(0);
    const [startingDateHour2, setStartingDateHour2] = React.useState(0);
    const [startingDateMinute2, setStartingDateMinute2] = React.useState(0);
    const [endingDateHour2, setEndingDateHour2] = React.useState(0);
    const [endingDateMinute2, setEndingDateMinute2] = React.useState(0);
    const [startingDateHour3, setStartingDateHour3] = React.useState(0);
    const [startingDateMinute3, setStartingDateMinute3] = React.useState(0);
    const [endingDateHour3, setEndingDateHour3] = React.useState(0);
    const [endingDateMinute3, setEndingDateMinute3] = React.useState(0);
    const [prestations, setPrestations] = React.useState([]);
    const [prestationList, setPrestationList] = React.useState([]);
    const [signature, setSignature] = React.useState([]);
    const [signatureDate, setSignatureDate] = React.useState([]);
    const [signatory, setSignatory] = React.useState(undefined);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [employeeIds, setEmployeeIds] = React.useState([]);
    const [employeeTypeId, setEmployeeTypeId] = React.useState(undefined);
    const [totalDurationHour, setTotalDurationHour] = React.useState(undefined);
    const [totalDurationMinute, setTotalDurationMinute] = React.useState(undefined);
    const [formAnswers, setFormAnswers] = React.useState([]);
    const [forms, setForms] = React.useState([]);
    const minCalendarDate = moment(appState.calendarDays[0]['date']);
    const maxCalendarDate = moment(appState.calendarDays[appState.calendarDays.length - 1]['date']);

    let currentEmploye = employeeState.team.find(empl =>
        empl.id === employeeState.selectedEmployee
    );
    if (!currentEmploye) {
        currentEmploye = employeeState.all.find(empl =>
            empl.id === employeeState.selectedEmployee
        );
    }

    const closePopup = () => {
        props.onClose();
    };

    const addFile = file => {
        const _files = uploadedFiles.slice(0);
        _files.push({ file: file, desc: '' });
        setUploadedFiles(_files);
    };

    const getTimeslotDuration = (startH, startM, endH, endM) => {
        const startMinute = parseInt(startH) * 60 + parseInt(startM);
        const endMinute = parseInt(endH) * 60 + parseInt(endM);
        if (startMinute <= endMinute) {
            return endMinute - startMinute;
        } else {
            return 60 * 24 - startMinute + endMinute;
        }
    };

    const getCleanedOptions = () => {
        const _options = Object.assign({}, eventOptions);
        Object.keys(_options).forEach(option_key => {
            if (_options[option_key] === false) {
                delete _options[option_key];
                return;
            }
            if (_options[option_key]?.length === 0) {
                delete _options[option_key];
                return;
            }
            if (parseFloat(_options[option_key]) === 0) {
                delete _options[option_key];
                return;
            }
            return true;
        });
        return _options;
    };

    const updateWorkshopList = (site_id) => {
        const site = sitesState.list.find(s => s.id === site_id);
        const workshops = site ? site.workshops.sort((a, b) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
        }).map((w, idx) => {
            return {
                'label': w.designation,
                'value': w.id,
                'code': 'ID: ' + idx,
                'import_id': w.import_id,
            };
        }) : [];
        // set default val for workshop
        let workshop;
        if (props.event) {
            workshop = workshops.find(w => w.value === props.event.workshop_id);
        } else {
            workshop = workshops[0];
        }
        setEventWorkshop(workshop?.value);
        setWorkshopList(workshops);
        if (props.type === 'intervention' && !eventCustomer) {
            setEventCustomer(site?.customer_id ? site.customer_id : undefined);
        }
    };

    const updatePrestationList = (site_id) => {
        let unitPriceListId = 0;

        if (site_id) {
            const site = sitesState.list.find(s => s.id === site_id);
            unitPriceListId = site?.unit_price_list_id ? site.unit_price_list_id : 0;
        }

        // Clear selected prestations.
        if (!props.event || props.event.site_id != site_id) {
            setPrestations([]);
        }

        const prestList = prestationState.all.filter(p => p.unit_price_list_id === unitPriceListId);
        const prest = {
            all: prestList,
            groups: prestationState.groups
        };

        setPrestationList(prest);
    };

    const confirmDelete = () => {
        confirmDialog({
            message: t('app:really_remove_event'),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                dispatch(deleteEvent(id));
                closePopup();
            },
            reject: () => { }
        });
    };

    const validForm = (updateAllOtherSiteDailyEvent, keepOpen) => {
        let fieldErrors = [];
        let needPrestation = false;
        let noScheduledEvent = false;
        let bothTimeslotDurationRequired = false;
        let durationIsSet = false;
        let timeslotSet = false;
        let durationAndTImeslotNotMatch = false;
        let totalDuration = totalDurationHour * 60 + totalDurationMinute;
        let consumedDuration = durationHour * 60 + durationMinute;
        childrenEvents.forEach(ce => {
            consumedDuration += ce.durationHour * 60 + ce.durationMinute;
        });
        const remainedDuration = Math.max(0, totalDuration - consumedDuration);
        if (props.type !== 'dayoff' && remainedDuration > 0) {
            fieldErrors.push('durationWrong');
        }
        // if an event as already an id, errors was previously checked.
        // We want to let user save scheduled tasks
        if (!id || (id && props.event.state > -1)) {
            switch (props.type) {
                case 'intervention':
                    if (!eventCustomer) fieldErrors.push('eventCustomer');
                    if (prestations.length === 0) {
                        fieldErrors.push('prestation');
                        needPrestation = true;
                    }
                // no breakpoint because an intervention is a superset of a production
                // eslint-disable-next-line no-fallthrough
                case 'production':
                    if (!eventSite) fieldErrors.push('eventSite');
                    if (!eventWorkshop) fieldErrors.push('eventWorkshop');
                    if (
                        durationMinute === 0 &&
                        durationHour === 0 &&
                        endingDateHour === 0 &&
                        endingDateMinute === 0 &&
                        endingDateHour2 === 0 &&
                        endingDateMinute2 === 0 &&
                        endingDateHour3 === 0 &&
                        endingDateMinute3 === 0 &&
                        // get only options that have a valid value
                        Object.keys(getCleanedOptions()).length === 0
                    ) {
                        fieldErrors.push('timeField');
                        noScheduledEvent = true;
                    }
                    break;
                case 'dayoff':
                    if (!dayOffCode) fieldErrors.push('dayOffCode');
                    break;
                default:
                    throw new Error('Unknow event type');
            }
        }
        if (employeeIds.filter(e => !isNaN(e)).length === 0) {
            fieldErrors.push('need_employee');
        }
        if (company.time_slot_mode) {
            let timeslotDuration = 0;
            let duration = parseInt(durationHour) * 60 + parseInt(durationMinute);
            childrenEvents.forEach(ce => {
                duration += parseInt(ce.durationHour) * 60 + parseInt(ce.durationMinute);
            });
            timeslotDuration = timeslotDuration + getTimeslotDuration(startingDateHour, startingDateMinute, endingDateHour, endingDateMinute);
            timeslotDuration = timeslotDuration + getTimeslotDuration(startingDateHour2, startingDateMinute2, endingDateHour2, endingDateMinute2);
            timeslotDuration = timeslotDuration + getTimeslotDuration(startingDateHour3, startingDateMinute3, endingDateHour3, endingDateMinute3);
            const timeOptions = appState.optionnalFields.filter(o => o.is_timerelated).reduce((prev, curr) => {
                if (eventOptions[curr.label]) {
                    return prev - parseFloat(eventOptions[curr.label]) * 60;
                }
                return prev;
            }, 0);
            switch (company.time_slot_mode) {
                case 'NOTHING_OR_DURATION_AND_TIMESLOT_NEEDED':
                    durationIsSet = durationHour > 0 || durationMinute > 0;
                    timeslotSet =
                        endingDateHour > 0 ||
                        endingDateMinute > 0 ||
                        endingDateHour2 > 0 ||
                        endingDateMinute2 > 0 ||
                        endingDateHour3 > 0 ||
                        endingDateMinute3 > 0;
                    if ((durationIsSet && !timeslotSet)) {
                        bothTimeslotDurationRequired = true;
                        fieldErrors.push('timeslotNeeded');
                    }
                    if (!durationIsSet && timeslotSet) {
                        bothTimeslotDurationRequired = true;
                        fieldErrors.push('durationNeeded');
                    }
                    break;
                case 'DURATION_AND_TIMESLOT_NEED_TO_BE_COHERENT':
                    timeslotDuration = timeslotDuration + timeOptions;
                    if (duration !== timeslotDuration) {
                        durationAndTImeslotNotMatch = true;
                        fieldErrors.push('timeslotNeeded');
                        fieldErrors.push('durationNeeded');
                    }
                    break;
            }
        }
        setFieldErrors(fieldErrors);
        if (noScheduledEvent) {
            toast.error(t('app:cannot_create_scheduled_event'));
        } else if (needPrestation) {
            toast.error(t('app:need_prestation'));
            setTab('intervention');
        } else if (employeeIds.length === 0) {
            toast.error(t('app:cannot_save_without_employee'));
        } else if (durationAndTImeslotNotMatch) {
            toast.error(t('app:difference_between_timeslot_and_duration'));
        } else if (bothTimeslotDurationRequired) {
            toast.error(t('app:both_timeslot_duration_required'));
            if (timeslotSet) {
                setTab('event');
            } else {
                setTab('timeslot');
            }
        } else if (fieldErrors.includes('durationWrong')) {
            toast.error(t('app:duration_wrong_error'));
        } else if (fieldErrors.length > 0) {
            setTab('event');
            toast.error(t('app:cannot_save_missing_fields', { fields: fieldErrors.map(err => t(`app:${err}`)).join(', ') }));
        } else {
            if(!keepOpen) closePopup();
            const params = {
                'event_type': props.type,
                'event_date': moment(eventDate).format('YYYY-MM-DD'),
                'site_id': eventSite,
                'customer_id': eventCustomer,
                'workshop_id': eventWorkshop,
                'dayoff_code_id': dayOffCode,
                'event_options': JSON.stringify(getCleanedOptions()),
                'event_duration': durationHour * 3600 + durationMinute * 60,
                'note': eventNote,
                'start_hour': startingDateHour,
                'start_minute': startingDateMinute,
                'end_hour': endingDateHour,
                'end_minute': endingDateMinute,
                'start_hour_2': startingDateHour2,
                'start_minute_2': startingDateMinute2,
                'end_hour_2': endingDateHour2,
                'end_minute_2': endingDateMinute2,
                'start_hour_3': startingDateHour3,
                'start_minute_3': startingDateMinute3,
                'end_hour_3': endingDateHour3,
                'end_minute_3': endingDateMinute3,
                'employee_id': employeeIds[0],
                'signature': signature,
                'signatory': signatory,
                'form_answers': formAnswers,
                'employee_type_id': employeeTypeId,
                'signature_date': signatureDate ? moment(signatureDate).format('YYYY-MM-DD HH:mm:ss') : null,
                'prestations': prestations,
                'children_events': childrenEvents
            };
            if (id) {
                if (updateAllOtherSiteDailyEvent) {
                    const events = eventState.all.filter(event => {
                        if (event.id === id) return false;
                        if (event.employee_id === params.employee_id) return false;
                        if (event.site_id !== params.site_id) return false;
                        if (event.state > -1) return false;
                        if (!moment(event.event_date).isSame(eventDate, 'day')) return false;
                        return true;
                    });
                    dispatch(updateEvent(id, params, uploadedFiles, false, events.length > 1));
                    events.map(event => {
                        dispatch(updateEvent(event.id, {
                            ...event,
                            workshop_id: params.workshop_id,
                            customer_id: params.customer_id,
                            dayoff_code_id: params.dayoff_code_id,
                            event_options: params.event_options,
                            event_duration: params.event_duration,
                            note: params.note,
                            start_hour: params.start_hour,
                            start_minute: params.start_minute,
                            end_hour: params.end_hour,
                            end_minute: params.end_minute,
                            start_hour_2: params.start_hour_2,
                            start_minute_2: params.start_minute_2,
                            end_hour_2: params.end_hour_2,
                            end_minute_2: params.end_minute_2,
                            start_hour_3: params.start_hour_3,
                            start_minute_3: params.start_minute_3,
                            end_hour_3: params.end_hour_3,
                            end_minute_3: params.end_minute_3,
                            signatory: params.signatory,
                            signature_date: params.signature_date,
                            employee_type_id: params.employee_type_id,
                            prestations: params.prestations,
                            form_answers: formAnswers,
                            children_events: params.children_events
                        }, uploadedFiles, true));
                    });
                } else {
                    dispatch(updateEvent(id, params, uploadedFiles, false, false));
                }
                if (props.callback) {
                    props.callback();
                }
            } else {
                // we can create multiple employee event directly
                if (props.callback) {
                    props.callback();
                }
                dispatch(createEvent(params, uploadedFiles, employeeIds));
            }
        }
    };

    React.useEffect(function () {
        if (!props.open) return;
        setTab('event');
        if (!props.event) {
            setUserHasSetDuration(false);
            setId(undefined);
            setDisabled(false);
            setFillabled(canEditPlanningForDay(props.date));
            setEventDate(props.date ? moment(props.date) : moment());
            setEventSite(undefined);
            setEventCustomer(undefined);
            setEventWorkshop(undefined);
            setChildrenEvents([]);
            setDayOffCode(undefined);
            setEventOptions({});
            setWorkshopList([]);
            setDurationHour(0);
            setDurationMinute(0);
            setEventNote('');
            setSignature(undefined);
            setSignatureDate(undefined);
            setSignatory(undefined);
            setStartingDateHour(0);
            setStartingDateMinute(0);
            setEndingDateHour(0);
            setEndingDateMinute(0);
            setStartingDateHour2(0);
            setStartingDateMinute2(0);
            setEndingDateHour2(0);
            setEndingDateMinute2(0);
            setStartingDateHour3(0);
            setStartingDateMinute3(0);
            setEndingDateHour3(0);
            setEndingDateMinute3(0);
            setFieldErrors([]);
            setUploadedFiles([]);
            setPrestations([]);
            setEmployeeTypeId(currentEmploye?.employee_type_id);
            if (props.mode === 'site') {
                setEventSite(sitesState.selectedSite);
                updateWorkshopList(sitesState.selectedSite);
            } else {
                setEmployeeIds([employeeState.selectedEmployee]);
            }
            setTotalDurationHour(0);
            setTotalDurationMinute(0);
            setFormAnswers([]);
        } else {
            setId(props.event.id);
            setUserHasSetDuration(props.event.event_duration !== 0);
            setFillabled(canEditPlanningForDay(props.event.event_date));
            setDisabled([1, 2].includes(parseInt(props.event.state, 10)));
            setEventDate(moment(props.event.event_date));
            setEventSite(props.event.site_id);
            setEventCustomer(props.event.customer_id);
            setEventWorkshop(props.event.workshop_id);
            setChildrenEvents(props.event.children_events.map(e => {
                return {
                    event_id: e.id,
                    workshop_id: e.workshop_id,
                    durationHour: parseInt(e.event_duration / 3600),
                    durationMinute: (e.event_duration % 3600) / 60
                };
            }));
            setDayOffCode(props.event.dayoff_code_id);
            setEventOptions(props.event.event_options || {});
            setDurationHour(parseInt(props.event.event_duration / 3600));
            setDurationMinute((props.event.event_duration % 3600) / 60);
            setEventNote(props.event.note || '');
            setStartingDateHour(props.event.state === -1 ? 0 : props.event.start_hour);
            setStartingDateMinute(props.event.state === -1 ? 0 : props.event.start_minute);
            setEndingDateHour(props.event.end_hour);
            setEndingDateMinute(props.event.end_minute);
            setStartingDateHour2(props.event.start_hour_2);
            setStartingDateMinute2(props.event.start_minute_2);
            setEndingDateHour2(props.event.end_hour_2);
            setEndingDateMinute2(props.event.end_minute_2);
            setStartingDateHour3(props.event.start_hour_3);
            setStartingDateMinute3(props.event.start_minute_3);
            setEndingDateHour3(props.event.end_hour_3);
            setEndingDateMinute3(props.event.end_minute_3);
            setPrestations(props.event.prestations);
            setSignature(props.event.signature);
            setSignatureDate(props.event.signature_date);
            setSignatory(props.event.signatory);
            setUploadedFiles([]);
            setFieldErrors([]);
            setEmployeeIds([props.event.employee_id]);
            setEmployeeTypeId(props.event.employee_type_id);
            let totalDuration = props.event.event_duration;
            props.event.children_events.forEach(ce => {
                totalDuration += ce.event_duration;
            });
            setTotalDurationHour(parseInt(totalDuration / 3600));
            setTotalDurationMinute((totalDuration % 3600) / 60);
            setFormAnswers(props.event.form_answers);
        }
    }, [props.open, props.event]);

    React.useEffect(function () {
        switch (props.type) {
            case 'intervention':
                setForms(formList.filter(f => {
                    if (!!f.entity_id && f.entity_id !== eventSite?.entity_id) return false;
                    return 'FEI' === f.type;
                }));
                break;
            case 'production':
                setForms(formList.filter(f => {
                    if (!!f.entity_id && f.entity_id !== eventSite?.entity_id) return false;
                    return 'FEC' === f.type;
                }));
                break;
        }
    }, [props.type, eventSite, formList]);


    React.useEffect(function () {
        if (!userHasSetDuration) {
            const timeOptions = appState.optionnalFields.filter(o => o.is_timerelated).reduce((prev, curr) => {
                if (eventOptions[curr.label]) {
                    return prev + parseFloat(eventOptions[curr.label]) * 60;
                }
                return prev;
            }, 0);
            // if time options are set, do not autocomplete event duration
            if (timeOptions === 0) {
                const timeslotDuration =
                    getTimeslotDuration(startingDateHour, startingDateMinute, endingDateHour, endingDateMinute) +
                    getTimeslotDuration(startingDateHour2, startingDateMinute2, endingDateHour2, endingDateMinute2) +
                    getTimeslotDuration(startingDateHour3, startingDateMinute3, endingDateHour3, endingDateMinute3);
                setDurationHour(Math.floor(timeslotDuration / 60));
                setDurationMinute(timeslotDuration % 60);
            }
        }
    }, [
        startingDateHour,
        startingDateMinute,
        startingDateHour2,
        startingDateMinute2,
        startingDateHour3,
        startingDateMinute3,
        endingDateHour,
        endingDateMinute,
        endingDateHour2,
        endingDateMinute2,
        endingDateHour3,
        endingDateMinute3,
        eventOptions
    ]);

    // React.useEffect(() => {
    //     // if no planned, return
    //     if (startingDateHour && startingDateMinute) return;

    //     // if end date already set, return
    //     if (endingDateHour || endingDateMinute) return;

    //     // dont set without duration
    //     if (!totalDurationHour && !totalDurationMinute) return;

    //     setEndingDateHour((startingDateHour + totalDurationHour) % 24);
    //     setEndingDateMinute((startingDateMinute + totalDurationMinute) % 60);

    // }, [totalDurationHour, totalDurationMinute]);

    React.useEffect(function () {
        setFillabled(canEditPlanningForDay(eventDate));
    }, [eventDate]);

    React.useEffect(function () {
        const localStorageKey = props.type === 'production' ? 'last-selected-site' : 'last-selected-site-intervention';
        if (eventSite) {
            const lastSelectedSite = localStorage.getItem(localStorageKey)?.split(',')?.map(x => parseInt(x)) || [];
            const idx = lastSelectedSite.indexOf(eventSite);
            if (idx > -1) {
                lastSelectedSite.splice(idx, 1);
            }
            lastSelectedSite.unshift(eventSite);
            if (lastSelectedSite.length > 9) {
                lastSelectedSite.length = 10;
            }
            localStorage.setItem(localStorageKey, lastSelectedSite.join(','));
        }
        updatePrestationList(eventSite);
    }, [eventSite]);

    const setDefaultEmployeeList = () => {
        if (props.mode === 'site') {
            const events = eventState.all;
            // by default select all employee from previous day
            let emplIds = events
                .filter(e => ['production', 'intervention'].includes(e.event_type) && e.site_id === sitesState.selectedSite && eventDate.clone().subtract(1, 'day').isSame(moment(e.event_date), 'day'))
                .map(e => e.employee_id)
                .filter((v, k, a) => a.indexOf(v) === k);
            // if no employee, try 2 days before
            if (emplIds.length === 0) {
                emplIds = events
                    .filter(e => ['production', 'intervention'].includes(e.event_type) && e.site_id === sitesState.selectedSite && eventDate.clone().subtract(2, 'day').isSame(moment(e.event_date), 'day'))
                    .map(e => e.employee_id)
                    .filter((v, k, a) => a.indexOf(v) === k);
            }
            // if no employee, try 3 days before
            if (emplIds.length === 0) {
                emplIds = events
                    .filter(e => ['production', 'intervention'].includes(e.event_type) && e.site_id === sitesState.selectedSite && eventDate.clone().subtract(3, 'day').isSame(moment(e.event_date), 'day'))
                    .map(e => e.employee_id)
                    .filter((v, k, a) => a.indexOf(v) === k);
            }
            // get last selection
            if (emplIds.length === 0) {
                emplIds = localStorage.getItem('last-selected-employees')?.
                    split(',').
                    map(x => parseInt(x, 10)).
                    filter(id => employeeListIds.includes(id))
                    || [];
            }
            setEmployeeIds(emplIds);
        }
    };

    useEffect(setDefaultEmployeeList, [eventDate]);

    React.useEffect(function () {
    }, [props.event]);

    React.useEffect(function () {
        if (!eventSite) {
            setWorkshopList([]);
            setEventCustomer(undefined);
            setEventWorkshop(undefined);
            return;
        }
        updateWorkshopList(eventSite);
    }, [eventCustomer, eventSite, props.event, props.type, sitesState.list]);

    // set default signatory on customer change
    React.useEffect(function () {
        if (props.type === 'intervention' && eventCustomer && !signature) {
            setSignatory(customersState.list.find(c => c.id === eventCustomer).shortname);
        }
    }, [eventCustomer, customersState.list, props.type, signature]);

    const getDayConfig = () => {
        let dayConfig = appState.calendarDays.find(
            d => moment(d.date).format('DD-MM-YYYY') === moment(eventDate).format('DD-MM-YYYY')
        );
        // override by employee profile if exists
        if (employeeIds.length > 0) {
            let employee = employeeState.all.find(e => e.id === employeeIds[0]);
            if (!employee) {
                employee = employeeState.team.find(e => e.id === employeeIds[0]);
            }
            if (employee?.timeslot_profile_id) {
                const profile = timeslotProfileState.all.find(p => p.id === employee.timeslot_profile_id);
                dayConfig = profile.timeslot_profile_days.find(
                    d => moment(d.date).format('DD-MM-YYYY') === moment(eventDate).format('DD-MM-YYYY')
                );
            }
        }
        return dayConfig;
    };

    const activateWholeDay = () => {
        const dayConfig = getDayConfig();
        if (!dayConfig) {
            toast.error(t('app:cannot_get_date_info'));
        } else {
            const actualTime = eventState.all
                .filter((event) => {
                    if (event.id === id) return false;
                    if (event.employee_id !== employeeState.selectedEmployee) return false;
                    if (!moment(event.event_date).isSame(eventDate, 'day')) return false;
                    return true;
                }).reduce((acc, cur) => acc + cur.event_duration, 0);
            const [hour, minute] = dayConfig.default_duration.split(':');
            const wholeDayTime = parseInt(hour) * 3600 + parseInt(minute) * 60;
            const missingTime = wholeDayTime - actualTime;
            const missingHour = Math.floor(missingTime / 3600);
            const missingMin = Math.floor((missingTime % 3600) / 60);
            setDurationHour(Math.max(0, parseInt(missingHour)));
            setDurationMinute(Math.max(0, parseInt(missingMin)));
            setTotalDurationHour(Math.max(0, parseInt(missingHour)));
            setTotalDurationMinute(Math.max(0, parseInt(missingMin)));
            if (appState.company.autocomplete_timeslot) {
                setStartingDateHour(parseInt(dayConfig.start_time?.split(':')[0]));
                setStartingDateMinute(parseInt(dayConfig.start_time?.split(':')[1]));
                setEndingDateHour(parseInt(dayConfig.end_time?.split(':')[0]));
                setEndingDateMinute(parseInt(dayConfig.end_time?.split(':')[1]));

                setStartingDateHour2(parseInt(dayConfig.start_time2?.split(':')[0]));
                setStartingDateMinute2(parseInt(dayConfig.start_time2?.split(':')[1]));
                setEndingDateHour2(parseInt(dayConfig.end_time2?.split(':')[0]));
                setEndingDateMinute2(parseInt(dayConfig.end_time2?.split(':')[1]));

                setStartingDateHour3(parseInt(dayConfig.start_time3?.split(':')[0]));
                setStartingDateMinute3(parseInt(dayConfig.start_time3?.split(':')[1]));
                setEndingDateHour3(parseInt(dayConfig.end_time3?.split(':')[0]));
                setEndingDateMinute3(parseInt(dayConfig.end_time3?.split(':')[1]));
            }
            setUserHasSetDuration(true);
        }
    };

    const updateEventDate = (date) => {
        if (minCalendarDate.clone().subtract(1, 'day') < date && maxCalendarDate > date) {
            setEventDate(moment(date));
        }
    };

    let prefix, suffix;
    if (id) {
        prefix = disabled ? 'see_an_event' : 'edit_an_event';
        let employee = employeeState.all.find(e => e.id === props.event?.employee_id);
        if (!employee) {
            employee = employeeState.team.find(e => e.id === employeeState.selectedEmployee);
        }
        suffix = `${employee?.firstname} ${employee?.lastname}`;
    } else {
        prefix = 'add_an_event';
        if (props.mode === 'site') {
            const site = sitesState.list.find(s => s.id === sitesState.selectedSite);
            suffix = site?.label;
        } else {
            let employee = employeeState.all.find(e => e.id === employeeState.selectedEmployee);
            if (!employee) {
                employee = employeeState.team.find(e => e.id === employeeState.selectedEmployee);
            }
            suffix = `${employee?.firstname} ${employee?.lastname}`;
        }
    }
    const title = t(`app:${prefix}`, { 'eventType': t(`common:${props.type}`), 'suffix': suffix });

    let icon = null;
    switch (props.type) {
        case 'production':
            icon = iconProduction;
            break;
        case 'intervention':
            icon = iconIntervention;
            break;
        case 'dayoff':
            icon = iconDayoff;
            break;
        default:
            break;
    }

    let numberOfTab = 3;
    if (props.type !== 'dayoff') numberOfTab++;
    if (props.type === 'intervention') numberOfTab++;
    if (forms.length > 0) numberOfTab++;

    let panelClassNbr = 'one-child';
    switch (numberOfTab) {
        case 2:
            panelClassNbr = 'two-child';
            break;
        case 3:
            panelClassNbr = 'three-child';
            break;
        case 4:
            panelClassNbr = 'four-child';
            break;
        case 5:
            panelClassNbr = 'five-child';
            break;
        case 6:
            panelClassNbr = 'six-child';
            break;
    }

    return (
        <>
            <Dialog
                maximized={true}
                modal={true}
                visible={props.open}
                onHide={closePopup}
                className="create-dialog"
                position="bottom"
                closable
            >
                <div className="header p-shadow-8">
                    <div className="topnavbar form-event">
                        <Button onClick={closePopup} className="p-button-link close-button">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <div className="title">
                            {title}
                        </div>
                        {
                            !disabled && fillabled ?
                                <Button
                                    onClick={() => validForm(false)}
                                    className={'p-button-link create-event-button'}>
                                    <FontAwesomeIcon icon={faSave} />
                                </Button> : null
                        }
                        {
                            props.event && props.event.state > -1 && childrenEvents.length === 0 ?
                                <Button
                                    onClick={() => {
                                        setId(undefined);
                                        setDisabled(false);
                                        setFillabled(true);
                                        setSignature(undefined);
                                        setSignatureDate(undefined);
                                        updateEventDate(moment(eventDate).clone().add(1, 'days'));
                                        window.scroll(0, 0);
                                    }}
                                    className={'p-button-link create-event-button'}>
                                    <FontAwesomeIcon icon={faCopy} />
                                </Button> : null
                        }
                        {
                            ((router.history.location.pathname === '/app/sites/activity') && (parseInt(props.event?.state) === -1)) ?
                                <Button
                                    onClick={() => {
                                        validForm(true);
                                    }}
                                    className={'p-button-link create-event-button'}>
                                    <FontAwesomeIcon style={{
                                        position: 'absolute',
                                        top: '11px',
                                        left: '15px',
                                        background: 'black'
                                    }} icon={faSave} />
                                    <FontAwesomeIcon style={{
                                        position: 'absolute',
                                        top: '16px',
                                        left: '12px',
                                        background: 'black'
                                    }} icon={faSave} />
                                    <FontAwesomeIcon style={{
                                        position: 'absolute',
                                        top: '21px',
                                        left: '9px',
                                        background: 'black'
                                    }} icon={faSave} />
                                </Button> : null
                        }
                        {
                            props.event && !!props.event.id && props.event.state !== 1 && !disabled && fillabled ?
                                <Button
                                    onClick={confirmDelete}
                                    className={'p-button-link create-event-button'}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button> : null
                        }
                    </div>
                </div>
                <div className="event-type-desc">
                    <div className="icon">
                        <img src={icon} />
                    </div>
                    <div className="text">
                        {t(`common:${props.type}`)}
                    </div>
                </div>
                <DayNavigation
                    disabled={disabled}
                    goBefore={() => setEventDate(moment(eventDate).clone().subtract(1, 'days'))}
                    goNext={() => setEventDate(moment(eventDate).clone().add(1, 'days'))}
                    value={eventDate}
                    onChange={(d) => setEventDate(moment(d.value))}
                />
                <div className={`panel ${panelClassNbr}`}>
                    <Button onClick={() => setTab('event')} className={`p-button-link ${tab === 'event' ? 'selected' : ''}`}>
                        <div className="icon">
                            <FontAwesomeIcon icon={faCalendarStar} />
                        </div>
                        <div className="text">
                            {t('common:event')}
                        </div>
                    </Button>
                    {props.type !== 'dayoff' ?
                        <Button onClick={() => setTab('options')} className={`p-button-link ${fieldErrors.includes('timeField') ? 'missing' : ''} ${tab === 'options' ? 'selected' : ''}`}>
                            <div className="icon">
                                <FontAwesomeIcon icon={faCog} />
                            </div>
                            <div className="text">
                                {t('common:options')}
                            </div>
                        </Button> : null
                    }
                    <Button onClick={() => setTab('timeslot')} className={`p-button-link ${fieldErrors.includes('timeField') || (fieldErrors.includes('timeslotNeeded')) ? 'missing' : ''} ${tab === 'timeslot' ? 'selected' : ''}`}>
                        <div className="icon">
                            <FontAwesomeIcon icon={faCalendar} />
                        </div>
                        <div className="text">
                            {t('common:timeslot')}
                        </div>
                    </Button>
                    {props.type === 'intervention' ?
                        <Button onClick={() => setTab('intervention')} className={`p-button-link ${fieldErrors.includes('prestation') ? 'missing' : ''} ${tab === 'intervention' ? 'selected' : ''}`}>
                            <div className="icon">
                                <FontAwesomeIcon icon={faTools} />
                            </div>
                            <div className="text">
                                {t('common:intervention')}
                            </div>
                        </Button> : null
                    }
                    {forms.length > 0 ?
                        <Button onClick={() => setTab('formulaire')} className={`p-button-link ${fieldErrors.includes('form') ? 'missing' : ''} ${tab === 'formulaire' ? 'selected' : ''}`}>
                            <div className="icon">
                                <FontAwesomeIcon icon={faBallotCheck} />
                            </div>
                            <div className="text">
                                Formulaire
                            </div>
                        </Button> : null
                    }
                    <Button onClick={() => setTab('infos')} className={`p-button-link ${tab === 'infos' ? 'selected' : ''}`}>
                        <div className="icon">
                            <FontAwesomeIcon icon={faFileAlt} />
                        </div>
                        <div className="text">
                            {t('common:info_and_doc')}
                        </div>
                    </Button>
                </div>
                {
                    tab === 'event' ?
                        <FormEventCore
                            fieldErrors={fieldErrors}
                            disabled={disabled || !fillabled}
                            eventSite={eventSite}
                            setEventSite={setEventSite}
                            eventNote={eventNote}
                            setEventNote={setEventNote}
                            eventCustomer={eventCustomer}
                            setEventCustomer={setEventCustomer}
                            eventWorkshop={eventWorkshop}
                            setEventWorkshop={setEventWorkshop}
                            workshopList={workshopList}
                            setUserHasSetDuration={setUserHasSetDuration}
                            durationHour={durationHour}
                            setDurationHour={setDurationHour}
                            durationMinute={durationMinute}
                            setDurationMinute={setDurationMinute}
                            dayOffCode={dayOffCode}
                            setDayOffCode={setDayOffCode}
                            type={props.type}
                            activateWholeDay={activateWholeDay}
                            employeeTypeId={employeeTypeId}
                            setEmployeeTypeId={setEmployeeTypeId}
                            mode={props.mode}
                            currentEmploye={currentEmploye}
                            setEmployeeIds={(list) => {
                                setEmployeeIds(list);
                                localStorage.setItem('last-selected-employees', list.join(','));
                            }}
                            employeeIds={employeeIds}
                            childrenEvents={childrenEvents}
                            setChildrenEvents={setChildrenEvents}
                            totalDurationHour={totalDurationHour}
                            setTotalDurationHour={setTotalDurationHour}
                            totalDurationMinute={totalDurationMinute}
                            setTotalDurationMinute={setTotalDurationMinute}
                        /> : null
                }
                {
                    tab === 'options' ?
                        <FormEventOptions
                            event={props.event}
                            fieldErrors={fieldErrors}
                            disabled={disabled || !fillabled}
                            eventType={props.type}
                            eventOptions={eventOptions}
                            setEventOptions={setEventOptions}
                            eventSite={eventSite}
                            eventCustomer={eventCustomer}
                        />
                        : null
                }
                {
                    tab === 'infos' ?
                        <FormEventInformations
                            disabled={disabled || !fillabled}
                            event={props.event}
                            eventSite={eventSite}
                            eventCustomer={eventCustomer}
                        />
                        : null
                }
                {
                    tab === 'timeslot' ?
                        <div className="tabTimeslot p-shadow-2">
                            {
                                fieldErrors.includes('timeField') ?
                                    <div className="event-options-error" justify="center">
                                        {t('app:cannot_create_scheduled_event')}
                                    </div>
                                    : null
                            }
                            {company.config_time_slot_shown > 0 ? <FormEventTimeslot
                                disabled={disabled || !fillabled}
                                slot={1}
                                company={company}
                                fieldErrors={fieldErrors}
                                startingDateHour={startingDateHour}
                                defaultStartingDateHour={props.event?.start_hour}
                                defaultStartingDateMinute={props.event?.start_minute}
                                setStartingDateHour={setStartingDateHour}
                                startingDateMinute={startingDateMinute}
                                setStartingDateMinute={setStartingDateMinute}
                                endingDateHour={endingDateHour}
                                setEndingDateHour={setEndingDateHour}
                                endingDateMinute={endingDateMinute}
                                setEndingDateMinute={setEndingDateMinute}
                            /> : null
                            }
                            {company.config_time_slot_shown > 1 ? <FormEventTimeslot
                                disabled={disabled || !fillabled}
                                slot={2}
                                company={company}
                                fieldErrors={fieldErrors}
                                previousEndingDateHour={endingDateHour}
                                previousEndingDateMinute={endingDateMinute}
                                startingDateHour={startingDateHour2}
                                setStartingDateHour={setStartingDateHour2}
                                startingDateMinute={startingDateMinute2}
                                setStartingDateMinute={setStartingDateMinute2}
                                endingDateHour={endingDateHour2}
                                setEndingDateHour={setEndingDateHour2}
                                endingDateMinute={endingDateMinute2}
                                setEndingDateMinute={setEndingDateMinute2}
                            /> : null
                            }
                            {company.config_time_slot_shown > 2 ? <FormEventTimeslot
                                disabled={disabled || !fillabled}
                                slot={3}
                                company={company}
                                fieldErrors={fieldErrors}
                                previousEndingDateHour={endingDateHour2}
                                previousEndingDateMinute={endingDateMinute2}
                                startingDateHour={startingDateHour3}
                                setStartingDateHour={setStartingDateHour3}
                                startingDateMinute={startingDateMinute3}
                                setStartingDateMinute={setStartingDateMinute3}
                                endingDateHour={endingDateHour3}
                                setEndingDateHour={setEndingDateHour3}
                                endingDateMinute={endingDateMinute3}
                                setEndingDateMinute={setEndingDateMinute3}
                            /> : null
                            }
                        </div> : null
                }
                {tab === 'intervention' ?
                    <FormEventIntervention
                        fieldErrors={fieldErrors}
                        event={props.event}
                        addFile={addFile}
                        setUploadedFiles={setUploadedFiles}
                        uploadedFiles={uploadedFiles}
                        disabled={disabled || !fillabled}
                        setPrestations={setPrestations}
                        prestations={prestations}
                        prestationList={prestationList}
                        signature={signature}
                        signatureDate={signatureDate}
                        signatory={signatory}
                        onSignatoryChange={setSignatory}
                        onSignatureChange={(val) => {
                            setSignature(val);
                            setSignatureDate(moment());
                        }}
                    /> : null
                }
                {tab === 'formulaire' ?
                    <FormSelector
                        forms={forms}
                        formAnswers={formAnswers}
                        setFormAnswers={setFormAnswers}
                        disabled={disabled}
                        eventDate={props.event?.event_date}
                        saveFormAnswer={() => validForm(false, true)}
                        customer={customersState.list?.find(c => c.id === eventCustomer)}
                        site={sitesState.list?.find(s => s.id === eventSite)}
                        employee={employeeIds[0]}
                        supplier={null}
                        material={null}
                        individualSave={true}
                    /> : null
                }
            </Dialog>
        </>
    );
};

export default FormEvent;
