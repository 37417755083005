import i18n from 'i18next';
import { toast } from 'react-toastify';

export const usersReceived = (users) => ({
    type: 'USERS_RECEIVED',
    payload: {
        users
    }
});

export const usersReceivedError = () => toast.error(i18n.t('users:cannot_get_user_list'));
