import React, { useEffect, useState } from 'react';
import { faBallotCheck, faPersonDigging } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormSelector from '../../forms/FormSelector';
import { InputTextarea } from 'primereact/inputtextarea';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { getLogguedUser } from '../../../utils/access';
import moment from 'moment';
import { toast } from 'react-toastify';
import { updateSiteValidation } from '../actions';
import { useTranslation } from 'react-i18next';

const SiteValidation = props => {
    const { t } = useTranslation();
    const siteState = useSelector(state => state.sites);
    const formList = useSelector(state => state.forms.list);
    const customerList = useSelector(state => state.customers.list);
    const isOfflineMode = useSelector(state => state.core.offlineMode);
    const site = siteState.list.find(s => s.id === siteState.selectedSite);
    const day = site?.validation_days.find(v => moment(v.date).isSame(moment(props.date), 'day'));
    const [comment, setComment] = useState(day?.comment || '');
    const [valid, setValid] = useState(day?.valid || false);
    const [tab, setTab] = React.useState('validation');
    const [formAnswers, setFormAnswers] = React.useState([]);
    const [forms, setForms] = React.useState([]);
    const dispatch = useDispatch();

    useEffect(function () {
        setForms(formList.filter(f => {
            if (!!f.entity_id && f.entity_id !== site?.entity_id) return false;
            return 'FCH' === f.type;
        }));
    }, [site, formList]);

    useEffect(function () {
        setComment(day?.comment || '');
        setValid(day?.valid || false);
        setFormAnswers(day?.form_answers || []);
        setTab('validation');
    }, [props.date, site.selectedSite]);

    const currentUser = getLogguedUser();
    let customer = null;
    if (site.customer_id) {
        customer = customerList.find(c => c.id === site.customer_id);
    }

    return <>
        {
            forms.length > 0 ?
                <div className="panel">
                    {
                        day?.valid ?
                            <>
                                <Button onClick={() => setTab('validation')} className={`p-button-link ${tab === 'event' ? 'selected' : ''}`}>
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faPersonDigging} />
                                    </div>
                                    <div className="text">
                                        {t('common:event')}
                                    </div>
                                </Button>
                                <Button onClick={() => setTab('forms')} className={`p-button-link ${tab === 'forms' ? 'selected' : ''}`}>
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faBallotCheck} />
                                    </div>
                                    <div className="text">
                                        {t('common:form')}
                                    </div>
                                </Button>
                            </>
                            : null
                    }
                </div>
                : null
        }
        {tab === 'validation' ?
            <>
                <div className="label">
                    {t('app:day_product')}
                </div>
                <InputTextarea
                    className="site_comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={10}
                />
                <div className="site_validation_info">
                    {
                        day?.valid ?
                            t('app:day_confirmed', {
                                when: moment(day?.date).format('dddd DD/MM/YYYY'),
                                who: `${day?.user?.lastname?.toUpperCase()} ${day?.user?.firstname}`
                            }) :
                            t('app:day_confirm', {
                                when: props.date.format('dddd DD/MM/YYYY'),
                                who: `${currentUser.lastname.toUpperCase()} ${currentUser.firstname}`
                            })
                    }
                </div>
                <Button
                    className={`site_validation p-shadow-2 p-button-link ${valid ? 'validated' : ''}`}
                    disabled={isOfflineMode || day?.valid}
                    onClick={() => {
                        dispatch(updateSiteValidation(site, props.date, !valid, formAnswers, comment));
                        setValid(!valid);
                    }}
                >
                    {valid ? <FontAwesomeIcon icon={faCheck} /> : ''}
                    <label htmlFor="valid">
                        {t(day?.valid ? 'common:confirmed' : 'common:confirm')}
                    </label>
                </Button>
            </>
            : null
        }
        {tab === 'forms' ?
            <div style={{
                margin: '0 1rem'
            }}>
                <FormSelector
                    forms={forms}
                    formAnswers={formAnswers}
                    setFormAnswers={setFormAnswers}
                    eventDate={props.date}
                    disabled={false}
                    showSaveButton={true}
                    saveFormAnswer={(_answers, showToast) => {
                        dispatch(updateSiteValidation(
                            site,
                            props.date,
                            valid,
                            _answers,
                            comment
                        ));
                        if (showToast) toast.success('Formulaire sauvegardé');
                    }}
                    site={site}
                    employee={null}
                    material={null}
                    supplier={null}
                    customer={customer}
                    siteDayShown={siteState.siteDayShown}
                />
            </div>
            : null
        }
    </>;
};

export default SiteValidation;

