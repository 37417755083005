const initialState = () => ({
    all: [],
});

const securityReportsReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'SECURITY_REPORT_RECEIVED':
            newState.all = action.payload;
            return newState;
        case 'SECURITY_REPORT_UPDATED':
            newState.all.forEach((sr, idx) => {
                if (sr.id === action.payload.id) {
                    newState.all[idx] = action.payload;
                }
            });
            return newState;
        case 'SECURITY_REPORT_CREATED':
            newState.all.push(action.payload);
            return newState;
        case 'SECURITY_REPORT_DELETED':
            newState.all.forEach((sr, idx) => {
                if (sr.id === action.payload) {
                    newState.all.splice(idx, 1);
                }
            });
            return newState;
        case 'SECURITY_REPORT_UPLOAD_PICTURES_SUCCESS':
            newState.all.forEach((report, idx) => {
                if (parseInt(report.id) === parseInt(action.payload[0].data.security_report_id)) {
                    action.payload.forEach(row => {
                        newState.all[idx].pictures.push(row.data);
                    });
                }
            });
            return newState;
        case 'PICTURE_UPDATED':
            if (!action.payload.security_report_id) return state;
            newState.all.forEach((m, idx) => {
                if (m.id === action.payload.security_report_id) {
                    newState.all[idx].pictures.forEach((p, m_idx) => {
                        if (newState.all[idx].pictures[m_idx].id === action.payload.id) {
                            newState.all[idx].pictures[m_idx].comment = action.payload.comment;
                        }
                    });
                }
            });
            return newState;
        case 'PICTURE_REMOVE_SUCCESS':
            // if (!action.payload.security_report_id) return state;
            newState.all.forEach((m, idx) => {
                if (m.team_picture_id === action.payload.id) {
                    newState.all[idx].team_picture_id = null;
                    newState.all[idx].team_picture = null;
                }
                if (m.id === action.payload.security_report_id) {
                    newState.all[idx].pictures.forEach((p, m_idx) => {
                        if (newState.all[idx].pictures[m_idx].id === action.payload.id) {
                            newState.all[idx].pictures.splice(m_idx, 1);
                        }
                    });
                }
            });
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default securityReportsReducer;
