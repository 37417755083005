import React, { useEffect, useState } from 'react';

import DocumentRow from '../../../files/components/DocumentRow';
import PictureRow from '../../../pictures/PictureRow';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const FormEventInformations = props => {
    const {
        eventSite,
        eventCustomer,
    } = props;

    const [site, setSite] = useState(null);
    const [customer, setCustomer] = useState(null);
    const { t } = useTranslation();
    const sites = useSelector(state => state.sites.list);
    const customers = useSelector(state => state.customers.list);

    useEffect(function () {
        if (eventSite) {
            setSite(sites.find(s => s.id === eventSite));
        }
    }, [eventSite, sites]);

    useEffect(function () {
        if (eventCustomer) {
            setCustomer(customers.find(c => c.id === eventCustomer));
        }
    }, [eventCustomer, customers]);

    return <div className="tabInfos p-shadow-2">
        {site ? <>
            <p className="select_title">{t('common:site')}</p>
            {site.label ? <>{site.label}<br /></> : null}
            {site.address ? <>{site.address}<br /></> : null}
            {site.city ? <>{site.city}<br /></> : null}
            {site.phone ? <>{site.phone}<br /></> : null}
        </> : null}
        {customer ? <>
            <p className="select_title">{t('common:customer')}</p>
            {customer.fullname ? <>{customer.fullname}<br /></> : null}
            {customer.address ? <>{customer.address}<br /></> : null}
            {customer.address_2 ? <>{customer.address_2}<br /></> : null}
            {customer.zipcode ? <>{customer.zipcode}<br /></> : null}
            {customer.phone ? <>{customer.phone}<br /></> : null}
            {customer.mobile ? <>{customer.mobile}<br /></> : null}
            {customer.information ? <>{customer.information}<br /></> : null}
        </> : null}
        {
            props.event?.files?.length > 0 ?
                props.event?.files.sort((a, b) => {
                    if (a.extension > b.extension) return 1;
                    if (a.extension > b.extension) return -1;
                    return 0;
                })
                    .sort((a, b) => {
                        if (a.title > b.title) return 1;
                        if (a.title < b.title) return -1;
                        return 0;
                    })
                    .map(file => {
                        if (file.extension.toLowerCase() === 'pdf') {
                            return <>
                                <DocumentRow key={file.id} file={file} />
                            </>;
                        }
                        if (file.extension.toLowerCase() === 'png' || file.extension.toLowerCase() === 'jpg' || file.extension.toLowerCase() === 'jpeg') {
                            return <>
                                <PictureRow key={file.id} file={file} />
                            </>;
                        }
                        return null;
                    })
                : <div style={{marginTop:'20px',fontStyle:'italic'}}>{t('app:no_file')}</div>
        }
    </div >;
};

export default FormEventInformations;
