import React, { useEffect } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useSelector } from 'react-redux';

const DayNavigation = props => {

    const appState = useSelector(state => state.app);

    const minCalendarDate = moment(appState.calendarDays[0]['date']);
    const maxCalendarDate = moment(appState.calendarDays[appState.calendarDays.length - 1]['date']);

    useEffect(function() {
        if(!props.noLimit) {
            if (minCalendarDate.isAfter(props.value)) {
                props.onChange({value: minCalendarDate.toDate()});
            }
            if (maxCalendarDate.isBefore(props.value)) {
                props.onChange({value: maxCalendarDate.toDate()});
            }
        }
    }, []);


    return <div className="day-navigation">
        <Button disabled={
            !props.noLimit && minCalendarDate.isSameOrAfter(props.value) || props.disabled
        } size="small" className="p-button-link" onClick={props.goBefore}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <div className="currentDay">
            <Calendar
                minDate={props.noLimit ? null : minCalendarDate.toDate()}
                maxDate={props.noLimit ? null : maxCalendarDate.toDate()}
                disabled={props.disabled}
                locale="fr"
                dateFormat="DD dd MM yy"
                value={props.value.toDate()}
                onChange={props.onChange}
            />
        </div>
        <Button disabled={
            !props.noLimit && maxCalendarDate.isSameOrBefore(props.value) || props.disabled
        } size="small" className="p-button-link" onClick={props.goNext}>
            <FontAwesomeIcon icon={faChevronRight} />
        </Button>
    </div>;
};

export default DayNavigation;