// Libs
import { applyMiddleware, combineReducers, createStore } from 'redux';

import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

const stores = ['forms', 'attachment', 'securityReports', 'groupRisks', 'cycles', 'orders', 'timeslotProfiles', 'folders', 'auth', 'articles', 'users', 'materials', 'core', 'app', 'dayoff', 'employees', 'sites', 'customers', 'events', 'prestations', 'suppliers', 'depositTickets', 'siteTickets', 'typedoc', 'siteTrashDeposit', 'materialTypes'];
const reducers = {};
let middlewares;
const states = {};

stores.forEach(store => {
    reducers[store] = require(`./modules/${store}/reducer`).default; // eslint-disable-line
});

const errorMiddleware = (/* store */) => next => action => {
    try {
        next(action);
    } catch (e) {
        // eslint-disable-next-line no-console
    }
};

if (process.env.NODE_ENV === 'production') {
    middlewares = applyMiddleware(errorMiddleware, ReduxThunk);
} else {
    middlewares = applyMiddleware(errorMiddleware, ReduxThunk, createLogger({ duration: true }));
}

const appReducer = combineReducers(reducers);
const store = createStore(appReducer, states, middlewares);

export default store;
