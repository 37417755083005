import axios from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { deleteStoreAndCache, addToStoreAndCache } from '../offline/helper';
import store from '../../store';

export const dayoffCreated = (dayoff) => ({
    type: 'DAYOFF_CREATED',
    payload: dayoff
});

export const dayoffReceived = (dayoffs) => ({
    type: 'DAYOFF_RECEIVED',
    payload: dayoffs
});


export const dayoffCreateSuccess = () => toast.success(i18n.t('app:dayoff_created'));
export const dayoffCreateError = () => toast.error(i18n.t('app:cannot_create_dayoff'));

export const createDayoff = (params) => (dispatch) => {
    const isOfflineMode = store.getState().core.offlineMode;
    if(isOfflineMode) {
        addToStoreAndCache('dayoff', params, () => {
            dayoffCreateSuccess();
            dispatch(dayoffCreated(params));
        });
    } else {
        return axios.post('/dayoff', params)
            .then(data => {
                dayoffCreateSuccess();
                dispatch(dayoffCreated(data.data));
            })
            .catch(err => dayoffCreateError(err));
    }
};

export const dayoffDeleted = (id) => ({
    type: 'DAYOFF_DELETED',
    payload: id
});

export const deleteSuccess = () => toast.success(i18n.t('app:dayoff_deleted'));
export const deleteError = () => toast.error(i18n.t('app:cannot_delete_dayoff'));
export const deleteDayoff = (id) => (dispatch) => {
    const isOfflineMode = store.getState().core.offlineMode;
    if(isOfflineMode) {
        deleteStoreAndCache('dayoff', id, () => {
            deleteSuccess();
            dispatch(dayoffDeleted(id));
        });
    } else {
        return axios.delete(`/dayoff/${id}`)
            .then(() => {
                deleteSuccess();
                dispatch(dayoffDeleted(id));
            })
            .catch(err => deleteError(err));
    }
};
