const initialState = () => ({
    all: [],
});

const groupRisksReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'GROUP_RISKS_RECEIVED':
            newState.all = action.payload;
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default groupRisksReducer;
