import { faMinus, faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';

import { Button } from 'primereact/button';
import Canvas from '../../../signature/SignatureCanva';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiSelect } from 'primereact/multiselect';
import PictureRow from '../../../pictures/PictureRow';
import PictureRowPreview from '../../../pictures/PictureRowPreview';
import React from 'react';
import Uploader from '../../../uploader';
import { canSetPrestationQuantity } from '../../../../utils/access';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../../../utils/components/NumberInput';

const FormEventIntervention = function (props) {
    const { t } = useTranslation();

    const prestationIds = props.prestations.map(p => p.id);

    const dropdownOptionPrestation = (option) => {
        return (
            <div key={option.value} className="dropdown-row">
                <div>{option.label}</div>
            </div>
        );
    };

    const prestationOptions = props.prestationList.groups.map(group => {
        return {
            label: group.label,
            items: props.prestationList.all
                .filter(p => p.prestation_group_id === group.id)
                .map(p => {
                    return {
                        'label': p.description,
                        'value': p.id,
                        'code': '' + p.code
                    };
                })
        };
    }).filter(g => g.items.length > 0);

    const removeTempPicture = (idx) => {
        const newUploadedFile = props.uploadedFiles.splice(0);
        newUploadedFile.splice(idx, 1);
        props.setUploadedFiles(newUploadedFile);
    };

    const handleUpdateQtPrestation = (p, qt) => {
        const _prestations = props.prestations.slice(0);
        const prest = _prestations.find(pr => pr.id === p.id);
        prest.pivot.qt_prestation = qt || 0;
        props.setPrestations(_prestations);
    };

    const handleOnChangePrestation = (e) => {
        // Parcours des prestations sélectionnées.
        const _prestations = e.value.map(p_id => {
            const prestationExist = props.prestations.find(p => p.id === p_id);

            if (!prestationExist) {
                const _prest = props.prestationList.all.find(p => p.id === p_id);

                return ({
                    code: _prest.code,
                    company_id: _prest.company_id,
                    created_at: _prest.created_at,
                    description: _prest.description,
                    disabled: _prest.disabled,
                    id: _prest.id,
                    import_id: _prest.import_id,
                    pivot: {
                        prestation_id: _prest.id,
                        qt_prestation: 1
                    },
                    prestation_group_id: _prest.prestation_group_id,
                    updated_at: _prest.updated_at
                });
            }
            return prestationExist;
        });
        props.setPrestations(_prestations);
    };

    return <>
        <div className="tabIntervention p-shadow-2">
            <p className="select_title">
                {t('common:prestations')}
            </p>
            {
                <div className="event-options-error" justify="center">
                    {props.fieldErrors.includes('prestation') ? t('app:need_prestation') : ''}
                </div>

            }
            {props.prestations.length > 0 ?
                <div className="selected-prestation-list">
                    {props.prestations.map(p =>
                        <div key={p.id} className="prestation-row">
                            <div className="title" >
                                <div className="category">
                                    {props.prestationList.groups.find(g => g.id === p.prestation_group_id).label}
                                </div>
                                <div className="desc">
                                    {p.description}
                                </div>
                            </div>

                            {canSetPrestationQuantity() ?
                                <div className='prestation-input-qt'>
                                    <Button
                                        disabled={props.disabled}
                                        className="prestation-button p-button-link"
                                        onClick={() => { handleUpdateQtPrestation(p, p.pivot.qt_prestation - 1); }}
                                    >
                                        <FontAwesomeIcon icon={faMinus} />
                                    </Button>
                                    <div className="article-count">
                                        <NumberInput
                                            disabled={props.disabled}
                                            value={p.pivot.qt_prestation}
                                            onValueChange={e => { handleUpdateQtPrestation(p, e.value); }}
                                        />
                                    </div>
                                    <Button
                                        disabled={props.disabled}
                                        className="prestation-button p-button-link"
                                        onClick={() => { handleUpdateQtPrestation(p, parseFloat(p.pivot.qt_prestation) + 1); }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </div>
                                : null
                            }
                            {!props.disabled ?
                                <Button
                                    className="remove p-button-link"
                                    onClick={() => {
                                        const _prestations = props.prestations.slice(0);
                                        const index = prestationIds.indexOf(p.id);
                                        _prestations.splice(index, 1);
                                        props.setPrestations(_prestations);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                                : null
                            }
                        </div>
                    )}
                </div>
                : null
            }
            <div className="available-prestation">

                <MultiSelect
                    panelClassName="multiselect-prestation"
                    disabled={props.disabled}
                    value={prestationIds}
                    options={prestationOptions}
                    onChange={e => { handleOnChangePrestation(e); }}
                    placeholder={t('app:find_prestations')}
                    filter
                    filterBy="code,label"
                    itemTemplate={dropdownOptionPrestation}
                    dropdownIcon="pi pi-plus"
                    optionLabel="label"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    showSelectAll={false}
                    selectedItemsLabel={t('app:prestation_selected', { count: prestationIds.length })}
                    maxSelectedLabels={2}

                />
            </div>
        </div>
        <div className="tabIntervention p-shadow-2">
            <p className="select_title">
                {t('common:pictures')}
            </p>
            <div className="pictures-list">
                <Uploader withFootbar sendPicture={props.addFile} />
                {
                    props.event?.pictures.length > 0
                        ? props.event.pictures.map(p => <PictureRow key={p.id} picture={p} />
                        ) : null
                }
                {props.uploadedFiles.map((p, idx) => <PictureRowPreview key={p.file} onDelete={() => removeTempPicture(idx)} picture={p.file} desc={p.desc} onChangeDesc={value => {
                    const newUploadedFiles = props.uploadedFiles.slice(0);
                    newUploadedFiles[idx].desc = value;
                    props.setUploadedFiles(newUploadedFiles);
                }} />)}
            </div>
        </div>
        <div className="tabIntervention p-shadow-2">
            <p className="select_title">
                {t('common:signature')}
            </p>
            <Canvas
                disabled={props.disabled}
                signature={props.signature}
                signatory={props.signatory}
                showSignatory={true}
                signatureDate={props.signatureDate}
                onSignatureChange={props.onSignatureChange}
                onSignatoryChange={props.onSignatoryChange}
            />
            {
                (props.signature) ? <div style={{
                    margin: '1rem',
                    marginBottom: '1rem'
                }} className="signature-date">
                    {t('app:inter_signed_by', {
                        who: props.signatory,
                        day: moment(props.signatureDate).format('DD/MM/YY'),
                        time: moment(props.signatureDate).format('HH:mm')
                    })}
                </div> : null
            }
        </div>
    </>;
};

export default FormEventIntervention;
