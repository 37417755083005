import React, { useRef, useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';


const Canvas = function (props) {

    /* props :
    IN :
    - disabled
    - signature
    - signatory
    - signatureDate
    OUT :
    - onSignatureChange
    - onSignatoryChange
    */
    const sigCanvas = useRef(null);
    const sigCanvasWrapper = useRef(null);
    const [width, setWidth] = useState(450);
    const [height, setHeight] = useState(300);
    const { t } = useTranslation();

    useEffect(function () {
        if (props.disabled) {
            sigCanvas.current.off();
        } else {
            sigCanvas.current.on();
        }
    }, [props.disabled]);


    useEffect(function () {
        if (props.signature) {
            sigCanvas.current.off();
        } else {
            sigCanvas.current.on();
        }
    }, [props.signature]);

    useEffect(function() {
        setWidth(Math.min(1200, sigCanvasWrapper.current.offsetWidth));
        setHeight(Math.min(650, sigCanvasWrapper.current.offsetWidth / 1.5));
    }, [sigCanvasWrapper.current]);

    useEffect(function () {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        // const ratio = 1;
        const canvas = sigCanvas.current.getCanvas();
        canvas.width = Math.ceil(width * ratio);
        canvas.height = Math.ceil(height * ratio - 2);
        const context = canvas.getContext('2d');
        sigCanvas.current.fromDataURL(props.signature);
        canvas.width = Math.ceil(width);
        canvas.height = Math.ceil(height);
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = 'black';
    }, [props.signature, width, height]);

    return <>
        {
            !props.disabled && props.showSignatory ?
                <InputText
                    className="signatory"
                    placeholder={t('app:signatory')}
                    value={props.signatory}
                    onChange={(e) => props.onSignatoryChange(e.target.value)}
                />
                : null
        }
        <div className="sigCanvasWrapper" style={{'display':'flex','justifyContent':'center'}} ref={sigCanvasWrapper}>
            <SignatureCanvas penColor='black'
                ref={sigCanvas}
                canvasProps={{
                    width: width,
                    height: height,
                    background: 'rgba(255,255,255)'
                }}
            />
        </div>
        {!props.disabled ?
            <div className="signature-actions">
                <Button disabled={!props.signature} className="clear-canvas" variant="contained" color="primary" size="large" onClick={() => {
                    props.onSignatureChange(undefined);
                }}>
                    {t('common:modify')}
                </Button>
                <Button disabled={props.signature} className="clear-canvas" variant="contained" color="primary" size="large" onClick={() => {
                    if (!props.signatory && props.showSignatory) { toast.error(t('app:need_signatory')); return; }
                    props.onSignatureChange(sigCanvas.current.toDataURL('image/jpeg'));
                }}>
                    {t('common:valid')}
                </Button>
            </div> : null
        }
    </>;
};

export default Canvas;