import { getLogguedUser } from './access';
import moment from 'moment';
import store from '../store';

export const getEventDuration = event => {
    const state = store.getState();
    return event.event_duration + state.app.optionnalFields.filter(o => o.is_timerelated === 1).reduce((total, option) => {
        if(event.event_options === null || event.event_options === undefined) return total;
        const event_option_value = event.event_options[option.import_id];
        if (event_option_value) return total + event_option_value * 3600;
        return total;
    }, 0);
};

export const canEditPlanningForDay = day => {
    const state = store.getState();
    const appState= state.app;
    const currentUser = getLogguedUser();

    // if has valid access
    if (currentUser.is_valid) return true;
    const dayConfig = appState.calendarDays.find(
        d => moment(d.date).format('DD-MM-YYYY') === moment(day).format('DD-MM-YYYY')
    );

    // if no day was found, block access
    if (!dayConfig) return false;

    //else check if day is fillable
    return !!dayConfig.fillable_by_users;
};
