import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Dayoff from './components/Dayoff';

import {deleteDayoff} from './actions';
import './style.scss';
import DropDownEmployee from '../employees/components/DropDownEmployee';
import Footbar from '../employees/components/FootBar';

import imgNoEvent from '../../assets/no-event.png';

const DayOff = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const employeeState = useSelector(state => state.employees);
    const dayoffState = useSelector(state => state.dayoff);
    // get current employee
    let currentEmploye = employeeState.team.find(empl =>
        empl.id === employeeState.selectedEmployee
    );
    if (!currentEmploye) {
        currentEmploye = employeeState.all.find(empl =>
            empl.id === employeeState.selectedEmployee
        );
    }
    const dayoffs = dayoffState.all.filter(d => d.employee_id === currentEmploye.id);
    return <div className="module-page module-dayoff">
        <div className="label">
            {t('app:selected_employee')}
        </div>
        <DropDownEmployee/>
        <Footbar/>
        {
            dayoffs.length === 0
                ? <div className="no-data">
                    <img src={imgNoEvent} />
                    <div className="label">{t('app:no_dayoff_request')}</div>
                </div>
                : <div className="dayoffs">
                    <div className="label" style={{marginTop:'32px'}}>
                        {t('app:request_dayoff_list')}
                    </div>
                    { dayoffs.map(d => <Dayoff delete={() => dispatch(deleteDayoff(d.id))} key={d.id} className="dayoff" dayoff={d}/>)}
                </div>
        }
    </div>;
};

export default DayOff;
