// libs
import axios from 'axios';
import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { SpinnerDotted } from 'spinners-react';
// import { useDispatch } from 'react-redux';
import { stopOfflineMode } from '../core/actions';
import localforage from 'localforage';
import { toast } from 'react-toastify';
// eslint-disable-next-line no-unused-vars
import _localforageGetItems from 'localforage-getitems';
import { createEvent, deleteEvent, updateEvent } from '../events/actions';
import { useDispatch } from 'react-redux';
import { createSiteConsumption, createSupplyOrder, deleteSupply, updateSiteConsumption, updateSupplyOrder } from '../sites/actions';
import { createSiteTicket, deleteSiteTicket, updateSiteTicket } from '../siteTickets/actions';
import { createDayoff, deleteDayoff } from '../dayoff/actions';
import { deleteDepositTicket, updateDepositTicket } from '../depositTickets/actions';

const FormStopOffline = props => {
    const { t } = useTranslation();
    const { opened, onClose } = props;
    const dispatch = useDispatch();

    useEffect(async () => {
        if (opened) {
            const resourceLF = localforage.createInstance({ name: 'optim_resources'});
            const resourceLFCreated= localforage.createInstance({ name: 'optim_resources_created'});
            const resourceLFUpdated = localforage.createInstance({ name: 'optim_resources_updated'});
            const resourceLFDeleted= localforage.createInstance({ name: 'optim_resources_deleted'});

            const filesLF = localforage.createInstance({ name: 'optim_files'});
            const filesLFCreated= localforage.createInstance({ name: 'optim_files_created'});
            const filesLFUpdated= localforage.createInstance({ name: 'optim_files_updated'});
            const filesLFDeleted = localforage.createInstance({ name: 'optim_files_deleted'});

            const picturesLF = localforage.createInstance({ name: 'optim_pictures'});
            const picturesLFCreated= localforage.createInstance({ name: 'optim_pictures_created'});
            const picturesLFUpdated= localforage.createInstance({ name: 'optim_pictures_updated'});
            const picturesLFDeleted = localforage.createInstance({ name: 'optim_pictures_deleted'});

            const dataPicturesLF = localforage.createInstance({ name: 'optim_data_pictures'});
            const dataPicturesLFCreated = localforage.createInstance({ name: 'optim_data_pictures_created'});
            const dataPicturesLFUpdated = localforage.createInstance({ name: 'optim_data_pictures_updated'});
            const dataPicturesLFDeleted = localforage.createInstance({ name: 'optim_data_pictures_deleted'});

            const dataFilesLF = localforage.createInstance({ name: 'optim_data_files'});
            const dataFilesLFCreated= localforage.createInstance({ name: 'optim_data_files_created'});
            const dataFilesLFUpdated= localforage.createInstance({ name: 'optim_data_files_updated'});
            const dataFilesLFDeleted = localforage.createInstance({ name: 'optim_data_files_deleted'});

            // const filesLF = localforage.createInstance({ name: 'optim_files' });
            // const picturesLF = localforage.createInstance({ name: 'optim_pictures' });
            // const dataFilesLF = localforage.createInstance({ name: 'optim_data_files' });
            // const dataPicturesLF = localforage.createInstance({ name: 'optim_data_pictures' });
            await axios.get('/check').then(async () => {
                await Promise.all([
                    resourceLF.getItems(),
                    resourceLFCreated.getItems(),
                    resourceLFUpdated.getItems(),
                    resourceLFDeleted.getItems(),
                    filesLF.getItems(),
                    filesLFCreated.getItems(),
                    filesLFUpdated.getItems(),
                    filesLFDeleted.getItems(),
                    picturesLF.getItems(),
                    picturesLFCreated.getItems(),
                    picturesLFUpdated.getItems(),
                    picturesLFDeleted.getItems(),
                    dataPicturesLF.getItems(),
                    dataPicturesLFCreated.getItems(),
                    dataPicturesLFUpdated.getItems(),
                    dataPicturesLFDeleted.getItems(),
                    dataFilesLF.getItems(),
                    dataFilesLFCreated.getItems(),
                    dataFilesLFUpdated.getItems(),
                    dataFilesLFDeleted.getItems(),
                ]).then(async results => {
                    await dispatch(stopOfflineMode());
                    const resource = results[0];
                    // const resourceCreated = results[1];
                    const resourceUpdated = results[2];
                    const resourceDeleted = results[3];
                    // const files = results[4];
                    // const filesCreated = results[5];
                    // const filesUpdated = results[6];
                    // const filesDeleted = results[7];
                    // const pictures = results[8];
                    // const picturesCreated = results[9];
                    // const picturesUpdated = results[10];
                    // const picturesDeleted = results[11];
                    // const dataPictures = results[12];
                    // const dataPicturesCreated = results[13];
                    // const dataPicturesUpdated = results[14];
                    // const dataPicturesDeleted = results[15];
                    // const dataFiles = results[15];
                    // const dataFilesCreated = results[16];
                    // const dataFilesUpdated = results[17];
                    // const dataFilesDelete = results[18];

                    // sync create event
                    if (resource.events) {
                        for (const event of resource.events.all) {
                            if (event.id < 0) {
                                delete event.id;
                                await dispatch(createEvent({
                                    ...event,
                                    prestations: event.prestations.map(p => p.id)
                                }, [], [event.employee_id]));
                            }
                        }
                    }

                    // sync update event
                    if (resourceUpdated.events) {
                        for (const eventId of resourceUpdated.events) {
                            const event = resource.events.all.find(e => e.id === eventId);
                            await dispatch(updateEvent(event.id, {
                                ...event,
                                prestations: event.prestations.map(p => p.id)
                            }, []));
                        }
                    }

                    // sync deleted event
                    if (resourceDeleted.events) {
                        for (const eventId of resourceDeleted.events) {
                            if (eventId >= 0) {
                                await dispatch(deleteEvent(eventId));
                            }
                        }
                    }

                    // sync update / delete siteConsumption
                    if (resourceUpdated.siteConsumptions) {
                        for (const siteConsumption of resourceUpdated.siteConsumptions) {
                            await dispatch(updateSiteConsumption(
                                resource.sites.list.find(s => s.id === siteConsumption.site_id),
                                siteConsumption.date,
                                siteConsumption.articles.concat(siteConsumption.site_articles))
                            );
                        }
                    }

                    // sync created siteConsumption
                    if (resource.sites) {
                        for (const site of resource.sites.list) {
                            for (const consumption of site.consumptions) {
                                if(consumption.id < 0) {
                                    delete consumption.id;
                                    await dispatch(createSiteConsumption(consumption));
                                }
                            }
                        }
                    }

                    // sync created siteTicket
                    if (resource.siteTickets) {
                        for (const siteTicket of resource.siteTickets.all) {
                            if (siteTicket.id < 0) {
                                delete siteTicket.id;
                                await dispatch(createSiteTicket(siteTicket, []));
                            }
                        }
                    }

                    // sync updated siteTicket
                    if (resourceUpdated.siteTickets) {
                        for (const siteTicketId of resourceUpdated.siteTickets) {
                            const siteTicket = resource.siteTickets.all.find(st => st.id === siteTicketId);
                            await dispatch(updateSiteTicket(siteTicket, []));
                        }
                    }

                    // sync deleted siteTicket
                    if (resourceDeleted.siteTickets) {
                        for (const siteTicketId of resourceDeleted.siteTickets) {
                            if (siteTicketId >= 0) {
                                await dispatch(deleteSiteTicket(siteTicketId));
                            }
                        }
                    }

                    // sync create dayoff
                    if (resource.dayoff) {
                        for (const dayoff of resource.dayoff.all) {
                            if (dayoff.id < 0) {
                                delete dayoff.id;
                                await dispatch(createDayoff(dayoff));
                            }
                        }
                    }

                    // sync deleted dayoff
                    if (resourceDeleted.dayoff) {
                        for (const dayoffId of resourceDeleted.dayoff) {
                            if (dayoffId >= 0) {
                                await dispatch(deleteDayoff(dayoffId));
                            }
                        }
                    }

                    // sync updated depositTicket
                    if (resourceUpdated.depositTickets) {
                        for (const depositTicketId of resourceUpdated.depositTickets) {
                            const depositTicket = resource.depositTickets.all.find(t => t.id === depositTicketId);
                            await dispatch(updateDepositTicket(depositTicket, []));
                        }
                    }
                    // sync deleted depositTicket
                    if (resourceDeleted.depositTickets) {
                        for (const depositTicketId of resourceDeleted.depositTickets) {
                            if (depositTicketId >= 0) {
                                await dispatch(deleteDepositTicket(depositTicketId));
                            }
                        }
                    }

                    // sync created siteSupplyOrder
                    if (resource.sites) {
                        for (const site of resource.sites.list) {
                            for (const supplyOrder of site.supplies) {
                                if(supplyOrder.id < 0) {
                                    delete supplyOrder.id;
                                    await dispatch(createSupplyOrder(supplyOrder));
                                }
                            }
                        }
                    }

                    // sync deleted siteSupplyOrder
                    if (resource.sites) {
                        for (const site of resource.sites.list) {
                            for (const supply of site.supplies) {
                                if(supply.id < 0) {
                                    delete supply.id;
                                    await dispatch(updateSupplyOrder(supply));
                                }
                            }
                        }
                    }

                    // sync updated siteSupplyOrder
                    if (resourceUpdated.supply_orders) {
                        for (const supply of resourceUpdated.supply_orders) {
                            if (supply.id >= 0) {
                                await dispatch(updateSupplyOrder(supply));
                            }
                        }
                    }

                    // sync deleted supply
                    if (resourceDeleted.supply_orders) {
                        for (const supply of resourceDeleted.supply_orders) {
                            if (supply.id >= 0) {
                                await dispatch(deleteSupply(supply));
                            }
                        }
                    }

                    setTimeout(() => {
                        onClose();
                        location.reload();
                    }, 2000);
                });
            }).catch(() => {
                toast.error(t('app:cannot_sync'));
                onClose();
            });
        }
    }, [opened]);

    return (
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="dialog-stop-offline"
            position="bottom"
            closable={false}
        >
            <div className="loader">
                <SpinnerDotted size={150} thickness={150} speed={100} color="rgb(251, 187, 0)" />
                <div className="text">{t('app:sync_data')}</div>
            </div>
        </Dialog>
    );
};

export default FormStopOffline;
