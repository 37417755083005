import {
    applicationOnError,
    getCompanySuccess,
    getConfigCalendarDaySuccess,
    getConfigDayOffCodeSuccess,
    getConfigOptionalFieldSuccess
} from './actions';
import { cycleStatusesReceived, cycleTypesReceived, cyclesReceived } from '../cycles/actions';
import { prestationGroupsReceived, prestationsReceived } from '../prestations/actions';

import { articleReceived } from '../articles/actions';
import { attachmentReceived } from '../attachment/actions';
import axios from 'axios';
import { dayoffReceived } from '../dayoff/actions';
import { depositTicketReceived } from '../depositTickets/actions';
import { employeesReceived } from '../employees/actions';
import { eventsReceived } from '../events/actions';
import { formReceived } from '../forms/actions';
import { getCustomerSuccess } from '../customers/actions';
import { getFoldersSuccess } from '../folders/actions';
import { getGroupRisksSuccess } from '../groupRisks/actions';
import { getMaterialSuccess } from '../materials/actions';
import { getSecurityReports } from '../securityReports/actions';
import { getSiteSuccess } from '../sites/actions';
import i18n from 'i18next';
import localforage from 'localforage';
import { materialTypesReceived } from '../materialTypes/actions';
import { ordersReceived } from '../orders/actions';
import { siteTicketReceived } from '../siteTickets/actions';
import { siteTrashDepositReceived } from '../siteTrashDeposit/actions';
import store from '../../store';
import { suppliersReceived } from '../suppliers/actions';
import { timeslotProfileReceived } from '../timeslotProfiles/actions';
import { toast } from 'react-toastify';
import { typeDocReceived } from '../typedoc/actions';
import { usersReceived } from '../users/actions';

const { dispatch } = store;

export const getResourceSource = () => {
    return store.getState().core.offlineMode ? 'offline' : 'api';
};

export const updateApp = () => {
    const resourceLF = localforage.createInstance({ name: 'optim_resources'});
    switch(getResourceSource()) {
        case 'api':
            return Promise.all([
                axios.get('/user'),
                axios.get('/company'),
                axios.get('/employee'),
                axios.get('/config/day-off-code'),
                axios.get('/config/optional-field'),
                axios.get('/config/calendar-day'),
                axios.get('/site'),
                axios.get('/customer'),
                axios.get('/event'),
                axios.get('/prestation'),
                axios.get('/prestation/group'),
                axios.get('/dayoff'),
                axios.get('/article'),
                axios.get('/cycle'),
                axios.get('/cyclestatus'),
                axios.get('/cycletype'),
                axios.get('/material'),
                axios.get('/folder'),
                axios.get('/supplier'),
                axios.get('/deposit_ticket'),
                axios.get('/order'),
                axios.get('/site_ticket'),
                axios.get('/typedoc'),
                axios.get('/timeslot_profile'),
                axios.get('/group'),
                axios.get('/security-report'),
                axios.get('/trash-deposit'),
                axios.get('/material-types'),
                axios.get('/attachments'),
                axios.get('/forms')
            ]).then(values => {
                dispatch(usersReceived(values[0].data));
                dispatch(getCompanySuccess(values[1].data));
                dispatch(employeesReceived(values[2].data));
                dispatch(getConfigDayOffCodeSuccess(values[3].data));
                dispatch(getConfigOptionalFieldSuccess(values[4].data));
                dispatch(getConfigCalendarDaySuccess(values[5].data));
                dispatch(getSiteSuccess(values[6].data));
                dispatch(getCustomerSuccess(values[7].data));
                dispatch(eventsReceived(values[8].data));
                dispatch(prestationsReceived(values[9].data));
                dispatch(prestationGroupsReceived(values[10].data));
                dispatch(dayoffReceived(values[11].data));
                dispatch(articleReceived(values[12].data));
                dispatch(cyclesReceived(values[13].data));
                dispatch(cycleStatusesReceived(values[14].data));
                dispatch(cycleTypesReceived(values[15].data));
                dispatch(getMaterialSuccess(values[16].data));
                dispatch(getFoldersSuccess(values[17].data));
                dispatch(suppliersReceived(values[18].data));
                dispatch(depositTicketReceived(values[19].data));
                dispatch(ordersReceived(values[20].data));
                dispatch(siteTicketReceived(values[21].data));
                dispatch(typeDocReceived(values[22].data));
                dispatch(timeslotProfileReceived(values[23].data));
                dispatch(getGroupRisksSuccess(values[24].data));
                dispatch(getSecurityReports(values[25].data));
                dispatch(siteTrashDepositReceived(values[26].data));
                dispatch(materialTypesReceived(values[27].data));
                dispatch(attachmentReceived(values[28].data));
                dispatch(formReceived(values[29].data));
            }).catch(function(error) {
                if (error?.response?.data?.message === 'maximum_user_reached') {
                    dispatch(applicationOnError('maximum_user_reached'));
                    toast.error(i18n.t('app:maximum_connection_reached_block'), { autoClose: false, closeButton: false, closeOnClick: false });
                } else {
                    toast.error(i18n.t('common:cannot_bootstrap_app'), { autoClose: false, closeButton: false, closeOnClick: false });
                }
                dispatch(applicationOnError(true));
            });
        case 'offline':
            return Promise.all([
                resourceLF.getItem('users').then(res => dispatch(usersReceived(res.list))),
                resourceLF.getItem('app').then(res => dispatch(getCompanySuccess(res.company))),
                resourceLF.getItem('employees').then(res => dispatch(employeesReceived(res))),
                resourceLF.getItem('app').then(res => dispatch(getConfigDayOffCodeSuccess(res.dayOffCodes))),
                resourceLF.getItem('app').then(res => dispatch(getConfigOptionalFieldSuccess(res.optionnalFields))),
                resourceLF.getItem('app').then(res => dispatch(getConfigCalendarDaySuccess(res.calendarDays))),
                resourceLF.getItem('sites').then(res => dispatch(getSiteSuccess(res.list))),
                resourceLF.getItem('customers').then(res => dispatch(getCustomerSuccess(res.list))),
                resourceLF.getItem('events').then(res => dispatch(eventsReceived(res.all))),
                resourceLF.getItem('prestations').then(res => dispatch(prestationsReceived(res.all))),
                resourceLF.getItem('prestations').then(res => dispatch(prestationGroupsReceived(res.groups))),
                resourceLF.getItem('dayoff').then(res => dispatch(dayoffReceived(res.all))),
                resourceLF.getItem('articles').then(res => dispatch(articleReceived(res.all))),
                resourceLF.getItem('materials').then(res => dispatch(getMaterialSuccess(res.list))),
                resourceLF.getItem('folders').then(res => dispatch(getFoldersSuccess(res.list))),
                resourceLF.getItem('suppliersTickets').then(res => dispatch(suppliersReceived(res.all))),
                resourceLF.getItem('depositTickets').then(res => dispatch(depositTicketReceived(res.all))),
                resourceLF.getItem('orders').then(res => dispatch(ordersReceived(res.all))),
                resourceLF.getItem('siteTickets').then(res => dispatch(siteTicketReceived(res.all))),
                resourceLF.getItem('typedoc').then(res => dispatch(typeDocReceived(res.all))),
                resourceLF.getItem('timeslotProfiles').then(res => dispatch(timeslotProfileReceived(res.all))),
                resourceLF.getItem('group_risks').then(res => dispatch(getGroupRisksSuccess(res.all))),
                resourceLF.getItem('securityReports').then(res => dispatch(getSecurityReports(res.all))),
                resourceLF.getItem('materialTypes').then(res => dispatch(materialTypesReceived(res.all))),
                resourceLF.getItem('attachment').then(res => dispatch(attachmentReceived(res.all))),
            ]);
        default:
            throw new Error('Unknow resource source');
    }
};

export const updateSite = () => {
    const resourceLF = localforage.createInstance({ name: 'optim_resources'});
    switch(getResourceSource()) {
        case 'api':
            return Promise.all([
                axios.get('/employee'),
                axios.get('/config/calendar-day'),
                axios.get('/site'),
                axios.get('/customer'),
                axios.get('/event'),
                axios.get('/prestation'),
                axios.get('/prestation/group'),
                axios.get('/article'),
                axios.get('/material'),
                axios.get('/folder'),
                axios.get('/supplier'),
                axios.get('/deposit_ticket'),
                axios.get('/security-report'),
                axios.get('/site_ticket'),
                axios.get('/trash-deposit'),
                axios.get('/material-types'),
            ]).then(values => {
                dispatch(employeesReceived(values[0].data));
                dispatch(getConfigCalendarDaySuccess(values[1].data));
                dispatch(getSiteSuccess(values[2].data));
                dispatch(getCustomerSuccess(values[3].data));
                dispatch(eventsReceived(values[4].data));
                dispatch(prestationsReceived(values[5].data));
                dispatch(prestationGroupsReceived(values[6].data));
                dispatch(articleReceived(values[7].data));
                dispatch(getMaterialSuccess(values[8].data));
                dispatch(getFoldersSuccess(values[9].data));
                dispatch(suppliersReceived(values[10].data));
                dispatch(depositTicketReceived(values[11].data));
                dispatch(getSecurityReports(values[12].data));
                dispatch(siteTicketReceived(values[13].data));
                dispatch(siteTrashDepositReceived(values[14].data));
                dispatch(materialTypesReceived(values[15].data));
            }).catch(function(error) {
                if (error?.response?.data?.message === 'maximum_user_reached') {
                    dispatch(applicationOnError('maximum_user_reached'));
                    toast.error(i18n.t('app:maximum_connection_reached_block'), { autoClose: false, closeButton: false, closeOnClick: false });
                // } else {
                //     toast.error(i18n.t('common:cannot_bootstrap_app'), { autoClose: false, closeButton: false, closeOnClick: false });
                }
                // dispatch(applicationOnError(true));
            }).finally(() => {
                // stub
            });
        case 'offline':
            return Promise.all([
                resourceLF.getItem('employees').then(res => dispatch(employeesReceived(res))),
                resourceLF.getItem('app').then(res => dispatch(getConfigCalendarDaySuccess(res.calendarDays))),
                resourceLF.getItem('sites').then(res => dispatch(getSiteSuccess(res.list))),
                resourceLF.getItem('customers').then(res => dispatch(getCustomerSuccess(res.list))),
                resourceLF.getItem('events').then(res => dispatch(eventsReceived(res.all))),
                resourceLF.getItem('prestations').then(res => dispatch(prestationsReceived(res.all))),
                resourceLF.getItem('prestations').then(res => dispatch(prestationGroupsReceived(res.groups))),
                resourceLF.getItem('articles').then(res => dispatch(articleReceived(res.all))),
                resourceLF.getItem('materials').then(res => dispatch(getMaterialSuccess(res.list))),
                resourceLF.getItem('folders').then(res => dispatch(getFoldersSuccess(res.list))),
                resourceLF.getItem('suppliersTickets').then(res => dispatch(suppliersReceived(res.all))),
                resourceLF.getItem('depositTickets').then(res => dispatch(depositTicketReceived(res.all))),
                resourceLF.getItem('siteTickets').then(res => dispatch(siteTicketReceived(res.all))),
                resourceLF.getItem('trashDeposits').then(res => dispatch(siteTrashDepositReceived(res.all))),
                resourceLF.getItem('securityReports').then(res => dispatch(getSecurityReports(res.all))),
                resourceLF.getItem('materialTypes').then(res => dispatch(materialTypesReceived(res.all))),
            ]);
        default:
            throw new Error('Unknow resource source');
    }
};

export const updateEmployee = () => {
    const resourceLF = localforage.createInstance({ name: 'optim_resources'});
    switch(getResourceSource()) {
        case 'api':
            return Promise.all([
                axios.get('/employee'),
                axios.get('/config/calendar-day'),
                axios.get('/site'),
                axios.get('/customer'),
                axios.get('/event'),
                axios.get('/prestation'),
                axios.get('/prestation/group'),
                axios.get('/material'),
                axios.get('/folder'),
                axios.get('/dayoff'),
                axios.get('/security-report'),
            ]).then(values => {
                dispatch(employeesReceived(values[0].data));
                dispatch(getConfigCalendarDaySuccess(values[1].data));
                dispatch(getSiteSuccess(values[2].data));
                dispatch(getCustomerSuccess(values[3].data));
                dispatch(eventsReceived(values[4].data));
                dispatch(prestationsReceived(values[5].data));
                dispatch(prestationGroupsReceived(values[6].data));
                dispatch(getMaterialSuccess(values[7].data));
                dispatch(getFoldersSuccess(values[8].data));
                dispatch(dayoffReceived(values[9].data));
                dispatch(getSecurityReports(values[10].data));
            }).catch(function(error) {
                if (error?.response?.data?.message === 'maximum_user_reached') {
                    dispatch(applicationOnError('maximum_user_reached'));
                    toast.error(i18n.t('app:maximum_connection_reached_block'), { autoClose: false, closeButton: false, closeOnClick: false });
                // } else {
                //     toast.error(i18n.t('common:cannot_bootstrap_app'), { autoClose: false, closeButton: false, closeOnClick: false });
                }
                // dispatch(applicationOnError(true));
            // finally(() => {
                // stub
            });
        case 'offline':
            return Promise.all([
                resourceLF.getItem('employees').then(res => dispatch(employeesReceived(res))),
                resourceLF.getItem('app').then(res => dispatch(getConfigCalendarDaySuccess(res.calendarDays))),
                resourceLF.getItem('sites').then(res => dispatch(getSiteSuccess(res.list))),
                resourceLF.getItem('customers').then(res => dispatch(getCustomerSuccess(res.list))),
                resourceLF.getItem('events').then(res => dispatch(eventsReceived(res.all))),
                resourceLF.getItem('prestations').then(res => dispatch(prestationsReceived(res.all))),
                resourceLF.getItem('prestations').then(res => dispatch(prestationGroupsReceived(res.groups))),
                resourceLF.getItem('dayoff').then(res => dispatch(dayoffReceived(res.all))),
                resourceLF.getItem('materials').then(res => dispatch(getMaterialSuccess(res.list))),
                resourceLF.getItem('folders').then(res => dispatch(getFoldersSuccess(res.list))),
                resourceLF.getItem('securityReports').then(res => dispatch(getSecurityReports(res.all))),
            ]);
        default:
            throw new Error('Unknow resource source');
    }
};

export const updateMaterial = () => {
    const resourceLF = localforage.createInstance({ name: 'optim_resources'});
    switch(getResourceSource()) {
        case 'api':
            return Promise.all([
                axios.get('/material')
            ]).then(values => {
                dispatch(getMaterialSuccess(values[0].data));
            }).catch(function(error) {
                if (error?.response?.data?.message === 'maximum_user_reached') {
                    dispatch(applicationOnError('maximum_user_reached'));
                    toast.error(i18n.t('app:maximum_connection_reached_block'), { autoClose: false, closeButton: false, closeOnClick: false });
                // } else {
                    // toast.error(i18n.t('common:cannot_bootstrap_app'), { autoClose: false, closeButton: false, closeOnClick: false });
                }
                // dispatch(applicationOnError(true));
            // }).finally(() => {
                // stub
            });
        case 'offline':
            return Promise.all([
                resourceLF.getItem('materials').then(res => dispatch(getMaterialSuccess(res.list))),
            ]);
        default:
            throw new Error('Unknow resource source');
    }
};

export const updateCycle = () => {
    switch(getResourceSource()) {
        case 'api':
            return Promise.all([
                axios.get('/cycle'),
                axios.get('/cyclestatus'),
                axios.get('/cycletype'),
            ]).then(values => {
                dispatch(cyclesReceived(values[0].data));
                dispatch(cycleStatusesReceived(values[1].data));
                dispatch(cycleTypesReceived(values[2].data));
            }).catch(function(error) {
                if (error?.response?.data?.message === 'maximum_user_reached') {
                    dispatch(applicationOnError('maximum_user_reached'));
                    toast.error(i18n.t('app:maximum_connection_reached_block'), { autoClose: false, closeButton: false, closeOnClick: false });
                // } else {
                    // toast.error(i18n.t('common:cannot_bootstrap_app'), { autoClose: false, closeButton: false, closeOnClick: false });
                }
                // dispatch(applicationOnError(true));
            // }).finally(() => {
                // stub
            });
        case 'offline':
            // no cycle validation online
            return Promise.all([]);
        default:
            throw new Error('Unknow resource source');
    }
};

export const updateDepositTicket = () => {
    const resourceLF = localforage.createInstance({ name: 'optim_resources'});
    switch(getResourceSource()) {
        case 'api':
            return Promise.all([
                axios.get('/deposit_ticket')
            ]).then(values => {
                dispatch(depositTicketReceived(values[0].data));
            }).catch(function(error) {
                if (error?.response?.data?.message === 'maximum_user_reached') {
                    dispatch(applicationOnError('maximum_user_reached'));
                    toast.error(i18n.t('app:maximum_connection_reached_block'), { autoClose: false, closeButton: false, closeOnClick: false });
                // } else {
                    // toast.error(i18n.t('common:cannot_bootstrap_app'), { autoClose: false, closeButton: false, closeOnClick: false });
                }
                // dispatch(applicationOnError(true));
            // }).finally(() => {
                // stub
            });
        case 'offline':
            return Promise.all([
                resourceLF.getItem('depositTickets').then(res => dispatch(depositTicketReceived(res.all))),
            ]);
        default:
            throw new Error('Unknow resource source');
    }
};

export const updateSiteTrashDeposit= () => {
    const resourceLF = localforage.createInstance({ name: 'optim_resources'});
    switch(getResourceSource()) {
        case 'api':
            return Promise.all([
                axios.get('/trash-deposit')
            ]).then(values => {
                dispatch(siteTrashDepositReceived(values[0].data));
            }).catch(function(error) {
                if (error?.response?.data?.message === 'maximum_user_reached') {
                    dispatch(applicationOnError('maximum_user_reached'));
                    toast.error(i18n.t('app:maximum_connection_reached_block'), { autoClose: false, closeButton: false, closeOnClick: false });
                }
            });
        case 'offline':
            return Promise.all([
                resourceLF.getItem('trashDesposits').then(res => dispatch(depositTicketReceived(res.all))),
            ]);
        default:
            throw new Error('Unknow resource source');
    }
};

export const updateSiteTicket = () => {
    const resourceLF = localforage.createInstance({ name: 'optim_resources'});
    switch(getResourceSource()) {
        case 'api':
            return Promise.all([
                axios.get('/site_ticket')
            ]).then(values => {
                dispatch(siteTicketReceived(values[0].data));
            }).catch(function(error) {
                if (error?.response?.data?.message === 'maximum_user_reached') {
                    dispatch(applicationOnError('maximum_user_reached'));
                    toast.error(i18n.t('app:maximum_connection_reached_block'), { autoClose: false, closeButton: false, closeOnClick: false });
                // } else {
                    // toast.error(i18n.t('common:cannot_bootstrap_app'), { autoClose: false, closeButton: false, closeOnClick: false });
                }
                // dispatch(applicationOnError(true));
            // }).finally(() => {
                // stub
            });
        case 'offline':
            return Promise.all([
                resourceLF.getItem('siteTickets').then(res => dispatch(siteTicketReceived(res.all))),
            ]);
        default:
            throw new Error('Unknow resource source');
    }
};

export const updateUser = () => {
    const resourceLF = localforage.createInstance({ name: 'optim_resources'});
    switch(getResourceSource()) {
        case 'api':
            return Promise.all([
                axios.get('/user')
            ]).then(values => {
                dispatch(usersReceived(values[0].data));
            }).catch(function(error) {
                if (error?.response?.data?.message === 'maximum_user_reached') {
                    dispatch(applicationOnError('maximum_user_reached'));
                    toast.error(i18n.t('app:maximum_connection_reached_block'), { autoClose: false, closeButton: false, closeOnClick: false });
                // } else {
                    // toast.error(i18n.t('common:cannot_bootstrap_app'), { autoClose: false, closeButton: false, closeOnClick: false });
                }
                // dispatch(applicationOnError(true));
            // }).finally(() => {
                // stub
            });
        case 'offline':
            return Promise.all([
                resourceLF.getItem('siteTickets').then(res => dispatch(siteTicketReceived(res.all))),
            ]);
        default:
            throw new Error('Unknow resource source');
    }
};
