import { faClock, faWarning } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { getEventDuration } from '../../../utils/event_tool';
import iconDayoff from '../../../assets/icon-dayoff.svg';
import iconIntervention from '../../../assets/icon-intervention.svg';
import iconProduction from '../../../assets/icon-production.svg';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const EventRow = props => {
    const { t } = useTranslation();

    const dayOffState = useSelector(state => state.app.dayOffCodes);

    let obj = null;
    let title = props.title;
    if (!title) {
        switch (props.event.event_type) {
            case 'dayoff':
                obj = dayOffState.find(doff => doff.id === props.event.dayoff_code_id);
                title = obj ? obj.label : '';
                break;
            case 'production':
            case 'intervention':
                title = (props.event.site.disabled ? <span>{props.event.site.code} - {props.event.site.label}<br/><FontAwesomeIcon icon={faWarning} />Chantier/BI clôturé</span>  : <span>{props.event.site.code} - {props.event.site.label}</span>);
                break;
            default:
                break;
        }
    }

    let status = null;
    switch (parseInt(props.event.state, 34)) {
        case -1:
            status = <div className="status planned">{t('app:planned')}</div>;
            break;
        case 0:
            status = <div className="status filled">{t('app:filled')}</div>;
            break;
        case 2:
            status = <div className="status signed">{t('app:signed')}</div>;
            break;
        case 1:
            status = <div className="status validated">{t('app:validated')}</div>;
            break;
    }

    const event_duration = getEventDuration(props.event);
    const hour = parseInt(event_duration / 3600);
    const min = (event_duration / 3600 % 1) * 60;

    let icon = null;
    switch (props.event.event_type) {
        case 'production':
            icon = iconProduction;
            break;
        case 'intervention':
            icon = iconIntervention;
            break;
        case 'dayoff':
            icon = iconDayoff;
            break;
        default:
            break;
    }

    return (
        <>
            <div className={`event-list-row ${props.event.event_type} ${parseInt(props.event.state, 10) === -1 ? 'pending' : 'done'}`} onClick={props.onClick}>
                <div className="event-details">
                    <FontAwesomeIcon icon={faClock} />
                    {hour < 10 ? '0' : ''}{hour}:{min < 10 ? '0' : ''}{min}<br />
                    {status}
                </div>
                <div className="title">
                    {
                        props.event.start_hour > 0 || props.event.start_minute > 0 ?
                            <strong>
                                {('0' + props.event.start_hour).slice(-2)}:{('0' + props.event.start_minute).slice(-2)} -&nbsp;
                            </strong>
                            : null
                    }
                    {title}
                    {props.event.site_workshop && props.event.site_workshop.import_id !== '0' ?
                        <div className="workshop">
                            {props.event.site_workshop.designation}
                        </div>
                        : null
                    }
                </div>
                <div className="icon"><img src={icon} /></div>
            </div>
        </>);
};



export default EventRow;
