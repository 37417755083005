import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FootBar from './FootBar';
import DropDownEmployee from './DropDownEmployee';
import { updateEmployee } from '../../app/updateApp';
import SecurityReport from '../../securityReports';
import noSupply from '../../../assets/no-supply.png';

const EmployeeSecurity = () => {
    const { t } = useTranslation();
    useEffect(function () {
        updateEmployee();
    }, []);

    const employeeState = useSelector(state => state.employees);
    const securityReports = useSelector(state => state.securityReports.all.filter(sr => sr.employee_id === employeeState.selectedEmployee));

    const showNoData = securityReports.length === 0;

    let currentEmploye = employeeState.team.find(empl =>
        empl.id === employeeState.selectedEmployee
    );
    if (!currentEmploye) {
        currentEmploye = employeeState.all.find(empl =>
            empl.id === employeeState.selectedEmployee
        );
    }

    return <div className="module-page">
        <div className="label">
            {t('app:selected_employee')}
        </div>
        <DropDownEmployee />
        {showNoData ?
            <div className="no-supply">
                <img src={noSupply} />
                <div className="label">{t('app:no_security')}</div>
            </div>
            : <SecurityReport reports={securityReports}/>
        }
        <FootBar />
    </div>;
};

export default EmployeeSecurity;