import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { faFileAlt, faTachometerAltSlow } from '@fortawesome/pro-duotone-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'primereact/button';
import useRouter from '../../../utils/hooks/useRouter';

import DropDownMaterial from './DropDownMaterial';
import MaterialInfo from './MaterialInfo';
import { hasAccessToDocMaterial, hasAccessToOdometer } from '../../../utils/access';

const SubmoduleList = () => {
    const router = useRouter();
    const { t } = useTranslation();
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);
    const appState = useSelector(state => state.app);
    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const materialState = useSelector(state => state.materials);
    const material = materialState.list.find(mat => mat.id === materialState.selectedMaterial);

    return (
        <div className="moduleSelector">
            <div className="userDescription">
                <div className="userInfo">
                    <div className="initials">
                        {currentUser.firstname[0].toUpperCase()}{currentUser.lastname[0].toUpperCase()}
                    </div>
                    <div className="name">
                        {currentUser.firstname.toUpperCase()} {currentUser.lastname.toUpperCase()}
                    </div>
                    <div className="agency">
                        {currentUser?.entity?.rs ?
                            currentUser.entity.rs :
                            appState.company.name
                        }
                    </div>
                </div>
            </div>
            <div className="userModule">
                <DropDownMaterial materials={materialState.list} />
                <MaterialInfo material={material} />
                { hasAccessToDocMaterial() &&
                    <Button className="p-button-link p-shadow-2 module-button" onClick={() => materialState.selectedMaterial
                        ? router.push('/app/materials/documents')
                        : toast.error(t('app:please_select_a_material'))}>
                        <div className="icon"><FontAwesomeIcon icon={faFileAlt} /></div>
                        <div className="text">
                            <div className="title">
                                {t('common:document')}
                            </div>
                            <div className="desc">
                                <div>{t('app:material_document')}</div>
                            </div>
                        </div>
                    </Button>
                }
                { hasAccessToOdometer() &&
                    <Button style={{ marginBottom: '32px' }} className="p-button-link p-shadow-2 module-button" onClick={() => materialState.selectedMaterial
                        ? router.push('/app/materials/events')
                        : toast.error(t('app:please_select_a_material'))}>
                        <div className="icon"><FontAwesomeIcon icon={faTachometerAltSlow} /></div>
                        <div className="text">
                            <div className="title">
                                {t('app:odometer_and_events')}
                            </div>
                            <div className="desc">
                                <div>{t('app:fill_odometer_let_observation')}</div>
                            </div>
                        </div>
                    </Button>
                }
            </div>
        </div>
    );
};

export default SubmoduleList;
