import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DropDownMaterial from './DropDownMaterial';
import Footbar from './FootBar';
import { updateMaterial } from '../../app/updateApp';
import MaterialEventContainer from './MaterialEventContainer';

const MaterialEvent = () => {
    const { t } = useTranslation();
    const materialState = useSelector(state => state.materials);

    useEffect(function () { updateMaterial(); }, []);

    return <div className="module-page">
        <div className="label">
            {t('app:selected_material')}
        </div>
        <DropDownMaterial materials={materialState.list}/>
        <MaterialEventContainer />
        <Footbar />
    </div>;
};

export default MaterialEvent;

