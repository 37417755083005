import React from 'react';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserHardHat, faBell, faTruckPlow } from '@fortawesome/pro-solid-svg-icons';
import useRouter from '../../../utils/hooks/useRouter';
import { useSelector } from 'react-redux';
import { hasAccessToSite, hasAccessToValidation } from '../../../utils/access';
import siteActive from '../../../assets/site_active.svg';
import siteImg from '../../../assets/site.svg';
// import { useTranslation } from 'react-i18next';

const TopNavbar = () => {
    const router = useRouter();
    // const { t }  = useTranslation();
    const cycles = useSelector(state => state.cycles.list
        .filter(c => ![ 5, 9 ].includes(c.cycle_status.import_id))
    );
    const isOfflineMode = useSelector(state => state.core.offlineMode);

    return <div className={'topnavbar'}>
        { /** isOfflineMode && <div className="offline-info">{t('app:offline_info')}</div> **/}
        <Button
            onClick={() => {
                router.push('/app/materials');
            }}
            className={`${router.location.pathname.includes('/app/materials') ? 'active' : ''} p-button-link nav-button employee`}>
            <FontAwesomeIcon icon={faTruckPlow} />
        </Button>
        { hasAccessToSite() ?
            <Button
                onClick={() => {
                    router.push('/app/sites');
                    window.localStorage.setItem('optim_module', 'site');
                }}
                className={`${router.location.pathname.includes('/app/sites') ? 'active' : ''} p-button-link nav-button site`}>
                {router.location.pathname.includes('/app/sites') ?
                    <img src={siteActive} /> :
                    <img src={siteImg} />
                }
                {/* <FontAwesomeIcon icon={faConstruction} /> */}
            </Button> : null
        }
        { ( hasAccessToValidation() && cycles.length > 0 ) ?
            <Button
                disabled={isOfflineMode}
                onClick={() => router.push('/app/cycles')}
                className={`${router.location.pathname.includes('/app/cycles') ? 'active' : ''} p-button-link nav-button button-alert`}>
                <FontAwesomeIcon icon={faBell} />
                <div className="button-alert-number"> {cycles.length} </div>
            </Button> : null
        }
        <Button
            onClick={() => {
                router.push('/app/employees');
                window.localStorage.setItem('optim_module', 'employee');
            }}
            className={`${router.location.pathname.includes('/app/employees') ? 'active' : ''} p-button-link nav-button employee`}>
            <FontAwesomeIcon icon={faUserHardHat} />
        </Button>
    </div>;}
;

export default TopNavbar;
