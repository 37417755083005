import React, { useState } from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { deleteSiteTrashDeposit} from './actions';
import { confirmDialog } from 'primereact/confirmdialog';
import { useDispatch, useSelector } from 'react-redux';
import FormCreateTrashDeposit from './FormCreateTrashDeposit';

// import './styles.scss';

import noDeposit from '../../assets/no-deposit-ticket.png';


const SiteTrashDeposit = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const siteTrashDepositState = useSelector(state => state.siteTrashDeposit);
    const siteState = useSelector(s => s.sites);
    const trashDeposits = siteTrashDepositState.all.filter(d => {
        if(d.site_id !== siteState.selectedSite) return false;
        return true;
    });

    const confirmDelete = siteTrashDeposit=>  {
        confirmDialog({
            // message: t('app:really_remove_site_ticket', {typedoc: localStorage.getItem('site_ticket_label') || t('common:site_ticket')}),
            message: 'Voulez-vous vraiment supprimer la collecte de déchet ?',
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                dispatch(deleteSiteTrashDeposit(siteTrashDeposit.id));
            },
            reject: () => {}
        });
    };

    const [popupOpen, setPopupOpen] = useState(false);
    const [siteTrashDepositShown, setSiteTrashDepositShown] = useState(null);

    return <>
        {
            trashDeposits.length === 0 ?
                <div className="no-data">
                    <img src={noDeposit} />
                    <div className="label">{t('app:no_site_trash_deposit')}</div>
                </div>:
                <div className="site-tickets">
                    {
                        trashDeposits.map(trashDeposit=> {
                            return <div key={trashDeposit.id} className="site_ticket_row p-shadow-1">
                                <Button className="p-button-link" onClick={() => {
                                    setSiteTrashDepositShown(trashDeposit);
                                    setPopupOpen(true);
                                }}>
                                    <div className="inside_text">
                                        <div className="update">
                                            {moment(trashDeposit.date).format('DD/MM/Y')} - {trashDeposit.supplier.label.substr(0, 25)}{trashDeposit.supplier.label.length > 25 ? '...' : null}
                                            { trashDeposit.synchronized ? ' - Envoyé' : ''}
                                        </div>
                                    </div>
                                </Button>
                                {
                                    !props.readOnly && <Button className="p-button-link" onClick={() => confirmDelete(trashDeposit)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                }
                            </div>;
                        })
                    }
                </div>
        }
        <FormCreateTrashDeposit
            date={props.date}
            trashDeposit={siteTrashDepositShown}
            opened={popupOpen}
            onClose={() => {
                setPopupOpen(false);
                setSiteTrashDepositShown(null);
            }}
        />
    </>;
};

export default SiteTrashDeposit;



