const initialState = () => ({
    all: [],
    groups: []
});

const prestationsReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'PRESTATIONS_RECEIVED':
            newState.all = action.payload.sort((a, b) => {
                if (a.prestation_group_id > b.prestation_group_id) return -1;
                if (a.prestation_group_id < b.prestation_group_id) return 1;
                if (a.code > b.code) return 1;
                if (a.code < b.code) return -1;
                return 0;
            });
            return newState;
        case 'PRESTATION_GROUPS_RECEIVED':
            newState.groups = action.payload;
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default prestationsReducer;
