import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const FormAddRisks = props => {
    const { t } = useTranslation();
    // const dispatch = useDispatch();
    const { opened, selectedRisks, onAdd, onClose } = props;
    const [filter, setFilter] = useState('');
    // const riskState = useSelector(state => state.risks);
    const groupState = useSelector(state => state.groupRisks);

    const handleClick = r => {
        onAdd(r);
        onClose();
    };

    return (
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={onClose} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div className="title">
                        {t('app:add_more_risk')}
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '1rem' }}>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="Filtrer" />
                </span>
            </div>
            {groupState.all.filter(g => {
                if (filter.length === 0) return true;
                return g.risks.filter(
                    r => r.label.toLowerCase().includes(filter.toLowerCase())
                ).length > 0;
            }).map(g => {
                return <div key={g.id}>
                    <h4 style={{ marginLeft: '1rem' }}>{g.label}</h4>
                    {g.risks.filter(r => {
                        if (filter.length === 0) return true;
                        return r.label.toLowerCase().includes(filter.toLowerCase());
                    })
                        .map(r => {
                            return <div key={r.id} className="wrapper-risk">
                                <Checkbox
                                    id={`r-${r.id}-p`}
                                    disabled={props.disabled}
                                    checked={selectedRisks[r.id] || false}
                                    onChange={() => handleClick(r)}
                                />
                                <div className="risk-row"
                                    onClick={() => handleClick(r)}
                                >
                                    {r.label}
                                </div>
                            </div>;
                        })}
                </div>;
            })}
        </Dialog>
    );
};

export default FormAddRisks;
