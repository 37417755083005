import {
    addInStoreAndCacheSiteConsumption,
    addInStoreAndCacheSupplyOrder,
    deleteStoreAndCacheSupplyOrder,
    updateInStoreAndCacheSiteConsumption,
    updateInStoreAndCacheSupplyOrder
} from '../offline/helper';

import axios from 'axios';
import i18n from 'i18next';
import moment from 'moment';
import store from '../../store';
import { toast } from 'react-toastify';

export const createSupplySuccess = () => toast.success(i18n.t('app:supply_created'));
export const createSupplyError = () => toast.error(i18n.t('app:cannot_create_supply'));
export const updateSupplySuccess = () => toast.success(i18n.t('app:supply_updated'));
export const updateSupplyError = () => toast.error(i18n.t('app:cannot_update_supply'));
export const createSendPictureSuccess = () => toast.success(i18n.t('app:add_picture_success'));
export const createSendPictureError = () => toast.error(i18n.t('app:cannot_update_picture'));
export const createAskMaterialSuccess = () => toast.success(i18n.t('app:ask_material_created'));
export const createAskMaterialError = () => toast.error(i18n.t('app:cannot_create_ask_material'));
export const updateAskMaterialSuccess = () => toast.success(i18n.t('app:ask_material_updated'));
export const updateAskMaterialError = () => toast.error(i18n.t('app:cannot_update_ask_material'));

export const setSiteDayShown = day => ({
    type: 'SET_SITE_DAY_SHOWN',
    payload: day
});

export const setSelectedSite = site => ({
    type: 'SET_SELECTED_SITE',
    payload: {
        site
    }
});

export const updateSiteSuccess = site => ({
    type: 'SITE_UPDATED',
    payload: {
        site
    }
});

export const getSiteSuccess = sites => ({
    type: 'SITES_RECEIVED',
    payload: {
        sites
    }
});

export const supplyCreated = supply => ({
    type: 'SUPPLY_CREATED',
    payload: {
        supply
    }
});

export const supplyDeleted = (supply) => ({
    type: 'SUPPLY_DELETED',
    payload: supply
});

export const supplyUpdate = (supply) => ({
    type: 'SUPPLY_EDITED',
    payload: supply
});

export const askMaterialCreated = ask_material => ({
    type: 'ASK_MATERIAL_CREATED',
    payload: {
        ask_material
    }
});

export const askMaterialDeleted = (ask_material) => ({
    type: 'ASK_MATERIAL_DELETED',
    payload: ask_material
});

export const askMaterialUpdate = (ask_material) => ({
    type: 'ASK_MATERIAL_EDITED',
    payload: ask_material
});

export const pictureSentWithSuccess = picture => ({
    type: 'SITE_PICTURE_SENT',
    payload: picture
});

export const createSiteConsumptionSuccess = picture => ({
    type: 'CREATE_SITE_CONSUMPTION_SUCCESS',
    payload: picture
});

export const updateSiteConsumptionSuccess = consumption => ({
    type: 'UPDATE_SITE_CONSUMPTION_SUCCESS',
    payload: consumption
});

export const createSiteValidationDaySuccess = picture => ({
    type: 'CREATE_SITE_VALIDATION_DAY_SUCCESS',
    payload: picture
});

export const updateSiteValidationDaySuccess = picture => ({
    type: 'UPDATE_SITE_VALIDATION_DAY_SUCCESS',
    payload: picture
});

export const createSupplyOrder = _params => dispatch => {
    const state = store.getState();
    const site = state.sites.list.find(s => s.id === _params.site_id);
    const isOfflineMode = store.getState().core.offlineMode;
    const params = {
        ..._params,
        articles: simulateArticleAsFromBackend(_params.articles, state.articles.all),
        site_articles: simulateArticleAsFromBackend(_params.site_articles, site.articles)
    };
    if (isOfflineMode) {
        addInStoreAndCacheSupplyOrder(params, () => {
            createSupplySuccess();
            dispatch(supplyCreated(params));
        });
    } else {
        return axios.post('/supply', params)
            .then(data => {
                createSupplySuccess();
                dispatch(supplyCreated(data.data));
            })
            .catch(err => createSupplyError(err));
    }
};

export const updateSupplyOrder = _params => dispatch => {
    const state = store.getState();
    const site = state.sites.list.find(s => s.id === _params.site_id);
    const isOfflineMode = store.getState().core.offlineMode;
    const params = {
        ..._params,
        articles: simulateArticleAsFromBackend(_params.articles, state.articles.all),
        site_articles: simulateArticleAsFromBackend(_params.site_articles, site.articles)
    };
    if (isOfflineMode) {
        updateInStoreAndCacheSupplyOrder(params, () => {
            updateSupplySuccess();
            dispatch(supplyUpdate(params));
        });
    } else {
        return axios.patch(`/supply/${params.id}`, params)
            .then(data => {
                updateSupplySuccess();
                dispatch(supplyUpdate(data.data));
            })
            .catch(err => updateSupplyError(err));
    }
};

export const deleteSupplySuccess = () => toast.success(i18n.t('app:supply_deleted'));
export const deleteSupplyError = () => toast.error(i18n.t('app:cannot_delete_supply'));
export const deleteAskMaterialSuccess = () => toast.success(i18n.t('app:ask_material_deleted'));
export const deleteAskMaterialError = () => toast.error(i18n.t('app:cannot_delete_ask_material'));


export const deleteSupply = supply => dispatch => {
    const isOfflineMode = store.getState().core.offlineMode;
    if (isOfflineMode) {
        deleteStoreAndCacheSupplyOrder(supply, () => {
            deleteSupplySuccess();
            dispatch(supplyDeleted(supply));
        });
    } else {
        return axios.delete(`/supply/${supply.id}`)
            .then(() => {
                deleteSupplySuccess();
                dispatch(supplyDeleted(supply));
            })
            .catch(err => deleteSupplyError(err));
    }
};

export const sendSitePicture = (site_id, file, folder_id) => dispatch => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('site_id', site_id);
    if (folder_id) {
        formData.append('folder_id', folder_id);
    }
    return axios.post('/pictures/upload_picture', formData)
        .then(res => {
            createSendPictureSuccess();
            dispatch(pictureSentWithSuccess(res.data));
        })
        .catch(err => createSendPictureError(err));
};

const simulateArticleAsFromBackend = (articles, articleList) => {
    return articles.map(a => {
        const article = articleList.find(art => art.id === a.id);
        return {
            id: a.id,
            count: a.count,
            pivot: {
                count: a.count
            },
            label: article.label,
            code: article.code,
            type: 'order'
        };
    });
};

export const updateSiteConsumption = (site, date, selectedArticles) => dispatch => {
    const isOfflineMode = store.getState().core.offlineMode;
    const selectedArticlesKeys = Object.keys(selectedArticles);
    const consumption_articles = selectedArticlesKeys
        .filter(k => selectedArticles[k].type === 'order')
        .map(k => {
            return {
                'id': selectedArticles[k].id,
                'count': selectedArticles[k].count
            };
        });
    const consumption_site_articles = selectedArticlesKeys
        .filter(k => selectedArticles[k].type === 'supply')
        .map(k => {
            return {
                'id': selectedArticles[k].id,
                'count': selectedArticles[k].count
            };
        });

    const consumption = site.consumptions.find(c => moment(c.date).isSame(date, 'day'));
    if (isOfflineMode) {
        const state = store.getState();

        const params = {
            articles: simulateArticleAsFromBackend(consumption_articles, state.articles.all),
            site_articles: simulateArticleAsFromBackend(consumption_site_articles, site.articles),
        };
        if (consumption) {
            updateInStoreAndCacheSiteConsumption({ ...consumption, ...params }, () => {
                dispatch(updateSiteConsumptionSuccess({ ...consumption, ...params }));
            });
        } else {
            const consumption = {
                date: date.format('YYYY-MM-DD'),
                site_id: site.id,
                ...params
            };
            addInStoreAndCacheSiteConsumption(consumption, (id) => {
                dispatch(createSiteConsumptionSuccess({ ...consumption, id: id }));
            });
        }
    } else {
        if (consumption) {
            const params = {
                articles: consumption_articles,
                site_articles: consumption_site_articles
            };
            return axios.patch(`/site_consumption/${consumption.id}`, params)
                .then(data => {
                    dispatch(updateSiteConsumptionSuccess(data.data));
                });
        } else {
            const params = {
                date: date.format('YYYY-MM-DD'),
                site_id: site.id,
                articles: consumption_articles,
                site_articles: consumption_site_articles
            };
            return axios.post('/site_consumption', params)
                .then(data => {
                    dispatch(createSiteConsumptionSuccess(data.data));
                });

        }
    }
};

export const createSiteConsumption = _params => dispatch => {
    const state = store.getState();
    const site = state.sites.list.find(s => s.id === _params.site_id);
    const params = {
        ..._params,
        article: simulateArticleAsFromBackend(params.articles, state.articles.all),
        site_article: simulateArticleAsFromBackend(params.articles, site.articles)
    };
    return axios.post('/site_consumption', params)
        .then(data => {
            dispatch(createSiteConsumptionSuccess(data.data));
        });
};

export const updateSiteValidation = (site, date, valid, answers, comment) => dispatch => {
    const validationDay = site.validation_days.find(c => moment(c.date).isSame(date, 'day'));
    if (validationDay) {

        return axios.patch(`/site_validation_day/${validationDay.id}`, {
            valid: valid || false,
            date: date.format('YYYY-MM-DD'),
            form_answers: answers || {},
            comment: comment || ''
        })
            .then(data => {
                dispatch(updateSiteValidationDaySuccess(data.data));
            });
    } else {
        return axios.post('/site_validation_day', {
            date: date.format('YYYY-MM-DD'),
            site_id: site.id,
            valid: valid || false,
            form_answers: answers|| {},
            comment: comment || ''
        })
            .then(data => {
                dispatch(createSiteValidationDaySuccess(data.data));
            });
    }
};

export const createAskMaterialOrder = _params => dispatch => {
    const state = store.getState();
    const isOfflineMode = store.getState().core.offlineMode;
    const params = {
        ..._params,
        material_types: simulateArticleAsFromBackend(_params.material_types, state.materialTypes.all),
    };
    if (isOfflineMode) {
        addInStoreAndCacheSupplyOrder(params, () => {
            createAskMaterialSuccess();
            dispatch(askMaterialCreated(params));
        });
    } else {
        return axios.post('/ask_material', params)
            .then(data => {
                createAskMaterialSuccess();
                dispatch(askMaterialCreated(data.data));
            })
            .catch(err => createAskMaterialError(err));
    }
};

export const updateAskMaterialOrder = _params => dispatch => {
    const state = store.getState();
    const isOfflineMode = store.getState().core.offlineMode;
    const params = {
        ..._params,
        material_types: simulateArticleAsFromBackend(_params.material_types, state.materialTypes.all),
    };
    if (isOfflineMode) {
        updateInStoreAndCacheSupplyOrder(params, () => {
            updateAskMaterialSuccess();
            dispatch(askMaterialUpdate(params));
        });
    } else {
        return axios.patch(`/ask_material/${params.id}`, params)
            .then(data => {
                updateAskMaterialSuccess();
                dispatch(askMaterialUpdate(data.data));
            })
            .catch(err => updateAskMaterialError(err));
    }
};


export const deleteAskMaterial = ask_material => dispatch => {
    const isOfflineMode = store.getState().core.offlineMode;
    if (isOfflineMode) {
        deleteStoreAndCacheSupplyOrder(ask_material, () => {
            deleteAskMaterialSuccess();
            dispatch(askMaterialDeleted(ask_material));
        });
    } else {
        return axios.delete(`/ask_material/${ask_material.id}`)
            .then(() => {
                deleteAskMaterialSuccess();
                dispatch(askMaterialDeleted(ask_material));
            })
            .catch(err => deleteAskMaterialError(err));
    }
};