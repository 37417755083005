import React, { useState } from 'react';
import { faPalletAlt, faShieldCheck, faShovel, faTrash, faTruckLoading, faTruckMoving, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { hasAccessToDepositTicket, hasAccessToMaterial, hasAccessToOdometer, hasAccessToSecurity, hasAccessToSitePlanning, hasAccessToSiteTicket, hasAccessToSupply } from '../../../utils/access';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormCreateDepositeTicket from '../../depositTickets/FormCreateDepositTicket';
import FormCreateOrViewAskMaterial from './FormCreateOrViewAskMaterial';
import FormCreateOrViewSupply from './FormCreateOrViewSupply';
import FormCreateSiteTicket from '../../siteTickets/FormCreateSiteTicket';
import FormCreateTrashDeposit from '../../siteTrashDeposit/FormCreateTrashDeposit';
import FormMaterialEvent from '../../materials/components/FormMaterialEvent';
import FormSecurityReport from '../../securityReports/components/FormSecurityReport';
import PopupCreateEvent from '../../events/components/PopupCreateEvent';
import { faTachometerAltSlow } from '@fortawesome/pro-duotone-svg-icons';
import useRouter from '../../../utils/hooks/useRouter';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SiteOverlayCreate = props => {
    const { t } = useTranslation();
    const [showCreateSupplyPopup, setShowCreateSupplyPopup] = useState(false);
    const [showCreateAskMaterialPopup, setShowCreateAskMaterialPopup] = useState(false);
    const [showCreateDepositTicket, setShowCreateDepositTicket] = useState(false);
    const [showCreateSiteTicket, setShowCreateSiteTicket] = useState(false);
    const [showCreateEvent, setShowCreateEvent] = useState(false);
    const [showFormMaterialEvent, setShowFormMaterialEvent] = useState(false);
    const [showSecurityReport, setShowSecurityReport] = useState(false);
    const [showCreateSiteTrashDeposit, setShowCreateSiteTrashDeposit] = useState(false);
    const isOfflineMode = useSelector(state => state.core.offlineMode);
    const siteState = useSelector(state=> state.sites);

    const router = useRouter();

    let optionShownForCurrentPath = [];
    let readOnly = false;
    switch (router.location.pathname) {
        case '/app/sites/activity':
            optionShownForCurrentPath = ['event', 'depositTicket', 'siteTicket', 'attachment'];
            break;
        case '/app/sites/data':
            optionShownForCurrentPath = [];
            readOnly = true;
            break;
        case '/app/sites/material':
            optionShownForCurrentPath = ['materialevent'];
            readOnly = true;
            break;
        case '/app/sites/supply':
            optionShownForCurrentPath = ['supply', 'ask_material'];
            break;
        case '/app/sites/security_trash':
            optionShownForCurrentPath = ['security', 'trash'];
            break;
        case '/app/sites/trash_management':
            optionShownForCurrentPath = ['trash'];
            break;
        default:
            optionShownForCurrentPath = ['event', 'depositTicket', 'ask_material', 'trash', 'security', 'supply', 'siteTicket'];
            break;
    }

    return <div className={`${props.show ? 'show' : ''} overlay-creation`}>
        <div className="action-zone">
            <div className="label">{t('app:iwant')}</div>
            {
                (hasAccessToSitePlanning() && optionShownForCurrentPath.includes('event')) ? <Button onClick={() => {
                    setShowCreateEvent(true);
                    props.onClose();
                }}>
                    <FontAwesomeIcon icon={faUsers} />
                    {t('app:create_event_for_site')}
                </Button> : null
            }
            {
                (hasAccessToDepositTicket() && optionShownForCurrentPath.includes('depositTicket')) ? <Button onClick={() => {
                    setShowCreateDepositTicket(true);
                    props.onClose();
                }}
                disabled={isOfflineMode}
                >
                    <FontAwesomeIcon icon={faTruckLoading} />
                    {t('app:create_pickup_ticket')}
                </Button> : null
            }
            {
                (hasAccessToSiteTicket() && optionShownForCurrentPath.includes('trash')) ? <Button onClick={() => {
                    setShowCreateSiteTrashDeposit(true);
                    props.onClose();
                }}>
                    <FontAwesomeIcon icon={faTrash} />
                    {/* { t('app:create_site_ticket', {typedoc: localStorage.getItem('site_ticket_label') || t('common:site_ticket')})} */}
                    {t('app:create_trash_deposit')}
                </Button> : null
            }
            {
                (hasAccessToSiteTicket() && optionShownForCurrentPath.includes('siteTicket')) ? <Button onClick={() => {
                    setShowCreateSiteTicket(true);
                    props.onClose();
                }}>
                    <FontAwesomeIcon icon={faPalletAlt} />
                    {/* { t('app:create_site_ticket', {typedoc: localStorage.getItem('site_ticket_label') || t('common:site_ticket')})} */}
                    {t('app:create_site_ticket', { typedoc: t('common:site_ticket') })}
                </Button> : null
            }
            {
                (hasAccessToSupply() && optionShownForCurrentPath.includes('supply')) ? <Button onClick={() => {
                    setShowCreateSupplyPopup(true);
                    props.onClose();
                }}>
                    <FontAwesomeIcon icon={faTruckMoving} />
                    {t('app:create_supply_order')}
                </Button> : null
            }
            {
                (hasAccessToMaterial() && optionShownForCurrentPath.includes('ask_material')) ? <Button onClick={() => {
                    setShowCreateAskMaterialPopup(true);
                    props.onClose();
                }}>
                    <FontAwesomeIcon icon={faShovel} />
                    Créer une demande de matériel
                </Button> : null
            }
            {
                hasAccessToOdometer() && optionShownForCurrentPath.includes('materialevent') ?
                    <Button onClick={() => {
                        setShowFormMaterialEvent(true);
                        props.onClose();
                    }}>
                        <FontAwesomeIcon icon={faTachometerAltSlow} />
                        {t('app:create_odometer')}
                    </Button> : null
            }
            {
                hasAccessToSecurity() && optionShownForCurrentPath.includes('security') ?
                    <Button onClick={() => {
                        setShowSecurityReport(true);
                        props.onClose();
                    }}>
                        <FontAwesomeIcon icon={faShieldCheck} />
                        {t('app:create_security_report')}
                    </Button> : null
            }
        </div>
        <PopupCreateEvent
            callback={() => props.setSiteActivityModuleShown && props.setSiteActivityModuleShown('planning')}
            mode='site'
            show={showCreateEvent}
            onClose={() => { setShowCreateEvent(false); }}
        />
        <FormMaterialEvent
            opened={showFormMaterialEvent}
            onClose={() => setShowFormMaterialEvent(false)}
        />
        <FormCreateDepositeTicket
            callback={() => props.setSiteActivityModuleShown && props.setSiteActivityModuleShown('deposit_ticket')}
            mode='site'
            opened={showCreateDepositTicket}
            onClose={() => { setShowCreateDepositTicket(false); }}
        />
        <FormCreateSiteTicket
            callback={() => props.setSiteActivityModuleShown && props.setSiteActivityModuleShown('site_ticket')}
            mode='site'
            date={siteState.siteDayShown}
            readOnly={readOnly}
            opened={showCreateSiteTicket}
            onClose={() => { setShowCreateSiteTicket(false); }}
        />
        <FormCreateTrashDeposit
            callback={() => props.setShowCreateDepositTicket}
            mode='site'
            readOnly={readOnly}
            opened={showCreateSiteTrashDeposit}
            onClose={() => { setShowCreateSiteTrashDeposit(false); }}
        />
        <FormCreateOrViewSupply
            mode='site'
            readOnly={readOnly}
            opened={showCreateSupplyPopup}
            onClose={() => { setShowCreateSupplyPopup(false); }}
        />
        <FormCreateOrViewAskMaterial
            mode='site'
            readOnly={readOnly}
            opened={showCreateAskMaterialPopup}
            onClose={() => { setShowCreateAskMaterialPopup(false); }}
        />
        <FormSecurityReport
            mode='site'
            readOnly={readOnly}
            opened={showSecurityReport}
            onClose={() => { setShowSecurityReport(false); }}
        />
    </div>;
};

export default SiteOverlayCreate;
