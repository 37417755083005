import { faArrowLeft, faSave } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import DayNavigation from '../../events/components/DayNavigation';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormDayoffRow from './FormDayoffComponents/FormDayoffRow';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import React from 'react';
import { createDayoff } from '../actions';
import moment from 'moment-business-days';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

moment.updateLocale('fr', {
    holidays: [
        '01/01',
        '01/05',
        '08/05',
        '14/07',
        '15/08',
        '01/11',
        '11/11',
        '25/12'
    ],
    holidayFormat: 'DD/MM'
});

const FormDayoff = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [dayoffStartDate, setDayoffStartDate] = React.useState(moment());
    const [dayoffEndDate, setDayoffEndDate] = React.useState(moment().businessAdd(7, 'days'));
    const [totalCA, setTotalCA] = React.useState(0);
    const [totalRTT, setTotalRTT] = React.useState(0);
    const [totalRC, setTotalRC] = React.useState(0);
    const [note, setNote] = React.useState('');
    const [dayoffStartPeriod, setDayoffStartPeriod] = React.useState('morning');
    const [dayoffEndPeriod, setDayoffEndPeriod] = React.useState('morning');
    const employeeState = useSelector(state => state.employees);

    // get current employee
    let currentEmploye = employeeState.team.find(empl =>
        empl.id === employeeState.selectedEmployee
    );
    if (!currentEmploye) {
        currentEmploye = employeeState.all.find(empl =>
            empl.id === employeeState.selectedEmployee
        );
    }

    const resetForm = () => {
        setDayoffStartDate((moment()));
        setDayoffStartPeriod('morning');
        setTotalCA(0);
        setTotalRTT(0);
        setTotalRC(0);
        setNote('');
    };

    const closePopup = () => {
        resetForm();
        props.onClose();
    };

    const validForm = () => {
        const sum = totalCA + totalRTT + totalRC;
        if (sum === 0) {
            toast.error(t('app:please_select_at_least_an_half_day'));
        } else {
            closePopup();
            resetForm();
            const params =  {
                start_date: dayoffStartDate.format('YYYY-MM-DD'),
                start_period: dayoffStartPeriod,
                end_date: dayoffEndDate.format('YYYY-MM-DD'),
                end_period: dayoffEndPeriod,
                total_ca: totalCA,
                total_rtt: totalRTT,
                total_rc: totalRC,
                employee_id: currentEmploye.id,
                note: note
            };
            dispatch(createDayoff(params));
        }
    };

    React.useEffect(function() {
        const sum = totalCA + totalRTT + totalRC;
        setDayoffEndDate(dayoffStartDate.businessAdd(
            dayoffStartPeriod === 'morning' ?
                Math.floor(sum) :
                Math.ceil(sum)
            , 'days'));
        setDayoffEndPeriod(sum % 1 === 0 ?
            dayoffStartPeriod :
            dayoffStartPeriod === 'afternoon' ? 'morning' : 'afternoon'
        );
    }, [totalCA, totalRTT, totalRC, dayoffStartDate, dayoffStartPeriod]);

    return (
        <>
            <Dialog
                maximized={true}
                modal={true}
                visible={props.open}
                onHide={closePopup}
                className="create-dialog dialog-dayoff"
                position="bottom"
                closable
            >
                <div className="header p-shadow-8">
                    <div className="topnavbar">
                        <Button onClick={closePopup} className="p-button-link close-button">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <div className="title">
                            {t('app:create_dayoff')}
                        </div>
                        <Button
                            onClick={() => validForm(false)}
                            className={'p-button-link create-event-button'}>
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </div>
                </div>
                <div className="label">
                    {t('app:dayoff_start_date')}
                </div>
                <DayNavigation
                    goBefore={() => setDayoffStartDate(moment(dayoffStartDate).clone().subtract(1, 'days'))}
                    goNext={() => setDayoffStartDate(moment(dayoffStartDate).clone().add(1, 'days'))}
                    value={dayoffStartDate}
                    onChange={(d) => setDayoffStartDate(moment(d.value))}
                    noLimit={true}
                />
                <div className="day-part">
                    <div className="p-field-radiobutton">
                        <RadioButton
                            inputId="morning-start"
                            checked={dayoffStartPeriod === 'morning'}
                            onChange={() => setDayoffStartPeriod('morning')}
                            value="morning"
                            name="dayoff-start-period"
                        />
                        <label htmlFor="morning-start">{t('common:morning')}</label>
                    </div>
                    <div className="p-field-radiobutton">
                        <RadioButton
                            inputId="afternoon-start"
                            checked={dayoffStartPeriod === 'afternoon'}
                            onChange={() => setDayoffStartPeriod('afternoon')}
                            value="afternoon"
                            name="dayoff-start-period"
                        />
                        <label htmlFor="afternoon-start">{t('common:afternoon')}</label>
                    </div>
                </div>
                <div className="label">
                    {t('app:dayoff_end_date')}
                </div>
                <DayNavigation
                    disabled
                    goBefore={() => setDayoffEndDate(moment(dayoffEndDate).clone().subtract(1, 'days'))}
                    goNext={() => setDayoffEndDate(moment(dayoffEndDate).clone().add(1, 'days'))}
                    value={dayoffEndDate}
                    onChange={(d) => setDayoffEndDate(moment(d.value))}
                />
                <div className="day-part">
                    <div className="p-field-radiobutton">
                        <RadioButton
                            inputId="morning-end"
                            disabled
                            checked={dayoffEndPeriod === 'morning'}
                            value="morning"
                            name="dayoff-end-period"
                        />
                        <label htmlFor="morning-end">{t('common:morning')}</label>
                    </div>
                    <div className="p-field-radiobutton">
                        <RadioButton
                            inputId="afternoon-end"
                            disabled
                            checked={dayoffEndPeriod === 'afternoon'}
                            value="afternoon"
                            name="dayoff-end-period"
                        />
                        <label htmlFor="afternoon-end">{t('common:afternoon')}</label>
                    </div>
                </div>
                <div className="label">
                    {t('common:repartition')}
                </div>
                <div className="dayrow">
                    <FormDayoffRow label="CA" value={totalCA} onChange={setTotalCA}/>
                    <FormDayoffRow label="RTT" value={totalRTT} onChange={setTotalRTT}/>
                    <FormDayoffRow label="RC" value={totalRC} onChange={setTotalRC}/>
                </div>
                <div className="label">
                    {t('common:Note')}
                </div>
                <InputTextarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="note"
                    label="Note"
                    rows={4}
                />
            </Dialog>
        </>
    );
};

export default FormDayoff;