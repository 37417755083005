import axios from 'axios';
import { checkToken } from '../auth/actions';
import localforage from 'localforage';
import { updateApp } from '../app/updateApp';

export const bootstrap = () => (dispatch) => {
    // configure axios
    axios.defaults.headers.pragma = 'no-cache';
    let apiUrl = localStorage.getItem('optimbtp_api_url');
    if (!apiUrl || apiUrl === 'https://api.applimobile.optimbtp.fr') {
        switch(window.location.hostname) {
            case 'test.appli.optimbtp.fr':
                apiUrl = 'https://api.test.applimobile.optimbtp.fr';
                break;
            case 'appli.optimbtp.fr':
                apiUrl = 'https://api.appli.optimbtp.fr';
                break;
            case 'localhost':
                apiUrl = 'http://api.localhost';
                break;
            default:
                apiUrl = `${window.location.protocol}//api.${window.location.hostname}`;
                break;
        }
        localStorage.setItem('optimbtp_api_url', apiUrl);
    }
    axios.defaults.baseURL = `${apiUrl}/api/`;

    // use token
    let jwtToken = localStorage.getItem('optimbtp_session');
    if (jwtToken) {
        dispatch(checkToken(jwtToken));
        // if we have an user loggued, we load application
        if (JSON.parse(atob(jwtToken.split('.')[1])).user_id) {
            updateApp().finally(() => {
                setTimeout(dispatch(isReady(), 0));
            });

        } else {
            dispatch(isReady());
        }
    } else {
        dispatch(isReady());
    }
};

export const isReady = () => ({
    type: 'IS_READY',
    payload: {}
});

export const startOfflineMode = () => ({
    type: 'START_OFFLINE',
    payload: {}
});

export const stopOfflineMode = () =>{
    localforage.dropInstance({ name: 'optim_resources' });
    localforage.dropInstance({ name: 'optim_resources_created' });
    localforage.dropInstance({ name: 'optim_resources_updated' });
    localforage.dropInstance({ name: 'optim_resources_deleted' });
    localforage.dropInstance({ name: 'optim_data_files' });
    localforage.dropInstance({ name: 'optim_data_files_created' });
    localforage.dropInstance({ name: 'optim_data_files_updated' });
    localforage.dropInstance({ name: 'optim_data_files_deleted' });
    localforage.dropInstance({ name: 'optim_data_pictures' });
    localforage.dropInstance({ name: 'optim_data_pictures_created' });
    localforage.dropInstance({ name: 'optim_data_pictures_updated' });
    localforage.dropInstance({ name: 'optim_data_pictures_deleted' });
    localforage.dropInstance({ name: 'optim_files' });
    localforage.dropInstance({ name: 'optim_files_created' });
    localforage.dropInstance({ name: 'optim_files_updated' });
    localforage.dropInstance({ name: 'optim_files_deleted' });
    localforage.dropInstance({ name: 'optim_pictures' });
    localforage.dropInstance({ name: 'optim_pictures_created' });
    localforage.dropInstance({ name: 'optim_pictures_updated' });
    localforage.dropInstance({ name: 'optim_pictures_deleted' });
    return {
        type: 'STOP_OFFLINE',
        payload: {}
    };
};

export const notReady = () => ({
    type: 'NOT_READY',
    payload: {}
});
