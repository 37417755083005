import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faCalendarAlt} from '@fortawesome/pro-solid-svg-icons';
import { faCalendar} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

import EventDay from './components/EventDay.jsx';
import EventWeek from './components/EventWeek.jsx';
import FootBar from '../employees/components/FootBar';
import DropDownEmployee from '../employees/components/DropDownEmployee';
import useRouter from '../../utils/hooks/useRouter';

const Events = () => {
    const { t } = useTranslation();

    const router = useRouter();

    return (
        <div className="module-event">
            <div className="label-employee">
                {t('app:selected_employee')}
            </div>
            <DropDownEmployee/>
            <div className="type-selector">
                <div
                    onClick={() => router.push('/app/employees/event/day')}
                    className={`${router.location.pathname.includes('/app/employees/event/day') ? 'selected' : ''}`}>
                    <FontAwesomeIcon icon={faCalendar} />
                    {t('common:day')}
                </div>
                <div
                    onClick={() => router.push('/app/employees/event/week')}
                    className={`${router.location.pathname.includes('/app/employees/event/week') ? 'selected' : ''}`}>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    {t('common:week')}
                </div>
            </div>
            <Route exact path="/app/employees/event/day">
                <EventDay />
            </Route>
            <Route exact path="/app/employees/event/week">
                <EventWeek />
            </Route>
            <FootBar/>
        </div>
    );
};

export default Events;
