import i18n from 'i18next';
import { toast } from 'react-toastify';
import axios from 'axios';

export const pictureUpdated = (picture) => ({
    type: 'PICTURE_UPDATED',
    payload: picture
});

export const deletePicture = picture => dispatch => {
    return axios.delete(`/picture/${picture.id}`)
        .then(() => {
            toast.success(i18n.t('app:picture_deleted'));
            dispatch({
                type: 'PICTURE_REMOVE_SUCCESS',
                payload: picture
            });
        })
        .catch(() => toast.error(i18n.t('app:cannot_delete_picture')));
};

export const updatePictureComment = (id, description) => dispatch => {
    return axios.patch(`/picture/${id}`, {comment: description})
        .then(picture => dispatch(pictureUpdated(picture.data)))
        .catch(() => toast.error(i18n.t('app:cannot_update_comment')));
};

