import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'primereact/dropdown';
import { useSelector, useDispatch } from 'react-redux';


import { selectEmployee } from '../actions';
const DropDownEmployee = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);
    const employeeState = useSelector(state => state.employees);
    const userState = useSelector(state => state.users);
    const currentUser = userState.list.find(user => user.id === authState.user_id);

    const employeeList = currentUser.is_multi_employee === 1 ? employeeState.all : employeeState.team;

    return <div className="userSelection">
        <Dropdown
            scrollHeight="250px"
            className="p-shadow-2"
            autoFocus
            value={employeeState.selectedEmployee}
            options={employeeList.map(employee => { return {
                value: employee.id,
                label: employee.lastname + ' ' + employee.firstname
            };})}
            onChange={(e) => dispatch(selectEmployee(e.target.value))}
            filter
            placeholder={t('app:no_selected_employee')}
        />
    </div>;

};

export default DropDownEmployee;