import React from 'react';
import { Route } from 'react-router-dom';

import './style.scss';

import SubmoduleList from './components/SubmoduleList';
import Events from '../events';
import DayOff from '../dayoff';
import EmployeeDocument from './components/EmployeeDocument';
import EmployeeMaterial from './components/EmployeeMaterial';
import EmployeeSecurity from './components/EmployeeSecurity';

const Employees = () => {
    return (
        <>
            <Route path="/app/employees/event">
                <Events />
            </Route>
            <Route path="/app/employees/dayoff">
                <DayOff />
            </Route>
            <Route path="/app/employees/documents">
                <EmployeeDocument />
            </Route>
            <Route path="/app/employees/material">
                <EmployeeMaterial/>
            </Route>
            <Route path="/app/employees/security">
                <EmployeeSecurity/>
            </Route>
            <Route exact path="/app/employees">
                <SubmoduleList />
            </Route>
        </>
    );
};

export default Employees;
