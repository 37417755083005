import React from 'react';

import { Route } from 'react-router-dom';

import SubmoduleList from './components/SubmoduleList';
import Supply from '../supply';
import SiteDocument from './components/SiteDocument';

import './style.scss';
import SiteActivity from './components/SiteActivity';
import SiteData from './components/SiteData';
import SiteMaterial from './components/SiteMaterial';
import SecurityTrash from './components/SecurityTrash';
import SiteTrashManagement from './components/SiteTrashManagement';

const Sites = () => {
    return <>
        <Route exact path="/app/sites">
            <SubmoduleList />
        </Route>
        <Route path="/app/sites/data">
            <SiteData/>
        </Route>
        <Route path="/app/sites/documents">
            <SiteDocument/>
        </Route>
        <Route path="/app/sites/supply">
            <Supply/>
        </Route>
        <Route path="/app/sites/activity">
            <SiteActivity/>
        </Route>
        <Route path="/app/sites/material">
            <SiteMaterial/>
        </Route>
        <Route path="/app/sites/security_trash">
            <SecurityTrash/>
        </Route>
        <Route path="/app/sites/trash_management">
            <SiteTrashManagement/>
        </Route>
    </> ;
};

export default Sites;
