import { addImageToStore, deleteStoreAndCache, updateInStoreAndCache } from '../offline/helper';

import axios from 'axios';
import i18n from 'i18next';
import store from '../../store';
import { toast } from 'react-toastify';

export const deleteDepositTicketSuccess = () => toast.success(i18n.t('app:deposit_ticket_deleted'));
export const deleteDepositTicketError = () => toast.error(i18n.t('app:cannot_delete_deposit_ticket'));
export const createDepositTicketSuccess = data => {
    toast.info(i18n.t('app:deposit_ticket_created', {
        code: data.data.code,
    }), {
        autoClose: false,
        position: 'top-center',
        className: 'toast-deposit-ticket-created'
    });
};
export const createDepositTicketError = () => {
    toast.error(i18n.t('app:cannot_create_deposit_ticket'));
};
export const updateDepositTicketSuccess = () => toast.success(i18n.t('app:deposit_ticket_updated'));
export const updateDepositTicketError = () => toast.error(i18n.t('app:cannot_update_deposit_ticket'));

export const depositTicketReceived = depositTickets => ({
    type: 'DEPOSIT_TICKET_RECEIVED',
    payload: depositTickets
});

export const depositTicketUpdate = depositTicket => ({
    type: 'DEPOSIT_TICKET_EDITED',
    payload: depositTicket
});

export const depositTicketDeleted = depositTicketId => ({
    type: 'DEPOSIT_TICKET_DELETED',
    payload: depositTicketId
});

export const depositTicketCreated = depositTicket => ({
    type: 'DEPOSIT_TICKET_CREATED',
    payload: depositTicket
});

export const picturesSentWithSuccess = (files) => ({
    type: 'DEPOSIT_TICKET_UPLOAD_PICTURES_SUCCESS',
    payload: files
});

export const createDepositTicket = (params, files) => dispatch => {
    return axios.post('/deposit_ticket', params)
        .then(data => {
            dispatch(depositTicketCreated(data.data));
            const axiosCalls = [];
            files.forEach(file => {
                const formdata = new FormData();
                formdata.append('file', file.file);
                formdata.append('comment', file.desc);
                formdata.append('deposit_ticket_id', data.data.id);
                axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
            });
            return Promise.all(axiosCalls)
                .then(data_pict => {
                    createDepositTicketSuccess(data);
                    if (files.length > 0) {
                        dispatch(picturesSentWithSuccess(data_pict));
                    }
                });
        })
        .catch(err => {
            createDepositTicketError(err);
        });
};

export const updateDepositTicket = (params, files) => dispatch => {
    const state = store.getState();
    const isOfflineMode = state.core.offlineMode;
    const sites = state.sites.list;
    const depositTickets = state.depositTickets.all;
    const suppliers = state.suppliers.all;
    if (isOfflineMode) {
        updateInStoreAndCache('depositTickets', params, () => {
            const current_site_ticket = depositTickets.find(t => t.id === params.id);
            params.site = sites.find(s => s.id === params.site_id);
            params.supplier = suppliers.find(s => s.id === params.supplier_id);
            dispatch(depositTicketUpdate({ ...current_site_ticket, ...params }));
            updateDepositTicketSuccess();
            if (files.length > 0) {
                files.forEach(file => {
                    addImageToStore({
                        depositTicketId: params.id,
                        file: file.file,
                        comment: file.desc
                    }, () => { });
                });
            }
        });
    } else {
        return axios.patch(`/deposit_ticket/${params.id}`, params)
            .then(data => {
                dispatch(depositTicketUpdate(data.data));
                const axiosCalls = [];
                files.forEach(file => {
                    const formdata = new FormData();
                    formdata.append('file', file.file);
                    formdata.append('deposit_ticket_id', data.data.id);
                    formdata.append('comment', file.desc);
                    axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
                });
                return Promise.all(axiosCalls);
            })
            .then(data => {
                updateDepositTicketSuccess();
                if (files.length > 0) {
                    dispatch(picturesSentWithSuccess(data));
                }
            })
            .catch(err => updateDepositTicketError(err));
    }
};

export const deleteDepositTicket = deposit_ticket_id => dispatch => {
    const isOfflineMode = store.getState().core.offlineMode;
    if (isOfflineMode) {
        deleteStoreAndCache('depositTickets', deposit_ticket_id, () => {
            deleteDepositTicketSuccess();
            dispatch(depositTicketDeleted(deposit_ticket_id));
        });
    } else {
        return axios.delete(`/deposit_ticket/${deposit_ticket_id}`)
            .then(() => {
                deleteDepositTicketSuccess();
                dispatch(depositTicketDeleted(deposit_ticket_id));
            })
            .catch(err => deleteDepositTicketError(err));
    }
};
