import React, { useState } from 'react';
import { faCalendarDay, faFileAlt, faShieldCheck, faTruckPlow, faUmbrellaBeach } from '@fortawesome/pro-duotone-svg-icons';
import { hasAccessToDangerousSituation, hasAccessToDayOff, hasAccessToEmployeeDoc, hasAccessToMaterial, hasAccessToPlanning, hasAccessToSecurity } from '../../../utils/access';

import { Button } from 'primereact/button';
import DropDownEmployee from './DropDownEmployee';
import EmployeeOverlayCreate from './EmployeeOverlayCreate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import useRouter from '../../../utils/hooks/useRouter';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import { faPlus } from '@fortawesome/pro-light-svg-icons';



const SubmoduleList = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const authState = useSelector(state => state.auth);
    const employeeState = useSelector(state => state.employees);
    const appState = useSelector(state => state.app);
    const userState = useSelector(state => state.users);
    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const [showOverlayCreate, setShowOverlayCreate] = useState(false);

    return (
        <div className="moduleSelector">
            <div className="userDescription">
                <div className="userInfo">
                    <div className="initials">
                        {currentUser?.firstname[0].toUpperCase()}{currentUser.lastname[0].toUpperCase()}
                    </div>
                    <div className="name">
                        {currentUser?.firstname.toUpperCase()} {currentUser.lastname.toUpperCase()}
                    </div>
                    <div className="agency">
                        { currentUser?.entity?.rs ?
                            currentUser.entity.rs :
                            appState.company.name
                        }
                    </div>
                </div>
            </div>
            <div className="userModule">
                <DropDownEmployee />
                { hasAccessToPlanning() ?
                    <Button onClick={() => !employeeState.selectedEmployee ? toast.error(t('app:please_select_a_employee')) : router.push('/app/employees/event/day')} className="p-button-link p-shadow-2 module-button">
                        <div className="icon"><FontAwesomeIcon icon={faCalendarDay} /></div>
                        <div className="text">
                            <div className="title">
                                {t('common:planning_employee')}
                            </div>
                            <div className="desc">
                                <div>{t('app:fill_schedule')}</div>
                            </div>
                        </div>
                    </Button> : null
                }
                { hasAccessToEmployeeDoc() ?
                    <Button onClick={() => !employeeState.selectedEmployee ? toast.error(t('app:please_select_a_employee')) : router.push('/app/employees/documents')} className="p-button-link p-shadow-2 module-button">
                        <div className="icon"><FontAwesomeIcon icon={faFileAlt} /></div>
                        <div className="text">
                            <div className="title">
                                {t('common:document')}
                            </div>
                            <div className="desc">
                                <div>{t('app:employee_documents')}</div>
                            </div>
                        </div>
                    </Button> : null
                }
                { hasAccessToDayOff() ?
                    <Button onClick={() => !employeeState.selectedEmployee ? toast.error(t('app:please_select_a_employee')) : router.push('/app/employees/dayoff')} className="p-button-link p-shadow-2 module-button">
                        <div className="icon"><FontAwesomeIcon icon={faUmbrellaBeach} /></div>
                        <div className="text">
                            <div className="title">
                                {t('common:dayoff')}
                            </div>
                            <div className="desc">
                                <div>{t('app:leave_request')}</div>
                            </div>
                        </div>
                    </Button> : null
                }
                { hasAccessToMaterial() ?
                    <Button onClick={() => !employeeState.selectedEmployee ? toast.error(t('app:please_select_a_employee')) : router.push('/app/employees/material')} className="p-button-link p-shadow-2 module-button">
                        <div className="icon"><FontAwesomeIcon icon={faTruckPlow} /></div>
                        <div className="text">
                            <div className="title">
                                {t('common:material')}
                            </div>
                            <div className="desc">
                                <div>{t('app:material_documents')}</div>
                            </div>
                        </div>
                    </Button> : null
                }
                { hasAccessToDangerousSituation() || hasAccessToSecurity() ?
                    <Button onClick={() => !employeeState.selectedEmployee ? toast.error(t('app:please_select_a_employee')) : router.push('/app/employees/security')} className="p-button-link p-shadow-2 module-button">
                        <div className="icon"><FontAwesomeIcon icon={faShieldCheck} /></div>
                        <div className="text">
                            <div className="title">
                                {t('app:security')}
                            </div>
                            <div className="desc">
                                <div>{t('app:security_desc')}</div>
                            </div>
                        </div>
                    </Button> : null
                }
            </div>
            {/* <Button disabled={!employeeState.selectedEmployee} className={`flashy plus-button-module-list ${showOverlayCreate ? 'overlay-shown' : ''}`} onClick={() => setShowOverlayCreate(!showOverlayCreate)}>
                <FontAwesomeIcon icon={faPlus}/><br/>
            </Button> */}
            <EmployeeOverlayCreate show={showOverlayCreate} onClose={() =>  setShowOverlayCreate(false)}/>
        </div>
    );
};

export default SubmoduleList;