import React, { useEffect } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { signEvent, validateEvent } from '../actions';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import EventRow from './EventRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormEvent from './FormEvent';
import SubFootbarWeek from './SubFootbarWeek';
import { canValidEvent } from '../../../utils/access';
import { getEventDuration } from '../../../utils/event_tool';
import imgNoEvent from '../../../assets/no-event.png';
import moment from 'moment';
import { updateEmployee } from '../../app/updateApp';
import { useTranslation } from 'react-i18next';

const getWeekOfDay = currentDate => {
    const weekStart = currentDate.clone().startOf('isoWeek');

    var days = [];

    for (var i = 0; i <= 6; i++) {
        days.push(moment(weekStart).clone().add(i, 'days'));
    }
    return days;
};

const EvenementWeek = () => {
    const employeeState = useSelector(state => state.employees);
    const eventState = useSelector(state => state.events);
    const appState = useSelector(state => state.app);
    const [event, setEvent] = React.useState(null);
    const [currentDate, setCurrentDate] = React.useState(moment());
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [eventType, setEventType] = React.useState('production');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const minCalendarDate = moment(appState.calendarDays[0]['date']).startOf('isoWeek').clone().add(6, 'days');
    const maxCalendarDate = moment(appState.calendarDays[appState.calendarDays.length - 1]['date']).startOf('isoWeek');
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);
    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const userEmployee = currentUser?.employees.find(e => e.pivot.is_default);

    useEffect(function () { updateEmployee();} , []);

    const weekDates = getWeekOfDay(currentDate);
    const list = [];
    let totalWeekHour = 0;
    const weekEvents = [];
    weekDates.map(day => {
        const events = eventState.all
            .filter((event) => {
                if (event.employee_id !== employeeState.selectedEmployee) return false;
                if (!moment(event.event_date).isSame(day, 'day')) return false;
                return true;
            });
        if (events.length === 0) {
            return false;
        }

        // build new block
        // const hourTime = events.reduce((acc, event) => {return acc + (event.event_duration || 0 );}, 0) ;
        // const hour = Math.floor(hourTime / 3600);
        // const min = ((hourTime / 3600) % 1) * 60;
        // list.push(<ListItem key={} className="event-list-row week-day-list">
        //     <ListItemIcon>
        //         <TodayIcon />
        //     </ListItemIcon>
        //     {
        //         !popupOpen ?
        //             <SpeedDial isWeekRow onOpen={type => {
        //                 setPopupOpen(true);
        //                 setEventType(type);
        //                 dispatch(setCalendarDay(day));
        //             }}/> : null
        //     }
        //     <ListItemText primary={`${day.format('dddd DD')} (${hour < 10 ? '0' : ''}${hour}:${min < 10 ? '0' : ''}${min})`}/>
        // </ListItem>);
        list.push(<div className="dayOfWeek" key={day}>
            <div className="dayLabel">
                {day.format('dddd DD MMMM YYYY')}
            </div>
            <div className="events">
                { events.map(
                    event => {
                        weekEvents.push(event);
                        totalWeekHour += getEventDuration(event) / 3600;
                        return <EventRow key={event.id} onClick={() => {
                            if(event.parent_event_id) {
                                setEvent(events.find(e => e.id === event.parent_event_id));
                            } else {
                                setEvent(event);
                            }
                            setEventType(event.event_type);
                            setPopupOpen(true);
                        }} event={event} />;
                    }
                )}
            </div>
        </div>);
        return false;
    });

    const signEvents = () => {
        dispatch(signEvent(weekEvents.filter(e => e.state === 0)));
    };

    const validateEvents = () => {
        if (appState.company.is_signature) {
            dispatch(validateEvent(weekEvents.filter(e => e.state === 2)));
        } else {
            dispatch(validateEvent(weekEvents.filter(e => e.state === 0)));
        }
    };

    // get current employee
    let currentEmploye = employeeState.team.find(empl =>
        empl.id === employeeState.selectedEmployee
    );
    if (!currentEmploye) {
        currentEmploye = employeeState.all.find(empl =>
            empl.id === employeeState.selectedEmployee
        );
    }
    return (
        <>
            <div className="day-navigation">
                <Button disabled={
                    minCalendarDate.isAfter(currentDate)
                } size="small" className="p-button-link" onClick={() => setCurrentDate(currentDate.clone().subtract(1, 'week')) }>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <div className="currentDay">
                    {currentDate.clone().startOf('isoWeek').format('DD MMMM YYYY')} - {currentDate.clone().startOf('isoWeek').add(6,'days').format('DD MMMM YYYY')}
                </div>
                <Button disabled={
                    maxCalendarDate.isBefore(currentDate)
                }size="small" className="p-button-link" onClick={() => setCurrentDate(currentDate.clone().add(1, 'week'))}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
            {
                list.length === 0 ?
                    <div className="no-data">
                        <img src={imgNoEvent} />
                        <div className="label">{t('app:no_evenement')}</div>
                    </div>:
                    <div className="event-data">
                        <div className="planning week-list">
                            {list}
                            <SubFootbarWeek
                                canSign={weekEvents.length > 0 && weekEvents.filter( e => {
                                    if (appState.company.is_signature) {
                                        // user can only sign his event or any event if he is "admin"
                                        if (canValidEvent() || e.employee_id === userEmployee?.id || e.user_id === currentUser.id) {
                                            return e.state === 0 || e.state === '0';
                                        }
                                    }
                                    return false;
                                }).length > 0}
                                canValidate={weekEvents.length > 0 && weekEvents.filter( e => {
                                    if (appState.company.is_signature) {
                                        return e.state === 2 || e.state === '1';
                                    } else {
                                        return e.state === 0 || e.state === '1';
                                    }
                                }).length > 0}
                                onClickSign={signEvents}
                                onClickValid={validateEvents}
                                count={totalWeekHour}
                            />
                        </div>
                    </div>
            }
            <FormEvent
                event={event}
                type={eventType}
                open={popupOpen}
                onClose={() => {
                    setEvent(null);
                    setPopupOpen(false);
                }}
            />
        </>
    );
};

export default EvenementWeek ;