import { faFileAlt, faPalletAlt, faTruckLoading } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SiteDepositTicket from '../../depositTickets/SiteDepositTicket';
import SiteSiteTicket from '../../siteTickets/SiteSiteTicket';

import DropDownSite from './DropDownSite';
import Footbar from './FootBar';
import { updateSite } from '../../app/updateApp';
import SiteDocument from './SiteDocument';
import { hasAccessToDepositTicket, hasAccessToSiteTicket } from '../../../utils/access';

const SiteData = () => {
    const { t } = useTranslation();
    const [module, setModule] = useState('documents');

    useEffect(function () {
        updateSite();
    }, []);

    // const siteTicketName = localStorage.getItem('site_ticket_label') || t('common:site_ticket');
    const siteTicketName = t('common:site_ticket');

    return <div className="module-page site-data">
        <div className="label">
            {t('app:selected_site')}
        </div>
        <DropDownSite/>
        <div className="type-selector">
            <div
                onClick={() => setModule('documents')}
                className={module === 'documents' ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faFileAlt} />
                { module === 'documents' && t('common:document') }
            </div>
            { hasAccessToDepositTicket() && <div
                onClick={() => setModule('deposit_ticket')}
                className={module === 'deposit_ticket' ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faTruckLoading} />
                { module === 'deposit_ticket' && t('common:deposit_ticket') }
            </div> }
            { hasAccessToSiteTicket() && <div
                onClick={() => setModule('site_ticket')}
                className={module === 'site_ticket' ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faPalletAlt} />
                { module === 'site_ticket' && siteTicketName }
            </div> }
        </div>
        { module === 'deposit_ticket' && <SiteDepositTicket readOnly noDate/> }
        { module === 'documents' && <SiteDocument /> }
        { module === 'site_ticket' && <SiteSiteTicket readOnly noDate/> }
        <Footbar />
    </div>;
};

export default SiteData;
