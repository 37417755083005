const initialState = () => ({
    all: []
});

const suppliersReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'SUPPLIERS_RECEIVED':
            newState.all = action.payload.sort((a, b) => {
                if (a.name > b.name ) return 1;
                if (a.name < b.name ) return -1;
                return 0;
            });
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default suppliersReducer;
