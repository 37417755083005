import axios from 'axios';
const initialState = () => ({
    token: false,
    logguedAsCompany: false,
    logguedAsUser: false,
    company_id: null,
    user_id: null
});
const authReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    let jwt_data = null;
    switch (action.type) {
        case 'CHECK_TOKEN':
            newState.token = action.payload.token;
            window.localStorage.setItem('optimbtp_session', action.payload.token);
            axios.defaults.headers.common.Authorization = action.payload.token;
            jwt_data = JSON.parse(atob(action.payload.token.split('.')[1]));
            if (jwt_data.company_id) {
                newState.company_id = jwt_data.company_id;
                newState.logguedAsCompany = true;
            }
            if (jwt_data.user_id) {
                newState.user_id = jwt_data.user_id;
                newState.logguedAsUser = true;
            }
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default authReducer;
