import React, { useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import FormEventCoreChildrenEvent from './FormEventCoreChildrenEvent';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const hours = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

const FormEventCore = props => {
    const { t } = useTranslation();
    const sitesState = useSelector(state => state.sites);
    const employeeState = useSelector(state => state.employees);
    const customersState = useSelector(state => state.customers);
    const company = useSelector(state => state.app.company);

    const {
        fieldErrors,
        disabled,
        eventSite,
        setEventSite,
        eventCustomer,
        setEventCustomer,
        eventNote,
        setEventNote,
        eventWorkshop,
        setEventWorkshop,
        workshopList,
        durationHour,
        setDurationHour,
        durationMinute,
        setDurationMinute,
        dayOffCode,
        setDayOffCode,
        employeeIds,
        setEmployeeIds,
        employeeTypeId,
        setEmployeeTypeId,
        setUserHasSetDuration,
        currentEmploye,
        childrenEvents,
        setChildrenEvents,
        totalDurationHour,
        setTotalDurationHour,
        totalDurationMinute,
        setTotalDurationMinute
    } = props;

    const appState = useSelector(state => state.app);
    const userState = useSelector(state => state.users);
    const authState = useSelector(state => state.auth);
    const [siteFilter, setSiteFilter] = useState('');
    const [employeeFilter, setEmployeeFilter] = useState('');
    const currentUser = userState.list.find(user => user.id === authState.user_id);

    const employeeList = currentUser.is_multi_employee === 1 ? employeeState.all : employeeState.team;
    const [localEmployeeList, setLocalEmployeeList] = useState([]);

    const dropdownOptionSite = (option) => {
        return (
            <div className="dropdown-row">
                <div className="label">{option.label}</div>
                <div className="code">{option.code}</div>
            </div>
        );
    };

    const dropdownOptionTemplate = (option) => {
        return (
            <div className="dropdown-row">
                <div>{option.label}</div>
            </div>
        );
    };

    const sortUserAlgo = (a, b) => {
        if (employeeIds.includes(a.id) && !employeeIds.includes(b.id)) return -1;
        if (employeeIds.includes(b.id) && !employeeIds.includes(a.id)) return 1;
        if (a.lastname > b.lastname) return 1;
        if (a.lastname < b.lastname) return -1;
        if (a.firstname > b.firstname) return 1;
        if (a.firstname < b.firstname) return -1;
        return 0;
    };

    useEffect(function () {
        setLocalEmployeeList(employeeList
            .sort(sortUserAlgo)
            .map(e => {
                return {
                    'label': `${e.lastname.toUpperCase()} ${e.firstname}`,
                    'value': e.id
                };
            })
        );
    }, []);

    useEffect(function () {
        // reset children events if workshhop is "Chantier"
        if (props.type === 'dayoff' || workshopList.find(w => eventWorkshop === w.value)?.import_id === '0') {
            setChildrenEvents([]);
            setDurationHour(totalDurationHour || '0');
            setDurationMinute(totalDurationMinute || '0');
        }
    }, [eventWorkshop, totalDurationHour, totalDurationMinute]);

    const localStorageKey = props.type === 'production' ? 'last-selected-site' : 'last-selected-site-intervention';
    const lastSelectedSite = localStorage.getItem(localStorageKey)?.split(',')?.map(x => parseInt(x, 10)) || [];

    const getUnsetWorkspace = (workspace_id_to_valid) => workshopList.filter(w => {
        if (w.value === workspace_id_to_valid) return true;
        // dont propose 'Chantier' value
        if (w.import_id === '0') return false;
        // dont propose already set values
        let setted = false;
        if (w.value === eventWorkshop) return false;
        childrenEvents.forEach((ce) => {
            if (ce.workshop_id === w.value) setted = true;
        });
        return !setted;
    });

    return <><div className="tabEvent p-shadow-2">
        {(props.mode === 'site') && <>
            <p className="select_title">
                {t('common:employees')}
            </p>
            <MultiSelect
                className={fieldErrors.includes('need_employee') ? 'p-invalid' : ''}
                panelClassName="multiselect-employee"
                disabled={disabled}
                value={employeeIds}
                options={localEmployeeList.filter(emp => emp.label.toLowerCase().includes(employeeFilter.toLowerCase()))}
                panelHeaderTemplate={() =>
                    <div className="filter-desabler">
                        <Button
                            label={t('app:unselect-all')}
                            className="p-button-outlined p-button-plain"
                            onClick={() => {
                                setEmployeeIds([]);
                            }}
                        />
                        <div>
                            <span className="p-input-icon-right">
                                <i className="pi pi-search" />
                                <InputText value={employeeFilter} onChange={(e) => setEmployeeFilter(e.target.value)} placeholder="" />
                            </span>
                        </div>
                    </div>
                }
                onShow={() => setLocalEmployeeList(employeeList
                    .sort(sortUserAlgo)
                    .map(e => {
                        return {
                            'label': `${e.lastname.toUpperCase()} ${e.firstname}`,
                            'value': e.id
                        };
                    })
                )}
                onChange={e => setEmployeeIds(e.value)}
                placeholder={t('app:find_employee')}
                itemTemplate={dropdownOptionTemplate}
                dropdownIcon="pi pi-plus"
                optionLabel="label"
                showSelectAll={false}
                selectedItemsLabel={t('app:employee_selected', { count: employeeIds.length })}
                maxSelectedLabels={1}
            />
            {
                fieldErrors.includes('need_employee') ?
                    <small id="event-site-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                    : null
            }
        </>
        }
        {
            ['production', 'intervention'].includes(props.type) ?
                <>
                    {props.mode !== 'site' && <>
                        <p className="select_title">
                            {props.type === 'production' ? t('app:choose_site') : t('app:choose_intervention')} :
                        </p>
                        <Dropdown
                            disabled={disabled}
                            value={eventSite}
                            area-describedby="event-site-error"
                            virtualScrollerOptions={{ itemSize: 62 }}
                            onFilter={({ filter }) => {
                                setSiteFilter(filter);
                            }}
                            options={sitesState.list
                                .sort((a, b) => {
                                    if (siteFilter.length === 0) {
                                        if (lastSelectedSite.indexOf(a.id) > -1 && lastSelectedSite.indexOf(b.id) === -1) return -1;
                                        if (lastSelectedSite.indexOf(a.id) === -1 && lastSelectedSite.indexOf(b.id) > -1) return 1;
                                        if (a.label > b.label) return 1;
                                        if (a.label < b.label) return -1;
                                    }
                                    return 0;
                                })
                                .filter(site => {
                                    // show last selected sites
                                    if (props.type === 'production') return site.type === 'CHT';
                                    if (props.type === 'intervention') return site.type === 'INT';
                                    return false;
                                })
                                .map(site => {
                                    return {
                                        'label': site.label,
                                        'value': site.id,
                                        'code': '' + site.code
                                    };
                                })}
                            onChange={e => setEventSite(e.value)}
                            filter
                            filterBy="code,label"
                            itemTemplate={dropdownOptionSite}
                            className={fieldErrors.includes('eventSite') ? 'p-invalid' : ''}
                        />
                        {
                            fieldErrors.includes('eventSite') ?
                                <small id="event-site-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                                : null
                        }
                    </>
                    }
                    {
                        props.type === 'intervention' ? <>
                            <p className="select_title">
                                {t('app:choose_customer')} :
                            </p>
                            <Dropdown
                                disabled={disabled}
                                value={eventCustomer}
                                area-describedby="event-customer-error"
                                options={customersState.list.map(customer => {
                                    return {
                                        'label': customer.fullname,
                                        'value': customer.id
                                    };
                                })}
                                onChange={e => setEventCustomer(e.value)}
                                filter
                                itemTemplate={dropdownOptionTemplate}
                                className={fieldErrors.includes('eventCustomer') ? 'p-invalid' : ''}
                            />
                            {
                                fieldErrors.includes('eventCustomer') ?
                                    <small id="event-customer-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                                    : null
                            }
                        </> : null
                    }
                </> :
                (props.type === 'dayoff') ? <>
                    <p className="select_title">
                        {t('app:choose_dayoff_type')} :
                    </p>
                    <Dropdown
                        value={dayOffCode}
                        options={appState.dayOffCodes.map(d => {
                            return {
                                'label': d.label,
                                'code': '' + d.code,
                                'value': d.id
                            };
                        })}
                        area-describedby="event-dayoffcode-error"
                        onChange={e => setDayOffCode(e.value)}
                        disabled={disabled}
                        filter
                        itemTemplate={dropdownOptionTemplate}
                        className={fieldErrors.includes('dayOffCode') ? 'p-invalid' : ''}
                    />
                    {
                        fieldErrors.includes('dayOffCode') ?
                            <small id="event-dayoffcode-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                            : null
                    }
                </> : null
        }
        <p className="select_title">
            {t('app:event_duration')} :
        </p>
        <div className="event-duration">
            <Dropdown
                value={'' + totalDurationHour}
                options={hours.map(h => {
                    return {
                        label: `${h} ${t('common:hour')}${h > 1 ? 's' : ''}`,
                        value: '' + h
                    };
                })}
                disabled={disabled}
                onChange={e => {
                    setTotalDurationHour(e.value);
                    setUserHasSetDuration(true);
                }}
                area-describedby="event-schedule-error"
                className={fieldErrors.includes('mandatoryFields') || fieldErrors.includes('durationWrong') || fieldErrors.includes('durationNeeded') ? 'p-invalid' : ''}
            />
            <div className="separator"> : </div>
            <Dropdown
                value={'' + totalDurationMinute}
                options={['0', '15', '30', '45'].map(m => {
                    return {
                        label: `${m} ${t('common:minute')}${m > 1 ? 's' : ''}`,
                        value: m
                    };
                })}
                disabled={disabled}
                onChange={e => {
                    setTotalDurationMinute(e.value);
                    setUserHasSetDuration(true);
                }}
                area-describedby="event-schedule-error"
                className={fieldErrors.includes('mandatoryFields') || fieldErrors.includes('durationWrong') || fieldErrors.includes('timeslotAndDurationNeeded') ? 'p-invalid' : ''}
            />
        </div>
        {
            fieldErrors.includes('mandatoryFields') ?
                <small id="event-schedule-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                : null
        }
        <div className="wholeday">
            <Button
                className="p-button-raised p-button-plain p-button-text"
                disabled={disabled}
                onClick={props.activateWholeDay}
            >
                <label htmlFor="wholeDay">
                    {t('app:whole_day')}
                </label>
            </Button>

        </div>
        {company.active_employee_type ?
            <>
                <p className="select_title">
                    Sélectionnez un type d&apos;heure:
                </p>
                <Dropdown
                    value={employeeTypeId}
                    options={currentEmploye?.employee_types.map(type => {
                        return {
                            label: type.label,
                            value: type.id
                        };
                    })}
                    disabled={disabled || !company.active_employee_type}
                    onChange={e => {
                        setEmployeeTypeId(e.value);
                    }}
                    area-describedby="event-schedule-error"
                    className={fieldErrors.includes('mandatoryFields') || fieldErrors.includes('durationNeeded') ? 'p-invalid' : ''}
                />
            </>
            : null
        }
        <p className="select_title">
            Note :
        </p>
        <InputTextarea
            className="note"
            disabled={disabled}
            value={eventNote}
            onChange={(e) => setEventNote(e.target.value)}
            rows={4}
        />
    </div>
    {
        props.type !== 'dayoff' ?
            <div className="tabEvent p-shadow-2">
                <p className="select_title">
                    {t('app:choose_workshop')} :
                </p>
                <Dropdown
                    value={eventWorkshop}
                    // options={workshopList}
                    options={workshopList.filter(w => {
                        // dont propose // eady set values
                        let setted = false;
                        if (w.value === eventWorkshop) return true;
                        childrenEvents.forEach(ce => {
                            // if (index === index2) return;
                            if (ce.workshop_id === w.value) setted = true;
                        });
                        if (setted) return false;
                        return true;
                    })}
                    onChange={e => setEventWorkshop(e.value)}
                    filter
                    disabled={disabled}
                    area-describedby="event-workshop-error"
                    itemTemplate={dropdownOptionTemplate}
                    className={fieldErrors.includes('eventWorkshop') ? 'p-invalid' : ''}
                />
                {
                    fieldErrors.includes('eventWorkshop') ?
                        <small id="event-workshop-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                        : null
                }
                {
                    workshopList.find(w => eventWorkshop === w.value)?.import_id !== '0' ?
                        <div className="event-duration" key={eventWorkshop}>
                            <Dropdown
                                value={'' + durationHour}
                                options={hours.map(h => {
                                    return {
                                        label: `${h} ${t('common:hour')}${h > 1 ? 's' : ''}`,
                                        value: '' + h
                                    };
                                })}
                                disabled={disabled}
                                onChange={e => {
                                    setDurationHour(e.value);
                                    if (!durationMinute) {
                                        setDurationMinute(0);
                                    }
                                    setUserHasSetDuration(true);
                                }}
                                area-describedby="event-schedule-error"
                                className={fieldErrors.includes('mandatoryFields') || fieldErrors.includes('durationWrong') || fieldErrors.includes('durationNeeded') ? 'p-invalid' : ''}
                            />
                            <div className="separator"> : </div>
                            <Dropdown
                                value={'' + durationMinute}
                                options={['0', '15', '30', '45'].map(m => {
                                    return {
                                        label: `${m} ${t('common:minute')}${m > 1 ? 's' : ''}`,
                                        value: m
                                    };
                                })}
                                disabled={disabled}
                                onChange={e => {
                                    setDurationMinute(e.value);
                                    setUserHasSetDuration(true);
                                }}
                                area-describedby="event-schedule-error"
                                className={fieldErrors.includes('mandatoryFields') || fieldErrors.includes('durationWrong') || fieldErrors.includes('durationNeeded') ? 'p-invalid' : ''}
                            />
                        </div>
                        : null
                }
                {childrenEvents.map((childEventConfig, index) => {
                    return <FormEventCoreChildrenEvent
                        fieldErrors={fieldErrors}
                        key={childEventConfig.workshop_id}
                        workshopList={ getUnsetWorkspace(childEventConfig.workshop_id)}
                        disabled={disabled}
                        childEventConfig={childEventConfig}
                        onChange={(propperty, value)=> {
                            const _childrenEvents = [...childrenEvents];
                            _childrenEvents[index][propperty] = value;
                            setChildrenEvents(_childrenEvents);
                        }}
                        removeChildrenEvent={() => {
                            const _childrenEvents = [...childrenEvents];
                            _childrenEvents.splice(index, 1);
                            setChildrenEvents(_childrenEvents);
                        }}
                    />;
                })}
                {
                    (workshopList.find(w => eventWorkshop === w.value)?.import_id !== '0' && getUnsetWorkspace().length > 0) ?
                        <div className="add-workshop">
                            <Button
                                className="p-button-raised p-button-plain p-button-text"
                                disabled={disabled}
                                onClick={() => {
                                    const _childrenEvents = [...childrenEvents];
                                    const totalDuration = parseInt(totalDurationHour, 10) * 60 + parseInt(totalDurationMinute, 10);
                                    let consumedDuration = parseInt(durationHour, 10) * 60 + parseInt(durationMinute, 10);
                                    _childrenEvents.forEach(ce => {
                                        consumedDuration += parseInt(ce.durationHour, 10) * 60 + parseInt(ce.durationMinute, 10);
                                    });
                                    const remainedDuration = Math.max(0, parseInt(totalDuration, 10) - parseInt(consumedDuration, 10));
                                    _childrenEvents.push({
                                        durationMinute: remainedDuration % 60,
                                        durationHour: parseInt(remainedDuration / 60, 10),
                                        event_id: null,
                                        workshop_id: getUnsetWorkspace()[0].value
                                    });
                                    setChildrenEvents(_childrenEvents);
                                }}
                            >
                                <label htmlFor="wholeDay">
                                    {t('app:add_workshop')}
                                </label>
                            </Button>
                        </div> : null
                }
            </div>
            : null
    }
    </>;
};

export default FormEventCore;