import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'primereact/dropdown';
import { useSelector, useDispatch } from 'react-redux';

import { setSelectedMaterial } from '../actions';

const DropDownMaterial = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const materialState = useSelector(state => state.materials);

    return <div className="userSelection">
        <Dropdown
            autoFocus
            className="p-shadow-2"
            value={materialState.selectedMaterial}
            options={props.materials?.map(mat => { return {
                value: mat.id,
                label: `${mat.type} > ${mat.designation} (${mat.numero})`,
                code: ''+mat.numero
            };})}
            filterBy="code,label"
            onChange={(e) => dispatch(setSelectedMaterial(e.target.value))}
            filter
            placeholder={t('app:no_selected_material')}
        />
    </div>;
};

export default DropDownMaterial;

