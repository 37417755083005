import React, { useEffect } from 'react';
import { updateEvent, validateEvent } from '../actions';
import { useDispatch, useSelector } from 'react-redux';

import DayNavigation from './DayNavigation';
import EventRow from './EventRow';
import FormEvent from './FormEvent';
import SubFootbarDay from './SubFootbarDay';
import { canValidEvent } from '../../../utils/access';
import imgNoEvent from '../../../assets/no-event.png';
import moment from 'moment';
import { setSiteDayShown } from '../../sites/actions';
import { useTranslation } from 'react-i18next';

const PlanningValidation = () => {
    const { t } = useTranslation();
    const [event, setEvent] = React.useState(null);
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [eventType, setEventType] = React.useState('production');
    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);
    const employeeState = useSelector(state => state.employees);
    const eventState = useSelector(state => state.events);
    const appState = useSelector(state => state.app);

    const earlierEvent = eventState.all
        .filter(e => [appState.company.is_signature ? 2 : 0].includes(e.state))
        .reduce((prev, curr) => {
            if (prev === null) return curr;
            return moment(prev.event_date).isBefore(moment(curr.event_date)) ?
                prev :
                curr;
        }, null);
    const earlierDate = moment(earlierEvent?.event_date);
    const [date, setDate] = React.useState(earlierDate);

    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const userEmployee = currentUser?.employees.find(e => e.pivot.is_default);
    const siteList = useSelector(state => state.sites.list);

    const list = [];
    const siteEvents = eventState.all.filter(event => {
        if (appState.company.is_signature) {
            return [2].includes(event.state) &&
                moment(event.event_date).isSame(date, 'day') &&
                ['production', 'intervention'].includes(event.event_type);
        } else {
            return [0].includes(event.state) &&
                moment(event.event_date).isSame(date, 'day') &&
                ['production', 'intervention'].includes(event.event_type);
        }
    });
    const dateList = eventState.all
        .filter(event => {
            if (appState.company.is_signature) {
                return [2].includes(event.state);
            } else {
                return [0].includes(event.state);
            }
        })
        .map(e => e.event_date)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        });

    const siteIds = siteEvents
        .map(e => e.site_id)
        .filter((value, index, self) => self.indexOf(value) === index);

    siteIds.forEach(siteId => {
        let totalSiteHour = 0;
        const site = siteList.find(s => s.id === siteId);
        const events = siteEvents.filter(event => event.site_id === siteId);
        list.push(<div key={siteId} className="dayOfWeek">
            <div className="dayLabel">
                {site?.label}
            </div>
            <div className="events">
                {events
                    .filter(event => employeeState.all.find(e => e.id === event.employee_id))
                    .map(
                        event => {
                            totalSiteHour += event.event_duration / 3600;
                            return <EventRow onClick={() => {
                                if(event.parent_event_id) {
                                    setEvent(events.find(e => e.id === event.parent_event_id));
                                } else {
                                    setEvent(event);
                                }
                                setEventType(event.event_type);
                                setPopupOpen(true);
                            }} title={`${employeeState.all.find(e => e.id === event.employee_id)?.lastname || 'Employé désactivé'} ${employeeState.all.find(e => e.id === event.employee_id)?.firstname || ''}`}
                            key={event.id} event={event} />;
                        }
                    )}
            </div>
            <SubFootbarDay
                canSign={events.length > 0 && events.filter(e => {
                    if (appState.company.is_signature) {
                        // user can only sign his event or any event if he is "admin"
                        if (canValidEvent() || e.employee_id === userEmployee?.id || e.user_id === currentUser.id) {
                            return e.state === 0 || e.state === '0';
                        }
                    }
                    return false;
                }).length > 0}
                canValidate={events.length > 0 && events.filter(e => {
                    if (appState.company.is_signature) {
                        return e.state === 2 || e.state === '1';
                    } else {
                        return e.state === 0 || e.state === '1';
                    }
                }).length > 0}
                onClickSign={() => {
                    dispatch(validateEvent(events));
                }}
                onClickValid={() => {
                    dispatch(validateEvent(events));
                }}
                count={totalSiteHour}
            />
        </div>);
    });

    useEffect(function () {
        updateEvent();
    }, []);

    useEffect(function () {
        dispatch(setSiteDayShown(date));
    }, [date]);

    if (dateList.length === 0) {
        return <div className="module-page">
            <div className="pointage-validation p-shadow-2">{t('app:planning_to_valid')}</div>
            <div className="no-data">
                <img src={imgNoEvent} />
                <div className="label">{t('app:no_evenement_to_valid')}</div>
            </div>
        </div>;
    }
    return <div className="module-page">
        <div className="pointage-validation p-shadow-2">{t('app:planning_to_valid')}</div>
        <div style={{ marginTop: '12px', marginBottom: '0px' }}>
            <DayNavigation
                goBefore={() => {
                    const d = dateList.reverse().find(d => moment(d).isBefore(date, 'day'));
                    d && setDate(moment(d));
                }}
                goNext={() => {
                    const d = dateList.find(d => moment(d).isAfter(date, 'day'));
                    d && setDate(moment(d));
                }}
                value={date}
                onChange={(d) => setDate(moment(d.value))}
            />
        </div>
        {
            list.length === 0 ?
                <div className="no-data">
                    <img src={imgNoEvent} />
                    <div className="label">{t('app:no_evenement')}</div>
                </div> :
                <div className="event-data">
                    <div className="planning week-list">
                        {list}
                    </div>
                </div>
        }
        <FormEvent
            event={event}
            type={eventType}
            open={popupOpen}
            mode="employee"
            onClose={() => {
                setEvent(null);
                setPopupOpen(false);
            }}
        />
    </div>;
};

export default PlanningValidation;

