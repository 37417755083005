import './style.scss';
import 'react-toastify/dist/ReactToastify.css';

import React, {useEffect} from 'react';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import App from '../app';
import Auth from '../auth';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Helmet } from 'react-helmet';
import PrimeReact from 'primereact/api';
import { ProgressSpinner } from 'primereact/progressspinner';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { bootstrap } from './actions';
import i18n from 'i18next';
import logo from '../../assets/logo.png';
import moment from 'moment';
import { toast } from 'react-toastify';

// eslint-disable-next-line no-undef
require('moment/locale/fr.js');
moment.locale(i18n.language);

// eslint-disable-next-line no-undef
const packagejson  = require('../../../package.json');

PrimeReact.ripple = true;

toast.configure({
    position: toast.POSITION.BOTTOM_LEFT
});

// eslint-disable-next-line no-console
console.log(`
 ██████╗ ██████╗ ████████╗██╗███╗   ███╗    ██████╗ ████████╗██████╗
██╔═══██╗██╔══██╗╚══██╔══╝██║████╗ ████║    ██╔══██╗╚══██╔══╝██╔══██╗
██║   ██║██████╔╝   ██║   ██║██╔████╔██║    ██████╔╝   ██║   ██████╔╝
██║   ██║██╔═══╝    ██║   ██║██║╚██╔╝██║    ██╔══██╗   ██║   ██╔═══╝
╚██████╔╝██║        ██║   ██║██║ ╚═╝ ██║    ██████╔╝   ██║   ██║
 ╚═════╝ ╚═╝        ╚═╝   ╚═╝╚═╝     ╚═╝    ╚═════╝    ╚═╝   ╚═╝

OptimBTP Version ${packagejson.version}`);

function Core() {
    const dispatch = useDispatch();
    const coreState = useSelector(state => state.core);
    useEffect(function () {
        // moment.locale(i18n.language);
        dispatch(bootstrap());
    }, [dispatch]);
    return (
        <>
            <ConfirmDialog />
            <ServiceWorkerWrapper/>
            <Helmet>
                <title>OptimBTP</title>
            </Helmet>
            {
                coreState.ready ?
                    <Router>
                        <Switch>
                            <Route path="/auth">
                                <Auth />
                            </Route>
                            <Route path="/app">
                                <App />
                            </Route>
                            <Redirect exact from="/" to="/auth" />
                        </Switch>
                    </Router> :
                    <div className="loading">
                        <div>
                            <img src={logo}/>
                        </div>
                        <div>
                            <ProgressSpinner strokeWidth="6"/>
                        </div>
                    </div>
            }
        </>
    );
}

export default Core;
