// libs
import React, { useEffect, useRef, useState } from 'react';
import { faArrowLeft, faMinus, faPlus, faSave } from '@fortawesome/pro-solid-svg-icons';

import { Accordion } from 'primereact/accordion';
import { AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
// actions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';
import NumberInput from '../../utils/components/NumberInput';

const FormSearchArticle = props => {
    const { t } = useTranslation();
    const {
        opened,
        onClose,
        options,
        updateSelectedArticles
        // changeArticleCount,
        // addArticle,
        // substractArticle,
        // selectedArticles,
    } = props;

    const [filter, setFilter] = useState('');
    const [openedTabs, setOpenedTabs] = useState([]);
    const [selectedArticles, setSelectedArticles] = useState([]);

    // const selectedArticlesKeys = Object.keys(selectedArticles);
    // const selectedArticlesValues = selectedArticlesKeys.map(k => selectedArticles[k].value);

    const addArticle = article => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        if (_selectedArticles[article.value]) {
            _selectedArticles[article.value].count = parseFloat(_selectedArticles[article.value].count) + 1;
        } else {
            _selectedArticles[article.value] = {
                id: article.id,
                value: article.slug,
                type: article.type,
                code: article.code,
                count: 1,
                unit: article.unit,
                label: article.label
            };
        }
        setSelectedArticles(_selectedArticles);
    };

    const substractArticle = article => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        if (_selectedArticles[article.value]) {
            if (_selectedArticles[article.value].count === 1) {
                delete _selectedArticles[article.value];
            } else {
                _selectedArticles[article.value].count = Math.max(0, _selectedArticles[article.value].count - 1);
            }
            setSelectedArticles(_selectedArticles);
        }
    };

    const changeArticleCount = (article, count) => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        _selectedArticles[article.value] = {
            id: article.id,
            value: article.value,
            type: article.type,
            code: article.code,
            unit: article.unit,
            count: count,
            label: article.label
        };
        setSelectedArticles(_selectedArticles);
        return _selectedArticles;
    };


    useEffect(function () {
        setSelectedArticles(cloneDeep(props.selectedArticles));
    }, [props.selectedArticles, opened]);

    useEffect(function () {
        if (filter.length >= 2) {
            setOpenedTabs([
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
                10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
                30, 31, 32, 33, 34, 35, 36, 37, 38, 39
            ]);
        } else {
            setOpenedTabs([]);
        }
    }, [filter]);

    return (
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={onClose} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div className="title">
                        {t('app:search_articles')}
                    </div>
                    <Button onClick={() => {
                        updateSelectedArticles(selectedArticles);
                        onClose();
                    }} className="p-button-link create-event-button">
                        <FontAwesomeIcon icon={faSave} />
                    </Button>
                </div>
            </div>
            <span className="article-filter p-input-icon-right">
                <i className="pi pi-search" />
                <InputText className="p-shadow-2" style={{ width: '100%' }} value={filter} onChange={e => setFilter(e.target.value)} />
            </span>
            <Accordion className="article-groups" multiple activeIndex={openedTabs} onTabChange={(e) => setOpenedTabs(e.index)}>
                {
                    options
                        .sort((a, b) => {
                            if (a.items[0].type > b.items[0].type) return 1;
                            if (a.items[0].type < b.items[0].type) return -1;
                            return 0;
                        }).map(group => {
                            // for (const option of options) {
                            const groupItems = filter.length > 1 ? group.items.filter(i => {
                                if (i.label.toLowerCase().includes(filter.toLowerCase())) return true;
                                if (i.code.toLowerCase().includes(filter.toLowerCase())) return true;
                                return false;
                            }) : group.items;

                            const parentRef = React.useRef();
                            const rowVirtualizer = useVirtual({
                                size: groupItems.length,
                                parentRef,
                                estimateSize: React.useCallback(() => 80, []),
                                overscan: 5
                            });

                            if (groupItems.length === 0) return null;

                            return <AccordionTab key={group.label} openead header={
                                `${group.items[0].type === 'order' ?
                                    t('common:deposit') :
                                    t('common:order')} - ${group.label}`}>
                                <div
                                    key={group.label}
                                    style={{ height: `${Math.min(400, 80 * groupItems.length)}px`, width: '100%', overflow: 'auto' }}
                                    ref={parentRef}
                                >
                                    <div
                                        style={{
                                            height: `${rowVirtualizer.totalSize}px`,
                                            width: '100%',
                                            position: 'relative'
                                        }}
                                    >
                                        {rowVirtualizer.virtualItems.map(virtualRow => {
                                            const option = groupItems[virtualRow.index];
                                            return <div
                                                key={virtualRow.index}
                                                className={virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: `${virtualRow.size}px`,
                                                    transform: `translateY(${virtualRow.start}px)`
                                                }}
                                            >
                                                <div className="article-row" key={option.id}>
                                                    <div className="title">
                                                        {`${option.code} > ${option.label.length > 50 ?
                                                            option.label.substr(0, 50) + '...' :
                                                            option.label
                                                        }`}
                                                    </div>
                                                    <div className="counter">
                                                        <Button
                                                            className="counter-button p-button-link"
                                                            onClick={() => substractArticle(option)}
                                                        >
                                                            <FontAwesomeIcon icon={faMinus} />
                                                        </Button>
                                                        <div className="article-count">
                                                            <NumberInput
                                                                value={selectedArticles[option.slug] ? selectedArticles[option.slug].count : 0}
                                                                onValueChange={e => changeArticleCount(option, e.value)}
                                                            />
                                                        </div>
                                                        <Button
                                                            className="counter-button p-button-link"
                                                            onClick={() => addArticle(option)}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>;
                                        })}
                                    </div>
                                </div></AccordionTab>;
                        })
                }
            </Accordion>

        </Dialog>
    );
};

export default FormSearchArticle;
