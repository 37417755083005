import './style.scss';

/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { faCalendarCheck, faFileCheck, faFileUser, faHome, faQuestionCircle, faShieldCheck, faWifiSlash } from '@fortawesome/pro-light-svg-icons';
import { hasAccessToDangerousSituation, hasAccessToPlanningValidation, hasAccessToSecurity, hasAccessToValidation } from '../../utils/access';
import { isReady, notReady } from '../core/actions';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import Cycles from '../cycles';
import Employees from '../employees';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormStartOffline from '../offline/FormStartOffline';
import FormStopOffline from '../offline/FormStopOffline';
import Materials from '../materials';
import { slide as Menu } from 'react-burger-menu';
import ModuleList from './components/ModuleList';
import PlanningValidation from '../events/components/PlanningValidation';
import { Route } from 'react-router-dom';
import Sites from '../sites';
import TopNavbar from './components/TopNavbar';
// eslint-disable-next-line no-unused-vars
import _localforageGetItems from 'localforage-getitems';
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';
import filesize from 'filesize';
import localforage from 'localforage';
import { logout } from '../auth/actions';
import moment from 'moment';
import { pdfjs } from 'react-pdf';
import { selectEmployee } from '../employees/actions';
import { setSelectedMaterial } from '../materials/actions';
import { setSelectedSite } from '../sites/actions';
import { showHelpPdf } from '../../utils/manageFile';
import { toast } from 'react-toastify';
import { updateApp } from './updateApp';
import useRouter from '../../utils/hooks/useRouter';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-undef
const packageInfo = require('../../../package.json');

// set pdf worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; // eslint-disable-line

const App = () => {
    const authState = useSelector(state => state.auth);
    const coreState = useSelector(state => state.core);
    const userState = useSelector(state => state.users);
    const appState = useSelector(state => state.app);
    const eventState = useSelector(state => state.events);
    const employeesState = useSelector(state => state.employees);
    const sitesState = useSelector(state => state.sites);
    const materialsState = useSelector(state => state.materials);

    const cycles = useSelector(state => state.cycles.list
        .filter(c => ![5, 9].includes(c.cycle_status.import_id))
    );
    const router = useRouter();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const userEmployee = currentUser?.employees.find(e => e.pivot.is_default);

    const [menuOpen, handleMenu] = useState(false);
    const [showFormOffline, setShowFormOffline] = useState(false);
    const [showFormLeaveOffline, setShowFormLeaveOffline] = useState(false);
    const [filesSize, setFilesSize] = useState(0);
    const [picturesSize, setPicturesSize] = useState(0);

    const LFOptimDataPicturesOfflineMode = localforage.createInstance({ name: 'optim_data_pictures' });
    const LFOptimDataFilesOfflineMode = localforage.createInstance({ name: 'optim_data_files' });

    const isOfflineMode = useSelector(state => state.core.offlineMode);

    LFOptimDataFilesOfflineMode.getItems().then((vals) => {
        const size = Object.keys(vals).reduce((prev, key) => {
            return vals[key].size + prev;
        }, 0);
        setFilesSize(size);
    });
    LFOptimDataPicturesOfflineMode.getItems().then((vals) => {
        const size = Object.keys(vals).reduce((prev, key) => {
            return vals[key].size + prev;
        }, 0);
        setPicturesSize(size);
    });

    useEffect(function () {
        // if (window.localStorage.getItem('optim_module')) {
        //     switch(window.localStorage.getItem('optim_module')) {
        //         case "site":
        //             router.push('/app/sites');
        //             break;
        //         case "employee":
        //             router.push('/app/employees');
        //             break;
        //     }
        // }
        // check if selectedSite exists
        if (employeesState.selectedEmployee) {
            if (undefined === employeesState.all.find(e => e.id === employeesState.selectedEmployee)) {
                if (undefined === employeesState.team.find(e => e.id === employeesState.selectedEmployee)) {
                    dispatch(selectEmployee(null));
                    localStorage.removeItem('selected_employee');
                }
            }
        }
        // check if selectedSite exists
        if (sitesState.selectedSite) {
            if (undefined === sitesState.list.find(s => s.id === sitesState.selectedSite)) {
                dispatch(setSelectedSite(null));
                localStorage.removeItem('selected_site');
            }
        }
        // check if selectedSite exists
        if (materialsState.selectedMaterial) {
            if (undefined === materialsState.list.find(m => m.id === materialsState.selectedMaterial)) {
                dispatch(setSelectedMaterial(null));
                localStorage.removeItem('selected_material');
            }
        }
    }, []);

    useEffect(function () {
        if (!authState.logguedAsCompany || !authState.logguedAsUser) {
            router.push('/');
        }
    }, [router, authState]);

    useEffect(function () {
        if (appState.company.max_connections_monthly > 0 && appState.company.current_monthly_user_connected > appState.company.max_connections_monthly) {
            toast.error(t('app:maximum_connection_reached', { count: appState.company.max_connections_monthly }), {
                autoClose: false
            });
        }
    }, [appState.company.max_connections_reached]);

    if (!coreState.ready) {
        return null;
    }

    const offlineDurationMinutes = parseInt(moment.duration(moment().utc().diff(moment.unix(localStorage.getItem('offline_mode_start')))).as('minutes'));

    let offlineLimitReach = false;
    if (isOfflineMode && (offlineDurationMinutes > 60 * appState.company.offline_hours)) {
        offlineLimitReach = true;
    }

    return (
        <>
            {
                appState.applicationOnError ?
                    <div className="error-center">
                        <div>
                            {t('app:cannot_boot_app')}
                        </div>
                        <div>
                            <Button onClick={() => {
                                localStorage.removeItem('optimbtp_api_url');
                                localStorage.removeItem('optimbtp_session');
                                location.reload();
                            }}>
                                {'Retourner à l\'écran de connexion'}
                            </Button>
                        </div>
                    </div>
                    :
                    <div className={`module-app ${isOfflineMode ? 'offlinemode' : ''}`}>
                        <Menu isOpen={menuOpen} onStateChange={state => handleMenu(state.isOpen)} className="p-shadow-8" width={'260px'}>
                            <div className="username">{currentUser?.firstname.toUpperCase()} {currentUser?.lastname.toUpperCase()}</div>
                            <div className="agency">
                                {currentUser?.entity?.rs ?
                                    currentUser.entity.rs :
                                    appState.company.name
                                }
                            </div>
                            <Button onClick={() => { router.push('/'); handleMenu(false); }} className="p-button-link menu-button">
                                <div className="menu-icon"><FontAwesomeIcon icon={faHome} /></div> {t('common:home')}
                            </Button>
                            {hasAccessToValidation() ?
                                <Button disabled={isOfflineMode} onClick={() => { router.push('/app/cycles'); handleMenu(false); }} className="p-button-link menu-button">
                                    <div className="menu-icon"><FontAwesomeIcon icon={faFileCheck} /></div>{t('app:document_to_valid')} {`( ${cycles.length} )`}
                                </Button>
                                : null}
                            {hasAccessToPlanningValidation() ?
                                <Button onClick={() => { router.push('/app/planningvalidation'); handleMenu(false); }} className="p-button-link menu-button" disabled={isOfflineMode} >
                                    <div className="menu-icon" style={{ width: 33 }}><FontAwesomeIcon icon={faCalendarCheck} /></div>
                                    {t('app:planning_to_valid')} {`( ${eventState.all.filter(event => {
                                        if (!(employeesState.all.find(e => e.id === event.employee_id))) {
                                            return false;
                                        }
                                        if (appState.company.is_signature) {
                                            return [2].includes(event.state);
                                        } else {
                                            return [0].includes(event.state);
                                        }
                                    })?.length || 0} )`}
                                </Button>
                                : null}
                            {(hasAccessToSecurity() || hasAccessToDangerousSituation()) ?
                                <Button disabled={isOfflineMode} onClick={() => { router.push(hasAccessToSecurity() ? '/app/sites/security_trash' : '/app/employees/security'); handleMenu(false); }} className="p-button-link menu-button">
                                    <div className="menu-icon"><FontAwesomeIcon icon={faShieldCheck} /></div>{t('app:security')}
                                </Button>
                                : null}
                            {/* { hasAccessToMaterial() ?
                                <Button onClick={() => {router.push('/app/materials'); handleMenu(false);}} className={`p-button-link menu-button ${router.location.pathname.includes('/app/materials') ? 'active' : ''}`}>
                                    <div className="menu-icon"><FontAwesomeIcon icon={faShovel} /></div> {t('app:all_materials')}
                                </Button>
                                : null } */}
                            {
                                userEmployee && <Button onClick={() => {
                                    dispatch(selectEmployee(userEmployee.id));
                                    router.push('/app/employees/documents'); handleMenu(false);
                                }} className="p-button-link menu-button">
                                    <div className="menu-icon"><FontAwesomeIcon icon={faFileUser} /></div> {t('app:my_documents')}
                                </Button>
                            }
                            {appState?.company?.help_file && <Button onClick={() => showHelpPdf(appState.company.id)} className={'p-button-link menu-button'}>
                                <div className="menu-icon"><FontAwesomeIcon icon={faQuestionCircle} /></div> {t('common:help')}
                            </Button>}
                            {
                                appState.company.offline_hours > 0 ?
                                    coreState.offlineMode ?
                                        <>
                                            <Button onClick={() => {
                                                handleMenu(false);
                                                setShowFormLeaveOffline(true);
                                            }} className="p-button-link menu-button">
                                                <div className="menu-icon" style={{ width: '36px' }}><FontAwesomeIcon icon={faWifiSlash} /></div>{t('app:deactivate_offline_mode')}
                                            </Button>
                                            {!offlineLimitReach ?
                                                <div style={{
                                                    backgroundColor: '#ddd',
                                                    padding: '10px',
                                                    textAlign: 'center'
                                                }}>                                                        Temps restant en mode offline : {('' + (appState.company.offline_hours - Math.ceil(offlineDurationMinutes / 60))).padStart(2, '0')}:
                                                    {('' + ((appState.company.offline_hours * 60 - offlineDurationMinutes) % 60)).padStart(2, '0')}
                                                </div>
                                                :
                                                <div style={{
                                                    color: 'white',
                                                    backgroundColor: '#cc0000',
                                                    padding: '10px',
                                                    textAlign: 'center'
                                                }}>
                                                    LIMITE DE TEMPS OFFLINE ATTEINTE
                                                </div>
                                            }
                                        </>
                                        :
                                        <>
                                            <Button onClick={() => {
                                                dispatch(notReady());
                                                updateApp().finally(() => {
                                                    setTimeout(dispatch(isReady(), 0));
                                                });
                                            }} className="p-button-link menu-button" style={{ marginTop: '2rem' }}>
                                                <div className="menu-icon" style={{ width: '36px' }}><FontAwesomeIcon icon={faArrowsRotate} /></div>Recharger les données
                                            </Button>
                                            <Button onClick={() => {
                                                setShowFormOffline(true);
                                                handleMenu(false);
                                            }} className="p-button-link menu-button">
                                                <div className="menu-icon" style={{ width: '36px' }}><FontAwesomeIcon icon={faWifiSlash} /></div>{t('app:activate_offline_mode')}
                                            </Button>
                                        </>
                                    : null
                            }
                            {!coreState.offlineMode && <Button onClick={() => { dispatch(logout()); handleMenu(false); }} className="p-button-link menu-button button-disconnect">
                                <i className="pi pi-sign-out"></i> {t('app:disconnect')}
                            </Button>}
                            <div className="storeSize">
                                {
                                    coreState.offlineMode ? `${t('app:cache_usage')}: ${filesize(picturesSize + filesSize)}` : null
                                }
                            </div>
                            <div className="package-version">{packageInfo.version}</div>
                        </Menu>
                        <div className={`header ${isOfflineMode ? 'offlinemode p-shadow-4' : ''}`}>
                            {isOfflineMode && <FontAwesomeIcon className="offlineicon" icon={faWifiSlash} />}
                            <Route exact path="/app">
                                <div>
                                    <div className="username">{currentUser?.firstname.toUpperCase()} {currentUser?.lastname.toUpperCase()}</div>
                                    <div className="agency">
                                        {currentUser?.entity?.rs ?
                                            currentUser.entity.rs :
                                            appState.company.name
                                        }
                                    </div>
                                </div>
                            </Route>
                            <Route path="/app/sites">
                                <TopNavbar />
                            </Route>
                            <Route path="/app/materials">
                                <TopNavbar />
                            </Route>
                            <Route path="/app/employees">
                                <TopNavbar />
                            </Route>
                            <Route path="/app/cycles">
                                <TopNavbar />
                            </Route>
                            <Route path="/app/planningvalidation">
                                <TopNavbar />
                            </Route>
                        </div>
                        {offlineLimitReach ?
                            <div className="module-page" style={{
                                justifyContent: 'center',
                            }}>
                                <div style={{
                                    textAlign: 'center'
                                }}>
                                    LIMITE DE TEMPS OFFLINE ATTEINTE
                                </div>
                            </div>
                            : <>
                                <Route exact path="/app">
                                    <ModuleList />
                                </Route>
                                <Route path="/app/employees">
                                    <Employees />
                                </Route>
                                <Route path="/app/sites">
                                    <Sites />
                                </Route>
                                <Route path="/app/materials">
                                    <Materials />
                                </Route>
                                <Route path="/app/cycles">
                                    <Cycles />
                                </Route>
                                <Route path="/app/planningvalidation">
                                    <PlanningValidation />
                                </Route>
                            </>}
                    </div>
            }
            <FormStopOffline opened={showFormLeaveOffline} onClose={() => setShowFormLeaveOffline(false)} />
            <FormStartOffline opened={showFormOffline} onClose={() => setShowFormOffline(false)} />
        </>
    );
};

export default App;
