import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { faFileAlt, faTrash, faTruckMoving, faTrafficCone, faTruckPlow, faShieldCheck } from '@fortawesome/pro-duotone-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'primereact/button';
import useRouter from '../../../utils/hooks/useRouter';

import DropDownSite from './DropDownSite';
// import { faPlus } from '@fortawesome/pro-light-svg-icons';
import SiteOverlayCreate from './SiteOverlayCreate';
import { hasAccessToConsumption, hasAccessToDepositTicket, hasAccessToMaterial, hasAccessToSecurity, hasAccessToSitePlanning, hasAccessToSiteTicket, hasAccessToSupply, hasAccessToValidation } from '../../../utils/access';
import { } from '@fortawesome/pro-solid-svg-icons';

const SubmoduleList = () => {
    const router = useRouter();
    const { t } = useTranslation();
    const siteState = useSelector(state => state.sites);
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);
    const appState = useSelector(state => state.app);
    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const [showOverlayCreate, setShowOverlayCreate] = useState(false);

    const canSeeData = hasAccessToConsumption() || hasAccessToSitePlanning() || hasAccessToValidation() || hasAccessToSiteTicket() || hasAccessToDepositTicket();

    return (
        <div className="moduleSelector">
            <div className="userDescription">
                <div className="userInfo">
                    <div className="initials">
                        {currentUser?.firstname[0].toUpperCase()}{currentUser?.lastname[0].toUpperCase()}
                    </div>
                    <div className="name">
                        {currentUser?.firstname.toUpperCase()} {currentUser?.lastname.toUpperCase()}
                    </div>
                    <div className="agency">
                        { currentUser?.entity?.rs ?
                            currentUser.entity.rs :
                            appState.company.name
                        }
                    </div>
                </div>
            </div>
            <div className="userModule">
                <DropDownSite />
                { canSeeData && <Button className="p-button-link p-shadow-2 module-button" onClick={() => siteState.selectedSite
                    ? router.push('/app/sites/activity')
                    : toast.error(t('app:please_select_a_site'))}
                >
                    <div className="icon"><FontAwesomeIcon icon={faTrafficCone} /></div>
                    <div className="text">
                        <div className="title">
                            {t('common:day_activity')}
                        </div>
                        <div className="desc">
                            <div>{t('app:site_activity')}</div>
                        </div>
                    </div>
                </Button> }
                { canSeeData && <Button className="p-button-link p-shadow-2 module-button" onClick={() => siteState.selectedSite
                    ? router.push('/app/sites/data')
                    : toast.error(t('app:please_select_a_site'))}
                >
                    <div className="icon"><FontAwesomeIcon icon={faFileAlt} /></div>
                    <div className="text">
                        <div className="title">
                            {t('common:site_history')}
                        </div>
                        <div className="desc">
                            <div>{t('app:site_history')}</div>
                        </div>
                    </div>
                </Button> }
                { (hasAccessToSupply() || hasAccessToMaterial()) && <Button className="p-button-link p-shadow-2 module-button" onClick={() => siteState.selectedSite
                    ? router.push('/app/sites/supply')
                    : toast.error(t('app:please_select_a_site'))}
                >
                    <div className="icon">
                        <FontAwesomeIcon icon={faTruckMoving} />
                    </div>
                    <div className="text">
                        <div className="title">
                            {t('app:supply_mat')}
                        </div>
                        <div className="desc">
                            <div>{t('app:site_supply')}</div>
                        </div>
                    </div>
                </Button> }
                { hasAccessToMaterial() ?
                    <Button onClick={() => !siteState.selectedSite ? toast.error(t('app:please_select_a_site')) : router.push('/app/sites/material')} className="p-button-link p-shadow-2 module-button">
                        <div className="icon"><FontAwesomeIcon icon={faTruckPlow} /></div>
                        <div className="text">
                            <div className="title">
                                {t('common:material')}
                            </div>
                            <div className="desc">
                                <div>{t('app:material_documents')}</div>
                            </div>
                        </div>
                    </Button> : null
                }
                { (hasAccessToSecurity() || hasAccessToSiteTicket()) ?
                    <Button onClick={() => !siteState.selectedSite ? toast.error(t('app:please_select_a_site')) : router.push('/app/sites/security_trash')} className="p-button-link p-shadow-2 module-button">
                        <div className="icon">
                            <FontAwesomeIcon icon={faShieldCheck} />
                            <FontAwesomeIcon icon={faTrash} />
                        </div>
                        <div className="text">
                            <div className="title">
                                Sécurité / Déchets
                            </div>
                            <div className="desc">
                                <div>{t('app:security_desc_site')}</div>
                            </div>
                        </div>
                    </Button> : null
                }
            </div>
            {/* <Button disabled={!siteState.selectedSite} className={`flashy plus-button-module-list ${showOverlayCreate ? 'overlay-shown' : ''}`} onClick={() => setShowOverlayCreate(!showOverlayCreate)}>
                <FontAwesomeIcon icon={faPlus}/><br/>
            </Button> */}
            <SiteOverlayCreate show={showOverlayCreate} onClose={() =>  setShowOverlayCreate(false)}/>
        </div>
    );
};

export default SubmoduleList;
