import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faUserHardHat, faTruckPlow  } from '@fortawesome/pro-duotone-svg-icons';

import useRouter from '../../../utils/hooks/useRouter';
import { hasAccessToSite } from '../../../utils/access';
import { useSelector } from 'react-redux';
import { showHelpPdf } from '../../../utils/manageFile';
import { } from '@fortawesome/pro-solid-svg-icons';

import siteActive from '../../../assets/site_active.svg';

const ModuleList = () => {
    const router = useRouter();
    const { t } = useTranslation();
    const appState = useSelector(state => state.app);
    return (
        <div className="module-list">
            <div className="choose_a_module">
                {t('app:choose_a_module')} :
            </div>
            <Button onClick={() => {
                router.push('/app/employees');
                window.localStorage.setItem('optim_module', 'employee');
            }} className="p-button-link module-button">
                <div className="icon"><FontAwesomeIcon icon={faUserHardHat} /></div>
                <div className="text">
                    <div className="title">
                        {t('common:employee')}
                    </div>
                    <div className="desc">
                        <div>{t('app:fill_schedule')}</div>
                        <div>{t('app:leave_request')}</div>
                        <div>{t('app:employee_documents')}</div>
                    </div>
                </div>
            </Button>
            {
                hasAccessToSite() ?
                    <Button onClick={() => {
                        router.push('/app/sites');
                        window.localStorage.setItem('optim_module', 'site');
                    }} className="p-button-link module-button">
                        <div className="icon icon-site"><img src={siteActive} /></div>
                        <div className="text">
                            <div className="title">
                                {t('common:site')}
                            </div>
                            <div className="desc">
                                <div>{t('app:supply_request')}</div>
                                <div>{t('app:site_document')}</div>
                            </div>
                        </div>
                    </Button>
                    : null
            }
            <Button onClick={() => {
                router.push('/app/materials');
                window.localStorage.setItem('optim_module', 'material');
            }} className="p-button-link module-button">
                <div className="icon"><FontAwesomeIcon icon={faTruckPlow} /></div>
                <div className="text">
                    <div className="title">
                        {t('common:material')}
                    </div>
                    <div className="desc">
                        <div>{t('app:material_documents')}</div>
                    </div>
                </div>
            </Button>
            {appState?.company?.help_file &&
                <Button className="p-button-link help-file" onClick={() => showHelpPdf(appState.company.id)}>
                    <FontAwesomeIcon icon={faQuestionCircle}/>
                </Button>}
        </div>
    );
};

export default ModuleList;
