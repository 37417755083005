import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const FormEventCoreChildrenEvent = props => {
    const { t } = useTranslation();
    const { fieldErrors, workshopList, disabled, childEventConfig, onChange, removeChildrenEvent } = props;
    const hours = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

    const dropdownOptionTemplate = (option) => {
        return (
            <div className="dropdown-row">
                <div>{option.label}</div>
            </div>
        );
    };

    return <>
        <hr style={{ margin: '16px 16px 0 16px' }} />
        <Dropdown
            style={{ marginTop: '16px' }}
            value={childEventConfig.workshop_id}
            options={workshopList}
            onChange={e => {
                onChange('workshop_id', e.target.value);
            }}
            filter
            disabled={disabled}
            area-describedby="event-workshop-error"
            itemTemplate={dropdownOptionTemplate}
        />
        {
            <div className="event-duration">
                <Dropdown
                    value={'' + childEventConfig.durationHour}
                    options={hours.map(h => {
                        return {
                            label: `${h} ${t('common:hour')}${h > 1 ? 's' : ''}`,
                            value: '' + h
                        };
                    })}
                    disabled={disabled}
                    onChange={e => {
                        onChange('durationHour', e.value);
                        // if(childEventConfig.durationMinute) {
                        //     onChange('durationMinute', '0');
                        // }
                    }}
                    area-describedby="event-schedule-error"
                    className={fieldErrors.includes('durationWrong') ? 'p-invalid' : ''}
                />
                <div className="separator"> : </div>
                <Dropdown
                    value={'' + childEventConfig.durationMinute}
                    options={['0', '15', '30', '45'].map(m => {
                        return {
                            label: `${m} ${t('common:minute')}${m > 1 ? 's' : ''}`,
                            value: m
                        };
                    })}
                    disabled={disabled}
                    onChange={e => {
                        onChange('durationMinute', e.value);
                    }}
                    area-describedby="event-schedule-error"
                    className={fieldErrors.includes('durationWrong') ? 'p-invalid' : ''}
                />
            </div>
        }
        <div style={{
            display:'grid',
            gridTemplateColumns: '1fr',
            padding: '16px'
        }}>
            <Button
                className="p-button-raised p-button-plain p-button-text"
                disabled={disabled}
                onClick={removeChildrenEvent}
            >
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '8px'
                }}>
                    <div>Supprimer</div>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            </Button>
        </div>
    </>;
};

export default FormEventCoreChildrenEvent;