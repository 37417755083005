const initialState = () => ({
    list: []
});

const customersReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'CUSTOMERS_RECEIVED':
            newState.list = action.payload.customers;
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default customersReducer;
