import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import DocumentDialog from '../../files/components/DocumentDialog';
import { updateCycle } from '../actions';
import { useTranslation } from 'react-i18next';

const CycleRow = props => {
    const { cycle } = props;
    const cycleStatusList = useSelector(state => state.cycles.listStatus);
    const [opened, setOpened] = useState(false);
    const [note, setNote] = useState(cycle.note);
    const [status, setStatus] = useState(cycle.cycle_status_id);
    const [statusMessage, setStatusMessage] = useState(cycle.valid_message_id);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    let statusMessages = [];
    const statusObj = cycleStatusList.find(s => s.id === status);
    if (statusObj) {
        statusMessages = statusObj.cycle_status_messages;
    }

    const openFile = () => {
        setOpened(true);
    };

    const saveCycle = () => {
        dispatch(updateCycle({
            ...cycle,
            note: note,
            valid_message_id: statusMessage,
            cycle_status_id: status
        }));
        props.goBack();
    };

    return ( <>
        <div className="cycle-box">
            {cycle.file
                ? <Button className="see-button flashy" onClick={openFile}>
                    {t('app:see_document')}
                </Button>
                : t('app:no_file_found')
            }
            <div className="cycle-box-row">
                <div>
                    {t('app:num_part')}<br/>
                    <strong>{cycle.part_num}</strong>
                </div>
                <div className="right">
                    {t('app:date_part')}<br/>
                    <strong>{moment(cycle.part_date).format('DD/MM/YYYY')}</strong>
                </div>
            </div>
            <div className="cycle-box-row row-2">
                <div>
                    {t('app:sender')}: <strong>{cycle.sender}</strong><br/>
                    {t('app:tierce')}: <strong>{cycle.tierce}</strong><br/>
                    {t('app:deadline')} :&nbsp;
                    <strong>{ cycle.date_deadline
                        ? moment(cycle.date_deadline).format('DD/MM/YYYY')
                        : 'n/a'
                    }</strong><br/>
                </div>
            </div>
            <div className="cycle-box-row">
                <div>
                    {t('app:payment_method')}<br/>
                    <strong>{cycle.payment_method}</strong>
                </div>
                <div className="right">
                    {t('app:pretax_amount')}<br/>
                    <strong>{cycle.pretax_amount}€</strong>
                </div>
            </div>
            <InputTextarea
                className="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                rows={3}
            />
            <Dropdown
                className="cycle-status"
                onChange={e => setStatus(e.value)}
                value={status}
                options={
                    cycleStatusList.map(status => {
                        return {
                            label: status.label,
                            value: status.id
                        };
                    })
                }
            />
            {
                statusMessages.length > 0 ?
                    <Dropdown
                        className="cycle-status-message"
                        onChange={e => setStatusMessage(e.target.value)}
                        value={statusMessage}
                        options={
                            statusMessages.map(message => {
                                return {
                                    label: message.label,
                                    value: message.id
                                };
                            })
                        }
                    /> : null
            }
            {
                (cycle.note !== note || cycle.valid_message_id !== statusMessage || cycle.cycle_status_id !== status)
                    ? <Button
                        disabled={statusMessages.length > 0 && !statusMessage}
                        className="save-button flashy"
                        onClick={saveCycle}
                    >
                        {t('common:valid')}
                    </Button>
                    : null
            }
        </div>
        { cycle.file
            ? <DocumentDialog opened={opened} file={cycle.file} onClose={() => setOpened(false)} />
            : null
        }
    </>
    );
};

export default CycleRow;
