const initialState = () => ({
    list: []
});

const usersReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'USERS_RECEIVED':
            newState.list = action.payload.users.sort((a, b) => {
                const a_name = `${a.lastname.toLowerCase()} ${a.firstname.toLowerCase()}`;
                const b_name = `${b.lastname.toLowerCase()} ${b.firstname.toLowerCase()}`;
                if (a_name > b_name) return 1;
                if (a_name < b_name) return -1;
                return 0;
            });
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default usersReducer;
