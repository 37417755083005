import React, { useEffect } from 'react';
import { signEvent, validateEvent } from '../../events/actions';
import { useDispatch, useSelector } from 'react-redux';

import EventRow from '../../events/components/EventRow';
import FormEvent from '../../events/components/FormEvent';
import SubFootbarDay from '../../events/components/SubFootbarDay';
import { canValidEvent } from '../../../utils/access';
import imgNoEvent from '../../../assets/no-event.png';
import moment from 'moment';
import { updateEmployee } from '../../app/updateApp';
import { useTranslation } from 'react-i18next';

const SitePlanning = props => {
    const [event, setEvent] = React.useState(null);
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [eventType, setEventType] = React.useState('production');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const eventState = useSelector(state => state.events);
    const appState = useSelector(state => state.app);
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);
    const employeeState = useSelector(state => state.employees);
    const siteState = useSelector(state => state.sites);
    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const userEmployee = currentUser?.employees.find(e => e.pivot.is_default);
    const selectedSite = (siteState.list.find(s =>
        parseInt(s.id) === parseInt(siteState.selectedSite)
    ));
    const employeeList = currentUser.is_multi_employee === 1 ? employeeState.all : employeeState.team;

    useEffect(function () {
        updateEmployee();
    } , []);

    const list = [];
    let totalSiteHour = 0;
    const siteEvents = eventState.all.filter(event => {
        return (event.site_id === selectedSite.id) && moment(event.event_date).isSame(props.date, 'day') && ['production', 'intervention'].includes(event.event_type);
    });

    const employeeIds = siteEvents.map(e => e.employee_id).filter((value, index, self) => self.indexOf(value) === index);

    employeeIds.forEach(employeeId => {
        const employee = employeeList.find(e => e.id === employeeId);
        list.push(<div key={employeeId} className="dayOfWeek">
            <div className="dayLabel">
                {`${employee?.firstname} ${employee?.lastname}`}
            </div>
            <div className="events">
                { siteEvents.filter(event => event.employee_id === employeeId).map(
                    event => {
                        totalSiteHour += event.event_duration / 3600;
                        return <EventRow onClick={() => {
                            if(event.parent_event_id) {
                                setEvent(siteEvents.find(e => e.id === event.parent_event_id));
                            } else {
                                setEvent(event);
                            }
                            setEventType(event.event_type);
                            setPopupOpen(true);
                        }} key={event.id} event={event} />;
                    }
                )}
            </div>
        </div>);
    });

    const signEvents = () => {
        dispatch(signEvent(siteEvents.filter(e => e.state === 0)));
    };

    const validateEvents = () => {
        if (appState.company.is_signature) {
            dispatch(validateEvent(siteEvents.filter(e => e.state === 2)));
        } else {
            dispatch(validateEvent(siteEvents.filter(e => e.state === 0)));
        }
    };
    return <>
        {
            list.length === 0 ?
                <div className="no-data">
                    <img src={imgNoEvent} />
                    <div className="label">{t('app:no_evenement')}</div>
                </div>:
                <div className="event-data">
                    <div className="planning week-list">
                        {list}
                        <SubFootbarDay
                            canSign={siteEvents.length > 0 && siteEvents.filter( e => {
                                if (appState.company.is_signature) {
                                    // user can only sign his event or any event if he is "admin"
                                    if (canValidEvent() || e.employee_id === userEmployee?.id || e.user_id === currentUser.id) {
                                        return e.state === 0 || e.state === '0';
                                    }
                                }
                                return false;
                            }).length > 0}
                            canValidate={siteEvents.length > 0 && siteEvents.filter( e => {
                                if (appState.company.is_signature) {
                                    return e.state === 2 || e.state === '1';
                                } else {
                                    return e.state === 0 || e.state === '1';
                                }
                            }).length > 0}
                            onClickSign={signEvents}
                            onClickValid={validateEvents}
                            count={totalSiteHour}
                        />
                    </div>
                </div>
        }
        <FormEvent
            event={event}
            type={eventType}
            open={popupOpen}
            mode="employee"
            onClose={() => {
                setEvent(null);
                setPopupOpen(false);
            }}
        />
    </>;
};

export default SitePlanning;
