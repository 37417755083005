import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShovel, faTruckMoving } from '@fortawesome/pro-duotone-svg-icons';

import { updateSite } from '../app/updateApp';
import DropDownSite from '../sites/components/DropDownSite';
import Footbar from '../sites/components/FootBar';

import AskMaterial from '../sites/components/AskMaterial';
import SiteSupply from '../sites/components/SiteSupply';
import { hasAccessToMaterial, hasAccessToSupply } from '../../utils/access';

const Supply = () => {
    const { t } = useTranslation();

    const [module, setModule] = useState(hasAccessToSupply() ? 'supply' : 'material');

    useEffect(function () {
        updateSite();
    }, []);

    return (
        <div className="module-page module-supply">
            <div className="label">
                {t('app:selected_site')}
            </div>
            <DropDownSite />
            <div className="type-selector">
                {hasAccessToSupply() && <div
                    onClick={() => setModule('supply')}
                    className={module === 'supply' ? 'selected' : ''}
                >
                    <FontAwesomeIcon icon={faTruckMoving} />
                    {t('common:supply')}
                </div>}
                {hasAccessToMaterial() && <div
                    onClick={() => setModule('material')}
                    className={module === 'material' ? 'selected' : ''}
                >
                    <FontAwesomeIcon icon={faShovel} />
                    {t('common:ask_material')}
                </div>}
            </div>
            { module === 'supply' && <SiteSupply /> }
            { module === 'material' && <AskMaterial/> }
            <Footbar />
        </div>
    );
};

export default Supply;

