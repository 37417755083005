import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

const MaterialInfo = ({ material }) => {
    if (!material) return null;
    const employeesState = useSelector(state => state.employees);
    let odometer = material.odometer;
    let odometer_date = material.odometer_date;
    let odometer_employee = '';
    let odometer_employee_id = material.odometer_employee_id;

    if (material.material_events.length > 0) {
        const sortedEvMat = material.material_events.sort((a, b) => {
            if (a.date > b.date) return -1;
            if (a.date < b.date) return 1;
            if (a.odometer > b.odometer) return -1;
            if (a.odometer < b.odometer) return 1;
            return 0;
        });
        if (!odometer_date || (moment(sortedEvMat[0].date).isAfter(moment(odometer_date)))) {
            odometer = sortedEvMat[0].odometer;
            odometer_date = sortedEvMat[0].date;
            odometer_employee_id = sortedEvMat[0].employee_id;
        }
        if (moment(sortedEvMat[0].date).isSame(moment(odometer_date)) && sortedEvMat[0].odometer > odometer) {
            odometer = sortedEvMat[0].odometer;
            odometer_date = sortedEvMat[0].date;
            odometer_employee_id = sortedEvMat[0].employee_id;
        }
    }

    if (odometer_employee_id) {
        const odo_emp = employeesState.all.find(e => e.id === odometer_employee_id);
        odometer_employee = `${odo_emp?.firstname || ''} ${odo_emp?.lastname.toUpperCase() || ''}`;
    }

    return <div className="material-info">
        <div className="mat_form p-shadow-2">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div className="mat_form_cell">
                                <div className="material-info-titre">
                                    Famille
                                </div>
                                <div className="material-info-data">
                                    {material?.family}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="mat_form_cell">
                                <div className="material-info-titre">
                                    Immatriculation
                                </div>
                                <div className="material-info-data">
                                    {material?.registration}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="mat_form_cell">
                                <div className="material-info-titre">
                                    Type
                                </div>
                                <div className="material-info-data">
                                    {material?.type}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="mat_form_cell">
                                <div className="material-info-titre">
                                    Numéro de série
                                </div>
                                <div className="material-info-data">
                                    {material?.serial_number}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="mat_form_cell">
                                <div className="material-info-titre">
                                    Marque
                                </div>
                                <div className="material-info-data">
                                    {material?.brand}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="mat_form_cell">
                                <div className="material-info-titre">
                                    Numéro interne
                                </div>
                                <div className="material-info-data">
                                    {material?.numero}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="mat_form_cell">
                                <div className="material-info-titre">
                                    Modèle
                                </div>
                                <div className="material-info-data">
                                    {material?.model}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="mat_form_cell">
                                <div className="material-info-titre">
                                    Parc / Loueur
                                </div>
                                <div className="material-info-data">
                                    {material?.parc}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div className="mat_form_cell">
                                <div className="material-info-titre">
                                    Compteur
                                </div>
                                <div className="material-info-data">
                                    {odometer} {material?.odometer_unit}<br />
                                    {odometer_date && moment(odometer_date).format('DD/MM/YYYY')}<br />
                                    {odometer_employee}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="mat_form_cell">
                                <div className="material-info-titre">
                                    Dotation
                                </div>
                                <div className="material-info-data">
                                    {material?.employee_linked?.firstname} {material?.employee_linked?.lastname.toUpperCase()}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
};

export default MaterialInfo;