import React from 'react';
import { useTranslation } from 'react-i18next';
import FormSecurityReport from './components/FormSecurityReport';
import SecurityReportRow from './components/SecurityReportRow';

import './style.scss';

import noDeposit from '../../assets/no-deposit-ticket.png';

const SecurityReport = (props) => {

    const { t } = useTranslation();
    const securityReports = props.reports;
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [securityReport, setSecurityReport] = React.useState(null);
    const securityReportInProgress = securityReports.filter(s => !s.sent);
    const securityReportSent = securityReports.filter(s => s.sent);

    return <>
        {
            <div className="security-report-wrapper">
                {
                    securityReportInProgress
                        .map(s =>
                            <SecurityReportRow
                                key={s.id}
                                open={() => {
                                    setSecurityReport(s);
                                    setPopupOpen(true);
                                }}
                                securityReport={s}
                            />
                        )
                }
                {(securityReportSent.length == 0) &&
                    <div className="no-data">
                        <img src={noDeposit} />
                        <div className="label">{t('app:no_security_report')}</div>
                    </div>
                }
                {(securityReportSent.length > 0) && <div className="previous_label"> {t('app:previous_site_report')} : </div>}
                {
                    securityReportSent.map(s =>
                        <SecurityReportRow
                            key={s.id}
                            open={() => {
                                setSecurityReport(s);
                                setPopupOpen(true);
                            }}
                            securityReport={s}
                        />
                    )
                }
            </div>
        }
        <FormSecurityReport
            onClose={() => setPopupOpen(false)}
            opened={popupOpen}
            securityReport={securityReport}
        />
    </>;
};

export default SecurityReport;
