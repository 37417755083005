import React, {useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import Resizer from 'react-image-file-resizer';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { Menu } from 'primereact/menu';

const Uploader = props => {
    const { sendPicture } = props;

    const { t } = useTranslation();
    const takePictureElement = useRef(null);
    const searchPictureElement = useRef(null);
    const menu = useRef(null);

    const resizeFile = file => new Promise(resolve => {
        Resizer.imageFileResizer(
            file, 1080, 1920, 'JPEG', 95, 0,
            uri => {
                resolve(uri);
            },
            'blob'
        );
    });

    const handleUpload = async event => {
        const file = event.target.files[0];
        const newBlob = await resizeFile(event.target.files[0]);
        sendPicture(new File(
            [newBlob],
            file.name,
            {
                type:'image/jpeg',
                lastModified:new Date()
            }
        ));
    };

    let pictureActions = [
        {
            label: t('app:take_photo'),
            icon: 'pi pi-fw pi-camera',
            command:()=> takePictureElement.current.click()
        },
        {
            label: t('app:search_photo'),
            icon: 'pi pi-fw pi-images',
            command:()=> searchPictureElement.current.click()
        }
    ];

    return <>
        <input className="hideInput" onChange={handleUpload} type="file" ref={takePictureElement} accept="image/*;capture=camera"/>
        <input className="hideInput" onChange={handleUpload} type="file" ref={searchPictureElement} accept="image/*"/>
        <div className="upload-image-button">
            <Menu model={pictureActions} popup ref={menu} />
            <Button className="p-shadow-1" disabled={props.disabled} onClick={e => menu?.current?.toggle(e)} >
                <FontAwesomeIcon icon={faCamera} /> { t('app:take_photo') }
            </Button><br/>
        </div>
    </>;
};

export default Uploader;

