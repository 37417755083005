// libs
import React, { useEffect } from 'react';
import { createDepositTicket, updateDepositTicket } from './actions';
import { faArrowLeft, faPaperPlane, faSave } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import DayNavigation from '../events/components/DayNavigation';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
// actions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import PictureRow from '../pictures/PictureRow';
import PictureRowPreview from '../pictures/PictureRowPreview';
import Uploader from '../uploader';
// components
import { addLocale } from 'primereact/api';
import moment from 'moment-business-days';
import { updateDepositTicket as syncDepositTicket } from '../app/updateApp';
import { toast } from 'react-toastify';
import useRouter from '../../utils/hooks/useRouter';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../utils/components/NumberInput';

addLocale('fr', {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
    monthNamesShort: ['jan', 'fev', 'mar', 'avr', 'mai', 'juin', 'juil', 'aout', 'sept', 'oct', 'nov', 'dec'],
    today: 'Aujourd\'hui',
    clear: 'Effacer'
});

const FormCreateDepositeTicket = props => {
    const { t } = useTranslation();
    const { opened, onClose } = props;

    const dispatch = useDispatch();
    const siteState = useSelector(state => state.sites);
    const suppliersState = useSelector(state => state.suppliers);

    const [disabled, setDisabled] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [date, setDate] = React.useState(moment());
    const [comment, setComment] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [amountFreeTaxe, setAmountFreeTaxe] = React.useState('');
    const [taxes, setTaxes] = React.useState('');
    const [site, setSite] = React.useState(null);
    const [supplierId, setSupplierId] = React.useState(null);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [referenceItem, setReferenceItem] = React.useState('');
    const [fieldErrors, setFieldErrors] = React.useState([]);
    const [sent, setSent] = React.useState(false);
    const router = useRouter();

    useEffect(function () {
        syncDepositTicket();
    }, []);

    useEffect(function () {
        const readOnly = props.readOnly || router.location.pathname === '/app/sites/data';
        if (props.depositTicket) {
            setId(props.depositTicket.id);
            setDisabled(props.depositTicket.synchronized || readOnly);
            setDate(moment(props.depositTicket.date));
            setComment(props.depositTicket.comment || '');
            setAmount(props.depositTicket.amount || '');
            setAmountFreeTaxe(props.depositTicket.amount_free_taxe || '');
            setTaxes(props.depositTicket.amount_taxe || '');
            setSupplierId(props.depositTicket.supplier_id);
            setSite(props.depositTicket.site_id);
            setUploadedFiles([]);
            setReferenceItem(props.depositTicket.reference_item || '');
            setSent(!!props.depositTicket.sent);
        } else {
            setId(null);
            setDisabled(false);
            setDate(moment(props.date));
            setComment('');
            setAmount('');
            setAmountFreeTaxe('');
            setTaxes('');
            setSupplierId(null);
            setSite(siteState.selectedSite);
            setUploadedFiles([]);
            setReferenceItem('');
            setSent(false);
        }
    }, [props.depositTicket, opened]);

    const addFile = file => {
        const _files = uploadedFiles.slice(0);
        _files.push({ file: file, desc: '' });
        setUploadedFiles(_files);
    };

    const isSendButtonAvailable = () => {
        return (amount) ||
            (referenceItem) ||
            (props.depositTicket?.pictures.length > 0) ||
            (uploadedFiles.length > 0)
        ;
    };

    const validForm = (sendNow) => {
        let fieldErrors = [];
        if (!supplierId) fieldErrors.push('supplierId');
        //if (id && !referenceItem) fieldErrors.push('refSupply');
        const params = {
            date: moment(date).format('YYYY-MM-DD'),
            comment: comment,
            reference_item: referenceItem,
            amount: amount,
            amount_free_taxe: amountFreeTaxe,
            amount_taxe: taxes,
            taxes: taxes,
            site_id: site,
            sent: sent || sendNow,
            supplier_id: supplierId,
        };

        if (id && sendNow) {
            if (!isSendButtonAvailable()) fieldErrors.push('cannot_sent');
        }

        setFieldErrors(fieldErrors);
        if (fieldErrors.length > 0) {
            if (fieldErrors.includes('refSupply')) {
                toast.error(t('app:need_to_specify_ref_supplier'));
            } else if (fieldErrors.includes('cannot_sent')) {
                toast.error('Impossible d\'envoyer de bon d\'enlèvement');
            } else {
                toast.error(t('app:cannot_create_deposit_ticket'));
            }
        } else {
            if (id) {
                params.id = id;
                dispatch(updateDepositTicket(params, uploadedFiles));
                if (props.callback) {
                    props.callback();
                }
            } else {
                dispatch(createDepositTicket(params, uploadedFiles));
                if (props.callback) {
                    props.callback();
                }
            }
            onClose();
        }
    };

    const removeTempPicture = (idx) => {
        const newUploadedFile = uploadedFiles.splice(0);
        newUploadedFile.splice(idx, 1);
        setUploadedFiles(newUploadedFile);
    };

    let sentButtonColor = 'grey';

    if (isSendButtonAvailable()) {
        sentButtonColor = 'white';
    }

    if (props?.depositTicket?.sent || props?.depositTicket?.synchronized) {
        sentButtonColor = 'green';
    }



    return (
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={onClose} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div className="title">
                        {id ?
                            t('app:see_deposit_ticket') :
                            t('app:add_deposit_ticket')
                        }
                    </div>
                    {
                        id &&
                        <Button
                            onClick={() => validForm(true)}
                            disabled={props.depositTicket?.sent || props.depositTicket?.synchronized}
                            className={'p-button-link create-event-button'}>
                            <FontAwesomeIcon color={sentButtonColor} icon={faPaperPlane} />
                        </Button> //: null
                    }
                    {
                        !disabled ?
                            <Button
                                onClick={() => validForm(false)}
                                className={'p-button-link create-event-button'}>
                                <FontAwesomeIcon icon={faSave} />
                            </Button> : null
                    }
                </div>
            </div>
            <DayNavigation
                goBefore={() => setDate(moment(date).clone().subtract(1, 'days'))}
                goNext={() => setDate(moment(date).clone().add(1, 'days'))}
                value={date}
                onChange={(d) => setDate(moment(d.value))}
                disabled={disabled}
            ></DayNavigation>
            <div className="label">
                {t('common:site')}
            </div>
            <div className="site-information p-shadow-2">
                {siteState.list.find(s => s.id === site)?.label}
            </div>
            {
                id &&
                <>
                    <div className="label">
                        {t('common:deposit_ticket')}
                    </div>
                    <div className="depositCode p-shadow-2">
                        {props.depositTicket?.code}
                    </div>
                </>
            }
            <div className={`label ${fieldErrors.includes('supplierId') ? 'p-invalid' : ''}`}>

                {t('common:suppliers')}
            </div>
            <Dropdown
                className={fieldErrors.includes('supplierId') ? 'p-invalid' : ''}
                disabled={disabled}
                value={supplierId}
                filter
                filterInputAutoFocus={false}
                options={suppliersState.all.map(s => {
                    return {
                        label: s.name,
                        value: s.id
                    };
                })
                }
                onChange={e => setSupplierId(e.target.value)}
            />
            {
                fieldErrors.includes('supplierId') ?
                    <small id="event-site-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                    : null
            }
            {id && <>
                <div className="label">
                    {t('common:pictures')}
                </div>
                <div className="pictures-list">
                    <Uploader disabled={disabled} withFootbar sendPicture={addFile} />
                    {
                        props.depositTicket?.pictures.length > 0
                            ? props.depositTicket?.pictures.map(p => <PictureRow key={p.id} picture={p} />
                            ) : null
                    }
                    {uploadedFiles.map((p, idx) => <PictureRowPreview onDelete={() => removeTempPicture(idx)} key={p.file} picture={p.file} desc={p.desc} onChangeDesc={value => {
                        const newUploadedFiles = uploadedFiles.slice(0);
                        newUploadedFiles[idx].desc = value;
                        setUploadedFiles(newUploadedFiles);
                    }} />)}
                </div>
                <div className={`label ${fieldErrors.includes('refSupply') ? 'p-invalid' : ''}`}>
                    {t('common:reference_item')}
                </div>
                <InputText
                    disabled={disabled}
                    value={referenceItem}
                    onChange={e => setReferenceItem(e.target.value)}
                />
                <div className="label">
                    {t('common:amount_free_taxe')}
                </div>
                <NumberInput
                    disabled={disabled}
                    value={amountFreeTaxe}
                    digit={2}
                    onValueChange={e => {
                        setAmountFreeTaxe(e.value);
                    }}
                    onBlur={e => {
                        if (amount && !taxes) setTaxes(parseFloat(amount) - parseFloat(e.target.value?.replace(',','.')));
                        if (taxes && !amount) setAmount(parseFloat(e.target.value?.replace(',','.')) + parseFloat(taxes));
                    }}

                />
                <div className="label">
                    {t('common:taxes')}
                </div>
                <NumberInput
                    disabled={disabled}
                    value={taxes}
                    digit={2}
                    onValueChange={e => {
                        setTaxes(e.value);
                    }}
                    onBlur={e => {
                        if (amount && !amountFreeTaxe) setAmountFreeTaxe(parseFloat(amount) - parseFloat(e.target.value?.replace(',','.')));
                        if (amountFreeTaxe && !amount) setAmount(parseFloat(e.target.value?.replace(',','.')) + parseFloat(amountFreeTaxe));
                    }}
                />
                <div className="label">
                    {t('common:amount')}
                </div>
                <NumberInput
                    disabled={disabled}
                    value={amount}
                    digit={2}
                    onValueChange={e => {
                        setAmount(e.value);
                    }}
                    onBlur={e => {
                        if (amountFreeTaxe && !taxes) setTaxes(parseFloat(e.target.value?.replace(',','.')) - parseFloat(amountFreeTaxe));
                        if (taxes && !amountFreeTaxe) setAmountFreeTaxe(parseFloat(e.target.value?.replace(',','.')) - parseFloat(taxes));
                    }}
                />
                <div className="label">
                    {t('common:comment')}
                </div>
                <InputTextarea
                    className="comment"
                    disabled={disabled}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={4}
                />
            </>}
            {
                !id && <Button className="flashy add_the_deposit_ticket" onClick={() => validForm(false)}>
                    {t('app:add_the_deposit_ticket')}
                </Button>
            }
        </Dialog>
    );
};

export default FormCreateDepositeTicket;
