
export const prestationsReceived = (prestations) => ({
    type: 'PRESTATIONS_RECEIVED',
    payload: prestations
});

export const prestationGroupsReceived = (prestations) => ({
    type: 'PRESTATION_GROUPS_RECEIVED',
    payload: prestations
});
