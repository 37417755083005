import React, {useRef} from 'react';
import { InputNumber } from 'primereact/inputnumber';

const NumberInput = props => {

    const inputRef = useRef(null);

    const onBlur = (e) => {
        const cursorPosition = inputRef.current.selectionStart;

        const newValue = parseFloat(props.value);
        if (!isNaN(newValue)) {
            props.onValueChange({
                value: newValue
            });
        } else {
            props.onValueChange({
                value: null
            });
        }
        if (props.onBlur) props.onBlur(e);

        setTimeout(() => {
            inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };

    let newTextVal = ('' + props.value)?.replace(',', '.');

    if (newTextVal) {
        // avoid strange round in js
        newTextVal = ('' + newTextVal)
            ?.replace(/[^0-9.]/, '')
            .replace(/\./g, (match, offset, string) => {
                // Count the commas before the current position
                let count = string.slice(0, offset).split('.').length - 1;
                // Replace with a blank character if it's not the first comma
                return count > 0 ? '' : match;
            });
    }
        
    if (isNaN(newTextVal)) newTextVal = null;

    return <span className="p-inputnumber p-component p-inputwrapper">
        <input
            {...props}
            ref={inputRef}
            className='p-inputnumber-input p-inputtext p-component'
            value={newTextVal}
            type="text"
            inputMode="decimal"
            pattern="[0-9]*" 
            onChange={e => props.onValueChange({ value: e.target.value })}
            onBlur={onBlur}
        /></span>;
};


export default NumberInput;
