import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FormEvent from './FormEvent';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DayNavigation from './DayNavigation';
import { canEditPlanningForDay } from '../../../utils/event_tool';

import iconProduction from '../../../assets/icon-production.svg';
import iconIntervention from '../../../assets/icon-intervention.svg';
import iconDayoff from '../../../assets/icon-dayoff.svg';

const PopupCreateEvent = props => {
    const { t } = useTranslation();
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [canCreate, setCanCreate] = React.useState(false);
    const [eventType, setEventType] = React.useState('production');
    const [eventDate, setEventDate] = React.useState(moment());

    const employeeState = useSelector(state => state.employees);
    const siteState = useSelector(state => state.sites);

    useEffect(function(){
        if(localStorage.getItem('optim_module') === 'site' && siteState.siteDayShown) {
            setEventDate(siteState.siteDayShown);
            return;
        }
        if(localStorage.getItem('optim_module') === 'employee' && employeeState.dayShown) {
            setEventDate(employeeState.dayShown);
            return;
        }
        setEventDate(moment());
    }, [props.show]);

    useEffect(function() {
        setCanCreate(canEditPlanningForDay(eventDate));
    }, [eventDate]);

    return (<>
        <Dialog
            maximized={true}
            modal={true}
            visible={props.show}
            onHide={props.onClose}
            className="overlay-create-event"
            position="bottom"
            closable
        >
            <Button className="close p-button-rounded p-button-lg p-button-text" onClick={props.onClose}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <div className="event-selector">
                <Button disabled={!canCreate} className="p-button-link" onClick={() => {
                    setEventType('production');
                    props.onClose();
                    setPopupOpen(true);
                }}>
                    <div className={`icon ${canCreate ? '' : 'disabled'}`}>
                        <img src={iconProduction}/>
                    </div>
                    <div className="text">
                        {t('common:production')}
                    </div>
                </Button>
                {
                    props.mode !== 'site' && <Button disabled={!canCreate} className="p-button-link" onClick={() => {
                        setEventType('intervention');
                        props.onClose();
                        setPopupOpen(true);
                    }}>
                        <div className={`icon ${canCreate ? '' : 'disabled'}`}>
                            <img src={iconIntervention}/>
                        </div>
                        <div className="text">
                            {t('common:intervention')}
                        </div>
                    </Button>
                }
                {
                    props.mode !== 'site' && <Button disabled={!canCreate} className="p-button-link" onClick={() => {
                        setEventType('dayoff');
                        props.onClose();
                        setPopupOpen(true);
                    }}>
                        <div className={`icon ${canCreate ? '' : 'disabled'}`}>
                            <img src={iconDayoff}/>
                        </div>
                        <div className="text">
                            {t('common:dayoff')}
                        </div>
                    </Button>
                }
            </div>
            <DayNavigation
                goBefore={() => setEventDate(moment(eventDate).clone().subtract(1, 'days'))}
                goNext={() => setEventDate(moment(eventDate).clone().add(1, 'days'))}
                value={eventDate}
                onChange={(d) => setEventDate(moment(d.value))}
            />
        </Dialog>
        <FormEvent
            callback={props.callback}
            date={eventDate}
            type={eventType}
            open={popupOpen}
            mode={props.mode}
            onClose={() => {
                setPopupOpen(false);
            }}
        />
    </>
    );
};

export default PopupCreateEvent;
