import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as serviceWorker from '../../serviceWorker';

import { toast } from 'react-toastify';
import i18n from 'i18next';
import { confirmDialog } from 'primereact/confirmdialog';

export const getCompanyError = () => toast.error(i18n.t('app:cannot_get_company'));


const ServiceWorkerWrapper = () => {
    const { t } = useTranslation();

    const reloadPage = (waitingWorker) => {
        waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        setTimeout(() => {
            window.location.reload(true);
        }, 200);
    };


    const onSWUpdate = (registration) => {
        confirmDialog({
            header: t('common:update'),
            message: t('app:new_version_available'),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:install'),
            rejectLabel: null,
            accept: () => reloadPage(registration.waiting),
            rejectClassName: 'hide'
        });
    };

    useEffect(function () {
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);

    return null;
};

export default ServiceWorkerWrapper;