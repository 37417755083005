import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faShieldCheck} from '@fortawesome/pro-duotone-svg-icons';

import { updateSite } from '../../app/updateApp';
import DropDownSite from './DropDownSite';
import Footbar from './FootBar';

import SiteTrashManagement from './SiteTrashManagement';
import SecurityReport from '../../securityReports/index';
import { hasAccessToSecurity, hasAccessToSiteTicket } from '../../../utils/access';

const Supply = () => {
    const { t } = useTranslation();

    const [module, setModule] = useState(hasAccessToSecurity() ? 'security' : 'trash');

    const sitesState = useSelector(state => state.sites);
    const securityReports = useSelector(state => state.securityReports.all.filter(sr => sr.site_id === sitesState.selectedSite));

    useEffect(function () {
        updateSite();
    }, []);

    return (
        <div className="module-page module-supply">
            <div className="label">
                {t('app:selected_site')}
            </div>
            <DropDownSite />
            <div className="type-selector">
                {hasAccessToSecurity() && <div
                    onClick={() => setModule('security')}
                    className={module === 'security' ? 'selected' : ''}
                >
                    <FontAwesomeIcon icon={faShieldCheck} />
                    {t('app:security')}
                </div>}
                {hasAccessToSiteTicket() && <div
                    onClick={() => setModule('trash')}
                    className={module === 'trash' ? 'selected' : ''}
                >
                    <FontAwesomeIcon icon={faTrash} />
                    Déchets
                </div>}
            </div>
            { module === 'trash' && <SiteTrashManagement/> }
            { module === 'security' && <SecurityReport reports={securityReports}/> }
            <Footbar />
        </div>
    );
};

export default Supply;


