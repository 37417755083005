import React, { useState } from 'react';
import { faFileAlt, faShieldCheck, faTrafficCone, faTrash, faTruckMoving, faTruckPlow } from '@fortawesome/pro-solid-svg-icons';
import { hasAccessToMaterial, hasAccessToSecurity, hasAccessToSiteTicket, hasAccessToSupply } from '../../../utils/access';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SiteOverlayCreate from './SiteOverlayCreate';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { toast } from 'react-toastify';
import useRouter from '../../../utils/hooks/useRouter';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Footbar = props => {
    const { t } = useTranslation();
    const router = useRouter();
    const siteState = useSelector(state => state.sites);
    const materialState = useSelector(state => state.materials);
    const materialSelected = materialState.list.filter(m => m.sites.map(s => s.id).includes(siteState.selectedSite)).find(m => m.id === materialState.selectedMaterial);
    const [showOverlayCreate, setShowOverlayCreate] = useState(false);
    return <div className="footbar">
        <Button
            className={`p-button-link ${router.history.location.pathname === '/app/sites/activity' ? 'selected' : null}`}
            onClick={() => {
                if (siteState.selectedSite) {
                    router.push('/app/sites/activity');
                } else {
                    toast.error(t('app:please_select_a_site'));
                }
            }}>
            <div className="icon">
                <FontAwesomeIcon icon={faTrafficCone} /><br />
            </div>
            <div className="text">
                {t('common:activity')}
            </div>
        </Button>
        <Button
            className={`p-button-link ${router.history.location.pathname.includes('/app/sites/data') ? 'selected' : null}`}
            onClick={() => {
                if (siteState.selectedSite) {
                    router.push('/app/sites/data');
                } else {
                    toast.error(t('app:please_select_a_site'));
                }
            }}>
            <div className="icon">
                <FontAwesomeIcon icon={faFileAlt} /><br />
            </div>
            <div className="text">
                {t('common:documents')}
            </div>
        </Button>
        {(hasAccessToMaterial() || hasAccessToSupply()) && <Button
            className={`p-button-link ${router.history.location.pathname.includes('/app/sites/supply') ? 'selected' : null}`}
            onClick={() => {
                if (siteState.selectedSite) {
                    router.push('/app/sites/supply');
                } else {
                    toast.error(t('app:please_select_a_site'));
                }
            }}>
            <div className="icon">
                <FontAwesomeIcon icon={faTruckMoving} /><br />
            </div>
            <div className="text">
                {t('app:supply_request')}
            </div>
        </Button>
        }
        {hasAccessToMaterial() ?
            <Button
                className={`p-button-link ${router.history.location.pathname === '/app/sites/material' ? 'selected' : null}`}
                onClick={() => {
                    if (siteState.selectedSite) {
                        router.push('/app/sites/material');
                    } else {
                        toast.error(t('app:please_select_a_site'));
                    }
                }}>
                <div className="icon">
                    <FontAwesomeIcon icon={faTruckPlow} /><br />
                </div>
                <div className="text">
                    {t('common:material')}
                </div>
            </Button>
            : null
        }
        {(hasAccessToSiteTicket() || hasAccessToSecurity()) ?
            <Button
                className={`p-button-link ${router.history.location.pathname === '/app/sites/security_trash' ? 'selected' : null}`}
                onClick={() => {
                    if (siteState.selectedSite) {
                        router.push('/app/sites/security_trash');
                    } else {
                        toast.error(t('app:please_select_a_site'));
                    }
                }}>
                <div className="icon">
                    <div style={{'display':'flex'}}>
                        <FontAwesomeIcon icon={faShieldCheck} />
                        <FontAwesomeIcon icon={faTrash} />
                    </div>
                </div>
                <div className="text">
                    Sécurité/ Déchets
                </div>
            </Button>
            : null
        }
        <Button disabled={(router.location.pathname === '/app/sites/material' && !materialSelected) || !siteState.selectedSite || router.location.pathname === '/app/sites/data'} className={`flashy plus-button-footbar ${showOverlayCreate ? 'overlay-shown' : ''}`} onClick={() => setShowOverlayCreate(!showOverlayCreate)}>
            <FontAwesomeIcon icon={faPlus} /><br />
        </Button>
        <SiteOverlayCreate setSiteActivityModuleShown={props.setModule} show={showOverlayCreate} onClose={() => setShowOverlayCreate(false)} />
    </div>;
};

export default Footbar;

