const initialState = () => ({
    all: [],
    lastSiteNumber: null
});

const siteTicketReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'SITE_TICKET_RECEIVED':
            newState.all = action.payload.sort((a, b) => {
                if (a.date > b.date ) return -1;
                if (a.date < b.date ) return 1;
                return 0;
            });
            if (newState.all.length > 0) {
                newState.lastSiteNumber = newState.all.sort((a, b) => {
                    if(a.number < b.number) return 1;
                    if(a.number > b.number) return -1;
                    return 0;
                })[0].number;
            }
            return newState;
        case 'SITE_TICKET_CREATED':
            newState.all.push(action.payload);
            if (newState.all.length > 0) {
                newState.lastSiteNumber = newState.all.sort((a, b) => {
                    if(a.number < b.number) return 1;
                    if(a.number > b.number) return -1;
                    return 0;
                })[0].number;
            }
            return newState;
        case 'SITE_TICKET_EDITED':
            newState.all.forEach((ticket, idx) => {
                if (ticket.id === action.payload.id) {
                    newState.all[idx] = {...action.payload };
                }
            });
            return newState;
        case 'SITE_TICKET_DELETED':
            newState.all.forEach((ticket, idx) => {
                if (ticket.id === action.payload) {
                    newState.all.splice(idx, 1);
                }
            });
            return newState;
        case 'SITE_TICKET_UPLOAD_PICTURES_SUCCESS':
            newState.all.forEach((ticket, idx) => {
                // [0] because all images are on same event
                if (parseInt(ticket.id) === parseInt(action.payload[0].data.site_ticket_id)) {
                    action.payload.forEach(row => {
                        newState.all[idx].pictures.push(row.data);
                    });
                }
            });
            return newState;
        case 'PICTURE_UPDATED':
            if (!action.payload.site_ticket_id) return state;
            newState.all.forEach((m, idx) => {
                if (m.id === action.payload.site_ticket_id) {
                    newState.all[idx].pictures.forEach((p, m_idx) => {
                        if (newState.all[idx].pictures[m_idx].id === action.payload.id) {
                            newState.all[idx].pictures[m_idx].comment = action.payload.comment;
                        }
                    });
                }
            });
            return newState;
        case 'PICTURE_REMOVE_SUCCESS':
            if (!action.payload.site_ticket_id) return state;
            newState.all.forEach((m, idx) => {
                if (m.id === action.payload.site_ticket_id) {
                    newState.all[idx].pictures.forEach((p, m_idx) => {
                        if (newState.all[idx].pictures[m_idx].id === action.payload.id) {
                            newState.all[idx].pictures.splice(m_idx, 1);
                        }
                    });
                }
            });
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default siteTicketReducer;

