import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {}, fr: {}
};

const locales = [ 'common', 'auth', 'app' ];

locales.forEach(locale => {
    resources.fr[locale] = require(`./locales/fr/${locale}.json`); // eslint-disable-line
    resources.en[locale] = require(`./locales/en/${locale}.json`); // eslint-disable-line
});

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        ns: locales,
        defaultNS: 'common',
        resources,
        debug: false,
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
            format(value, format) {
                if (format === 'uppercase') {
                    return value.toUpperCase();
                }
                return value;
            }
        }
    });

export default i18n;
