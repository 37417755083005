import React, { useEffect } from 'react';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormSearchArticle from '../../articles/FormSearchArticle';
import { confirmDialog } from 'primereact/confirmdialog';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { updateSiteConsumption } from '../actions';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../../utils/components/NumberInput';

const SiteConsumption = props => {
    const { t } = useTranslation();
    const siteState = useSelector(state => state.sites);
    const { date } = props;
    const site = siteState.list.find(s => s.id === siteState.selectedSite);
    const [showFormArticles, setShowFormArticles] = React.useState(false);
    const dispatch = useDispatch();

    const [selectedArticles, setSelectedArticles] = React.useState([]);

    const all_articles = useSelector(state => state.articles.all);
    const selectedArticlesKeys = Object.keys(selectedArticles);
    const selectedArticlesValues = selectedArticlesKeys.map(k => selectedArticles[k].value);

    const groups = {};
    const allArticles = [];
    all_articles.forEach(a => {
        allArticles.push({
            id: a.id,
            value: `order_${a.id}`,
            slug: `order_${a.id}`,
            label: a.label,
            code: a.code,
            unit: a.unit,
            group: a.group,
            type: 'order'
        });
    });
    // if (site) {
    //     site.articles.forEach(a => {
    //         allArticles.push({
    //             id: a.id,
    //             value: `supply_${a.id}`,
    //             slug: `supply_${a.id}`,
    //             label: a.label,
    //             code: a.code,
    //             group: a.group,
    //             type: 'supply'
    //         });
    //     });
    // }

    allArticles.forEach(a => {
        if (groups[a.group] === undefined) {
            groups[a.group] = [];
        }
        groups[a.group].push(a);
    });

    const groupLabels = Object.keys(groups);
    const articleOptions = groupLabels.sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }).map(groupLabel => {
        return {
            label: groupLabel,
            items: groups[groupLabel]
        };
    });

    useEffect(function () {
        const selection = {};
        const consumption = site?.consumptions.find(c => moment(c.date).isSame(date, 'day'));
        if (consumption) {
            consumption.articles.forEach(art => {
                selection[`order_${art.id}`] = {
                    key: art.id,
                    unit: art.unit,
                    code: art.code,
                    count: art.pivot.count,
                    id: art.id,
                    label: art.label,
                    type: 'order',
                    value: `order_${art.id}`
                };
            });
            consumption.site_articles.forEach(art => {
                selection[`supply_${art.id}`] = {
                    key: art.id,
                    code: art.code,
                    unit: art.unit,
                    count: art.pivot.count,
                    id: art.id,
                    label: art.label,
                    type: 'supply',
                    value: `supply_${art.id}`
                };
            });
        }
        setSelectedArticles(selection);
    }, [siteState.selectedSite, date]);

    const dispatchNewSelectedArticles = articles => {
        setSelectedArticles(articles);
        dispatch(updateSiteConsumption(site, date, articles));
    };

    const addArticle = article => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        if (_selectedArticles[article.value]) {
            _selectedArticles[article.value].count = parseFloat(_selectedArticles[article.value].count) + 1;
        } else {
            _selectedArticles[article.value] = {
                id: article.id,
                value: article.slug,
                code: article.code,
                unit: article.unit,
                type: article.type,
                count: 1,
                label: article.label
            };
        }
        dispatchNewSelectedArticles(_selectedArticles);
    };

    const changeSelectedArticles = articleValues => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        const selectedArticlesKeys = Object.keys(selectedArticles);

        // remove missing articles
        selectedArticlesKeys.forEach(k => {
            if (!articleValues.includes(selectedArticles[k].value)) {
                delete (_selectedArticles[k]);
            }
        });

        // add new articles
        articleValues.forEach(value => {
            if (!selectedArticlesValues.includes(value)) {
                const article = allArticles.find(a => a.value === value);
                _selectedArticles[article.value] = {
                    count: 1,
                    id: article.id,
                    code: article.code,
                    unit: article.unit,
                    type: article.type,
                    value: article.value,
                    label: article.label
                };
            }
        });
        dispatchNewSelectedArticles(_selectedArticles);
    };

    const substractArticle = article => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        if (_selectedArticles[article.value]) {
            if (_selectedArticles[article.value].count === 1) {
                delete _selectedArticles[article.value];
            } else {
                _selectedArticles[article.value].count = Math.max(0, _selectedArticles[article.value].count - 1);
            }
            dispatchNewSelectedArticles(_selectedArticles);
        }
    };

    const removeArticle = article => {
        confirmDialog({
            header: t('common:update'),
            message: t('app:remove_article'),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                const _selectedArticles = Object.assign({}, selectedArticles);
                delete _selectedArticles[article.value];
                dispatchNewSelectedArticles(_selectedArticles);
            },
        });
    };

    const changeArticleCount = (article, count) => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        count = count.replace(',', '.');
        _selectedArticles[article.value] = {
            id: article.id,
            value: article.value,
            code: article.code,
            unit: article.unit,
            type: article.type,
            count: count,
            label: article.label
        };
        dispatchNewSelectedArticles(_selectedArticles);
        return _selectedArticles;
    };


    return (
        <div className="consumption">
            <div className="available-articles">
                <Button className="button-add-article p-button-link" onClick={() => setShowFormArticles(true)}>
                    {t('app:add_articles')}
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
                <FormSearchArticle
                    // addArticle={addArticle}
                    // substractArticle={substractArticle}
                    updateSelectedArticles={dispatchNewSelectedArticles}
                    changeArticleCount={changeArticleCount}
                    opened={showFormArticles}
                    onClose={() => setShowFormArticles(false)}
                    selectedArticles={selectedArticles}
                    options={articleOptions}
                    onChange={e => changeSelectedArticles(e.value)}
                />
            </div>
            {selectedArticlesKeys.length > 0 ?
                <div className="selected-article-list">
                    {selectedArticlesKeys.map(k => {
                        const art = selectedArticles[k];
                        return <div key={k} className="article-row">
                            <div className="title">
                                {`${art.code} > ${art.label.length > 50 ? art.label.substr(0, 50) + '...' : art.label} [${art.unit}]`}
                            </div>
                            <div className="counter">
                                <Button
                                    className="counter-button p-button-link"
                                    onClick={() => substractArticle(art)}
                                >
                                    <FontAwesomeIcon icon={faMinus} />
                                </Button>
                                <div className="article-count">
                                    <NumberInput
                                        digit={5}
                                        value={art.count}
                                        onValueChange={e => changeArticleCount(art, e.value)}
                                    />
                                </div>
                                <Button
                                    className="counter-button p-button-link"
                                    onClick={() => addArticle(art)}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                                <Button
                                    className="delete-button p-button-link"
                                    onClick={() => removeArticle(art)}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </div>
                        </div>;
                    }
                    )}
                </div>
                : null
            }
        </div>
    );
};

export default SiteConsumption;