import i18n from 'i18next';
import { toast } from 'react-toastify';

export const employeesReceived = (employees) => ({
    type: 'EMPLOYEES_RECEIVED',
    payload: {
        employees
    }
});

export const employeesReceivedError = () => toast.error(i18n.t('users:cannot_get_employeee_list'));

export const selectEmployee = (id) => ({
    type: 'SELECT_EMPLOYEE',
    payload: id
});

export const goToNextCalendarDay = () => ({
    type: 'CALENDAR_NEXT_DAY'
});

export const goToPreviousCalendarDay = () => ({
    type: 'CALENDAR_PREVIOUS_DAY'
});

export const setCalendarDay = (date) => ({
    type: 'SET_DAY',
    payload: date
});
