import React, {useState} from 'react';
import moment from 'moment';
import { Button } from 'primereact/button';
import DocumentDialog from './DocumentDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-duotone-svg-icons';

const DocumentRow = props => {
    const { file } = props;
    const [opened, setOpened] = useState(false);

    const openFile = () => {
        setOpened(true);
    };

    return ( <>
        <Button className="file-box p-shadow-1" onClick={openFile}>
            <div className="icon"><FontAwesomeIcon icon={faFileAlt}/></div>
            <div>{file.title}</div>
            <div className="date">{moment(file.date).format('DD/MM/YYYY')}</div>
            <div>{file.comment}</div>
        </Button>
        <DocumentDialog opened={opened} file={file} onClose={() => setOpened(false)}/>
    </>
    );
};

export default DocumentRow;