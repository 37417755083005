// libs
import React, { useEffect } from 'react';
import { createMaterialEvent, updateMaterialEvent } from '../actions';
import { faArrowLeft, faCalendarStar, faSave } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import DayNavigation from '../../events/components/DayNavigation';
import { Dialog } from 'primereact/dialog';
import DocumentRow from '../../files/components/DocumentRow';
import { Dropdown } from 'primereact/dropdown';
// actions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormSelector from '../../forms/FormSelector';
import { InputTextarea } from 'primereact/inputtextarea';
import PictureRow from '../../pictures/PictureRow';
import PictureRowPreview from '../../pictures/PictureRowPreview';
import Uploader from '../../uploader';
import { faBallotCheck } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment-business-days';
import { toast } from 'react-toastify';
import useRouter from '../../../utils/hooks/useRouter';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../../utils/components/NumberInput';

const FormMaterialEvent = props => {
    const { t } = useTranslation();
    const { opened, onClose } = props;

    const dispatch = useDispatch();
    const materialState = useSelector(state => state.materials);
    const material = materialState.list.find(m => m.id === materialState.selectedMaterial);
    const userState = useSelector(state => state.users);
    const employeeState = useSelector(state => state.employees);
    const authState = useSelector(state => state.auth);
    const formList = useSelector(state => state.forms.list);

    const router = useRouter();

    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const userEmployee = currentUser?.employees.find(e => e.pivot.is_default);

    const siteState = useSelector(state => state.sites);
    const employeeList = currentUser.is_multi_employee === 1 ? employeeState.all : employeeState.team;

    const selectedEmployee = employeeState.selectedEmployee;
    const selectedSite = siteState.selectedSite;

    const [tab, setTab] = React.useState('event');
    const [disabled, setDisabled] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [date, setDate] = React.useState(moment());
    const [comment, setComment] = React.useState('');
    const [odometer, setOdometer] = React.useState('');
    const [employeeId, setEmployeeId] = React.useState(null);
    const [siteId, setSiteId] = React.useState(null);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [siteFilter, setSiteFilter] = React.useState('');
    const [formAnswers, setFormAnswers] = React.useState([]);
    const [forms, setForms] = React.useState([]);
    const lastSelectedSite = localStorage.getItem('last-selected-site')?.split(',')?.map(x => parseInt(x, 10)) || [];

    React.useEffect(function () {
        setForms(formList.filter(f => {
            const site = siteState.list.find(s => s.id === siteId);
            if (!!f.entity_id && f.entity_id !== site?.entity_id) return false;
            return 'FMA' === f.type;
        }));
    }, [siteId, formList]);


    useEffect(function () {
        let employeeId = null;
        // if we edit a material, set the selected employee
        if (props.materialEvent?.employee_id) {
            employeeId = props.materialEvent.employee_id;
        }
        // else if it exists a default employee for this material set it
        else if (employeeList.find(e => e.id === material?.employee_linked_id)) {
            employeeId = material?.employee_linked_id;
            // else select current user
        } else {
            employeeId = userEmployee?.id;
        }
        setEmployeeId(employeeId);
        if (props.materialEvent) {
            setId(props.materialEvent.id);
            setDisabled(props.materialEvent.synchronized);
            setDate(moment(props.materialEvent.date));
            setComment(props.materialEvent.comment || '');
            setOdometer(props.materialEvent.odometer || '');
            setSiteId(props.materialEvent.site_id || null);
            setEmployeeId(props.materialEvent.employee_id || null);
            setUploadedFiles([]);
            setFormAnswers(props.materialEvent.form_answers);
        } else {
            setId(null);
            setDisabled(false);
            setDate(moment());
            setComment('');
            setOdometer('');
            setSiteId(router.location.pathname === '/app/sites/material' ? selectedSite : null);
            setEmployeeId(router.location.pathname === '/app/employees/material' ? selectedEmployee : null);
            setUploadedFiles([]);
            setFormAnswers([]);
        }
        setTab('event');
    }, [props.materialEvent, opened]);

    const addFile = file => {
        const _files = uploadedFiles.slice(0);
        _files.push({ file: file, desc: '' });
        setUploadedFiles(_files);
    };

    const validForm = (keepOpen) => {
        let fieldErrors = [];
        if (!odometer || parseInt(odometer) === 0) fieldErrors.push('odometer');
        if (!materialState.selectedMaterial) fieldErrors.push('material');

        const params = {
            date: moment(date).format('YYYY-MM-DD'),
            comment: comment,
            odometer: odometer,
            site_id: siteId,
            material_id: materialState.selectedMaterial,
            employee_id: employeeId,
            form_answers: formAnswers,
        };

        if (fieldErrors.length > 0) {
            if (fieldErrors.includes('odometer')) {
                toast.error(t('app:need_to_specify_odometer'));
            } else {
                toast.error(t('app:cannot_create_material_event'));
            }
        } else {
            if (id) {
                params.id = id;
                dispatch(updateMaterialEvent(params, uploadedFiles));
            } else {
                dispatch(createMaterialEvent(params, uploadedFiles));
            }
            if(!keepOpen) onClose();
        }
    };

    const removeTempPicture = (idx) => {
        const newUploadedFile = uploadedFiles.splice(0);
        newUploadedFile.splice(idx, 1);
        setUploadedFiles(newUploadedFile);
    };


    const dropdownOptionSite = (option) => {
        return (
            <div className="dropdown-row">
                <div className="label">{option.label}</div>
                <div className="code">{option.code}</div>
            </div>
        );
    };

    const dropdownOptionTemplate = (option) => {
        return (
            <div className="dropdown-row">
                <div>{option.label}</div>
            </div>
        );
    };


    return (
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={onClose} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div className="title">
                        {id ?
                            t('app:see_material_event') :
                            t('app:add_material_event')
                        }
                    </div>
                    {
                        !disabled ?
                            <Button
                                onClick={() => validForm()}
                                className={'p-button-link create-event-button'}>
                                <FontAwesomeIcon icon={faSave} />
                            </Button> : null
                    }
                </div>
            </div>
            <div className="p-shadow-6 material-shown">
                {`${material?.designation} (${material?.numero})`}
            </div>
            <DayNavigation
                goBefore={() => setDate(moment(date).clone().subtract(1, 'days'))}
                goNext={() => setDate(moment(date).clone().add(1, 'days'))}
                value={date}
                onChange={(d) => setDate(moment(d.value))}
                disabled={disabled}
            ></DayNavigation>
            {
                forms.length > 0 ?
                    <div className="panel">
                        <Button onClick={() => setTab('event')} className={`p-button-link ${tab === 'event' ? 'selected' : ''}`}>
                            <div className="icon">
                                <FontAwesomeIcon icon={faCalendarStar} />
                            </div>
                            <div className="text">
                                {t('common:event')}
                            </div>
                        </Button>
                        <Button onClick={() => setTab('forms')} className={`p-button-link ${tab === 'forms' ? 'selected' : ''}`}>
                            <div className="icon">
                                <FontAwesomeIcon icon={faBallotCheck} />
                            </div>
                            <div className="text">
                                {t('common:form')}
                            </div>
                        </Button>
                    </div>
                    : null
            }
            {tab === 'event' ?
                <div className="tabEvent p-shadow-2">
                    <div className="label">
                        {t('common:site')}
                    </div>
                    <Dropdown
                        disabled={disabled}
                        value={siteId}
                        area-describedby="event-site-error"
                        virtualScrollerOptions={{ itemSize: 62 }}
                        onFilter={({ filter }) => {
                            setSiteFilter(filter);
                        }}
                        options={siteState.list
                            ?.filter(site => {
                                return site.type === 'CHT';
                            })
                            .sort((a, b) => {
                                if (siteFilter.length === 0) {
                                    if (lastSelectedSite.indexOf(a.id) > -1 && lastSelectedSite.indexOf(b.id) === -1) return -1;
                                    if (lastSelectedSite.indexOf(a.id) === -1 && lastSelectedSite.indexOf(b.id) > -1) return 1;
                                    if (a.label > b.label) return 1;
                                    if (a.label < b.label) return -1;
                                }
                                return 0;
                            })
                            .map(site => {
                                return {
                                    'label': site.label,
                                    'value': site.id,
                                    'code': '' + site.code
                                };
                            })}
                        onChange={e => setSiteId(e.value)}
                        filter
                        filterBy="code,label"
                        itemTemplate={dropdownOptionSite}
                    />
                    <div className="label">
                        {t('common:employee')}
                    </div>
                    <Dropdown
                        disabled={disabled}
                        value={employeeId}
                        area-describedby="event-site-error"
                        virtualScrollerOptions={{ itemSize: 62 }}
                        options={employeeList
                            .sort((a, b) => {
                                if (a.label > b.label) return 1;
                                if (a.label < b.label) return -1;
                                return 0;
                            })
                            .map(e => {
                                return {
                                    'label': `${e.lastname.toUpperCase()} ${e.firstname}`,
                                    'value': e.id
                                };
                            })}
                        onChange={e => setEmployeeId(e.value)}
                        filter
                        filterBy="label"
                        itemTemplate={dropdownOptionTemplate}
                    />
                    <div className="label">
                        {t('common:odometer')}
                    </div>
                    <div className="wrapper-odometer">
                        <NumberInput
                            disabled={disabled}
                            value={odometer}
                            onValueChange={e => {
                                setOdometer(e.value);
                            }}
                        />
                        <div> {material?.odometer_unit}</div>
                    </div>
                    <div className="label">
                        {t('common:pictures')}
                    </div>
                    <div className="pictures-list">
                        <Uploader disabled={disabled} withFootbar sendPicture={addFile} />
                        {
                            props.materialEvent?.pictures.length > 0
                                ? props.materialEvent.pictures.map(p => <PictureRow key={p.id} picture={p} />
                                ) : null
                        }
                        {uploadedFiles.map((p, idx) => <PictureRowPreview onDelete={() => removeTempPicture(idx)} key={p.file} picture={p.file} desc={p.desc} onChangeDesc={value => {
                            const newUploadedFiles = uploadedFiles.slice(0);
                            newUploadedFiles[idx].desc = value;
                            setUploadedFiles(newUploadedFiles);
                        }} />)}
                        {
                            props.materialEvent?.files?.length > 0 &&
                            props.materialEvent?.files.sort((a, b) => {
                                if (a.extension > b.extension) return 1;
                                if (a.extension > b.extension) return -1;
                                return 0;
                            })
                                .sort((a, b) => {
                                    if (a.title > b.title) return 1;
                                    if (a.title < b.title) return -1;
                                    return 0;
                                })
                                .map(file => {
                                    if (file.extension.toLowerCase() === 'pdf') {
                                        return <DocumentRow key={file.id} file={file} />;
                                    }
                                    if (file.extension.toLowerCase() === 'png' || file.extension.toLowerCase() === 'jpg' || file.extension.toLowerCase() === 'jpeg') {
                                        return <PictureRow key={file.id} file={file} />;
                                    }
                                    return null;
                                })
                        }
                    </div>
                    <div className="label">
                        {t('common:comment')}
                    </div>
                    <div className="comment-wrapper">
                        <InputTextarea
                            className="comment"
                            disabled={disabled}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            rows={4}
                        />
                    </div>
                </div>
                : null
            }
            {tab === 'forms' ?
                <FormSelector
                    forms={forms}
                    formAnswers={formAnswers}
                    setFormAnswers={setFormAnswers}
                    eventDate={props.materialEvent?.date}
                    disabled={disabled}
                    saveFormAnswer={() => validForm(true)}
                    site={siteState.list?.find(s => s.id === siteId)}
                    employee={employeeState.list?.find(e => e.id === employeeId)}
                    material={material}
                    supplier={null}
                    customer={null}
                    individualSave={true}
                />
                : null
            }
        </Dialog>
    );
};

export default FormMaterialEvent;
