import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import NumberInput from '../../../../utils/components/NumberInput';

const FormEventOptions = props => {
    const { t } = useTranslation();

    const {
        eventOptions,
        setEventOptions,
    } = props;

    const appState = useSelector(state => state.app);

    return <div className="tabOptions p-shadow-2">
        {
            props.fieldErrors.includes('mandatoryFields') ?
                <div className="event-options-error" justify="center">
                    {t('app:cannot_create_scheduled_event')}
                </div>
                : null
        }
        {appState.optionnalFields.length > 0 ? appState.optionnalFields.map(field => {
            switch (field.type) {
                case 'option':
                    return <div className="option" key={field.label}>
                        <div className="label">
                            {t(field.label)}
                        </div>
                        <div className="setter">
                            <Checkbox
                                disabled={props.disabled}
                                checked={eventOptions[field.label] || false}
                                onChange={() => {
                                    const newEventOption = Object.assign({}, eventOptions);
                                    if (newEventOption[field.label]) {
                                        delete newEventOption[field.label];
                                    } else {
                                        newEventOption[field.label] = true;
                                    }
                                    setEventOptions(newEventOption);
                                }}
                            />
                        </div>
                    </div>;
                case 'value':
                    return <div className="option" key={field.label}>
                        <div className="label">
                            {t(field.label)}
                        </div>
                        <div className="setter">
                            {
                                field.is_numeric ?
                                    <NumberInput
                                        digit={5}
                                        disabled={props.disabled}
                                        value={eventOptions[field.label] || ''}
                                        onValueChange={(e) => {
                                            const newEventOption = Object.assign({}, eventOptions);
                                            if (e.value?.length === 0) {
                                                delete newEventOption[field.label];
                                            } else {
                                                newEventOption[field.label] = e.value;
                                            }
                                            setEventOptions(newEventOption);
                                        }}
                                    />
                                    :
                                    <InputText
                                        disabled={props.disabled}
                                        value={eventOptions[field.label] || ''}
                                        type={field.is_numeric ? 'number' : 'text'}
                                        onChange={(e) => {
                                            const newEventOption = Object.assign({}, eventOptions);
                                            if (e.target.value.length === 0) {
                                                delete newEventOption[field.label];
                                            } else {
                                                newEventOption[field.label] = e.target.value;
                                            }
                                            setEventOptions(newEventOption);
                                        }}
                                    />
                            }
                        </div>
                    </div>;
                default:
                    return null;
            }
        }) : <div className="no_options">{t('app:no_options')}</div>}
    </div>;
};

export default FormEventOptions;
