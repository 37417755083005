import './styles.scss';

import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from './Form';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const FormSelector = ({
    forms,
    formAnswers,
    setFormAnswers,
    eventDate,
    disabled,
    customer,
    site,
    employee,
    material,
    supplier,
    siteDayShown,
    saveFormAnswer,
    individualSave,
}) => {
    const { t } = useTranslation();
    const [currentForm, setCurrentForm] = useState(forms?.length === 1 ?
        forms[0] : null);
    const { company } = useSelector(state => state.app);
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);
    const employeeState = useSelector(state => state.employees);
    let currentEmploye;
    if (employee) {
        currentEmploye = employeeState.team.find(empl =>
            empl.id === employee
        );
        if (!currentEmploye) {
            currentEmploye = employeeState.all.find(empl =>
                empl.id === employee
            );
        }
    }
    
    // If there is a filled form, show it!
    const filledFormAnswer = formAnswers.find(f => (f.status === 'valided' || f.status === 'sync'));
    if (!currentForm) {
        if (filledFormAnswer) {
            const form = forms.find(f => f.id === filledFormAnswer.form_id);
            setCurrentForm(form);
        }
    }


    const currentUser = userState.list.find(user => user.id === authState.user_id);

    const handleUpdateForm = (answer, status, save, showToast) => {
        let formUpdate = false;
        const _answers = formAnswers.map(a => {
            if (a.form_id === currentForm.id) {
                formUpdate = true;
                return { ...a, answer: JSON.stringify(answer), status: status };
            }
            return a;
        });
        if (!formUpdate) {
            _answers.push({
                answer: JSON.stringify(answer),
                status: status,
                form_id: currentForm.id,
                disabled: false,
                company_id: company.id,
            });
        }
        setFormAnswers(_answers);
        if (save) saveFormAnswer(_answers, showToast);
    };

    if (currentForm) {
        const formAnswer = formAnswers.find(f => f.form_id === currentForm.id);
        const answer = JSON.parse(formAnswer?.answer || '{}');
        const status = formAnswer?.status || 'todo';
        return <>
            {(!filledFormAnswer && forms?.length > 1) ? <Button onClick={() => setCurrentForm(null)} className="p-shadow-2 button-form-back p-button-link">
                <FontAwesomeIcon icon={faChevronLeft} className="back" />
                Liste de formulaire
            </Button> : null}
            <Form
                form={currentForm}
                formAnswer={answer}
                formStatus={status}
                update={handleUpdateForm}
                eventDate={eventDate}
                disabled={disabled}

                site={site}
                employee={currentEmploye}
                material={material}
                supplier={supplier}
                customer={customer}

                entity={currentUser?.entity}
                company={company}
                user={currentUser}

                siteDayShown={siteDayShown}
                individualSave={individualSave}
            />
        </>;
    }
    return <div className="form-selector">
        {forms?.sort((a, b) => {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        }).map(form => {
            return <div key={form.id} className="button-form p-shadow-2" onClick={() => setCurrentForm(form)}>
                {form.label} {formAnswers[form.id]?.status ? ' - ' + t(`common:${formAnswers[form.id]?.status}`) : ''}
            </div>;
        })}
    </div>;
};

export default FormSelector;


