import axios from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';
export const securityReportCreateSuccess = () => toast.success(i18n.t('app:security_report_created'));
export const updateSecurityReportSuccess = () => toast.success(i18n.t('app:security_report_edited'));
export const deleteSecurityReportSuccess = () => toast.success(i18n.t('app:security_report_deleted'));
export const securityReportCreateError = () => toast.error(i18n.t('app:cannot_create_security_report'));
export const updateSecurityReportError = () => toast.error(i18n.t('app:cannot_update_security_report'));
export const deleteSecurityReportError = () => toast.error(i18n.t('app:cannot_delete_security_report'));

export const getSecurityReports = (data) => ({
    type: 'SECURITY_REPORT_RECEIVED',
    payload: data
});

export const securityReportUpdated = (data) => ({
    type: 'SECURITY_REPORT_UPDATED',
    payload: data
});

export const securityReportCreated = (data) => ({
    type: 'SECURITY_REPORT_CREATED',
    payload: data
});

export const picturesSentWithSuccess = (files) => ({
    type: 'SECURITY_REPORT_UPLOAD_PICTURES_SUCCESS',
    payload: files
});

export const createSecurityReport = (params, pictures) => dispatch => {
    return axios.post('/security-report/', params)
        .then(data => {
            const axiosCalls = [];
            pictures.forEach(file => {
                const formdata = new FormData();
                formdata.append('file', file.file);
                formdata.append('security_report_id', data.data.id);
                formdata.append('comment', file.desc);
                axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
            });
            dispatch(securityReportCreated(data.data));
            return Promise.all(axiosCalls)
                .then(files => {
                    if (files.length > 0) {
                        dispatch(picturesSentWithSuccess(files));
                    }
                });
        })
        .then(() => {
            securityReportCreateSuccess();
        })
        .catch(err => securityReportCreateError(err));
};

export const updateSecurityReport = (id, params, pictures) => dispatch => {
    return axios.patch(`/security-report/${id}`, params)
        .then(data => {
            const axiosCalls = [];
            pictures.forEach(file => {
                const formdata = new FormData();
                formdata.append('file', file.file);
                formdata.append('security_report_id', data.data.id);
                formdata.append('comment', file.desc);
                axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
            });
            dispatch(securityReportUpdated(data.data));
            return Promise.all(axiosCalls);
        })
        .then(() => {
            updateSecurityReportSuccess();
        })
        .catch(err => updateSecurityReportError(err));
};


export const securityReportDeleted= (id) => ({
    type: 'SECURITY_REPORT_DELETED',
    payload: id
});

export const deleteSecurityReport = (id) => (dispatch) => {
    return axios.delete(`/security-report/${id}`)
        .then(() => {
            deleteSecurityReportSuccess();
            dispatch(securityReportDeleted(id));
        })
        .catch(err => deleteSecurityReportError(err));
};
