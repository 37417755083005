import { toast } from 'react-toastify';

export const formReceived = forms => ({
    type: 'FORMS_RECEIVED',
    payload: forms
});


export const saveFormAnswer = (forms) => {
    toast.success(`Formulaire ${forms[0].label} validé`);
};
