import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { confirmDialog } from 'primereact/confirmdialog';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { deleteAskMaterial } from '../actions';

const AskMaterialRow = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const confirmDelete = () =>  {
        confirmDialog({
            message: t('app:really_remove_supply'),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                dispatch(deleteAskMaterial(props.supply));
            },
            reject: () => {}
        });
    };

    return <div className="supply_row p-shadow-1">
        <Button className="p-button-link" onClick={props.open}>
            <div className="inside_text">
                { props.supply.sent ? t('app:request_sent') : t('app:request_inprogress')}
                {moment(props.supply.date).format('DD/MM/Y')}<br/>
                <span className="update">
                    {t('app:last_update')}: {moment(props.supply.updated_at).format('DD/MM/YYYY HH:mm')}
                </span>
            </div>
        </Button>
        <Button className="p-button-link" onClick={() => confirmDelete()}>
            <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
    </div> ;
};

export default AskMaterialRow;
