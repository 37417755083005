// libs
import React, { useEffect } from 'react';
import { createSiteTicket, updateSiteTicket } from './actions';
import { faArrowLeft, faCancel, faClipboardCheck as faClipboardCheckSolid, faFloppyDisk, faMinus, faPalletAlt, faPaperPlane, faPencil, faPlus, faSave, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { faClipboard, faClipboardCheck } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import DayNavigation from '../events/components/DayNavigation';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
// actions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormSearchArticle from '../articles/FormSearchArticle';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import PictureRow from '../pictures/PictureRow';
import PictureRowPreview from '../pictures/PictureRowPreview';
import Uploader from '../uploader';
// components
import { addLocale } from 'primereact/api';
import { canCreateTicketWithoutCommand } from '../../utils/access';
import { confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment-business-days';
import { updateSiteTicket as syncSiteTicket } from '../app/updateApp';
import { toast } from 'react-toastify';
import useRouter from '../../utils/hooks/useRouter';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../utils/components/NumberInput';

addLocale('fr', {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
    monthNamesShort: ['jan', 'fev', 'mar', 'avr', 'mai', 'juin', 'juil', 'aout', 'sept', 'oct', 'nov', 'dec'],
    today: 'Aujourd\'hui',
    clear: 'Effacer'
});

const FormCreateSiteTicket = props => {
    const { t } = useTranslation();
    const { opened, onClose } = props;

    const dispatch = useDispatch();
    const siteState = useSelector(state => state.sites);
    const suppliersState = useSelector(state => state.suppliers);
    const typeDocsState = useSelector(state => state.typedoc);
    const ordersState = useSelector(state => state.orders);

    const [disabled, setDisabled] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [sent, setSent] = React.useState(false);
    const [date, setDate] = React.useState(moment());
    const [comment, setComment] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [amountFreeTaxe, setAmountFreeTaxe] = React.useState('');
    const [taxes, setTaxes] = React.useState('');
    const [supplierId, setSupplierId] = React.useState(null);
    const [site, setSite] = React.useState(null);
    const [typeDocId, setTypeDocId] = React.useState(null);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [referenceItem, setReferenceItem] = React.useState('');
    const [fieldErrors, setFieldErrors] = React.useState([]);
    const [order, setOrder] = React.useState(null);
    const [orderLines, setOrderLines] = React.useState([]);
    const [tab, setTab] = React.useState('form');
    const [noOrderMode, setNoOrderMode] = React.useState(false);
    const [selectedArticles, setSelectedArticles] = React.useState([]);
    const [articleTitle, setArticleTitle] = React.useState('');
    const [showFormArticles, setShowFormArticles] = React.useState(false);
    const [showEditArticleDialog, setShowEditArticleDialog] = React.useState(null);
    const router = useRouter();

    const all_articles = useSelector(state => state.articles.all);
    const selectedArticlesKeys = Object.keys(selectedArticles);
    const selectedArticlesValues = selectedArticlesKeys.map(k => selectedArticles[k].value);

    const groups = {};
    const allArticles = [];
    all_articles.forEach(a => {
        allArticles.push({
            id: a.id,
            value: `order_${a.id}`,
            slug: `order_${a.id}`,
            label: a.label,
            code: a.code,
            unit: a.unit,
            group: a.group,
            type: 'order'
        });
    });

    allArticles.forEach(a => {
        if (groups[a.group] === undefined) {
            groups[a.group] = [];
        }
        groups[a.group].push(a);
    });

    const groupLabels = Object.keys(groups);
    const articleOptions = groupLabels.sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }).map(groupLabel => {
        return {
            label: groupLabel,
            items: groups[groupLabel]
        };
    });

    const createOrderLines = (order) => order?.order_lines.map(order_line => {
        return {
            'id': order_line.id,
            'label': order_line.label,
            'quantity': order_line.quantity,
            'unit': order_line.unit,
            'stols': order_line.site_ticket_order_lines,
            'cancelRemainder': order_line.site_ticket_order_lines?.find(stol => {
                return stol.site_ticket_id === props.siteTicket.id;
            })?.cancelRemainder === '1',
            'received': order_line.site_ticket_order_lines?.find(stol => {
                return stol.site_ticket_id === props.siteTicket.id;
            })?.received || ''
        };
    }) || [];

    useEffect(function () {
        syncSiteTicket();
    }, []);


    useEffect(function () {
        const readOnly = props.readOnly || router.location.pathname === '/app/sites/data';
        setTab('form');
        if (props.siteTicket) {
            setNoOrderMode(props.siteTicket.no_order_mode);
            const articleSelection = {};
            props.siteTicket.articles.forEach(art => {
                articleSelection[`order_${art.id}`] = {
                    key: art.id,
                    code: art.code,
                    unit: art.unit,
                    count: art.pivot.count,
                    text: art.pivot.label || '',
                    id: art.id,
                    label: art.label,
                    type: 'order',
                    value: `order_${art.id}`
                };
            });
            setSelectedArticles(articleSelection);
            setShowFormArticles(false);
            setId(props.siteTicket.id);
            setDisabled(props.siteTicket.synchronized || readOnly);
            setDate(moment(props.siteTicket.date));
            setComment(props.siteTicket.comment || '');
            setAmount(props.siteTicket.amount || '');
            setAmountFreeTaxe(props.siteTicket.amount_free_taxe || '');
            setTaxes(props.siteTicket.amount_taxe || '');
            setSupplierId(props.siteTicket.supplier_id);
            setSite(props.siteTicket.site_id);
            setTypeDocId(props.siteTicket.type_doc_id);
            setOrder(props.siteTicket.order || null);
            setOrderLines(createOrderLines(props.siteTicket.order));
            // setUploadedFiles(props.siteTicket.pictures.map(p => p.id));
            setReferenceItem(props.siteTicket.reference_item || '');
            setSent(props.siteTicket.sent);
        } else {
            setNoOrderMode(false);
            setSelectedArticles([]);
            setShowFormArticles(false);
            setId(null);
            setDisabled(false);
            setDate(moment(props.date));
            setComment('');
            setAmount('');
            setAmountFreeTaxe('');
            setTaxes('');
            setSupplierId(null);
            setSite(siteState.selectedSite);
            setTypeDocId(null);
            setUploadedFiles([]);
            setOrder(null);
            setOrderLines([]);
            setReferenceItem('');
            setSent(false);
        }
    }, [props.siteTicket, opened]);

    const addFile = file => {
        const _files = uploadedFiles.slice(0);
        _files.push({ file: file, desc: '' });
        setUploadedFiles(_files);
    };

    const isSendButtonAvailable = () =>
        uploadedFiles.length > 0 ||
        referenceItem.length > 0 ||
        !isNaN(order?.id) ||
        noOrderMode ||
        parseInt(amount) > 0;

    const validForm = (sendNow) => {
        let fieldErrors = [];
        if (!supplierId) fieldErrors.push('supplierId');
        if (!typeDocId) fieldErrors.push('typeDocId');

        if (sendNow) {
            if (!isSendButtonAvailable()) fieldErrors.push('cannot_sent');
        }

        const params = {
            date: moment(date).format('YYYY-MM-DD'),
            comment: comment,
            reference_item: referenceItem,
            amount: amount,
            amount_free_taxe: amountFreeTaxe,
            amount_taxe: taxes,
            taxes: taxes,
            site_id: site,
            type_doc_id: typeDocId,
            order_id: order?.id || null,
            supplier_id: supplierId,
            order_lines: noOrderMode ? [] : orderLines,
            sent: sent || sendNow,
            no_order_mode: noOrderMode,
            articles: noOrderMode ? selectedArticlesKeys
                .map(k => {
                    return {
                        'id': selectedArticles[k].id,
                        'count': selectedArticles[k].count,
                        'label': selectedArticles[k].text,
                    };
                }) : []

        };

        setFieldErrors(fieldErrors);
        if (fieldErrors.length > 0) {
            if (fieldErrors.includes('typeDocId')) {
                toast.error(t('app:site_ticket_need_type'));
            } else if (fieldErrors.includes('cannot_sent')) {
                toast.error(t('app:cannot_sent_deposit_ticket'), {
                    autoClose: 10000
                });
            } else {
                toast.error(t('app:cannot_create_site_ticket'));
            }
        } else {
            localStorage.setItem('site_ticket_label', typeDocsState.all.find(t => t.id === typeDocId).label);
            if (id) {
                params.id = id;
                dispatch(updateSiteTicket(params, uploadedFiles));
                if (props.callback) {
                    props.callback();
                }
            } else {
                dispatch(createSiteTicket(params, uploadedFiles));
                if (props.callback) {
                    props.callback();
                }
            }
            onClose();
        }
    };

    useEffect(function () {
        if (supplierId !== order?.supplier_id) {
            setOrder(null);
            setOrderLines([]);
        }
    }, [supplierId]);

    const removeTempPicture = (idx) => {
        const newUploadedFile = uploadedFiles.splice(0);
        newUploadedFile.splice(idx, 1);
        setUploadedFiles(newUploadedFile);
    };

    let sentButtonColor = 'grey';

    if (isSendButtonAvailable()) {
        sentButtonColor = 'white';
    }

    if (props?.siteTicket?.sent || props?.siteTicket?.synchronized) {
        sentButtonColor = 'green';
    }


    const orderOptions = ordersState.all
        .filter(o => o.supplier_id === supplierId)
        .filter(o => o.site_id === site)
        .sort((a, b) => {
            if (a.date > b.date) return -1;
            if (a.date < b.date) return 1;
            return 0;
        }).map(o => {
            return {
                label: `${o.reference} - ${moment(o.date).format('DD/MM/YY')} ${o.info.length > 0 ? ' - ' : ''} ${o.info.length > 50 ? o.info.substring(0, 50) + '...' : o.info}`,
                value: o.id
            };
        });

    if (canCreateTicketWithoutCommand()) {
        orderOptions.push({
            label: t('app:no_order'),
            value: -1
        });
    }

    const addArticle = article => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        if (_selectedArticles[article.value]) {
            _selectedArticles[article.value].count = parseFloat(_selectedArticles[article.value].count) + 1;
        } else {
            _selectedArticles[article.value] = {
                id: article.id,
                value: article.slug,
                code: article.code,
                unit: article.unit,
                type: article.type,
                count: 1,
                text: '',
                label: article.label
            };
        }
        setSelectedArticles(_selectedArticles);
    };

    const changeSelectedArticles = articleValues => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        const selectedArticlesKeys = Object.keys(selectedArticles);

        // remove missing articles
        selectedArticlesKeys.forEach(k => {
            if (!articleValues.includes(selectedArticles[k].value)) {
                delete (_selectedArticles[k]);
            }
        });

        // add new articles
        articleValues.forEach(value => {
            if (!selectedArticlesValues.includes(value)) {
                const article = allArticles.find(a => a.value === value);
                _selectedArticles[article.value] = {
                    count: 1,
                    text: '',
                    id: article.id,
                    code: article.code,
                    unit: article.unit,
                    type: article.type,
                    value: article.value,
                    label: article.label
                };
            }
        });
        setSelectedArticles(_selectedArticles);
    };

    const substractArticle = article => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        if (_selectedArticles[article.value]) {
            if (_selectedArticles[article.value].count === 1) {
                delete _selectedArticles[article.value];
            } else {
                _selectedArticles[article.value].count = Math.max(0, _selectedArticles[article.value].count - 1);
            }
            setSelectedArticles(_selectedArticles);
        }
    };

    const removeArticle = article => {
        confirmDialog({
            header: t('common:update'),
            message: t('app:remove_article'),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                const _selectedArticles = Object.assign({}, selectedArticles);
                delete _selectedArticles[article.value];
                setSelectedArticles(_selectedArticles);
            },
        });
    };

    const changeArticleText = (article) => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        _selectedArticles[article.value] = {
            id: article.id,
            value: article.value,
            code: article.code,
            unit: article.unit,
            type: article.type,
            count: article.count,
            text: articleTitle,
            label: article.label
        };
        setSelectedArticles(_selectedArticles);
        return _selectedArticles;
    };

    const changeArticleCount = (article, count) => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        count = count.replace(',', '.');
        _selectedArticles[article.value] = {
            id: article.id,
            value: article.value,
            code: article.code,
            unit: article.unit,
            type: article.type,
            count: count,
            text: article.text,
            label: article.label
        };
        setSelectedArticles(_selectedArticles);
        return _selectedArticles;
    };


    return (
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={onClose} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div className="title">
                        {id ?
                            t('app:see_site_ticket') :
                            t('app:add_site_ticket')
                        }
                    </div>
                    {
                        id &&
                        <Button
                            onClick={() => validForm(true)}
                            className={'p-button-link create-event-button'}>
                            <FontAwesomeIcon color={sentButtonColor} icon={faPaperPlane} />
                        </Button> //: null
                    }
                    {
                        <Button
                            onClick={() => validForm(false)}
                            className={'p-button-link create-event-button'}>
                            <FontAwesomeIcon icon={faSave} />
                        </Button> //: null
                    }
                </div>
            </div>
            {
                !id && <DayNavigation
                    disabled={disabled}
                    goBefore={() => setDate(moment(date).clone().subtract(1, 'days'))}
                    goNext={() => setDate(moment(date).clone().add(1, 'days'))}
                    value={date}
                    onChange={(d) => setDate(moment(d.value))}
                ></DayNavigation>
            }
            {
                (noOrderMode || (order && order.order_lines.length > 0)) &&
                <div className="panel">
                    <Button onClick={() => setTab('form')} className={`p-button-link ${tab === 'form' ? 'selected' : ''}`}>
                        <div className="icon">
                            <FontAwesomeIcon icon={faPalletAlt} />
                        </div>
                        <div className="text">
                            {t('common:ticket')}
                        </div>
                    </Button>
                    <Button onClick={() => setTab('lines')} className={`p-order-line p-button-link ${tab === 'lines' ? 'selected' : ''}`}>
                        <div className="icon">
                            <FontAwesomeIcon icon={faClipboardCheckSolid} />
                        </div>
                        <div className="text">
                            {t('common:details')}
                        </div>
                    </Button>
                </div>
            }
            {(tab === 'form') && <div className="formSiteTicketWrapper p-shadow-2">
                <div className="label">
                    {t('common:site')}
                </div>
                <div className="site-information p-shadow-2">
                    {siteState.list.find(s => s.id === site)?.label}
                </div>
                <div className={`label ${fieldErrors.includes('typeDocId') ? 'p-invalid' : ''}`}>

                    {t('common:type_doc')}
                </div>
                <Dropdown
                    disabled={disabled}
                    value={typeDocId}
                    className={fieldErrors.includes('typeDocId') ? 'p-invalid' : ''}
                    options={typeDocsState.all.map(s => {
                        return {
                            label: s.label,
                            value: s.id
                        };
                    })
                    }
                    onChange={e => setTypeDocId(e.target.value)}
                />
                {
                    fieldErrors.includes('typeDocId') ?
                        <small id="event-site-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                        : null
                }
                <div className={`label ${fieldErrors.includes('supplierId') ? 'p-invalid' : ''}`}>
                    {t('common:suppliers')}
                </div>
                <Dropdown
                    className={fieldErrors.includes('supplierId') ? 'p-invalid' : ''}
                    disabled={disabled}
                    value={supplierId}
                    filter
                    filterInputAutoFocus={false}
                    virtualScrollerOptions={{ itemSize: 43 }}
                    options={suppliersState.all.map(s => {
                        return {
                            label: s.name,
                            value: s.id
                        };
                    })
                    }
                    onChange={e => setSupplierId(e.target.value)}
                />
                {
                    fieldErrors.includes('supplierId') ?
                        <small id="event-site-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                        : null
                }
                {id && <>
                    <div className={`label ${fieldErrors.includes('orderId') ? 'p-invalid' : ''}`}>

                        {t('common:order')}
                    </div>
                    <Dropdown
                        disabled={disabled}
                        value={noOrderMode ? -1 : order?.id}
                        filter
                        options={orderOptions}
                        onChange={e => {
                            if (e.target.value === -1) {
                                setNoOrderMode(true);
                                setOrder(null);
                                setOrderLines([]);
                            } else {
                                setNoOrderMode(false);
                                const order = ordersState.all.find(o => o.id === e.target.value);
                                setOrder(order);
                                setOrderLines(createOrderLines(order));
                            }
                        }}
                    />
                    <div className="label">
                        {t('common:pictures')}
                    </div>
                    <div className="pictures-list">
                        <Uploader disabled={disabled} withFootbar sendPicture={addFile} />
                        {
                            props.siteTicket?.pictures.length > 0
                                ? props.siteTicket.pictures.map(p => <PictureRow key={p.id} picture={p} />
                                ) : null
                        }
                        {uploadedFiles.map((p, idx) => <PictureRowPreview onDelete={() => removeTempPicture(idx)} key={p.file} picture={p.file} desc={p.desc} onChangeDesc={value => {
                            const newUploadedFiles = uploadedFiles.slice(0);
                            newUploadedFiles[idx].desc = value;
                            setUploadedFiles(newUploadedFiles);
                        }} />)}
                    </div>
                    <div className="label">
                        {t('common:reference_item')}
                    </div>
                    <InputText
                        disabled={disabled}
                        value={referenceItem}
                        onChange={e => setReferenceItem(e.target.value)}
                    />
                    <div className="label">
                        {t('common:amount_free_taxe')}
                    </div>
                    <NumberInput
                        disabled={disabled}
                        value={amountFreeTaxe}
                        digit={2}
                        onValueChange={e => setAmountFreeTaxe(e.value)}
                        onBlur={e => {
                            if (amount && !taxes) setTaxes(parseFloat(parseFloat(amount) - parseFloat(e.target.value)));
                            if (taxes && !amount) setAmount(parseFloat(parseFloat(e.target.value) + parseFloat(taxes)));
                        }}
                    />
                    <div className="label">
                        {t('common:taxes')}
                    </div>
                    <NumberInput
                        disabled={disabled}
                        value={taxes}
                        digit={2}
                        onValueChange={e => setTaxes(e.value)}
                        onBlur={e => {
                            if (amount && !amountFreeTaxe) setAmountFreeTaxe(parseFloat(parseFloat(amount) - parseFloat(e.target.value)));
                            if (amountFreeTaxe && !amount) setAmount(parseFloat(parseFloat(e.target.value) + parseFloat(amountFreeTaxe)));
                        }}
                    />
                    <div className="label">
                        {t('common:amount')}
                    </div>
                    <NumberInput
                        disabled={disabled}
                        value={amount}
                        digit={2}
                        onValueChange={e => setAmount(e.value)}
                        onBlur={e => {
                            if (amountFreeTaxe && !taxes) setTaxes(parseFloat(parseFloat(e.target.value) - parseFloat(amountFreeTaxe)));
                            if (taxes && !amountFreeTaxe) setAmountFreeTaxe(parseFloat(parseFloat(e.target.value) - parseFloat(taxes)));
                        }}
                    />
                    <div className="label">
                        {t('common:comment')}
                    </div>
                    <InputTextarea
                        className="comment"
                        disabled={disabled}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        rows={4}
                    />
                </>}
                {
                    !id && <Button className="flashy add_the_site_ticket" onClick={() => validForm(false)}>
                        {t('app:add_the_site_ticket')}
                    </Button>
                }
            </div>}
            {(tab === 'lines') && <div className="formSiteTicketWrapper p-shadow-2">
                <div className={`${fieldErrors.includes('orderId') ? 'p-invalid' : ''}`}>
                    {
                        order ?
                            <>
                                <div className="label">
                                    {t('common:order')}
                                </div>
                                <div className="p-shadow-2 title title order-ref">
                                    {order?.reference} - {moment(order?.date)?.format('DD/MM/YYYY')}
                                </div>
                                <div className="label">
                                    {t('common:articles')}
                                </div>
                                <div className="order_lines p-shadow-2">
                                    <div className="meta-button">
                                        <div className="full_command">
                                            <Button className="p-shadow-2" onClick={() => {
                                                const newOrderLines = orderLines.slice(0);
                                                orderLines.forEach((line, index) => {
                                                    newOrderLines[index].received = line.quantity;
                                                    newOrderLines[index].cancelRemainder = false;
                                                });
                                                setOrderLines(newOrderLines);
                                            }}>
                                                <FontAwesomeIcon icon={faClipboardCheck} /> {t('app:full_order')}
                                            </Button>
                                        </div>
                                        <div className="full_command">
                                            <Button className="p-shadow-2" onClick={() => {
                                                const newOrderLines = orderLines.slice(0);
                                                orderLines.forEach((line, index) => {
                                                    newOrderLines[index].received = 0;
                                                    newOrderLines[index].cancelRemainder = false;
                                                });
                                                setOrderLines(newOrderLines);
                                            }}>
                                                <FontAwesomeIcon icon={faClipboard} /> <label htmlFor="cb3" className="p-checkbox-label">{t('app:reset_order')}</label>
                                            </Button>
                                        </div>
                                    </div>
                                    {
                                        orderLines.map((line, index) =>
                                            <div key={line.id} className="order_line">
                                                <div className="top_order_line">
                                                    <div className="order_line_label">
                                                        {line.label}
                                                    </div>
                                                    <div className="order_line_qte">
                                                        {line.quantity} {line.unit}
                                                    </div>
                                                </div>
                                                <div className="bottom_order_line">
                                                    <div className="order_line_checkboxes">
                                                        <div>
                                                            <Checkbox checked={line.received >= line.quantity} onChange={() => {
                                                                const newOrderLines = orderLines.slice(0);
                                                                if (newOrderLines[index].received >= line.quantity) {
                                                                    newOrderLines[index].received = 0;
                                                                    newOrderLines[index].cancelRemainder = false;
                                                                } else {
                                                                    newOrderLines[index].received = line.quantity;
                                                                    newOrderLines[index].cancelRemainder = false;
                                                                }
                                                                setOrderLines(newOrderLines);
                                                            }} />
                                                            <label htmlFor="cb3" className="p-checkbox-label">{t('app:all_received')}</label>
                                                        </div>
                                                        <div>
                                                            <Checkbox checked={!!line.cancelRemainder} onChange={() => {
                                                                const newOrderLines = orderLines.slice(0);
                                                                newOrderLines[index].cancelRemainder = !line.cancelRemainder;
                                                                setOrderLines(newOrderLines);
                                                            }} />
                                                            <label htmlFor="cb3" className="p-checkbox-label">{t('app:remainder')}</label>
                                                        </div>
                                                    </div>
                                                    <div className="order_line_counters">
                                                        <div className="counter">
                                                            <Button
                                                                className="counter-button p-button-link"
                                                                onClick={() => {
                                                                    const newOrderLines = orderLines.slice(0);
                                                                    newOrderLines[index].received = !isNaN(line.received) ? Math.max(0, parseFloat(line.received) - 1) : 0;
                                                                    setOrderLines(newOrderLines);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faMinus} />
                                                            </Button>
                                                            <div className="article-count">
                                                                <NumberInput
                                                                    digit={5}
                                                                    value={line.received === 0 ? null : line.received}
                                                                    onValueChange={e => {
                                                                        const newOrderLines = orderLines.slice(0);
                                                                        newOrderLines[index].received = e.value || 0;
                                                                        setOrderLines(newOrderLines);
                                                                    }}
                                                                />
                                                            </div>
                                                            <Button
                                                                className="counter-button p-button-link"
                                                                onClick={() => {
                                                                    const newOrderLines = orderLines.slice(0);
                                                                    newOrderLines[index].received = !isNaN(line.received) ? parseFloat(line.received) + 1 : 1;
                                                                    setOrderLines(newOrderLines);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faPlus} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                            :
                            <>
                                <div className="p-shadow-2 title order-ref">{t('app:no_order')}</div>
                                <div className="article-list">
                                    {disabled ? null :
                                        <Button className="button-add-article p-button-link" onClick={() => setShowFormArticles(true)}>
                                            {t('app:add_articles')}
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    }
                                    <FormSearchArticle
                                        // addArticle={addArticle}
                                        // substractArticle={substractArticle}
                                        updateSelectedArticles={setSelectedArticles}
                                        changeArticleCount={changeArticleCount}
                                        opened={showFormArticles}
                                        onClose={() => setShowFormArticles(false)}
                                        selectedArticles={selectedArticles}
                                        options={articleOptions}
                                        onChange={e => changeSelectedArticles(e.value)}
                                    />
                                    {selectedArticlesKeys.length > 0 ?
                                        <div className="selected-article-list">
                                            {selectedArticlesKeys.map(k => {
                                                const art = selectedArticles[k];
                                                return <div key={k} className="article-row">
                                                    <div className="title">
                                                        {`${art.code} > ${art.label.length > 50 ? art.label.substr(0, 50) + '...' : art.label} [${art.unit}]`}
                                                        {
                                                            art.text?.length > 0 ?
                                                                <>
                                                                    <br />
                                                                    <div style={{ fontStyle: 'italic' }}>
                                                                        {art.text}
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                    </div>
                                                    <div className="counter">
                                                        <Button
                                                            disabled={disabled}
                                                            className="counter-button p-button-link"
                                                            onClick={() => substractArticle(art)}
                                                        >
                                                            <FontAwesomeIcon icon={faMinus} />
                                                        </Button>
                                                        <div className="article-count">
                                                            <NumberInput
                                                                digit={5}
                                                                disabled={disabled}
                                                                value={art.count}
                                                                onValueChange={e => changeArticleCount(art, e.value)}
                                                            />
                                                        </div>
                                                        <Button
                                                            disabled={disabled}
                                                            className="counter-button p-button-link"
                                                            onClick={() => addArticle(art)}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} />
                                                        </Button>
                                                        <Button
                                                            disabled={disabled}
                                                            className="edit-button p-button-link"
                                                            onClick={() => {
                                                                // changeArticleText(art);
                                                                setShowEditArticleDialog(art);
                                                                setArticleTitle(art.text);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faPencil} />
                                                        </Button>
                                                        <Button
                                                            className="delete-button p-button-link"
                                                            disabled={disabled}
                                                            onClick={() => removeArticle(art)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </Button>
                                                    </div>
                                                </div>;
                                            }
                                            )}
                                        </div>
                                        : null
                                    }
                                </div>
                            </>
                    }
                </div>
            </div>}
            <Dialog className="create-site-ticket-text" header={'Remplacer le texte'} visible={!!showEditArticleDialog} style={{ width: '85vw' }} onHide={() => setShowEditArticleDialog(false)}>
                <InputText value={articleTitle} onChange={e => setArticleTitle(e.target.value)} placeholder={showEditArticleDialog?.label} />
                <div className="actions-btn">
                    <Button onClick={() => {
                        setShowEditArticleDialog(null);
                        setArticleTitle('');
                    }}>
                        <FontAwesomeIcon icon={faCancel}>
                        </FontAwesomeIcon>
                    </Button>
                    <Button onClick={() => {
                        setShowEditArticleDialog(null);
                        setArticleTitle('');
                        changeArticleText(showEditArticleDialog);
                    }}>
                        <FontAwesomeIcon icon={faFloppyDisk}>
                        </FontAwesomeIcon>
                    </Button>
                </div>
            </Dialog>
        </Dialog>
    );
};

export default FormCreateSiteTicket;
