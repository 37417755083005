import './styles.scss';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormCreateSiteeTicket from './FormCreateSiteTicket';
import { confirmDialog } from 'primereact/confirmdialog';
import { deleteSiteTicket } from './actions';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import noDeposit from '../../assets/no-deposit-ticket.png';
import { useTranslation } from 'react-i18next';

const SiteSiteTicket = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const siteTicketState = useSelector(state => state.siteTickets);
    const siteState = useSelector(s => s.sites);
    const typeDocState = useSelector(s => s.typedoc);
    const tickets = siteTicketState.all.filter(d => {
        if (!props.noDate) {
            if(!moment(d.date).isSame(moment(props.date), 'day')) return false;
        }
        if(d.site_id !== siteState.selectedSite) return false;
        return true;
    });

    const confirmDelete = siteTicket =>  {
        confirmDialog({
            // message: t('app:really_remove_site_ticket', {typedoc: localStorage.getItem('site_ticket_label') || t('common:site_ticket')}),
            message: t('app:really_remove_site_ticket', t('common:site_ticket')),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                dispatch(deleteSiteTicket(siteTicket.id));
            },
            reject: () => {}
        });
    };

    const [popupOpen, setPopupOpen] = useState(false);
    const [siteTicketShown, setSiteTicketShown] = useState(null);

    const isReadyToSend = (siteTicket) => {
        return siteTicket.pictures.length > 0 || 
            siteTicket.reference_item?.length > 0 ||
            (siteTicket.order_id !== null && !isNaN(siteTicket.order_id)) || 
            parseInt(siteTicket.amount) > 0;
    };

    return <>
        {
            tickets.length === 0 ?
                <div className="no-data">
                    <img src={noDeposit} />
                    <div className="label">{t('app:no_site_ticket')}</div>
                </div>:
                <div className="site-tickets">
                    {
                        tickets.map(siteTicket => {
                            const docLabel = typeDocState.all.find(t => t.id === siteTicket.type_doc_id)?.label || t('common:site_ticket');
                            let status = 'not_ready_to_send';
                            let status_color = 'red';

                            if (isReadyToSend(siteTicket)) {
                                status = 'ready_to_send';
                                status_color = 'orange';
                            }

                            if (siteTicket.synchronized || siteTicket.sent) {
                                status = 'sent';
                                status_color = 'darkgreen';
                            }

                            return <div key={siteTicket.id} className="site_ticket_row p-shadow-1">
                                <Button className="p-button-link" onClick={() => {
                                    setSiteTicketShown(siteTicket);
                                    setPopupOpen(true);
                                }}>
                                    <div className="inside_text">
                                        {docLabel}
                                        <div className="update">
                                            {moment(siteTicket.date).format('DD/MM/Y')} - {siteTicket.supplier.label.substr(0, 25)}{siteTicket.supplier.label.length > 25 ? '...' : null}
                                        </div>
                                        <div className="update">
                                            {t('app:status')}: <span style={{color:status_color}}>{t(`app:${status}`)}</span>
                                        </div>
                                    </div>
                                </Button>
                                {
                                    !props.readOnly && <Button className="p-button-link" onClick={() => confirmDelete(siteTicket)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                }
                            </div>;
                        })
                    }
                </div>
        }
        <FormCreateSiteeTicket
            date={props.date}
            siteTicket={siteTicketShown}
            opened={popupOpen}
            onClose={() => {
                setPopupOpen(false);
                setSiteTicketShown(null);
            }}
        />
    </>;
};

export default SiteSiteTicket;


