import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FileBrowser from '../../files/components/FileBrowser';
import FootBar from './FootBar';
import DropDownEmployee from './DropDownEmployee';
import { updateEmployee } from '../../app/updateApp';

const EmployeeDocument = () => {
    const { t } = useTranslation();
    useEffect(function() {
        updateEmployee();
    } , []);

    const employeeState = useSelector(state => state.employees);

    let currentEmploye = employeeState.team.find(empl =>
        empl.id === employeeState.selectedEmployee
    );
    if (!currentEmploye) {
        currentEmploye = employeeState.all.find(empl =>
            empl.id === employeeState.selectedEmployee
        );
    }

    return <div className="module-page">
        <div className="label">
            {t('app:selected_employee')}
        </div>
        <DropDownEmployee/>
        <FileBrowser
            resourceType='Empl'
            selectedResource={currentEmploye}
            title={t('app:employee_document')}
        />
        <FootBar/>
    </div>;
};

export default EmployeeDocument;