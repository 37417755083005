import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faFileAlt, faCommentAltLines, faUserHardHat, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { confirmDialog } from 'primereact/confirmdialog';
import { useTranslation} from 'react-i18next';
import moment from 'moment';

import { deleteMaterialEvent } from '../actions';
import FormMaterialEvent from './FormMaterialEvent';
import siteImg from '../../../assets/site.svg';
import noEvent from '../../../assets/no-event.png';

const MaterialEventContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const employeeState = useSelector(state => state.employees);
    const userState = useSelector(state => state.users);
    const authState = useSelector(state => state.auth);
    const sitesState = useSelector(state => state.sites);
    const materialState = useSelector(state => state.materials);

    const [selectedMaterialEvent, setSelectedMaterialEvent] = React.useState(null);
    const [showFormMaterialEvent, setShowFormMaterialEvent] = React.useState(false);
    const currentUser = userState.list.find(user => user.id === authState.user_id);

    const employeeList = currentUser.is_multi_employee === 1 ? employeeState.all : employeeState.team;

    const selectedResource = (materialState.list.find(m =>
        parseInt(m.id) === parseInt(materialState.selectedMaterial)
    ));

    const askDeletePhoto = matEvent => {
        confirmDialog({
            header: t('common:update'),
            message: t('app:remove_mat_event'),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                dispatch(deleteMaterialEvent(matEvent.id));
            },
        });
    };

    return selectedResource.material_events.length === 0 ?
        <div className="no-data">
            <img src={noEvent} />
            <div className="label">{t('app:no_material_event')}</div>
        </div> :
        <div className="material-event-container">
            {selectedResource.material_events.sort((a, b) => {
                if (a.date > b.date ) return -1;
                if (a.date < b.date ) return 1;
                return 0;
            }).map((materialEvent) =>
                <Button onClick={() => {
                    setSelectedMaterialEvent(materialEvent);
                    setShowFormMaterialEvent(true);
                }} key={materialEvent.id} className="event p-shadow-1">
                    {!materialEvent.synchronized &&
                        <Button onClick={e => { askDeletePhoto(materialEvent); e.stopPropagation(); }} className="p-button-link delete p-shadow-3">
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    }
                    <div className="site">
                        <img src={siteImg} />{`${sitesState.list.find(s => s.id === materialEvent.site_id)?.label || ''}`}
                    </div>
                    <div className="desc">
                        <div>
                            <div className="date">
                                <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '8px' }} />{moment(materialEvent.date).format('DD/MM/YYYY')}
                            </div>
                            <div className="user">
                                <FontAwesomeIcon icon={faUserHardHat} style={{ marginRight: '8px' }} />{`${employeeList?.find(e => e.id === materialEvent.employee_id)?.firstname || ''} ${employeeList?.find(e => e.id === materialEvent.employee_id)?.lastname?.toUpperCase() || ''}`}
                            </div>
                        </div>
                        <div>
                            <div className="odometer">
                                {materialEvent.odometer} {selectedResource.odometer_unit}
                            </div>
                            <div className="comment">
                                {(materialEvent.comment?.length > 0) && <FontAwesomeIcon icon={faCommentAltLines} />}
                                {(materialEvent.pictures?.length > 0) && <FontAwesomeIcon style={{ marginLeft: '8px' }} icon={faFileAlt} />}
                            </div>
                        </div>
                    </div>
                </Button>
            )}
            <FormMaterialEvent materialEvent={selectedMaterialEvent} opened={showFormMaterialEvent} onClose={() => setShowFormMaterialEvent(false)} />
        </div>;
};

export default MaterialEventContainer;
