import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FileBrowser from '../../files/components/FileBrowser';
import { sendSitePicture } from '../actions';
import { updateSite } from '../../app/updateApp';
import { useTranslation } from 'react-i18next';

const SiteDocument = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(function() {
        updateSite();
    }, []);

    const siteState = useSelector(state => state.sites);
    const selectedResource = (siteState.list.find(s =>
        parseInt(s.id) === parseInt(siteState.selectedSite)
    ));

    return <>
        <FileBrowser
            resourceType='Cht'
            selectedResource={selectedResource}
            title={t('app:site_document')}
            sendPicture={(file, folder) => dispatch(sendSitePicture(siteState.selectedSite, file, folder))}
        />
    </>;
};

export default SiteDocument;