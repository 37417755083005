import './styles.scss';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormCreateDepositeTicket from './FormCreateDepositTicket';
import { confirmDialog } from 'primereact/confirmdialog';
import { deleteDepositTicket } from './actions';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import noDeposit from '../../assets/no-deposit-ticket.png';
import { useTranslation } from 'react-i18next';

const SitePlanning = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const depositTicketState = useSelector(state => state.depositTickets);
    const siteState = useSelector(s => s.sites);
    const tickets = depositTicketState.all.filter(d => {
        if (!props.noDate) {
            if (!moment(d.date).isSame(moment(props.date), 'day')) return false;
        }
        if (d.site_id !== siteState.selectedSite) return false;
        return true;
    });

    const confirmDelete = depositTicket => {
        confirmDialog({
            message: t('app:really_remove_deposit_ticket', { code: depositTicket.code }),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                dispatch(deleteDepositTicket(depositTicket.id));
            },
            reject: () => { }
        });
    };

    const [popupOpen, setPopupOpen] = useState(false);
    const [depositTicketShown, setDepositTicketShown] = useState(null);

    return <>
        {
            tickets.length === 0 ?
                <div className="no-data">
                    <img src={noDeposit} />
                    <div className="label">{t('app:no_deposit_ticket')}</div>
                </div> :
                <div className="deposit-tickets">
                    {
                        tickets.map(depositTicket =>
                            <div key={depositTicket.id} className="deposit_ticket_row p-shadow-1">
                                <Button className="p-button-link" onClick={() => {
                                    setDepositTicketShown(depositTicket);
                                    setPopupOpen(true);
                                }}>
                                    <div className="inside_text">
                                        {t('app:deposit_ticket_code', { code: depositTicket.code })}
                                        <div className="update">
                                            {moment(depositTicket.date).format('DD/MM/Y')} - {depositTicket.supplier.label.substr(0, 25)}{depositTicket.supplier.label.length > 25 ? '...' : null}
                                        </div>
                                        <div className="update">
                                            {t('app:status')} : &nbsp;
                                            {
                                                ((depositTicket.amount) ||
                                                    (depositTicket.reference_item) ||
                                                    (depositTicket.pictures.length > 0)) ?
                                                    (depositTicket.sent) ?
                                                        <span style={{ color: 'darkgreen' }}>{t('app:sent')}</span> :
                                                        <span style={{ color: 'orange' }}>{t('app:ready_to_send')}</span>
                                                    : <span style={{ color: 'red' }}>{t('app:not_ready_to_send')}</span>
                                            }
                                        </div>
                                    </div>
                                </Button>
                                {
                                    !props.readOnly && <Button className="p-button-link" onClick={() => confirmDelete(depositTicket)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                }
                            </div>
                        )
                    }
                </div>
        }
        <FormCreateDepositeTicket
            date={props.date}
            depositTicket={depositTicketShown}
            opened={popupOpen}
            onClose={() => {
                setPopupOpen(false);
                setDepositTicketShown(null);
            }}
        />
    </>;
};

export default SitePlanning;

