import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faTachometerAltSlow } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../utils/hooks/useRouter';

import { useSelector } from 'react-redux';
import FormMaterialEvent from './FormMaterialEvent';
import { hasAccessToDocMaterial, hasAccessToOdometer } from '../../../utils/access';

const Footbar = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const materialState = useSelector(state => state.materials);
    const [showFormMaterialEvent, setShowFormMaterialEvent] = useState(false);
    return <div className="footbar">
        {hasAccessToDocMaterial() &&
            <Button
                className={`p-button-link ${router.history.location.pathname === '/app/materials/documents' ? 'selected' : null}`}
                onClick={() => {
                    if (materialState.selectedMaterial) {
                        router.push('/app/materials/documents');
                    } else {
                        toast.error(t('app:please_select_a_material'));
                    }
                }}>
                <div className="icon">
                    <FontAwesomeIcon icon={faFileAlt} /><br />
                </div>
                <div className="text">
                    {t('common:document')}
                </div>
            </Button>
        }
        {hasAccessToOdometer() &&
            <Button
                className={`p-button-link ${router.history.location.pathname.includes('/app/materials/events') ? 'selected' : null}`}
                onClick={() => {
                    if (materialState.selectedMaterial) {
                        router.push('/app/materials/events');
                    } else {
                        toast.error(t('app:please_select_a_material'));
                    }
                }}>
                <div className="icon">
                    <FontAwesomeIcon icon={faTachometerAltSlow} /><br />
                </div>
                <div className="text">
                    {t('common:event')}
                </div>
            </Button>
        }
        <Button disabled={!materialState.selectedMaterial || router.location.pathname !== '/app/materials/events'} className={`flashy plus-button-footbar ${showFormMaterialEvent ? 'overlay-shown' : ''}`} onClick={() => setShowFormMaterialEvent(!showFormMaterialEvent)}>
            <FontAwesomeIcon icon={faPlus} /><br />
        </Button>
        {/* <SiteOverlayCreate show={showOverlayCreate} onClose={() =>  setShowOverlayCreate(false)}/> */}
        <FormMaterialEvent opened={showFormMaterialEvent} onClose={() => setShowFormMaterialEvent(false)} />
    </div>;
};

export default Footbar;


