import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AskMaterialRow from './AskMaterialRow';

import FormCreateOrViewAskMaterial from './FormCreateOrViewAskMaterial';
import { updateSite } from '../../app/updateApp';

import noSupply from '../../../assets/no-supply.png';

const AskMaterial = () => {
    const { t } = useTranslation();

    useEffect(function () {
        updateSite();
    }, []);

    const [popupOpen, setPopupOpen] = React.useState(false);
    const [askMaterialShown, setAskMaterialShown] = React.useState(null);
    const siteState = useSelector(state => state.sites);

    const selectedSite = siteState.list.find(s =>
        s.id === siteState.selectedSite
    );

    const showNoData = selectedSite.ask_materials.length === 0;
    const askMaterials = selectedSite.ask_materials;

    return (
        <div className="module-subpage module-supply module-ask-material">
            { showNoData &&
                <div className="no-supply">
                    <img src={noSupply} />
                    <div className="label">{t('app:no_ask_material')}</div>
                </div>
            }
            <div className="supplies">
                {
                    askMaterials.map(s =>
                        <AskMaterialRow
                            key={s.id}
                            open={() => {
                                setAskMaterialShown(s);
                                setPopupOpen(true);
                            }}
                            supply={s}
                        />
                    )
                }
            </div>
            <FormCreateOrViewAskMaterial
                onClose={() => setPopupOpen(false)}
                opened={popupOpen}
                askMaterial={askMaterialShown}
            />
        </div>
    );
};

export default AskMaterial;
