import './style.scss';

// libs
import React, { useEffect } from 'react';
import { createSiteTrashDeposit, updateSiteTrashDeposit } from './actions';
import { faArrowLeft, faMinus, faPlus, faSave, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Accordion } from 'primereact/accordion';
import { AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
// import Uploader from '../uploader';
// import PictureRow from '../pictures/PictureRow';
// import PictureRowPreview from '../pictures/PictureRowPreview';
import Canvas from '../signature/SignatureCanva';
import DayNavigation from '../events/components/DayNavigation';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
// actions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { addLocale } from 'primereact/api';
import { confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment-business-days';
import { updateSiteTrashDeposit as syncTrashDeposit } from '../app/updateApp';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { useVirtual } from 'react-virtual';
import NumberInput from '../../utils/components/NumberInput';

addLocale('fr', {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
    monthNamesShort: ['jan', 'fev', 'mar', 'avr', 'mai', 'juin', 'juil', 'aout', 'sept', 'oct', 'nov', 'dec'],
    today: 'Aujourd\'hui',
    clear: 'Effacer'
});

const FormCreateTrashDeposit = props => {
    const { t } = useTranslation();
    const { opened, onClose } = props;

    const dispatch = useDispatch();
    const siteState = useSelector(state => state.sites);
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);
    const suppliersState = useSelector(state => state.suppliers);
    const customerState = useSelector(state => state.customers);
    const all_articles = useSelector(state => state.articles.all);

    const currentUser = userState.list.find(user => user.id === authState.user_id);

    const [filter, setFilter] = React.useState('');
    const [disabled, setDisabled] = React.useState(false);
    const [openedSearch, setOpenedSearch] = React.useState(false);
    const [readOnly, setReadOnly] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [date, setDate] = React.useState(moment());
    const [site, setSite] = React.useState(null);
    const [supplierId, setSupplierId] = React.useState(null);
    const [comment, setComment] = React.useState(null);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [fieldErrors, setFieldErrors] = React.useState([]);
    const [openedTabs, setOpenedTabs] = React.useState([]);
    const [signature, setSignature] = React.useState(null);
    const [signatory, setSignatory] = React.useState(null);
    const [signatureDate, setSignatureDate] = React.useState(null);
    const [signatureUserId, setSignatureUserId] = React.useState(null);
    const [signatureSupplier, setSignatureSupplier] = React.useState(null);
    const [signatorySupplier, setSignatorySupplier] = React.useState(null);
    const [signatureSupplierDate, setSignatureSupplierDate] = React.useState(null);

    const sigCanvas = useRef(null);
    // const sigSupplierCanvas = useRef(null);

    const [lines, setLines] = React.useState([]);

    const updateMethod = (index, method) => {
        const newLines = lines.slice(0);
        newLines[index]['pivot']['method'] = method;
        setLines(newLines);
    };

    const confirmDeleteArticle = (line, index) => {
        confirmDialog({
            message: t(`Voulez-vous vraiment enlever "${line.label}" ?`),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                const newLines = lines.slice(0);
                delete newLines[index];
                setLines(newLines);
            },
            reject: () => { }
        });
    };

    const groups = {};
    const allArticles = [];
    all_articles.filter(a => a.is_trash == 1).forEach(a => {
        allArticles.push({
            id: a.id,
            label: a.label,
            code: a.code,
            group: a.group,
            unit: a.unit
        });
    });

    allArticles.forEach(a => {
        if (groups[a.group] === undefined) {
            groups[a.group] = [];
        }
        groups[a.group].push(a);
    });

    const groupLabels = Object.keys(groups);
    const options = groupLabels.sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }).map(groupLabel => {
        return {
            label: groupLabel,
            items: groups[groupLabel]
        };
    });


    const counterLine = (index, line, attr, cannotEdit) => {
        return <div className="counter">
            <div>
                <strong>{t(`app:${attr}`)}</strong>
            </div>
            <div className="amount">
                <Button
                    className="counter-button p-button-link"
                    disabled={readOnly || cannotEdit}
                    onClick={() => {
                        const newLines = lines.slice(0);
                        newLines[index]['pivot'][attr] = Math.max(0, parseFloat(line['pivot'][attr]) - 1);
                        if (attr === 'qt_received') {
                            newLines[index]['pivot']['qt_accepted'] = newLines[index]['pivot']['qt_received'];
                        }
                        newLines[index]['pivot']['qt_unclassed'] = parseFloat(newLines[index]['pivot']['qt_received']) - parseFloat(newLines[index]['pivot']['qt_accepted']);
                        setLines(newLines);
                    }}
                >
                    <FontAwesomeIcon icon={faMinus} />
                </Button>
                <div className="article-count">
                    <NumberInput
                        digit={5}
                        disabled={readOnly || cannotEdit}
                        value={line['pivot'][attr] || 0}
                        onValueChange={e => {
                            const newLines = lines.slice(0);
                            newLines[index]['pivot'][attr] = e.value;
                            if (attr === 'qt_received') {
                                newLines[index]['pivot']['qt_accepted'] = newLines[index]['pivot']['qt_received'];
                            }
                            newLines[index]['pivot']['qt_unclassed'] = parseFloat(newLines[index]['pivot']['qt_received']) - parseFloat(newLines[index]['pivot']['qt_accepted']);
                            setLines(newLines);
                        }}
                    />
                </div>
                <Button
                    className="counter-button p-button-link"
                    disabled={readOnly || cannotEdit}
                    onClick={() => {
                        const newLines = lines.slice(0);
                        newLines[index]['pivot'][attr] = parseFloat(line['pivot'][attr]) + 1;
                        if (attr === 'qt_received') {
                            newLines[index]['pivot']['qt_accepted'] = newLines[index]['pivot']['qt_received'];
                        }
                        newLines[index]['pivot']['qt_unclassed'] = parseFloat(newLines[index]['pivot']['qt_received']) - parseFloat(newLines[index]['pivot']['qt_accepted']);
                        setLines(newLines);
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>
        </div>;
    };

    const current_site = siteState.list.find(s => s.id === site);
    const current_customer = customerState.list.find(c => c.id === current_site?.customer_id);
    const current_supplier = suppliersState.all.find(s => s.id === supplierId);

    useEffect(function () {
        syncTrashDeposit();
    }, []);

    useEffect(function () {
        let readOnly = false;
        if (props.trashDeposit) {
            readOnly = props.trashDeposit.synchronized;
            setId(props.trashDeposit.id);
            setDisabled(props.trashDeposit.synchronized || readOnly);
            setDate(moment(props.trashDeposit.date));
            setComment(props.trashDeposit.comment || '');
            setSupplierId(props.trashDeposit.supplier_id);
            setSite(props.trashDeposit.site_id);
            setLines(props.trashDeposit.lines);
            setUploadedFiles([]);
            setSignature(props.trashDeposit.signature);
            setSignatureUserId(props.trashDeposit.signature_user_id);
            setSignatureSupplier(props.trashDeposit.signature_supplier);
            setSignatorySupplier(props.trashDeposit.signatory_supplier);
            setSignatureSupplierDate(props.trashDeposit.signature_supplier_date);
        } else {
            setId(null);
            setDisabled(false);
            setDate(moment(props.date));
            setComment('');
            setSupplierId(null);
            setSite(siteState.selectedSite);
            setLines([]);
            setUploadedFiles([]);
            setSignature(null);
            setSignatureUserId(null);
            setSignatureSupplier(null);
            setSignatorySupplier(null);
            setSignatureSupplierDate(null);
        }
        setReadOnly(readOnly);
    }, [props.trashDeposit, opened]);

    useEffect(function () {
        if (props.trashDeposit?.signature && sigCanvas.current) {
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            const canvas = sigCanvas.current.getCanvas();
            canvas.width = Math.ceil(canvas.offsetWidth * ratio);
            canvas.height = Math.ceil(canvas.offsetHeight * ratio - 2);
            const context = canvas.getContext('2d');
            context.scale(ratio, ratio);
            sigCanvas.current.fromDataURL(props.trashDeposit.signature);
            canvas.width = Math.ceil(canvas.offsetWidth / ratio);
            canvas.height = Math.ceil(canvas.offsetHeight / ratio);
            context.fillStyle = 'white';
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = 'black';
        }
    }, [props.trashDeposit?.signature]);

    const validForm = () => {
        let fieldErrors = [];
        if (!supplierId) fieldErrors.push('supplierId');
        if (lines.length === 0) fieldErrors.push('lines');

        const params = {
            date: moment(date).format('YYYY-MM-DD'),
            comment: comment,
            site_id: site,
            lines: lines,
            signature: signature,
            supplier_id: supplierId,
            signature_user_id: signatureUserId,
            signature_supplier: signatureSupplier,
            signatory_supplier: signatorySupplier,
            signature_date: signatureDate ? moment(signatureDate).format('YYYY-MM-DD') : null,
            signature_supplier_date: signatureSupplierDate ? moment(signatureSupplierDate).format('YYYY-MM-DD') : null,
        };

        setFieldErrors(fieldErrors);
        if (fieldErrors.length > 0) {
            if (fieldErrors.includes('supplierId')) {
                toast.error('Merci de saisir une installation de collecte');
            } else if (fieldErrors.includes('lines')) {
                toast.error('Merci de saisir au moins un dechet.', {
                    autoClose: 10000
                });
            }
        } else {
            if (id) {
                params.id = id;
                dispatch(updateSiteTrashDeposit(params, uploadedFiles));
                if (props.callback) {
                    props.callback();
                }
            } else {
                dispatch(createSiteTrashDeposit(params, uploadedFiles));
                if (props.callback) {
                    props.callback();
                }
            }
            onClose();
        }
    };


    return (<>
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="create-dialog trash-create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={onClose} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div className="title">
                        {id ?
                            'Bordereau' :
                            'Création bordereau'
                        }
                    </div>
                    {
                        !readOnly && <Button
                            onClick={() => validForm(false)}
                            className={'p-button-link create-event-button'}>
                            <FontAwesomeIcon icon={faSave} />
                        </Button> //: null
                    }
                </div>
            </div>
            {
                <div>
                    <DayNavigation
                        disabled={disabled}
                        goBefore={() => setDate(moment(date).clone().subtract(1, 'days'))}
                        goNext={() => setDate(moment(date).clone().add(1, 'days'))}
                        value={date}
                        onChange={(d) => setDate(moment(d.value))}
                    ></DayNavigation>
                    <div className="label">
                        {t('common:site')}:
                    </div>
                    <div className="site-information p-shadow-2">
                        <strong>Nom: </strong>{current_site?.label}<br />
                        <strong>Adresse: </strong><br />{current_site?.address}<br />
                        {current_site?.city}<br />
                    </div>
                    <div className="label">
                        {t('common:customer')}:
                    </div>
                    <div className="site-information p-shadow-2">
                        <strong>Nom: </strong>{current_customer?.fullname}<br />
                        <strong>Siren/Siret :</strong> {current_customer?.siren_siret}<br />
                        <strong>Adresse: </strong><br />{current_customer?.address}<br />
                        {current_customer?.address_2}<br />
                        {current_customer?.zipcode}<br />
                    </div>
                    <div className="label">
                        Entreprise ayant réalisé les travaux:
                    </div>
                    <div className="site-information p-shadow-2">
                        <strong>Nom: </strong>{current_site?.entity?.rs}<br />
                        <strong>Siren/Siret :</strong> {current_site?.entity?.siren_siret}<br />
                        <strong>Adresse: </strong><br />
                        {current_site?.entity?.address}<br />
                        {current_site?.enitty?.zipcode}<br />
                    </div>
                    <div className={`label ${fieldErrors.includes('supplierId') ? 'p-invalid' : ''}`}>
                        Installation de collecte:
                    </div>
                    <Dropdown
                        style={{ width: 'calc(100% - 1rem)', margin: '0.5rem' }}
                        className={fieldErrors.includes('supplierId') ? 'p-invalid' : ''}
                        disabled={disabled}
                        value={supplierId}
                        filter
                        options={suppliersState.all.filter(f => f.is_trash == 1).map(s => {
                            return {
                                label: s.name,
                                value: s.id
                            };
                        })
                        }
                        onChange={e => setSupplierId(e.target.value)}
                    />
                    {current_supplier &&
                        <div className="site-information p-shadow-2">
                            <strong>Siren/Siret :</strong> {current_supplier?.siren_siret}<br />
                            <strong>Adresse: </strong><br />
                            {current_supplier?.address1}<br />
                            {current_supplier?.address2}<br />
                            {current_supplier?.zipcode_city}<br />
                        </div>
                    }
                    <hr />
                    <p className="label">
                        Déchets:
                    </p>
                    {
                        lines?.map((line, index) => {
                            return <div key={index} className="p-shadow-1 line">
                                { !readOnly && <Button className="p-button-link delete p-shadow-1" onClick={() => confirmDeleteArticle(line, index)}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button> }
                                <div>
                                    <strong>Nom: </strong>{line.label}<br />
                                    <strong>Code: </strong>{line.code}

                                </div>
                                <br />
                                <div>
                                    <strong>{'Méthode d\'estimation: '}</strong>
                                    <br />
                                    <div className="chooseMethod">
                                        <RadioButton disabled={readOnly} value="EV" name="method" onChange={(e) => updateMethod(index, e.value)} checked={lines[index].pivot.method === 'EV'} /> Evaluation visuelle
                                        <RadioButton disabled={readOnly} value="P" name="method" onChange={(e) => updateMethod(index, e.value)} checked={lines[index].pivot.method === 'P'} /> Pesée
                                    </div>
                                </div>
                                <div className="counter-lines">
                                    <div>
                                        {counterLine(index, line, 'qt_received')}
                                    </div>
                                    <div>
                                        {counterLine(index, line, 'qt_accepted')}
                                    </div>
                                    <div>
                                        {counterLine(index, line, 'qt_unclassed', true)}
                                    </div>
                                    <div style={{ fontSize: '12px', fontStyle: 'italic', marginTop: '8px', textAlign: 'center' }}>{`* Les quantités sont en ${line.unit}.`}</div>
                                </div>
                            </div>;
                        })
                    }
                    <div className="ta-center" style={{ marginBottom: '16px' }}>
                        {!readOnly && <Button className="ta-center p-button p-button-primary" onClick={() => { setOpenedSearch(true); }}>
                            Sélectionner un déchet
                        </Button> }
                    </div>
                    <hr />
                    <p className="label">
                        {'Signataire de l\'entreprise ayant réalisé les travaux'}<br />
                        {
                            signatureUserId ?
                                <strong>{userState.list.find(user => user.id === signatureUserId)?.lastname} {userState.list.find(user => user.id === authState.user_id)?.firstname}</strong> :
                                <strong>{currentUser?.lastname} {currentUser?.firstname}</strong>
                        }
                    </p>
                    <div className="sigCanvas">
                        <Canvas
                            disabled={disabled}
                            signature={signature}
                            signatory={signatory}
                            showSignatory={false}
                            signatureDate={null}
                            onSignatoryChange={setSignatory}
                            onSignatureChange={val => {
                                setSignatureDate(moment());
                                setSignature(val);
                                setSignatureUserId(authState.user_id);
                            }}
                        />
                    </div>
                    <hr />
                    <p className="label">
                        {'Signature de l\'installation de collecte'}
                    </p>
                    <div className="sigCanvas">
                        <Canvas
                            disabled={disabled}
                            signature={signatureSupplier}
                            signatory={signatorySupplier}
                            showSignatory={true}
                            signatureDate={null}
                            onSignatureChange={val => {
                                setSignatureSupplierDate(moment());
                                setSignatureSupplier(val);
                            }}
                            onSignatoryChange={setSignatorySupplier}
                        />
                    </div>
                    <hr />
                    <div className={`label ${fieldErrors.includes('comment') ? 'p-invalid' : ''}`}>
                        {t('common:comment')}
                    </div>
                    <InputTextarea
                        style={{ height: '200px', width: 'calc(100% - 1rem)', margin: '0.5rem' }}
                        disabled={disabled}
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                    />
                </div>
            }
        </Dialog>
        <Dialog
            maximized={true}
            modal={true}
            visible={openedSearch}
            onHide={() => setOpenedSearch(false)}
            className="create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={() => setOpenedSearch(false)} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                </div>
            </div>
            {
                <>
                    <span className="article-filter p-input-icon-right">
                        <i className="pi pi-search" />
                        <InputText className="p-shadow-2" style={{ width: '100%' }} value={filter} onChange={e => setFilter(e.target.value)} />
                    </span>
                    <Accordion className="article-groups" multiple activeIndex={openedTabs} onTabChange={(e) => setOpenedTabs(e.index)}>
                        {
                            options
                                .sort((a, b) => {
                                    if (a.items[0].type > b.items[0].type) return 1;
                                    if (a.items[0].type < b.items[0].type) return -1;
                                    return 0;
                                }).map(group => {
                                    // for (const option of options) {
                                    const groupItems = filter.length > 1 ? group.items.filter(i => {
                                        if (i.label.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (i.code.toLowerCase().includes(filter.toLowerCase())) return true;
                                        return false;
                                    }) : group.items;

                                    const parentRef = React.useRef();
                                    const rowVirtualizer = useVirtual({
                                        size: groupItems.length,
                                        parentRef,
                                        estimateSize: React.useCallback(() => 80, []),
                                        overscan: 5
                                    });

                                    if (groupItems.length === 0) return null;

                                    return <AccordionTab key={group.label} openead header={group.label}>
                                        <div
                                            key={group.label}
                                            style={{ height: `${Math.min(400, 80 * groupItems.length)}px`, width: '100%', overflow: 'auto' }}
                                            ref={parentRef}
                                        >
                                            <div
                                                style={{
                                                    height: `${rowVirtualizer.totalSize}px`,
                                                    width: '100%',
                                                    position: 'relative'
                                                }}
                                            >
                                                {rowVirtualizer.virtualItems.map(virtualRow => {
                                                    const option = groupItems[virtualRow.index];
                                                    return <div
                                                        onClick={() => {
                                                            setOpenedSearch(false);
                                                            lines.push({
                                                                code: option.code,
                                                                group: option.group,
                                                                is_trash: 1,
                                                                label: option.label,
                                                                id: option.id,
                                                                pivot: {
                                                                    article_id: option.id,
                                                                    weight: 0,
                                                                    qt_received: 0,
                                                                    qt_accepted: 0,
                                                                    qt_unclassed: 0,
                                                                    method: 'EV'
                                                                },
                                                                unit: option.unit,
                                                            });
                                                        }}
                                                        key={virtualRow.index}
                                                        className={virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: `${virtualRow.size}px`,
                                                            transform: `translateY(${virtualRow.start}px)`
                                                        }}
                                                    >
                                                        <div className="article-row" key={option.id}>
                                                            <div className="title">
                                                                {`${option.code} > ${option.label > 100 ? option.label.subst(0,100) : option.label}`}
                                                            </div>
                                                        </div>
                                                    </div>;
                                                })}
                                            </div>
                                        </div></AccordionTab>;
                                })
                        }
                    </Accordion>
                </>
            }
        </Dialog>
    </>
    );
};

export default FormCreateTrashDeposit;

