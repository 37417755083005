import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'primereact/dropdown';
import { useSelector, useDispatch } from 'react-redux';

import { setSelectedSite } from '../actions';

const DropDownSite = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const siteState = useSelector(state => state.sites);

    const dropdownSiteOption = (option) => {
        return (
            <div className="dropdown-row">
                <div className="label">{option.label}</div>
                <div className="code">{option.code}</div>
            </div>
        );
    };

    const lastSelectedSite = localStorage.getItem('last-selected-site')?.split(',')?.map(x => parseInt(x)) || [];

    return <div className="userSelection">
        <Dropdown
            virtualScrollerOptions={{ itemSize: 62 }}
            scrollHeight="250px"
            className="p-shadow-2"
            value={siteState.selectedSite}
            options={siteState.list
                .filter(site => site.type === 'CHT')
                .sort((a,b) => {
                    const aPresent = lastSelectedSite.indexOf(a.id) > -1;
                    const bPresent = lastSelectedSite.indexOf(b.id) > -1;
                    if (aPresent && !bPresent) return -1;
                    if (!aPresent && bPresent) return 1;
                    if (a.label > b.label) return 1;
                    if (a.label < b.label) return -1;
                    return 0;
                }).map(site=> { return {
                    value: site.id,
                    code: site.code,
                    label: site.label
                };})}
            onChange={(e) => {
                const site_id = e.target.value;
                dispatch(setSelectedSite(site_id));
                const idx = lastSelectedSite.indexOf(site_id);
                if (idx > -1) {
                    lastSelectedSite.splice(idx, 1);
                }
                lastSelectedSite.unshift(site_id);
                if (lastSelectedSite.length > 9) {
                    lastSelectedSite.length = 10;
                }
                localStorage.setItem('last-selected-site' , lastSelectedSite.join(','));
            }}
            autoFocus
            filter
            itemTemplate={dropdownSiteOption}
            filterBy="label,code"
            placeholder={t('app:no_selected_site')}
        />
    </div>;
};

export default DropDownSite;
