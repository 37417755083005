import moment from 'moment';
import i18n from 'i18next';

// eslint-disable-next-line no-undef
require('moment/locale/fr.js');
moment.locale(i18n.language);

const initialState = () => ({
    selectedEmployee: null,
    all: [],
    team: [],
    dayShown: moment()
});

const employeesReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    let selectedEmployee;
    let lsEmployeeId;
    switch (action.type) {
        case 'EMPLOYEES_RECEIVED':
            newState.all = action.payload.employees.all.sort((a, b) => {
                const a_name = `${a.lastname.toLowerCase()} ${a.firstname.toLowerCase()}`;
                const b_name = `${b.lastname.toLowerCase()} ${b.firstname.toLowerCase()}`;
                if (a_name > b_name) return 1;
                if (a_name < b_name) return -1;
                return 0;
            });
            newState.team = action.payload.employees.team.sort((a, b) => {
                const a_name = `${a.lastname.toLowerCase()} ${a.firstname.toLowerCase()}`;
                const b_name = `${b.lastname.toLowerCase()} ${b.firstname.toLowerCase()}`;
                if (a_name > b_name) return 1;
                if (a_name < b_name) return -1;
                return 0;
            });
            lsEmployeeId = localStorage.getItem('selected_employee', null);
            if ( lsEmployeeId ) {
                newState.selectedEmployee = parseInt(lsEmployeeId);
            } else {
                // by default select default employee
                selectedEmployee = action.payload.employees.team.find(emp => emp.pivot.is_default);
                newState.selectedEmployee = selectedEmployee ? selectedEmployee.id : null;
            }
            return newState;
        case 'SELECT_EMPLOYEE':
            newState.selectedEmployee = action.payload;
            localStorage.setItem('selected_employee', newState.selectedEmployee);
            return newState;
        case 'CALENDAR_NEXT_DAY':
            newState.dayShown = newState.dayShown.clone().add(1, 'days');
            return newState;
        case 'CALENDAR_PREVIOUS_DAY':
            newState.dayShown = newState.dayShown.clone().subtract(1, 'days');
            return newState;
        case 'SET_DAY':
            newState.dayShown = action.payload;
            return newState;
        case 'LOGOUT':
            localStorage.removeItem('selected_employee');
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default employeesReducer;
