import moment from 'moment';

const initialState = () => ({
    list: [],
    selectedSite: null,
    siteDayShown: moment()
});

const sitesReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    let lsSiteId;
    switch (action.type) {
        case 'SET_SITE_DAY_SHOWN':
            newState.siteDayShown= action.payload;
            return newState;
        case 'SET_SELECTED_SITE':
            newState.selectedSite = action.payload.site;
            localStorage.setItem('selected_site', newState.selectedSite);
            return newState;
        case 'SITES_RECEIVED':
            newState.list = action.payload.sites;
            lsSiteId = localStorage.getItem('selected_site', null);
            if ( lsSiteId ) {
                newState.selectedSite = parseInt(lsSiteId);
            }
            return newState;
        case 'PICTURE_UPDATED':
            if (!action.payload.site_id) return state;
            newState.list.forEach((m, idx) => {
                if (m.id === action.payload.site_id) {
                    newState.list[idx].pictures.forEach((p, m_idx) => {
                        if (newState.list[idx].pictures[m_idx].id === action.payload.id) {
                            newState.list[idx].pictures[m_idx].comment = action.payload.comment;
                        }
                    });
                }
            });
            return newState;
        case 'PICTURE_REMOVE_SUCCESS':
            if (!action.payload.site_id) return state;
            newState.list.forEach((s, idx) => {
                if(s.id === action.payload.site_id) {
                    newState.list[idx].pictures.forEach((p, p_idx) => {
                        if(newState.list[idx].pictures[p_idx].id === action.payload.id) {
                            newState.list[idx].pictures.splice(p_idx, 1);
                        }
                    });
                }
            });
            return newState;
        case 'SUPPLY_CREATED':
            newState.list.forEach((s, idx) => {
                if(s.id === action.payload.supply.site_id) {
                    newState.list[idx].ask_material.push(action.payload.supply);
                }
            });
            return newState;
        case 'ASK_MATERIAL_CREATED':
            newState.list.forEach((am, idx) => {
                if(am.id === action.payload.ask_material.site_id) {
                    newState.list[idx].ask_materials.push(action.payload.ask_material);
                }
            });
            return newState;
        case 'SUPPLY_DELETED':
            newState.list.forEach((site, idx_site) => {
                if (site.id === action.payload.site_id) {
                    site.supplies.forEach((supply, idx_supply) => {
                        if (supply.id === action.payload.id) {
                            newState.list[idx_site].supplies.splice(idx_supply, 1);
                        }
                    });
                }
            });
            return newState;
        case 'ASK_MATERIAL_DELETED':
            newState.list.forEach((site, idx_site) => {
                if (site.id === action.payload.site_id) {
                    site.ask_materials.forEach((am, idx_am) => {
                        if (am.id === action.payload.id) {
                            newState.list[idx_site].ask_materials.splice(idx_am, 1);
                        }
                    });
                }
            });
            return newState;
        case 'SUPPLY_EDITED':
            newState.list.forEach((site, idx_site) => {
                if (site.id === action.payload.site_id) {
                    site.supplies.forEach((supply, idx_supply) => {
                        if (supply.id === action.payload.id) {
                            newState.list[idx_site].supplies[idx_supply] = action.payload;
                        }
                    });
                }
            });
            return newState;
        case 'ASK_MATERIAL_EDITED':
            newState.list.forEach((site, idx_site) => {
                if (site.id === action.payload.site_id) {
                    site.ask_materials.forEach((am, idx_am) => {
                        if (am.id === action.payload.id) {
                            newState.list[idx_site].ask_materials[idx_am] = action.payload;
                        }
                    });
                }
            });
            return newState;
        case 'SITE_PICTURE_SENT':
            newState.list.forEach((site, idx_site) => {
                if (parseInt(site.id) === parseInt(action.payload.site_id)) {
                    newState.list[idx_site].pictures.push(action.payload);
                }
            });
            return newState;
        case 'CREATE_SITE_CONSUMPTION_SUCCESS':
            newState.list.forEach((site, idx_site) => {
                if (parseInt(site.id) === parseInt(action.payload.site_id)) {
                    newState.list[idx_site].consumptions.push(action.payload);
                }
            });
            return newState;
        case 'UPDATE_SITE_CONSUMPTION_SUCCESS':
            newState.list.forEach((site, idx_site) => {
                if (parseInt(site.id) === parseInt(action.payload.site_id)) {
                    site.consumptions.forEach((consumption, consumption_idx) => {
                        if (parseInt(consumption.id) === parseInt(action.payload.id)) {
                            newState.list[idx_site].consumptions[consumption_idx] = action.payload;
                        }
                    });
                }
            });
            return newState;
        case 'CREATE_SITE_VALIDATION_DAY_SUCCESS':
            newState.list.forEach((site, idx_site) => {
                if (parseInt(site.id) === parseInt(action.payload.site_id)) {
                    newState.list[idx_site].validation_days.push(action.payload);
                }
            });
            return newState;
        case 'SITE_UPDATED':
            newState.list.forEach((site, idx_site) => {
                if (parseInt(site.id) === action.payload.site.id) {
                    newState.list[idx_site] = action.payload.site;
                }
            });
            return newState;
        case 'UPDATE_SITE_VALIDATION_DAY_SUCCESS':
            newState.list.forEach((site, idx_site) => {
                if (parseInt(site.id) === parseInt(action.payload.site_id)) {
                    site.validation_days.forEach((validation, validation_idx) => {
                        if (parseInt(validation.id) === parseInt(action.payload.id)) {
                            newState.list[idx_site].validation_days[validation_idx] = action.payload;
                        }
                    });
                }
            });
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default sitesReducer;
