import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormEventTimeslot = props => {
    const { t } = useTranslation();

    const hourOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(h => {
        return  {
            label: h + ' ' + t('common:hour') + (h > 1 ? 's' : ''),
            value: '' + h
        };
    });

    const minuteOptions = [0, 15, 30, 45].map(h => {
        return  {
            label: h + ' ' + t('common:minute') + (h > 1 ? 's' : ''),
            value: '' + h
        };
    });

    const {
        startingDateHour,
        setStartingDateHour,
        startingDateMinute,
        setStartingDateMinute,
        endingDateHour,
        setEndingDateHour,
        endingDateMinute,
        setEndingDateMinute,
        defaultStartingDateHour,
        previousEndingDateHour,
        slot,
        company
    } = props;

    return <>
        <p className="timeslotname">
            {company[`timeslot_name_${slot}`] ?
                company[`timeslot_name_${slot}`] :
                `${t('app:slot')} ${slot}`
            }
        </p>
        <p className="select_title">
            {t('app:start_date')}
        </p>
        <div className="event-duration">
            <Dropdown
                disabled={props.disabled}
                value={''+startingDateHour}
                options={hourOptions}
                onChange={e => setStartingDateHour(e.target.value)}
                onShow={() => {
                    if(startingDateHour === 0 && previousEndingDateHour) {
                        setStartingDateHour(previousEndingDateHour);
                    } else if (startingDateHour === 0 && defaultStartingDateHour) {
                        setStartingDateHour(defaultStartingDateHour);
                    }
                }}
            />
            <div className="separator"> : </div>
            <Dropdown
                disabled={props.disabled}
                value={'' + startingDateMinute}
                options={minuteOptions}
                onChange={e => setStartingDateMinute(e.target.value)}
                onShow={() => {
                    if(startingDateHour === 0 && previousEndingDateHour) {
                        setStartingDateHour(previousEndingDateHour);
                    } else if (startingDateHour === 0 && defaultStartingDateHour) {
                        setStartingDateHour(defaultStartingDateHour);
                    }
                }}
            />
        </div>
        <p className="select_title">
            {t('app:end_date')}
        </p>
        <div className="event-duration">
            <Dropdown
                disabled={props.disabled}
                value={'' + endingDateHour}
                options={hourOptions}
                onChange={e => setEndingDateHour(e.target.value)}
                onShow={() => endingDateHour === 0 && setEndingDateHour(startingDateHour)}
            />
            <div className="separator"> : </div>
            <Dropdown
                disabled={props.disabled}
                value={'' + endingDateMinute}
                options={minuteOptions}
                onChange={e => setEndingDateMinute(e.target.value)}
            />
        </div>
    </>;
};

export default FormEventTimeslot;
