// actions
import { createSupplyOrder, updateSupplyOrder } from '../actions';
import { faArrowLeft, faConstruction, faCopy, faMinus, faPaperPlane, faSave, faWarehouseAlt } from '@fortawesome/pro-solid-svg-icons';
import { faChevronLeft, faChevronRight, faPlus } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
// components
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormSearchArticle from '../../articles/FormSearchArticle';
import { InputTextarea } from 'primereact/inputtextarea';
// libs
import React from 'react';
import { addLocale } from 'primereact/api';
import moment from 'moment-business-days';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../../utils/components/NumberInput';

addLocale('fr', {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
    monthNamesShort: ['jan', 'fev', 'mar', 'avr', 'mai', 'juin', 'juil', 'aout', 'sept', 'oct', 'nov', 'dec'],
    today: 'Aujourd\'hui',
    clear: 'Effacer'
});

const FormCreateSupply = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const siteState = useSelector(state => state.sites);
    const { opened, supplyOrder, onClose } = props;
    const site = siteState.list.find(s => s.id === siteState.selectedSite);
    const [showFormArticles, setShowFormArticles] = React.useState(false);

    const [disabled, setDisabled] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [sent, setSent] = React.useState(null);
    const [supplyOrderDate, setSupplyOrderDate] = React.useState(moment().businessAdd(1, 'day'));
    const [note, setNote] = React.useState('');
    const [selectedArticles, setSelectedArticles] = React.useState([]);
    const [supplyLocation, setSupplyLocation] = React.useState('site');

    const {offlineMode : isOfflineMode} = useSelector(state => state.core);

    const all_articles = useSelector(state => state.articles.all);
    const selectedArticlesKeys = Object.keys(selectedArticles);
    const selectedArticlesValues = selectedArticlesKeys.map(k => selectedArticles[k].value);

    const groups = {};
    const allArticles = [];
    all_articles.forEach(a => {
        allArticles.push({
            id: a.id,
            value: `order_${a.id}`,
            slug: `order_${a.id}`,
            label: a.label,
            group: a.group,
            code: a.code,
            unit: a.unit,
            type: 'order'
        });
    });
    if (site) {
        site.articles.forEach(a => {
            allArticles.push({
                id: a.id,
                value: `supply_${a.id}`,
                slug: `supply_${a.id}`,
                label: a.label,
                code: a.code,
                group: a.group,
                unit: a.unit,
                type: 'supply'
            });
        });
    }

    allArticles.forEach(a => {
        if (groups[a.group] === undefined) {
            groups[a.group] = [];
        }
        groups[a.group].push(a);
    });

    const groupLabels = Object.keys(groups);
    const articleOptions = groupLabels.sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }).map(groupLabel => {
        return {
            label: groupLabel,
            items: groups[groupLabel]
        };
    });

    const onDupplicate = () => {
        setId(null);
        setSent(false);
        setSupplyOrderDate(moment().businessAdd(1, 'day'));
        setDisabled(false);
    };

    const validForm = (sent) => {
        const selectedArticlesKeys = Object.keys(selectedArticles);
        const params = {
            note: note,
            articles: selectedArticlesKeys
                .filter(k => selectedArticles[k].type === 'order')
                .map(k => {
                    return {
                        'id': selectedArticles[k].id,
                        'count': selectedArticles[k].count,
                    };
                }),
            site_articles: selectedArticlesKeys
                .filter(k => selectedArticles[k].type === 'supply')
                .map(k => {
                    return {
                        'id': selectedArticles[k].id,
                        'count': selectedArticles[k].count,
                    };
                }),
            date: supplyOrderDate.format('YYYY-MM-DD'),
            site_id: site.id,
            location: supplyLocation,
            sent: sent ? 1 : 0
        };
        if (params.articles.length === 0 && params.site_articles.length === 0) {
            toast.error(t('app:cannot_create_supply_without_article'));
            return;
        }
        if (id) {
            params.id = id;
            dispatch(updateSupplyOrder(params));
            if (props.callback) {
                props.callback();
            }
        } else {
            dispatch(createSupplyOrder(params));
            if (props.callback) {
                props.callback();
            }
        }
        onClose();
    };

    const updateSupplyOrderDate = (date) => {
        setSupplyOrderDate(date);
    };

    React.useEffect(() => {
        if (opened && !sent && !supplyOrder) {
            setDisabled(false);
            setSupplyOrderDate(moment().businessAdd(1, 'day'));
        }
    }, [opened, sent, supplyOrder]);

    React.useEffect(function () {
        if (opened) {
            if (supplyOrder) {
                setId(supplyOrder.id);
                setSent(supplyOrder.sent);
                setNote(supplyOrder.note);
                setSupplyLocation(supplyOrder.location);
                setSupplyOrderDate(moment(supplyOrder.date));
                const _articles = {};
                supplyOrder.articles.forEach(a => {
                    _articles[`order_${a.id}`] = {
                        id: a.id,
                        type: 'order',
                        code: a.code,
                        label: a.label,
                        value: `order_${a.id}`,
                        unit: a.unit,
                        count: a.pivot.count
                    };
                });
                supplyOrder.site_articles.forEach(a => {
                    _articles[`supply_${a.id}`] = {
                        id: a.id,
                        type: 'supply',
                        code: a.code,
                        label: a.label,
                        value: `supply_${a.id}`,
                        unit: a.unit,
                        count: a.pivot.count
                    };
                });
                setSelectedArticles(_articles);
                setDisabled(!!supplyOrder.sent);
            } else {
                setId(null);
                setSent(false);
                setNote('');
                setSupplyLocation('site');
                setSupplyOrderDate(moment().businessAdd(1, 'day'));
                setSelectedArticles([]);
                setDisabled(false);
            }
        }
    }, [supplyOrder, opened]);

    const addArticle = article => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        if (_selectedArticles[article.value]) {
            _selectedArticles[article.value].count = parseFloat(_selectedArticles[article.value].count) + 1;
        } else {
            _selectedArticles[article.value] = {
                id: article.id,
                code: article.code,
                value: article.slug,
                type: article.type,
                count: 1,
                unit: article.unit,
                label: article.label
            };
        }
        setSelectedArticles(_selectedArticles);
    };

    const changeSelectedArticles = articleValues => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        const selectedArticlesKeys = Object.keys(selectedArticles);

        // remove missing articles
        selectedArticlesKeys.forEach(k => {
            if (!articleValues.includes(selectedArticles[k].value)) {
                delete (_selectedArticles[k]);
            }
        });

        // add new articles
        articleValues.forEach(value => {
            if (!selectedArticlesValues.includes(value)) {
                const article = allArticles.find(a => a.value === value);
                _selectedArticles[article.value] = {
                    count: 1,
                    id: article.id,
                    code: article.code,
                    type: article.type,
                    value: article.value,
                    unit: article.unit,
                    label: article.label
                };
            }
        });

        setSelectedArticles(_selectedArticles);
    };

    const substractArticle = article => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        if (_selectedArticles[article.value]) {
            if (_selectedArticles[article.value].count === 1) {
                delete _selectedArticles[article.value];
            } else {
                _selectedArticles[article.value].count = Math.max(0, _selectedArticles[article.value].count - 1);
            }
            setSelectedArticles(_selectedArticles);
        }
    };

    const changeArticleCount = (article, count) => {
        const _selectedArticles = Object.assign({}, selectedArticles);
        _selectedArticles[article.value] = {
            id: article.id,
            value: article.value,
            type: article.type,
            code: article.code,
            count: count,
            unit: article.unit,
            label: article.label
        };
        setSelectedArticles(_selectedArticles);
        return _selectedArticles;
    };

    return (
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={onClose} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div className="title">
                        {id ?
                            t('app:see_a_supply_order') :
                            t('app:add_a_supply_order')
                        }
                    </div>
                    {
                        !isOfflineMode && !sent ?
                            <Button
                                onClick={() => validForm(true)}
                                className={'p-button-link create-event-button'}>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </Button> : null
                    }
                    {
                        !sent ?
                            <Button
                                onClick={() => validForm(false)}
                                className={'p-button-link create-event-button'}>
                                <FontAwesomeIcon icon={faSave} />
                            </Button> : null
                    }
                    {
                        supplyOrder ?
                            <Button
                                onClick={onDupplicate}
                                className={'p-button-link create-event-button'}>
                                <FontAwesomeIcon icon={faCopy} />
                            </Button> : null
                    }
                </div>
            </div>
            <div className="day-navigation supply">
                <Button disabled={disabled} size="small" className="p-button-link" onClick={() => updateSupplyOrderDate(moment(supplyOrderDate).clone().subtract(1, 'days'))}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <div className="currentDay">
                    <Calendar
                        locale="fr"
                        disabled={disabled}
                        dateFormat="DD dd/mm/yy"
                        value={supplyOrderDate.toDate()}
                        onChange={date => setSupplyOrderDate(moment(date.value))}
                    />
                </div>
                <Button disabled={disabled} size="small" className="p-button-link" onClick={() => updateSupplyOrderDate(moment(supplyOrderDate).clone().add(1, 'days'))}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
            <div className="label">
                {t('app:supply_location')} :
            </div>
            <div className="type-selector">
                <div
                    onClick={() => disabled ? null : setSupplyLocation('site')}
                    className={supplyLocation === 'site' ? 'selected' : ''}
                >
                    <FontAwesomeIcon icon={faConstruction} />
                    {t('common:site')}
                </div>
                <div
                    onClick={() => disabled ? null : setSupplyLocation('deposit')}
                    className={supplyLocation === 'deposit' ? 'selected' : ''}
                >
                    <FontAwesomeIcon icon={faWarehouseAlt} />
                    {t('common:deposit')}
                </div>
            </div>
            <div className="article-list p-shadow-2">
                <p className="select_title">
                    {t('common:articles')}
                </p>
                <div className="available-articles">
                    <Button className="button-add-article p-button-link" onClick={() => setShowFormArticles(true)}>
                        {t('app:add_articles')}
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <FormSearchArticle
                        updateSelectedArticles={setSelectedArticles}
                        opened={showFormArticles}
                        onClose={() => setShowFormArticles(false)}
                        selectedArticles={selectedArticles}
                        options={articleOptions}
                        onChange={e => changeSelectedArticles(e.value)}
                    />
                </div>
                {selectedArticlesKeys.length > 0 ?
                    <div className="selected-article-list">
                        {selectedArticlesKeys.map(k => {
                            const art = selectedArticles[k];
                            return <div key={art.id}><div className="article-row">
                                <div className="title">
                                    {`${art.code} > ${art.label.length > 50 ? art.label.substr(0,50) + '...' : art.label} [${art.unit}]`}
                                </div>
                                <div className="counter">
                                    {!disabled ?
                                        <Button
                                            className="counter-button p-button-link"
                                            onClick={() => substractArticle(art)}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                        : null
                                    }
                                    <div className="article-count">
                                        <NumberInput
                                            digit={5}
                                            disabled={disabled}
                                            value={art.count}
                                            onValueChange={e => changeArticleCount(art, e.value)}
                                        />
                                    </div>
                                    {!disabled ?
                                        <Button
                                            className="counter-button p-button-link"
                                            onClick={() => addArticle(art)}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                        : null
                                    }
                                </div></div>
                            </div>;
                        }
                        )}
                    </div>
                    : null
                }
            </div>
            <div className="label">
                {t('common:note')}
            </div>
            <InputTextarea
                className="note"
                disabled={disabled}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                rows={4}
            />
        </Dialog>
    );
};

export default FormCreateSupply;
