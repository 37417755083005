import React, { useEffect } from 'react';

import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { useSelector } from 'react-redux';

const DayPicker = props => {

    const appState = useSelector(state => state.app);

    const minCalendarDate = moment(appState.calendarDays[0]['date']);
    const maxCalendarDate = moment(appState.calendarDays[appState.calendarDays.length - 1]['date']);

    useEffect(function() {
        if (minCalendarDate.isAfter(props.value)) {
            props.onChange({value: minCalendarDate.toDate()});
        }
        if (maxCalendarDate.isBefore(props.value)) {
            props.onChange({value: maxCalendarDate.toDate()});
        }

    }, []);


    return <div className="day-picker">
        <div className="currentDay">
            <Calendar
                minDate={minCalendarDate.toDate()}
                maxDate={maxCalendarDate.toDate()}
                disabled={props.disabled}
                locale="fr"
                dateFormat="DD dd MM yy"
                value={props.value.toDate()}
                onChange={props.onChange}
            />
        </div>
    </div>;
};

export default DayPicker ;