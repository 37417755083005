import axios from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import store from '../../store';
import { addImageToStore, addToStoreAndCache, deleteStoreAndCache, updateInStoreAndCache } from '../offline/helper';

export const deleteSiteTicketSuccess = () => toast.success(i18n.t('app:site_ticket_deleted'));
export const deleteSiteTicketError = () => toast.error(i18n.t('app:cannot_delete_site_ticket'));
export const createSiteTicketSuccess = data => {
    toast.success(i18n.t('app:site_ticket_created', { code: data.data.code }));
};
export const createSiteTicketError = () => toast.error(i18n.t('app:cannot_create_site_ticket'));
export const updateSiteTicketSuccess = () => toast.success(i18n.t('app:site_ticket_updated'));
export const updateSiteTicketError = () => toast.error(i18n.t('app:cannot_update_site_ticket'));

export const siteTicketReceived = siteTickets => ({
    type: 'SITE_TICKET_RECEIVED',
    payload: siteTickets
});

export const siteTicketUpdate = siteTicket => ({
    type: 'SITE_TICKET_EDITED',
    payload: siteTicket
});

export const siteTicketDeleted = siteTicketId => ({
    type: 'SITE_TICKET_DELETED',
    payload: siteTicketId
});

export const siteTicketCreated = siteTicket => ({
    type: 'SITE_TICKET_CREATED',
    payload: siteTicket
});

export const picturesSentWithSuccess = (files) => ({
    type: 'SITE_TICKET_UPLOAD_PICTURES_SUCCESS',
    payload: files
});

export const createSiteTicket = (params, files) => dispatch => {
    const state = store.getState();
    const isOfflineMode = state.core.offlineMode;
    const sites = state.sites.list;
    const suppliers = state.suppliers.all;
    const type_docs = state.typedoc.all;
    if(isOfflineMode) {
        addToStoreAndCache('siteTickets', params, (id) => {
            params.site = sites.find(s => s.id === params.site_id);
            params.type_doc = type_docs.find(d => d.id === params.type_doc_id);
            params.supplier = suppliers.find(s => s.id === params.supplier_id);
            params.pictures = [];
            dispatch(siteTicketCreated(params));
            files.forEach(file => {
                const formdata = new FormData();
                formdata.append('file', file.file);
                formdata.append('site_ticket_id', id);
                formdata.append('comment', file.desc);
                addImageToStore({
                    site_ticket_id: id,
                    file: file
                }, () => {});
            });
        });
    } else {
        return axios.post('/site_ticket', params)
            .then(data => {
                dispatch(siteTicketCreated(data.data));
                const axiosCalls = [];
                files.forEach(file => {
                    const formdata = new FormData();
                    formdata.append('file', file.file);
                    formdata.append('site_ticket_id', data.data.id);
                    formdata.append('comment', file.desc);
                    axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
                });
                return Promise.all(axiosCalls)
                    .then(() => {
                        createSiteTicketSuccess(data);
                        if (files.length > 0) {
                            dispatch(picturesSentWithSuccess(data));
                        }
                    })
                ;
            })
            .catch(err => createSiteTicketError(err));
    }
};

export const updateSiteTicket = (params, files) => dispatch => {
    const state = store.getState();
    const isOfflineMode = state.core.offlineMode;
    const sites = state.sites.list;
    const suppliers = state.suppliers.all;
    const type_docs = state.typedoc.all;
    const siteTickets = state.siteTickets.all;
    if(isOfflineMode) {
        updateInStoreAndCache('siteTickets', params, () => {
            const current_site_ticket = siteTickets.find(t => t.id === params.id);
            params.site = sites.find(s => s.id === params.site_id);
            params.type_doc = type_docs.find(d => d.id === params.type_doc_id);
            params.supplier = suppliers.find(s => s.id === params.supplier_id);
            dispatch(siteTicketUpdate({...current_site_ticket, ...params}));
            updateSiteTicketSuccess();
            if (files.length > 0) {
                files.forEach(file => {
                    addImageToStore({
                        siteTicketId: params.id,
                        comment: file.desc,
                        file: file.file
                    }, () => {});
                });
            }
        });
    } else {
        return axios.patch(`/site_ticket/${params.id}`, params)
            .then(data => {
                dispatch(siteTicketUpdate(data.data));
                const axiosCalls = [];
                files.forEach(file => {
                    const formdata = new FormData();
                    formdata.append('file', file.file);
                    formdata.append('site_ticket_id', data.data.id);
                    formdata.append('comment', file.desc);
                    axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
                });
                return Promise.all(axiosCalls);
            })
            .then(data => {
                updateSiteTicketSuccess();
                if (files.length > 0) {
                    dispatch(picturesSentWithSuccess(data));
                }
            })
            .catch(err => updateSiteTicketError(err));
    }
};

export const deleteSiteTicket = site_ticket_id => dispatch => {
    const isOfflineMode = store.getState().core.offlineMode;
    if(isOfflineMode) {
        deleteStoreAndCache('siteTickets', site_ticket_id, () => {
            deleteSiteTicketSuccess();
            dispatch(siteTicketDeleted(site_ticket_id));
        });
    } else {
        return axios.delete(`/site_ticket/${site_ticket_id}`)
            .then(() => {
                deleteSiteTicketSuccess();
                dispatch(siteTicketDeleted(site_ticket_id));
            })
            .catch(err => deleteSiteTicketError(err));
    }
};

