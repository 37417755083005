const initialState = () => ({
    company: {},
    user: {},
    dayOffCodes: [],
    optionnalFields: [],
    calendarDays: [],
    applicationOnError: false
});

const appReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'GET_COMPANY_SUCCESS':
            newState.company = action.payload.company;
            return newState;
        case 'GET_USER_SUCCESS':
            newState.user = action.payload;
            return newState;
        case 'GET_CONFIG_DAY_OFF_CODE_SUCCESS':
            newState.dayOffCodes = action.payload.config;
            return newState;
        case 'GET_CONFIG_OPTIONNAL_FIELD_SUCCESS':
            newState.optionnalFields = action.payload.config;
            return newState;
        case 'APPLICATION_ON_ERROR':
            newState.applicationOnError = action.payload;
            return newState;
        case 'GET_CONFIG_CALENDAR_DAY_SUCCESS':
            newState.calendarDays = action.payload.config;
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default appReducer;
