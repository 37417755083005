import './styles.scss';

import { Accordion, AccordionTab } from 'primereact/accordion';
import React, { useState } from 'react';
import { faCheck, faCircle, faSync } from '@fortawesome/pro-solid-svg-icons';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../utils/components/NumberInput';

const Form = ({
    form,
    formAnswer,
    formStatus,
    update,
    eventDate,
    disabled,
    customer,
    entity,
    company,
    user,
    site,
    employee,
    material,
    supplier,
    siteDayShown,
    individualSave,
}) => {
    const { t } = useTranslation();
    const [errors_themes, setErrorsThemes] = useState([]);
    const [errors_groups, setErrorsGroups] = useState([]);
    const [errors_fields, setErrorsFields] = useState([]);

    const formDisabled = disabled || ['sync', 'valided'].includes(formStatus);

    const unvalid = () => {
        update(formAnswer, 'todo', form.type === 'FCH', form.type === 'FCH');
    };

    const valid = () => {
        const e_fields = [];
        const e_groups = [];
        const e_themes = [];
        if (!formAnswer) {
            formAnswer = {
                'status': 'todo',
                'answer': '{}',
            };
        }
        form.themes.forEach(theme => {
            if (theme.disabled) return;
            theme.groups.forEach(group => {
                if (group.disabled) return;
                group.fields.forEach(field => {
                    if (field.disabled) return;
                    // if (field.type === 'T100D') return;
                    if (field.type === 'DateD') return;
                    if (field.mandatory) {
                        switch (formAnswer[field.id]) {
                            case undefined:
                            case 0:
                            case '0':
                            case null:
                            case '':
                                e_themes.push(theme);
                                e_groups.push(group);
                                e_fields.push(field);
                                break;
                        }
                        if (field.type === 'Mail') {
                            if (formAnswer[field.id] === undefined) {
                                e_themes.push(theme);
                                e_groups.push(group);
                                e_fields.push(field);
                                return;
                            }
                            if (!formAnswer[field.id].toLowerCase()
                                .match(
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                )) {
                                e_themes.push(theme);
                                e_groups.push(group);
                                e_fields.push(field);
                            }
                        }
                    }
                });
            });
        });
        setErrorsFields(e_fields);
        setErrorsGroups(e_groups);
        setErrorsThemes(e_themes);
        if (e_fields.length > 0) {
            const error_msg = e_fields.reduce((prev, error) => {
                return `${prev}- ${error.label}\n`;
            }, '');
            if (e_fields.length > 1) {
                toast.error(<pre style={{
                    fontFamily: 'Product',
                    wordWrap: 'normal',
                    whiteSpace: 'break-spaces'
                }}>Les champs suivants sont obligatoires : <br />{error_msg}</pre>);
            } else {
                toast.error(<pre style={{
                    fontFamily: 'Product',
                    wordWrap: 'normal',
                    whiteSpace: 'break-spaces'
                }}>Le champs suivant est obligatoire : <br />{error_msg}</pre>);
            }
        } else {
            update(formAnswer, 'valided', form.type === 'FCH', form.type === 'FCH');
        }
    };

    const handleChange = (field, val) => {
        formAnswer[field.id] = val;
        update(formAnswer, formStatus);
    };

    const getFieldComponent = (field, error) => {
        let valText = '';
        switch (field.variable) {
            case 'Empl_Soc':
                valText = entity?.name;
                break;
            case 'Empl_AdrSoc':
                valText = entity?.address + ' ' + entity?.zipcode;
                break;
            case 'User_Soc':
                valText = company?.name;
                break;
            case 'User_AdrSoc':
                valText = company?.address + ' ' + company?.zipcode;
                break;
            case 'User_NomPrenom':
                valText = user?.firstname + ' ' + user?.lastname;
                break;
            case 'Cht_Code':
                valText = site?.code;
                break;
            case 'Cht_NomReduit':
            case 'Cht_Libelle':
            case 'Cht_Lib':
                valText = site?.label;
                break;
            case 'CHT_Adr':
            case 'Cht_Adr':
                valText = site?.address + ' ' + site?.city;
                break;
            case 'Empl_NomPrenom':
                valText = employee?.firstname + ' ' + employee?.lastname;
                break;
            case 'Cli_NomRed':
            case 'Cli_NomReduit':
                valText = customer?.fullname;
                break;
            case 'Cli_Adr':
            case 'CLI_Adr':
                valText = customer?.address + ' ' + customer?.address_2;
                break;
            case 'Mat_Designation':
                valText = material?.designation;
                break;
            case 'Mat_Type':
                valText = material?.type;
                break;
            case 'Mat_Immat':
                valText = material?.registration;
                break;
            case 'FOUR_NomRéduit':
                valText = supplier?.name;
                break;
            case 'FOUR_Adr':
                valText = (supplier?.address1 || '') + ' ' + (supplier?.address2 || '');
                break;
        }
        switch (field.type) {
            case 'Mail':
            case 'T100':
                return <div style={{ marginTop: '1rem' }}>
                    {
                        field.label?.length > 0 ?
                            <div
                                style={{ color: '#888' }}
                                className={error ? 'form_error' : ''}
                            >
                                {field.label} {field.mandatory ? '*' : ''}
                            </div>
                            : null
                    }
                    <InputText
                        className={error ? 'form_error' : ''}
                        style={{ width: '100%' }}
                        disabled={formDisabled}
                        placeholder={field.comment}
                        value={formAnswer[field.id] || ''}
                        onChange={(e) => handleChange(field, e.target.value)}
                    />
                </div>;
            case 'T100L':
                return <div style={{ marginTop: '1rem' }}>
                    {
                        field.label?.length > 0 ?
                            <div
                                style={{ color: '#888' }}
                                className={error ? 'form_error' : ''}
                            >
                                {field.label} {field.mandatory ? '*' : ''}
                            </div>
                            : null
                    }
                    <Dropdown
                        style={{ width: '100%' }}
                        disabled={formDisabled}
                        options={field.options.sort((a, b) => {
                            if (a.order > b.order) return 1;
                            if (a.order < b.order) return -1;
                            return 0;
                        }).map(o => { return { value: o.id, label: o.label }; })}
                        value={formAnswer[field.id] || ''}
                        onChange={(e) => handleChange(field, e.target.value)}
                    />
                </div>;
            case 'T500':
                return <div style={{ marginTop: '1rem' }}>
                    {
                        field.label?.length > 0 ?
                            <div
                                style={{ color: '#888' }}
                                className={error ? 'form_error' : ''}
                            >
                                {field.label} {field.mandatory ? '*' : ''}
                            </div>
                            : null
                    }
                    <InputTextarea
                        style={{ width: '100%' }}
                        disabled={formDisabled}
                        value={formAnswer[field.id] || ''}
                        onChange={(e) => handleChange(field, e.target.value)}
                    />
                </div>;
            case 'T500F':
                return <div style={{ marginTop: '1rem' }}>
                    <pre style={{
                        color: '#888',
                        fontFamily: 'Product',
                        wordWrap: 'normal',
                        whiteSpace: 'break-spaces'
                    }}>
                        {field.comment}
                    </pre>
                </div>;
            case 'Num':
                return <div style={{ marginTop: '1rem' }}>
                    {
                        field.label?.length > 0 ?
                            <div
                                style={{ color: '#888' }}
                                className={error ? 'form_error' : ''}
                            >
                                {field.label} {field.mandatory ? '*' : ''}
                            </div>
                            : null
                    }
                    <NumberInput
                        style={{ width: '100%' }}
                        disabled={formDisabled}
                        digit={5}
                        value={formAnswer[field.id] || ''}
                        onValueChange={(e) => handleChange(field, e.value)}
                    />
                </div>;
            case 'Opt':
                return <div style={{
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '1rem 0'
                }}>
                    {field.label?.length > 0 ? <div style={{ color: '#888' }}>{field.label} {field.mandatory ? '*' : ''}</div> : null}
                    < InputSwitch
                        disabled={formDisabled}
                        checked={formAnswer[field.id] || false
                        }
                        onChange={(e) => handleChange(field, e.value)}
                    />
                </div >;
            case 'T100D':
                if(!(field.id in formAnswer)) {
                    formAnswer[field.id] = valText;
                }
                return <div style={{ marginTop: '1rem' }}>
                    {
                        field.label?.length > 0 ?
                            <div
                                style={{ color: '#888' }}
                                className={error ? 'form_error' : ''}
                            >
                                {field.label} {field.mandatory ? '*' : ''}
                            </div>
                            : null
                    }
                    <InputText
                        className={error ? 'form_error' : ''}
                        style={{ width: '100%' }}
                        disabled={formDisabled}
                        placeholder={field.comment}
                        value={formAnswer[field.id]}
                        onChange={(e) => handleChange(field, e.target.value)}
                    />
                </div>;
            case 'T500D':
                if(!(field.id in formAnswer)) {
                    formAnswer[field.id] = valText;
                }
                return <div style={{ marginTop: '1rem' }}>
                    {
                        field.label?.length > 0 ?
                            <div
                                style={{ color: '#888' }}
                                className={error ? 'form_error' : ''}
                            >
                                {field.label} {field.mandatory ? '*' : ''}
                            </div>
                            : null
                    }
                    <InputTextarea
                        style={{ width: '100%' }}
                        disabled={formDisabled}
                        value={formAnswer[field.id]}
                        onChange={(e) => handleChange(field, e.target.value)}
                    />
                </div>;
            case 'Date':
                return <div style={{ marginTop: '1rem' }}>
                    {field.label?.length > 0 ? <div
                        style={{ color: '#888' }}
                        className={error ? 'form_error' : ''}
                    >{field.label} {field.mandatory ? '*' : ''}</div> : null}
                    <Calendar
                        disabled={formDisabled}
                        locale="fr"
                        dateFormat="DD dd MM yy"
                        value={formAnswer[field.id] ? moment(formAnswer[field.id]).toDate() : null}
                        onChange={(e) => handleChange(field, e.value)}
                    />
                </div>;
            case 'Heur':
                return <div style={{ marginTop: '1rem' }}>
                    {field.label?.length > 0 ? <div
                        style={{ color: '#888' }}
                        className={error ? 'form_error' : ''}
                    >{field.label} {field.mandatory ? '*' : ''}</div> : null}
                    <Calendar
                        disabled={formDisabled}
                        locale="fr"
                        timeOnly
                        value={formAnswer[field.id] ? moment(formAnswer[field.id]).toDate() : null}
                        onChange={(e) => handleChange(field, e.value)}
                    />
                </div>;
            case 'DateD':
                // return <div style={{ marginTop: '1rem' }}>
                //     {field.label?.length > 0 ? <div
                //         style={{ color: '#888' }}
                //         className={error ? 'form_error' : ''}
                //     >{field.label} {field.mandatory ? '*' : ''}</div> : null}
                //     <pre style={{
                //         fontFamily: 'Product',
                //         wordWrap: 'normal',
                //         whiteSpace: 'break-spaces'
                //     }}>
                //         {eventDate ? moment(eventDate).format('DD/MM/YYYY') : null}
                //     </pre>
                // </div>;
                return <div style={{ marginTop: '1rem' }}>
                    {field.label?.length > 0 ? <div
                        style={{ color: '#888' }}
                        className={error ? 'form_error' : ''}
                    >{field.label} {field.mandatory ? '*' : ''}</div> : null}
                    <Calendar
                        disabled={formDisabled}
                        locale="fr"
                        dateFormat="DD dd MM yy"
                        value={formAnswer[field.id] ? moment(formAnswer[field.id]).toDate() : moment(siteDayShown ? siteDayShown : eventDate).toDate()}
                        onChange={(e) => handleChange(field, e.value)}
                    />
                </div>;
        }
    };

    return <>
        <div className="tabEvent p-shadow-2">
            <p className="select_title">
                Formulaire sélectionné : <span style={{ color: 'black' }}>{form?.label}</span>
            </p>
            <div style={{ padding: '0 1rem' }} className="form">
                <Accordion activeIndex={-1}>
                    {form?.themes.sort(((themeA, themeB) => {
                        if (themeA.order > themeB.order) return 1;
                        if (themeA.order < themeB.order) return -1;
                        return 0;
                    })).map(theme => {
                        let color = 'rgb(248, 242, 0)';
                        switch (theme.color) {
                            case 'CJA':
                                color = 'rgb(248, 242, 0)';
                                break;
                            case 'COR':
                                color = 'rgb(255, 192, 0)';
                                break;
                            case 'CRO':
                                color = 'rgb(255, 0, 0)';
                                break;
                            case 'CVE':
                                color = 'rgb(0, 176, 80)';
                                break;
                            case 'CBL':
                                color = 'rgb(5, 148, 255)';
                                break;
                            case 'CVI':
                                color = 'rgb(112, 48, 160)';
                                break;
                        }
                        if (theme.disabled) return;
                        return <AccordionTab
                            key={theme.id}
                            header={<div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'rows',
                                    gap: '16px',
                                    alignItems: 'center'
                                }}
                                className={errors_themes.map(t => t.id).includes(theme.id) ? 'form_error' : ''}
                            >
                                <FontAwesomeIcon color={color} icon={faCircle} />
                                <div>{theme.label}</div>
                            </div>}>
                            {
                                theme.groups.sort(((gA, gB) => {
                                    if (gA.order > gB.order) return 1;
                                    if (gA.order < gB.order) return -1;
                                    return 0;
                                })).map(group => {
                                    if (group.disabled) return;
                                    return <div style={{
                                        borderTop: `1px solid ${color}`,
                                        marginBottom: '1rem',
                                        paddingTop: '1rem',
                                        fontSize: '14px'
                                    }} key={group.id}>
                                        {
                                            group.label?.length > 0 ?
                                                <div style={{ fontSize: '16px', paddingBottom: '8px', textDecoration: 'underline', color: errors_groups.map(g => g.id).includes(group.id) ? '#c00' : '#888' }}>
                                                    {group.label}
                                                </div> : null
                                        }
                                        {
                                            group.fields.sort(((fA, fB) => {
                                                if (fA.order > fB.order) return 1;
                                                if (fA.order < fB.order) return -1;
                                                return 0;
                                            })).map(field => {
                                                if (field.disabled) return;
                                                return <div key={field.id} style={{
                                                    display: 'inline-block',
                                                    width: `${field.width_percent === 100 ? 100 : 46}%`,
                                                    marginRight: `${field.width_percent === 100 ? 0 : 4}%`
                                                }}>
                                                    {getFieldComponent(field, errors_fields.map(f => f.id).includes(field.id))}
                                                </div>;
                                            })}
                                    </div>;
                                })
                            }
                        </AccordionTab>;
                    })}
                </Accordion>
                <div className="form-actions">
                    {(!individualSave && !['valided', 'sync'].includes(formStatus)) ?
                        < Button
                            className={'form_validation p-shadow-2 p-button-link unvalid'}
                            onClick={() => {
                                update(formAnswer, formStatus, true, true);
                            }}
                        >
                            <label htmlFor="valid">
                                {
                                    <div>
                                        {t('common:save')}
                                    </div>
                                }
                            </label>
                        </Button>
                        : null}
                    {formStatus === 'valided' ?
                        < Button
                            className={'form_validation p-shadow-2 p-button-link unvalid'}
                            onClick={() => unvalid()}
                        >
                            <label htmlFor="valid">
                                {
                                    <div>
                                        Editer
                                    </div>
                                }
                            </label>
                        </Button>
                        : null}
                    <Button
                        className={`form_validation p-shadow-2 p-button-link ${formStatus}`}
                        onClick={valid}
                        disabled={formDisabled}
                    >
                        <label htmlFor="valid">
                            {
                                formStatus === 'valided' ?
                                    <div>
                                        <FontAwesomeIcon icon={faCheck} />
                                        Validé
                                    </div> :
                                    formStatus === 'sync' ?
                                        <div>
                                            <FontAwesomeIcon icon={faSync} />
                                            Synchronisé
                                        </div>
                                        : t('common:valid')
                            }
                        </label>
                    </Button>
                </div>
            </div>
        </div >
    </>;
};

export default Form;


