const initialState = () => ({
    all: []
});

const eventsReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'DAYOFF_RECEIVED':
            newState.all = action.payload;
            return newState;
        case 'DAYOFF_CREATED':
            newState.all.push(action.payload);
            return newState;
        case 'DAYOFF_DELETED':
            newState.all.forEach((dayoff, idx) => {
                if (dayoff.id === action.payload) {
                    newState.all.splice(idx, 1);
                }
            });
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default eventsReducer;
