const initialState = () => ({
    all: []
});

const timeslotProfileReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'TIMESLOT_PROFILE_RECEIVED':
            newState.all = action.payload.sort((a, b) => {
                if (a.label > b.label) return 1;
                if (a.label< b.label) return -1;
                return 0;
            });
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default timeslotProfileReducer;


