import { faArrowLeft, faChevronLeft, faChevronRight, faPaperPlane, faPlus, faSave, faTriangleExclamation, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hasAccessToSecurity } from '../../../utils/access';
import PictureRow from '../../pictures/PictureRow';
import PictureRowPreview from '../../pictures/PictureRowPreview';
import Uploader from '../../uploader';
import { createSecurityReport, updateSecurityReport } from '../actions';
import FormAddRisks from './FormAddRisks';

const FormSecurityReport = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { opened, securityReport, onClose } = props;
    const [fieldErrors, setFieldErrors] = React.useState([]);

    const [showAddRisks, setShowAddRisks] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [sent, setSent] = React.useState(null);
    const [comment, setComment] = React.useState('');
    const [securityReportDate, setSecurityReportDate] = React.useState(moment().businessAdd(1, 'day'));
    const [selectedRisks, setSelectedRisks] = React.useState({});
    const [pictures, setPictures] = React.useState([]);
    const [employee, setEmployee] = React.useState(null);
    const [site, setSite] = React.useState(null);
    const [teamPicture, setTeamPicture] = React.useState(null);
    const [siteFilter, setSiteFilter] = useState('');
    const [, setEmployeeFilter] = useState('');
    const [reportType, setReportType] = React.useState('danger');
    const { offlineMode: isOfflineMode } = useSelector(state => state.core);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [newRisks, setNewRisks] = React.useState({});

    const sitesState = useSelector(state => state.sites);
    const authState = useSelector(state => state.auth);
    const userState = useSelector(state => state.users);
    const groupRisksState = useSelector(state => state.groupRisks);
    const employeeState = useSelector(state => state.employees);

    const currentUser = userState.list.find(user => user.id === authState.user_id);
    const employeeList = currentUser.is_multi_employee === 1 ? employeeState.all : employeeState.team;

    let currentEmploye = employeeState.team.find(empl =>
        empl.id === employeeState.selectedEmployee
    );
    if (!currentEmploye) {
        currentEmploye = employeeState.all.find(empl =>
            empl.id === employeeState.selectedEmployee
        );
    }

    const siteRisksById = sitesState.list.find(s => s.id === site)?.risks.reduce((prev, curr) => {
        const _new = { ...prev };
        _new[curr.id] = { 'comment': curr.pivot.comment };
        return _new;
    }, {});

    const addFile = file => {
        const _files = uploadedFiles.slice(0);
        _files.push({ file: file, desc: '' });
        setUploadedFiles(_files);
    };

    const addNewRisk = risk => {
        setNewRisks({ ...newRisks, [risk.id]: true });
        const newSelectedRisks = Object.assign({}, selectedRisks);
        newSelectedRisks[risk.id] = true;
        setSelectedRisks(newSelectedRisks);
    };

    const uploadTeamPicture = (pict) => {
        const formdata = new FormData();
        formdata.append('file', pict);
        axios.post('/pictures/upload_picture', formdata).then((data) => {
            setTeamPicture(data.data);
        });
    };

    const removeTempPicture = (idx) => {
        const newUploadedFile = uploadedFiles.splice(0);
        newUploadedFile.splice(idx, 1);
        setUploadedFiles(newUploadedFile);
    };

    const dropdownOptionSite = (option) => {
        return (
            <div className="dropdown-row">
                <div className="label">{option.label}</div>
                <div className="code">{option.code}</div>
            </div>
        );
    };

    const dropdownOptionTemplate = (option) => {
        return (
            <div className="dropdown-row">
                <div>{option.label}</div>
            </div>
        );
    };

    const updateSecurityReportDate = (date) => {
        setSecurityReportDate(date);
    };


    React.useEffect(function () {
        if (!opened) return;
        if (!securityReport) {
            setId(undefined);
            setDisabled(false);
            setFieldErrors([]);
            setUploadedFiles([]);
            setSent(false);
            setSelectedRisks([]);
            setSite(sitesState.selectedSite || null);
            setEmployee(employeeState.selectedEmployee || currentEmploye || null);
            setPictures([]);
            setNewRisks([]);
            setComment('');
            setTeamPicture(false);
            setSecurityReportDate(moment());
            setReportType('danger');
        } else {
            setId(securityReport.id);
            setDisabled(securityReport.sent);
            setUploadedFiles([]);
            setFieldErrors([]);
            setNewRisks(securityReport.risks.reduce((p, c) => {
                p[c.id] = true;
                return p;
            }, {}));
            setSelectedRisks(securityReport.risks.reduce((p, c) => {
                p[c.id] = true;
                return p;
            }, {}));
            setSent(securityReport.sent);
            setSite(securityReport.site_id);
            setEmployee(securityReport.employee_id);
            setPictures(securityReport.pictures);
            setComment(securityReport.comment);
            setSecurityReportDate(moment(securityReport.date));
            setTeamPicture(securityReport.team_picture);
            setReportType(securityReport.doc_type);
        }
    }, [opened, securityReport]);

    const validForm = (sent) => {
        let fieldErrors = [];
        if (!site) fieldErrors.push('securityReportSite');
        if (!employee) fieldErrors.push('securityReportEmployee');
        setFieldErrors(fieldErrors);
        if (fieldErrors.length > 0) {
            toast.error(t('app:cannot_save_missing_fields', { fields: fieldErrors.map(err => t(`app:${err}`)).join(', ') }));
        } else {
            onClose();
            const params = {
                'date': moment(securityReportDate).format('YYYY-MM-DD HH:mm:ss'),
                'doc_type': reportType,
                'comment': comment,
                'employee_id': employee,
                'team_picture_id': teamPicture?.id,
                'site_id': site,
                'sent': sent,
                'risks': Object.keys(selectedRisks)
            };
            if (id) {
                dispatch(updateSecurityReport(id, params, uploadedFiles));
            } else {
                dispatch(createSecurityReport(params, uploadedFiles));
            }

        }
    };

    const lastSelectedSite = localStorage.getItem('last-selected-site')?.split(',')?.map(x => parseInt(x, 10)) || [];
    const lastEmployeeIds = localStorage.getItem('last-selected-employees')?.split(',')?.map(x => parseInt(x, 10)) || [];

    const sortUserAlgo = (a, b) => {
        if (lastEmployeeIds.includes(a.id) && !lastEmployeeIds.includes(b.id)) return -1;
        if (lastEmployeeIds.includes(b.id) && !lastEmployeeIds.includes(a.id)) return 1;
        if (a.lastname > b.lastname) return 1;
        if (a.lastname < b.lastname) return -1;
        if (a.firstname > b.firstname) return 1;
        if (a.firstname < b.firstname) return -1;
        return 0;
    };

    const handleClickOnRisk = r => {
        const newSelectedRisks = Object.assign({}, selectedRisks);
        if (newSelectedRisks[r.id]) {
            delete newSelectedRisks[r.id];
        } else {
            newSelectedRisks[r.id] = true;
        }
        setSelectedRisks(newSelectedRisks);
    };

    return (
        <Dialog
            maximized={true}
            modal={true}
            visible={opened}
            onHide={onClose}
            className="create-dialog"
            position="bottom"
            closable
        >
            <div className="header p-shadow-8">
                <div className="topnavbar">
                    <Button onClick={onClose} className="p-button-link close-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div className="title">
                        {id ?
                            t('app:see_a_security_report') :
                            t('app:add_a_security_report')
                        }
                    </div>
                    {
                        !isOfflineMode && !sent ?
                            <Button
                                onClick={() => validForm(true)}
                                className={'p-button-link create-event-button'}>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </Button> : null
                    }
                    {
                        !sent ?
                            <Button
                                onClick={() => validForm(false)}
                                className={'p-button-link create-event-button'}>
                                <FontAwesomeIcon icon={faSave} />
                            </Button> : null
                    }
                </div>
            </div>
            <div className="day-navigation supply">
                <Button disabled={disabled} size="small" className="p-button-link" onClick={() => updateSecurityReportDate(moment(securityReportDate).clone().subtract(1, 'days'))}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <div className="currentDay">
                    <Calendar
                        locale="fr"
                        disabled={disabled}
                        dateFormat="DD dd/mm/yy"
                        showTime hourFormat="24"
                        value={securityReportDate.toDate()}
                        onChange={date => setSecurityReportDate(moment(date.value))}
                    />
                </div>
                <Button disabled={disabled} size="small" className="p-button-link" onClick={() => updateSecurityReportDate(moment(securityReportDate).clone().add(1, 'days'))}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
            <div className="select_title">
                {t('app:report_type')} :
            </div>
            <div className="type-selector type-selector-secu">
                <div
                    onClick={() => disabled ? null : setReportType('danger')}
                    className={reportType === 'danger' ? 'selected' : ''}
                >
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    {t('app:dangerous_situation')}
                </div>
                {hasAccessToSecurity() && <div
                    onClick={() => disabled ? null : setReportType('report')}
                    className={reportType === 'report' ? 'selected' : ''}
                >
                    <FontAwesomeIcon icon={faUsers} />
                    {t('app:security_meeting')}
                </div>}
            </div>
            <p className="select_title">
                {t('app:choose_site')}
            </p>
            <Dropdown
                disabled={disabled}
                value={site}
                area-describedby="security-report-site-error"
                virtualScrollerOptions={{ itemSize: 62 }}
                onFilter={({ filter }) => {
                    setSiteFilter(filter);
                }}
                options={sitesState.list
                    .sort((a, b) => {
                        if (siteFilter.length === 0) {
                            if (lastSelectedSite.indexOf(a.id) > -1 && lastSelectedSite.indexOf(b.id) === -1) return -1;
                            if (lastSelectedSite.indexOf(a.id) === -1 && lastSelectedSite.indexOf(b.id) > -1) return 1;
                            if (a.label > b.label) return 1;
                            if (a.label < b.label) return -1;
                        }
                        return 0;
                    })
                    .filter(site => site.type === 'CHT')
                    .map(site => {
                        return {
                            'label': site.label,
                            'value': site.id,
                            'code': '' + site.code,
                        };
                    })}
                onChange={e => setSite(e.value)}
                filter
                filterBy="code,label"
                itemTemplate={dropdownOptionSite}
                className={fieldErrors.includes('securityReportSite') ? 'p-invalid' : ''}
            />
            {
                fieldErrors.includes('securityReportSite') ?
                    <small id="security-report-site-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                    : null
            }
            <p className="select_title">
                {t('common:animator')}
            </p>
            <Dropdown
                disabled={disabled}
                value={employee}
                area-describedby="security-report-employee-error"
                virtualScrollerOptions={{ itemSize: 62 }}
                onFilter={({ filter }) => {
                    setEmployeeFilter(filter);
                }}
                options={employeeList
                    .sort(sortUserAlgo)
                    .map(e => {
                        return {
                            'label': `${e.lastname.toUpperCase()} ${e.firstname}`,
                            'value': e.id
                        };
                    })}
                onChange={e => setEmployee(e.value)}
                filter
                filterBy="label"
                itemTemplate={dropdownOptionTemplate}
                className={fieldErrors.includes('securityReportEmployee') ? 'p-invalid' : ''}
            />
            {
                fieldErrors.includes('securityReportEmployee') ?
                    <small id="security-report-employee-error" className="p-error p-d-block">{t('app:missing_field')}</small>
                    : null
            }
            <p className="select_title">
                {t('app:risks')}
            </p>
            <div className="article-list p-shadow-2">
                {!disabled && <Button className="flashy add-risk-btn" onClick={() => setShowAddRisks(true)} >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>}
                <div>
                    {
                        groupRisksState.all.filter(g =>
                            g.risks.filter(r => (siteRisksById ? siteRisksById[r.id] : false) || newRisks[r.id]).length > 0
                        ).map(g => {
                            return <div key={g.id}>
                                <h4 style={{ marginLeft: '1rem' }}>{g.label}</h4>
                                {g.risks
                                    .filter(r => (siteRisksById ? siteRisksById[r.id] : false) || newRisks[r.id])
                                    .map(r =>
                                        <div key={r.id} className="wrapper-risk">
                                            <Checkbox
                                                id={`r-${r.id}`}
                                                disabled={disabled}
                                                checked={selectedRisks[r.id] || false}
                                                onChange={() => handleClickOnRisk(r)}
                                            />
                                            <div className="risk-row"
                                                onClick={() => handleClickOnRisk(r)}
                                            >
                                                {r.label} {siteRisksById[r.id]?.comment}
                                            </div>
                                        </div>
                                    )}
                            </div>;
                        })
                    }
                </div>
            </div>
            <div className="select-title">
                {t('common:note')}
            </div>
            <InputTextarea
                className="form-security-textarea"
                disabled={disabled}
                value={comment || ''}
                onChange={(e) => setComment(e.target.value)}
                rows={4}
            />
            {
                reportType === 'danger' ?
                    null :
                    <>
                        <p className="select_title">
                            {t('app:team_picture')}
                        </p>
                        <div className="pictures-list">
                            {(!teamPicture && !disabled) && <Uploader withFootbar sendPicture={uploadTeamPicture} />}
                            {
                                teamPicture
                                    ? <PictureRow onDelete={() => {
                                        setTeamPicture(null);
                                    }}readOnly={disabled} key={teamPicture.id} picture={teamPicture} />
                                    : null
                            }
                        </div>
                    </>
            }
            <p className="select_title">
                {t('common:pictures')}
            </p>
            <div className="pictures-list">
                {!disabled && <Uploader withFootbar sendPicture={addFile} />}
                {
                    pictures.length > 0
                        ? pictures.map(p => <PictureRow readyOnly={disabled} key={p.id} picture={p} />
                        ) : null
                }
                {uploadedFiles.map((p, idx) => <PictureRowPreview key={p.file.lastModified} onDelete={() => removeTempPicture(idx)} picture={p.file} desc={p.desc} onChangeDesc={value => {
                    const newUploadedFiles = uploadedFiles.slice(0);
                    newUploadedFiles[idx].desc = value;
                    setUploadedFiles(newUploadedFiles);
                }} />)}
            </div>
            <FormAddRisks
                selectedRisks={selectedRisks}
                onAdd={addNewRisk}
                opened={showAddRisks}
                handleClickOnRisk
                onClose={() => { setShowAddRisks(false); }}
            />
        </Dialog >
    );
};

export default FormSecurityReport;