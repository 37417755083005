import React from 'react';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormDayoffRow = props => {
    return (<div className="dayoff-row">
        <div className="total_dayoff_type">
            {props.label}<br/>
        </div>
        <div className="minus_add">
            <div className="add">
                <Button className="p-button-link" onClick={() => props.onChange(props.value + 0.5)}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>
            <div className="dayoff_used">
                {props.value}
            </div>
            <div className="minus">
                <Button className="p-button-link" onClick={() => props.onChange(Math.max(0, props.value - 0.5))}>
                    <FontAwesomeIcon icon={faMinus} />
                </Button>
            </div>
        </div>
    </div>
    );
};

export default FormDayoffRow;
