import './style.scss';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const PictureRowPreview = props => {
    const { picture, desc, onChangeDesc } = props;
    const preview = URL.createObjectURL(picture);
    const {t} = useTranslation();

    return ( <div key={picture} className="pictures-preview">
        {
            !props.readyOnly ? <Button onClick={props.onDelete} className="p-button-link delete p-shadow-3">
                <FontAwesomeIcon icon={faTimes} />
            </Button> : null
        }
        <div className="waitingLayer">
            {t('app:not_yet_uploaded')}
        </div>
        <img alt={preview} src={preview} />
        <InputTextarea
            className="photo-desc"
            placeholder="description"
            rows={5}
            value={desc === null ? picture?.comment : desc}
            onChange={e => {
                onChangeDesc(e.target.value);
            }}/>
    </div>
    );
};

export default PictureRowPreview;
