import axios from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';

export const updateCycleSuccess = () => toast.success(i18n.t('app:cycle_updated'));
export const updateCycleError = () => toast.error(i18n.t('app:cannot_update_cycle'));

export const cycleUpdate = cycle => ({
    type: 'CYCLE_EDITED',
    payload: cycle
});

export const updateCycle = params => dispatch => {
    return axios.patch(`/cycle/${params.id}`, params)
        .then(data => {
            updateCycleSuccess();
            dispatch(cycleUpdate(data.data));
        })
        .catch(err => updateCycleError(err));
};

export const cyclesReceived = cycles => ({
    type: 'CYCLES_RECEIVED',
    payload: {
        cycles
    }
});

export const cycleStatusesReceived = cycleStatuses => ({
    type: 'CYCLE_STATUSES_RECEIVED',
    payload: {
        cycleStatuses
    }
});

export const cycleTypesReceived = cycleTypes => ({
    type: 'CYCLE_TYPES_RECEIVED',
    payload: {
        cycleTypes
    }
});