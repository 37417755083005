import React, {useEffect, useState} from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import CycleRow from './components/CycleRow';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { updateCycle } from '../app/updateApp';

import noCycle from '../../assets/no-cycle.png';

const Cycles = () => {
    const { t } = useTranslation();
    const [doc, setDoc]= useState(null);
    const cycles = useSelector(state => state.cycles.list
        .filter(c => ![ 5, 9 ].includes(c.cycle_status.import_id))
    );

    useEffect(function () {updateCycle();}, []);

    return (
        <div className="module-page module-cycle">
            {
                cycles.length === 0
                    ? <div className="no-data">
                        <img src={noCycle} />
                        <div className="label">{t('app:no_cycle')}</div>
                    </div>:
                    doc === null ?
                        <div className="cycle-wrapper-root">
                            <div className="cycle-title">
                                {t('app:cycle_list')}
                            </div>
                            {cycles.filter(c => c.site !== null)
                                .map(c => c.site)
                                .filter((v, i, a) => a.findIndex( t => ( t.id === v.id)) ===i)
                                .sort((a, b) => {
                                    if (a.label > b.label) return 1;
                                    if (a.label < b.label) return -1;
                                    return 0;
                                }).map(doc=>
                                    <Button key={doc.id} onClick={() => setDoc(doc)} className="button-folder p-shadow-1">
                                        <FontAwesomeIcon icon={faFolder} className="folder"/>
                                        {doc.label} - {doc.code}
                                        <FontAwesomeIcon icon={faChevronRight} className="arrow"/>
                                    </Button>
                                )}
                            {
                                cycles.filter(c => c.site_id === null)
                                    .map(
                                        cycle=> <CycleRow goBack={() => setDoc(null)} key={cycle.id} cycle={cycle}/>
                                    )
                            }
                        </div>
                        : <>
                            <Button onClick={() => setDoc(null)} className="button-folder-back p-button-link">
                                <FontAwesomeIcon icon={faChevronLeft} className="back"/>
                                {doc.label} - {doc.code}
                            </Button>
                            <div className="cycle-wrapper">
                                {
                                    cycles.filter(c => c.site_id === doc.id)
                                        .sort((a, b) => {
                                            if (a.date_deadline > b.date_deadline ) return 1;
                                            if (a.date_deadline < b.date_deadline ) return -1;
                                            return 0;
                                        })
                                        .map(
                                            cycle=> <CycleRow goBack={() => setDoc(null)} key={cycle.id} cycle={cycle} />
                                        )
                                }
                            </div>
                        </>
            }
        </div>
    );
};

export default Cycles;

