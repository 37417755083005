const initialState = () => ({
    list: [],
    listStatus: [],
    listType: []
});

const cyclesReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'CYCLES_RECEIVED':
            newState.list = action.payload.cycles;
            return newState;
        case 'CYCLE_STATUSES_RECEIVED':
            newState.listStatus = action.payload.cycleStatuses;
            return newState;
        case 'CYCLE_TYPES_RECEIVED':
            newState.listType = action.payload.cycleTypes;
            return newState;
        case 'CYCLE_EDITED':
            newState.list.forEach((cycle, idx) => {
                if (cycle.id === action.payload.id) {
                    newState.list[idx] = action.payload;
                }
            });
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState());
        default:
            return state;
    }
};

export default cyclesReducer;

