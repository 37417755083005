// import { addImageToStore, deleteStoreAndCache, updateInStoreAndCache } from '../offline/helper';

import axios from 'axios';
import i18n from 'i18next';
import store from '../../store';
import { toast } from 'react-toastify';

export const deleteAttachmentSuccess = () => toast.success(i18n.t('app:attachment_deleted'));
export const deleteAttachmentError = () => toast.error(i18n.t('app:cannot_delete_attachment'));
export const createAttachmentSuccess = ()=> toast.success(i18n.t('app:attachment_created'));
export const createAttachmentError = () => toast.error(i18n.t('app:cannot_create_attachment'));
export const updateAttachmentSuccess = () => toast.success(i18n.t('app:attachment_updated'));
export const updateAttachmentError = () => toast.error(i18n.t('app:cannot_update_attachment'));

export const attachmentReceived = attachments => ({
    type: 'ATTACHMENT_RECEIVED',
    payload: attachments
});

export const attachmentUpdate = attachment => ({
    type: 'ATTACHMENT_EDITED',
    payload: attachment
});

export const attachmentDeleted = attachmentId => ({
    type: 'ATTACHMENT_DELETED',
    payload: attachmentId
});

export const attachmentCreated = attachment => ({
    type: 'ATTACHMENT_CREATED',
    payload: attachment
});

export const picturesSentWithSuccess = (files) => ({
    type: 'ATTACHMENT_UPLOAD_PICTURES_SUCCESS',
    payload: files
});

export const createAttachment = (params, files) => dispatch => {
    return axios.post('/attachment', params)
        .then(data => {
            dispatch(attachmentCreated(data.data));
            const axiosCalls = [];
            files.forEach(file => {
                const formdata = new FormData();
                formdata.append('file', file.file);
                formdata.append('comment', file.desc);
                formdata.append('attachment_id', data.data.id);
                axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
            });
            return Promise.all(axiosCalls)
                .then(data_pict => {
                    createAttachmentSuccess(data);
                    if (files.length > 0) {
                        dispatch(picturesSentWithSuccess(data_pict));
                    }
                });
        })
        .catch(err => {
            createAttachmentError(err);
        });
};

export const updateAttachment = (params, files) => dispatch => {
    const state = store.getState();
    const isOfflineMode = state.core.offlineMode;
    // const sites = state.sites.list;
    // const attachments = state.attachments.all;
    // const suppliers = state.suppliers.all;
    if (isOfflineMode) {
        // updateInStoreAndCache('attachments', params, () => {
        //     const current_attachment = attachments.find(t => t.id === params.id);
        //     dispatch(attachmentUpdate({ ...current_site_ticket, ...params }));
        //     updateAttachmentSuccess();
        //     if (files.length > 0) {
        //         files.forEach(file => {
        //             addImageToStore({
        //                 attachmentId: params.id,
        //                 file: file.file,
        //                 comment: file.desc
        //             }, () => { });
        //         });
        //     }
        // });
    } else {
        return axios.patch(`/attachment/${params.id}`, params)
            .then(data => {
                dispatch(attachmentUpdate(data.data));
                const axiosCalls = [];
                files.forEach(file => {
                    const formdata = new FormData();
                    formdata.append('file', file.file);
                    formdata.append('attachment_id', data.data.id);
                    formdata.append('comment', file.desc);
                    axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
                });
                return Promise.all(axiosCalls);
            })
            .then(data => {
                updateAttachmentSuccess();
                if (files.length > 0) {
                    dispatch(picturesSentWithSuccess(data));
                }
            })
            .catch(err => updateAttachmentError(err));
    }
};

export const deleteAttachment = attachment_id => dispatch => {
    const isOfflineMode = store.getState().core.offlineMode;
    if (isOfflineMode) {
        // deleteStoreAndCache('attachments', deposit_ticket_id, () => {
        //     deleteAttachmentSuccess();
        //     dispatch(attachmentDeleted(deposit_ticket_id));
        // });
    } else {
        return axios.delete(`/attachment/${attachment_id}`)
            .then(() => {
                deleteAttachmentSuccess();
                dispatch(attachmentDeleted(attachment_id));
            })
            .catch(err => deleteAttachmentError(err));
    }
};
