import './style.scss';

import React, {useEffect, useState} from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { deletePicture, updatePictureComment } from './actions';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputTextarea } from 'primereact/inputtextarea';
import { confirmDialog } from 'primereact/confirmdialog';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import localforage from 'localforage';
import { useTranslation } from 'react-i18next';

let stopSaveTimeout = null;

const PictureRow = props => {
    const offlineMode = useSelector(store => store.core.offlineMode);

    const { t } = useTranslation();
    const { picture, file, readOnly } = props; // work on generic file or specific pictures
    const [apiurl] = useState(localStorage.optimbtp_api_url);
    const dispatch = useDispatch();
    const [ready, setReady] = React.useState(false);
    const [url, setUrl] = React.useState(false);
    const [desc, setDesc] = React.useState(null);

    const cannotBeDeleted = (file ? true : picture.synchronized) || readOnly;
    const id = file ? file.id : picture.id;

    React.useEffect(function(){
        if(picture) {
            if (offlineMode) {
                const LFOptimDataPicturesOfflineMode = localforage.createInstance({name : 'optim_data_pictures'});
                LFOptimDataPicturesOfflineMode.getItem(picture.id).then((blobFile) => {
                    setUrl(URL.createObjectURL(blobFile));
                    setReady(true);
                });
            } else {
                setUrl(`${apiurl}/api/picture/${btoa(picture.file_path)}`);
                setReady(true);
            }
            setDesc(picture.comment || '');
        }
    }, [apiurl, picture]);

    React.useEffect(function(){
        if(file) {
            if (offlineMode) {
                const LFOptimDataFilesOfflineMode = localforage.createInstance({name : 'optim_data_files'});
                LFOptimDataFilesOfflineMode.getItem(file.id).then((blobFile) => {
                    if(blobFile) {
                        setUrl(URL.createObjectURL(blobFile));
                        setReady(true);
                    }
                });
            } else {
                const options = {
                    headers: {
                        'Authorization': localStorage.getItem('optimbtp_session')
                    }
                };

                fetch(`${apiurl}/api/file/${file.uuid}`, options)
                    .then(res => res.blob())
                    .then(blob => {
                        setUrl(URL.createObjectURL(blob));
                        setReady(true);
                    });
            }
        }
    }, [apiurl, file]);

    useEffect(() => {
        if (desc !== null && picture?.comment !== desc) {
            stopSaveTimeout && clearTimeout(stopSaveTimeout);
            stopSaveTimeout = setTimeout(() => dispatch(updatePictureComment(id, desc)), 1000);
        }
    }, [desc]);

    if(!ready) {
        return null;
    }

    const confirmDelete = () =>  {
        confirmDialog({
            message: t('app:really_remove_picture'),
            icon: 'pi pi-warning-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('common:yes'),
            rejectLabel: t('common:no'),
            accept: () => {
                dispatch(deletePicture(picture));
                if (props.onDelete) {
                    props.onDelete();
                }
            },
            reject: () => {}
        });
    };

    return ( <div className="pictures p-shadow-1">
        {
            !cannotBeDeleted && <Button onClick={confirmDelete} className="p-button-link delete p-shadow-3">
                <FontAwesomeIcon icon={faTimes} />
            </Button>
        }
        <TransformWrapper defaultPositionY={0} defaultPositionX={0}>
            <TransformComponent>
                <img className="picture-row" src={url} alt={id}/>
                {file && <div
                    className="photo-desc"
                >
                    {file?.comment}
                </div>}
                {picture && <InputTextarea
                    className="photo-desc"
                    placeholder="description"
                    rows={5}
                    value={desc === null ? picture?.comment : desc}
                    onChange={e => {
                        setDesc(e.target.value);
                    }}/>}
            </TransformComponent>
        </TransformWrapper>
    </div>
    );
};

export default PictureRow;
