const initialState = () => ({
    ready: false,
    offlineMode: localStorage.getItem('offline_mode') === '1'
});

const usersReducer = (state = initialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case 'IS_READY':
            newState.ready = true;
            return newState;
        case 'START_OFFLINE':
            newState.offlineMode = true;
            localStorage.setItem('offline_mode', '1');
            localStorage.setItem('offline_mode_start', parseInt(Date.now() / 1000));
            return newState;
        case 'STOP_OFFLINE':
            newState.offlineMode = false;
            localStorage.setItem('offline_mode', '0');
            return newState;
        case 'NOT_READY':
            newState.ready = false;
            return newState;
        case 'LOGOUT':
            return Object.assign({}, initialState(), {ready: true});
        default:
            return state;
    }
};

export default usersReducer;
