import { useTranslation } from 'react-i18next';
import { canValidEvent } from '../../../utils/access';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';

export const SubFootbarDay = props =>  {
    const { t } = useTranslation();
    const hour = parseInt(props.count);
    const min = (props.count % 1)*60;
    const {offlineMode : isOfflineMode} = useSelector(state => state.core);

    return <div className="sub-footbar">
        <div className="event-duration">
            <FontAwesomeIcon icon={faClock} /> {hour < 10 ? '0' : ''}{hour}:{min < 10 ? '0' : ''}{min}
        </div>
        <div className="title">{t('app:total_day')}</div>
        <div>
            {
                props.canSign ? <Button
                    onClick={props.onClickSign}
                >
                    {t('common:sign')}
                </Button> : null
            }
            {
                !props.canSign && canValidEvent() && !isOfflineMode ? <Button
                    disabled={!props.canValidate}
                    onClick={props.onClickValid}
                >
                    {t('common:valid')}
                </Button> : null
            }
        </div>
    </div>;
};

export default SubFootbarDay;
