import { addImageToStore, addToStoreAndCache, deleteStoreAndCache, updateInStoreAndCache } from '../offline/helper';

import axios from 'axios';
import i18n from 'i18next';
import store from '../../store';
import { toast } from 'react-toastify';

const { dispatch } = store;

export const eventsReceived = (events) => ({
    type: 'EVENTS_RECEIVED',
    payload: events
});

export const eventCreated = (event) => ({
    type: 'EVENT_CREATED',
    payload: event
});

export const picturesSentWithSuccess = (files) => ({
    type: 'EVENT_UPLOAD_PICTURES_SUCCESS',
    payload: files
});

export const eventUpdated = (event) => ({
    type: 'EVENT_UPDATED',
    payload: event
});

export const deleteDeleted = (id) => ({
    type: 'EVENT_DELETED',
    payload: id
});

export const signEvents = (data) => ({
    type: 'EVENTS_SIGNED',
    payload: data
});

export const validateEvents = (data) => ({
    type: 'EVENTS_VALIDATED',
    payload: data
});

export const updateEvents = () => {
    axios.get('/event')
        .then(data => {
            dispatch(eventsReceived(data.data));
        });
};

export const validateEventSuccess = () => toast.success(i18n.t('app:event_validated'));
export const signEventSuccess = () => toast.success(i18n.t('app:event_signed'));
export const eventCreateSuccess = () => {
    toast.success(i18n.t('app:event_created'));
    updateEvents();
};
export const updateEventSuccess = () => {
    toast.success(i18n.t('app:event_edited'));
    updateEvents();
};
export const updateEventsSuccess = () => {
    toast.success(i18n.t('app:events_edited'));
    updateEvents();
};
export const deleteEventSuccess = () => toast.success(i18n.t('app:event_deleted'));

export const validateEventError = () => toast.error(i18n.t('app:cannot_validate_event'));
export const signEventError = () => toast.error(i18n.t('app:cannot_sign_event'));
export const eventCreateError = () => toast.error(i18n.t('app:cannot_create_event'));
export const updateEventError = () => toast.error(i18n.t('app:cannot_update_event'));
export const deleteEventError = () => toast.error(i18n.t('app:cannot_delete_event'));

const simulateBackendEventPrestation = event => {
    const state = store.getState();
    return {
        ...event, prestations: event.prestations.map(p => {
            if (typeof p !== 'number') return p;
            return state.prestations.all.find(prest => prest.id === p);
        })
    };
};

export const createEvent = (params, files, employee_ids) => async (dispatch) => {
    const isOfflineMode = store.getState().core.offlineMode;
    const createEventPromises = [];
    // employee_ids.forEach((employee_id, index) => {
    for (const employee_id of employee_ids) {
        if (isOfflineMode) {
            await addToStoreAndCache('events', simulateBackendEventPrestation(params), (id) => {
                params.state = 0;
                params.employee_id = employee_id;
                dispatch(eventCreated(params));
                files.forEach(file => {
                    const formdata = new FormData();
                    formdata.append('file', file);
                    formdata.append('event_id', id);
                    addImageToStore({
                        eventId: id,
                        file: file.file,
                        comment: file.desc
                    }, () => { });
                });
            });
        } else {
            createEventPromises.push(
                // On multiple event created, do not set employee_type_id, all are differents
                axios.post('/event', { ...params, employee_id: employee_id, employee_type_id: employee_ids.length > 1 ? null : params.employee_type_id })
                    .then(data => {
                        dispatch(eventCreated(data.data));
                        const axiosCalls = [];
                        files.forEach(file => {
                            const formdata = new FormData();
                            formdata.append('file', file.file);
                            formdata.append('event_id', data.data.id);
                            formdata.append('comment', file.desc);
                            axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
                        });
                        return Promise.all(axiosCalls);
                    })
            );
        }
    }
    return Promise.all(createEventPromises)
        .then(data => {
            eventCreateSuccess();
            if (files.length > 0) {
                dispatch(picturesSentWithSuccess(data));
            }
        })
        .catch(err => eventCreateError(err));
};

export const updateEvent = (id, params, files, noNotif, isMultiple) => (dispatch) => {
    const isOfflineMode = store.getState().core.offlineMode;
    if (isOfflineMode) {
        params.id = id;
        const event_params = simulateBackendEventPrestation(params);
        event_params.state = 0;
        updateInStoreAndCache('events', event_params, () => {
            dispatch(eventUpdated(event_params));
            if (!noNotif) {
                isMultiple ? updateEventsSuccess() : updateEventSuccess();
            }
            if (files.length > 0) {
                files.forEach(file => {
                    addImageToStore({
                        eventId: id,
                        comment: file.desc,
                        file: file.file
                    }, () => { });
                });
            }
        });
    } else {
        return axios.patch(`/event/${id}`, params)
            // @TODO : recurtion sur autre event
            .then(data => {
                dispatch(eventUpdated(data.data));
                const axiosCalls = [];
                files.forEach(file => {
                    const formdata = new FormData();
                    formdata.append('file', file.file);
                    formdata.append('event_id', id);
                    formdata.append('comment', file.desc);
                    axiosCalls.push(axios.post('/pictures/upload_picture', formdata));
                });
                return Promise.all(axiosCalls);
            })
            .then(data => {
                if (!noNotif) {
                    isMultiple ? updateEventsSuccess() : updateEventSuccess();
                }
                if (files.length > 0) {
                    dispatch(picturesSentWithSuccess(data));
                }
            })
            .catch(err => updateEventError(err));
    }
};

export const signEvent = (events) => (dispatch) => {
    const axiosEvents = [];
    const isOfflineMode = store.getState().core.offlineMode;
    events.forEach(e => {
        if (isOfflineMode) {
            return new Promise(accept => {
                updateInStoreAndCache('events', e, (id) => {
                    e.id = id;
                    e.state = 2;
                    accept();
                });
            });
        } else {
            axiosEvents.push(axios.patch(`/event/${e.id}`, { state: 2 }));
        }
    });
    Promise.all(events).then(data => {
        dispatch(signEvents(data));
        signEventSuccess();
    }).catch(err => validateEventError(err));
};

export const validateEvent = (events) => (dispatch) => {
    const axiosEvents = [];
    const isOfflineMode = store.getState().core.offlineMode;
    events.forEach(e => {
        if (isOfflineMode) {
            return new Promise(accept => {
                updateInStoreAndCache('events', e, (id) => {
                    e.id = id;
                    e.state = 1;
                    accept();
                });
            });
        } else {
            axiosEvents.push(axios.patch(`/event/${e.id}`, { state: 1 }));
        }
    });
    Promise.all(events).then(data => {
        dispatch(validateEvents(data));
        validateEventSuccess();
    }).catch(err => validateEventError(err));
};

export const deleteEvent = (id) => (dispatch) => {
    const isOfflineMode = store.getState().core.offlineMode;
    if (isOfflineMode) {
        deleteStoreAndCache('events', id, () => {
            deleteEventSuccess();
            dispatch(deleteDeleted(id));
        });
    } else {
        return axios.delete(`/event/${id}`)
            .then(() => {
                deleteEventSuccess();
                dispatch(deleteDeleted(id));
            })
            .catch(err => deleteEventError(err));
    }
};
