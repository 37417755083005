import localforage from 'localforage';

export const addImageToStore = (obj, cb) => {
    const LFResources = localforage.createInstance({ name: 'optim_resources'});
    LFResources.getItem('optim_new_pictures').then(vals => {
        const lastFakeId = vals.all.reduce((prev, curr) => {
            return Math.min(prev, curr.id);
        }, 0);
        obj.id = parseInt(lastFakeId, 10) - 1;
        vals.all.push(obj);
        LFResources.setItem('optim_new_pictures', vals);
        cb();
    });
};

export const addToStoreAndCache = async (store, obj, cb) => {
    const LFResources = localforage.createInstance({ name: 'optim_resources'});
    return await LFResources.getItem(store).then(vals => {
        const lastFakeId = vals.all.reduce((prev, curr) => {
            return Math.min(prev, curr.id);
        }, 0) || -1;
        obj.id = parseInt(lastFakeId, 10) - 1;
        vals.all.push(obj);
        LFResources.setItem(store, vals);
        cb(obj.id);
    });
};

export const updateInStoreAndCache = (store, obj, cb) => {
    const LFResources = localforage.createInstance({ name: 'optim_resources'});
    const LFResourceUpdated = localforage.createInstance({ name: 'optim_resources_updated'});

    LFResourceUpdated.getItem(store).then(vals => {
        vals = vals || [];
        vals.push(obj.id);
        LFResourceUpdated.setItem(store, vals.filter((v, i, a) => a.indexOf(v) === i));
    });

    LFResources.getItem(store).then(vals => {
        const newVals = vals.all.map(val => {
            if(val.id === obj.id) {
                return {...val, ...obj};
            }
            return val;
        });
        LFResources.setItem(store, {...vals, all: newVals});
        cb(obj.id);
    });
};

export const deleteStoreAndCache = (store, id, cb) => {
    const LFResources = localforage.createInstance({ name: 'optim_resources'});
    const LFResourceDeleted = localforage.createInstance({ name: 'optim_resources_deleted'});

    LFResourceDeleted.getItem(store).then(vals => {
        vals = vals || [];
        vals.push(id);
        LFResourceDeleted.setItem(store, vals);
    });

    LFResources.getItem(store).then(vals => {
        vals.all = vals.all.filter(d => d.id !== id);
        LFResources.setItem(store, vals);
        cb();
    });
};


export const updateInStoreAndCacheSiteConsumption = (obj, cb) => {
    const LFResources = localforage.createInstance({ name: 'optim_resources'});
    const LFResourceUpdated = localforage.createInstance({ name: 'optim_resources_updated'});

    LFResourceUpdated.getItem('siteConsumptions').then(vals => {
        vals = vals || [];
        vals.push(obj);
        LFResourceUpdated.setItem('siteConsumptions', vals.filter((v, i, a) => a.indexOf(v) === i));
    });

    LFResources.getItem('sites').then(vals => {
        const newVals = vals.list.map(val => {
            if(val.id === obj.site_id) {
                val.consumptions = val.consumptions.map(c => {
                    if (c.id === obj.id) {
                        return {...c, ...obj};
                    }
                    return c;
                });
            }
            return val;
        });
        LFResources.setItem('sites', {...vals, list: newVals});
        cb(obj.id);
    });
};



export const addInStoreAndCacheSiteConsumption = (obj, cb) => {
    const LFResources = localforage.createInstance({ name: 'optim_resources'});

    LFResources.getItem('sites').then(vals => {
        const lastFakeId = vals.list.reduce((prevId, currSite) => {
            return Math.min(prevId, currSite.consumptions.reduce((prevCId, currC) => {
                return Math.min(prevCId, currC.id);
            }, prevId));
        }, 0);
        obj.id = lastFakeId - 1;
        const newValsList = vals.list.map(site => {
            if (site.id === obj.site_id) {
                site.consumptions.push(obj);
            }
            return site;
        });
        LFResources.setItem('sites', {...vals, list: newValsList});
        cb(obj.id);
    });
};

export const addInStoreAndCacheSupplyOrder = (obj, cb) => {
    const LFResources = localforage.createInstance({ name: 'optim_resources'});

    LFResources.getItem('sites').then(vals => {
        const lastFakeId = vals.list.reduce((prevId, currSite) => {
            return Math.min(prevId, currSite.supplies.reduce((prevCId, currC) => {
                return Math.min(prevCId, currC.id);
            }, prevId));
        }, 0);
        obj.id = lastFakeId - 1;
        const newValsList = vals.list.map(site => {
            if (site.id === obj.site_id) {
                site.supplies.push(obj);
            }
            return site;
        });
        LFResources.setItem('sites', {...vals, list: newValsList});
        cb(obj.id);
    });
};

export const updateInStoreAndCacheSupplyOrder = (obj, cb) => {
    const LFResources = localforage.createInstance({ name: 'optim_resources'});
    const LFResourceUpdated = localforage.createInstance({ name: 'optim_resources_updated'});

    LFResourceUpdated.getItem('supply_orders').then(vals => {
        vals = vals || [];
        vals.push(obj);
        LFResourceUpdated.setItem('supply_orders', vals.filter((v, i, a) => a.indexOf(v) === i));
    });

    LFResources.getItem('sites').then(vals => {
        const newVals = vals.list.map(val => {
            if(val.id === obj.site_id) {
                val.supplies = val.supplies.map(s => {
                    if (s.id === obj.id) {
                        return {...s, ...obj};
                    }
                    return s;
                });
            }
            return val;
        });
        LFResources.setItem('sites', {...vals, list: newVals});
        cb(obj.id);
    });
};

export const deleteStoreAndCacheSupplyOrder = (obj, cb) => {
    const LFResources = localforage.createInstance({ name: 'optim_resources'});
    const LFResourceDeleted = localforage.createInstance({ name: 'optim_resources_deleted'});

    LFResourceDeleted.getItem('supply_orders').then(vals => {
        vals = vals || [];
        vals.push(obj);
        LFResourceDeleted.setItem('supply_orders', vals);
    });

    LFResources.getItem('sites').then(sites => {
        LFResources.setItem('sites', {...sites, list: sites.list.map(s => {
            if (s.id === obj.site_id) {
                return {...s, supplies: s.supplies.filter(sup => sup.id !== obj.id)};
            }
            return s;
        })}).then(cb());
    });
};
