import React, { useEffect, useState } from 'react';
import { faDigging, faPalletAlt, faPaperclip, faPersonDolly, faTruckLoading, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { hasAccessToConsumption, hasAccessToDepositTicket, hasAccessToSitePlanning, hasAccessToSiteTicket, hasAccessToSiteValidation } from '../../../utils/access';
import { useDispatch, useSelector } from 'react-redux';

import DayNavigation from '../../events/components/DayNavigation';
import DropDownSite from './DropDownSite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footbar from './FootBar';
import SiteAttachment from '../../attachment/SiteAttachment';
import SiteConsumption from './SiteConsumption';
import SiteDepositTicket from '../../depositTickets/SiteDepositTicket';
import SitePlanning from './SitePlanning';
import SiteSiteTicket from '../../siteTickets/SiteSiteTicket';
import SiteValidation from './SiteValidation';
import moment from 'moment';
import { setSiteDayShown } from '../actions';
import { updateSite } from '../../app/updateApp';
import { useTranslation } from 'react-i18next';

const SiteActivity = () => {
    const { t } = useTranslation();
    const [module, setModule] = useState(hasAccessToSitePlanning() ? 'planning' : 'consumption');
    const [date, setDate] = React.useState(moment());
    const dispatch = useDispatch();

    const siteState = useSelector(state=> state.sites);
    const prestationState = useSelector(state => state.prestations);
    const site = siteState.list.find(s => s.id === siteState.selectedSite);
    const day = site?.validation_days.find(v => moment(v.date).isSame(moment(date), 'day'));
    // const siteTicketName = localStorage.getItem('site_ticket_label') || t('common:site_ticket');
    const siteTicketName = t('common:site_ticket');

    useEffect(function () {
        updateSite();
    }, []);

    useEffect(function() {
        dispatch(setSiteDayShown(date));
    }, [date]);

    return <div className="module-page">
        <div className="label">
            {t('app:selected_site')}
        </div>
        <DropDownSite/>
        <div style={{marginTop: '12px', marginBottom: '0px'}}>
            <DayNavigation
                goBefore={() => setDate(moment(date).clone().subtract(1, 'days'))}
                goNext={() => setDate(moment(date).clone().add(1, 'days'))}
                value={date}
                onChange={(d) => setDate(moment(d.value))}
            />
        </div>
        <div className="type-selector">
            { hasAccessToSitePlanning() && <div
                onClick={() => setModule('planning')}
                className={module === 'planning' ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faUsers} />
                { module === 'planning' && t('common:planning') }
            </div> }
            { hasAccessToConsumption() && <div
                onClick={() => setModule('consumption')}
                className={module === 'consumption' ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faPersonDolly} />
                { module === 'consumption' && t('common:consumption') }
            </div> }
            { hasAccessToDepositTicket() && <div
                onClick={() => setModule('deposit_ticket')}
                className={module === 'deposit_ticket' ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faTruckLoading} />
                { module === 'deposit_ticket' && t('common:deposit_ticket') }
            </div> }
            { hasAccessToSiteTicket() && <div
                onClick={() => setModule('site_ticket')}
                className={module === 'site_ticket' ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faPalletAlt} />
                { module === 'site_ticket' && siteTicketName }
            </div> }
            { hasAccessToSiteValidation() && prestationState.all.length > 0 && <div
                onClick={() => setModule('attachment')}
                className={module === 'attachment' ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faPaperclip}/>
                { module === 'attachment' && t('common:attachment') }
            </div> }
            { hasAccessToSiteValidation() && <div
                onClick={() => setModule('validation')}
                className={module === 'validation' ? 'selected' : ''}
            >
                <FontAwesomeIcon icon={faDigging} className={day?.valid ? 'valid' : ''}/>
                { module === 'validation' && t('common:production') }
            </div> }
        </div>
        { module === 'planning' && <SitePlanning date={date}/> }
        { module === 'consumption' && <SiteConsumption date={date}/> }
        { module === 'deposit_ticket' && <SiteDepositTicket date={date}/> }
        { module === 'site_ticket' && <SiteSiteTicket date={date}/> }
        { module === 'attachment' && <SiteAttachment date={date}/> }
        { module === 'validation' && <SiteValidation date={date}/> }
        <Footbar setModule={setModule} />
    </div>;
};

export default SiteActivity;
